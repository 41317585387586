import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class TranslatorService {
  private defaultLanguage: string = "spanish";

  private availablelangs = [
    { code: "spanish", text: "Español", image: "assets/menu-icons/mexico.png" },
    { code: "en", text: "English", image: "assets/menu-icons/usa.png" },
  ];

  constructor(public translateService: TranslateService) {
    if (!translateService.getDefaultLang())
      translateService.setDefaultLang(this.defaultLanguage);

    this.useLanguage();
  }

  useLanguage(lang?: string) {
    this.translateService.use(lang || this.translateService.getDefaultLang());
  }

  getAvailableLanguages() {
    return this.availablelangs;
  }

  /**
   * Gets the instant translated value of a key (or an array of keys).
   * @param key
   * @param interpolateParams
   * @returns {string|any}
   */
  public translate(key: string | string[], interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams) as string;
  }
}
