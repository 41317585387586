import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class GatewayService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  postGateway(obj) {
    return this.http
      .post(`${Uris.GATEWAY}`, obj, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Gateway ${resp.name} creado correctamente`
          );
          return resp;
        })
      );
  }

  patchGateway(obj, id) {
    return this.http
      .put(`${Uris.GATEWAY}${id}/`, obj, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Gateway ${resp.name} editado correctamente`
          );
          return resp;
        })
      );
  }

  deleteGateway(id) {
    return this.http
      .delete(`${Uris.GATEWAY}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Gateway eliminado correctamente`
          );
          return resp;
        })
      );
  }

  getLinkedGateways(query) {
    return this.http
      .get(`${Uris.GATEWAY}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getGateway(id) {
    return this.http
      .get(`${Uris.GATEWAY}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getGateways(query) {
    return this.http
      .get(`${Uris.GATEWAY}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  sendFeedFrame(value) {
    return this.http
      .post(`${Uris.GATEWAYFEED}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentado correctamente`
          );
          return resp;
        })
      );
  }

  postManualFeed(value) {
    return this.http
      .post(`${Uris.GATEWAYFEEDMANUAL}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentación manual correctamente`
          );
          return resp;
        })
      );
  }
}
