import { ClientsService } from './../../routes/services/clients.service';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';

@Component({
  selector: "[app-footer]",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  socialMedia = [
    {
      name: "facebook",
      url: "https://www.facebook.com/OchoaTechnology",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/ochoatechnology/",
    },
    /* {
       name: "twitter",
       url: "https://google.com",
     },
      {
       name: 'linkedin',
       url: 'https://google.com',
     },
     {
       name: "youtube",
       url: "https://google.com",
     }, */
  ];

  email: string = '';
  user: any;

  constructor(public settings: SettingsService, public clientsService: ClientsService) { }


  ngOnInit() {
    this.user = <any>JSON.parse(localStorage.getItem("USER"));
  }
}
