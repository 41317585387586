import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LabelType } from "@angular-slider/ngx-slider";
import * as moment from 'moment';

/* https://angular-slider.github.io/ngx-slider/demos */

@Component({
  selector: "app-date-slider",
  templateUrl: "./date-slider.html",
  styleUrls: ["./date-slider.scss"],
})
export class DateSliderComponent implements OnInit {
  @Output("setDate") setDate = new EventEmitter();

  @Input() firstDate: any;
  @Input() secondDate: any;

  dateRange: Date[] = [];

  value: number = 0;
  highValue: number = 0;

  options: any;

  constructor() {}

  ngOnInit(): void {
    this.dateRange = this.createDateRange();
    this.value = this.dateRange[0].getTime();
    this.highValue = this.dateRange[this.dateRange.length - 1].getTime();

    this.options = {
      stepsArray: this.dateRange.map((date: Date) => {
        return { value: date.getTime() };
      }),
      translate: (value: number, label: LabelType): string => {
        moment.locale('es');
        const formattedDate = moment(value).format('DD/MMM/YYYY');
        return formattedDate;
      },
    };
  }

  createDateRange(): Date[] {
    const dates: Date[] = [];
    const startDate = new Date(this.firstDate);
    const endDate = new Date(this.secondDate);

    while (startDate <= endDate) {
      dates.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    return dates;
  }

  changeValue() {
    let highV = this.convertMillisecondsToDate(this.highValue);
    let lowV = this.convertMillisecondsToDate(this.value);

    this.setDate.emit({
      highV: highV,
      lowV: lowV,
    });
  }

  convertMillisecondsToDate(milliseconds: number): Date | any {
    const date = new Date(milliseconds);
    const isoString = date.toISOString();
    const truncatedISOString =
      isoString.substring(0, isoString.length - 5) + "Z"; // Elimina los últimos 5 caracteres que representan los milisegundos
    return truncatedISOString;
  }
}
