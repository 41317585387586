import { Component, Input, OnInit } from "@angular/core";
import moment from "moment";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress_bar.html",
  styleUrls: ["./progress_bar.scss"],
})
export class ProgressBarComponent implements OnInit {
  /* mes, dia y año */
  @Input("start_date") start_date = "05-08-2023";
  @Input("finish_date") finish_date = "05-31-2023";

  progress: any = {
    coord_max: 0,
    coord_min: 0,
  };
  days = 0;

  constructor() {}

  ngOnInit(): void {
    this.validateDays();
  }
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + "k";
    }

    return value;
  }
  validateDays() {
    var fecha1 = moment(this.start_date);
    var fecha2 = moment(this.finish_date);

    const days = fecha2.diff(fecha1, "days");
    this.days = days;
    this.progress.coord_max = 0;
    this.progress.coord_min = 0;

  }
  onclickProgress(event) {
    let percentage: any = Number(
      (event.offsetX / event.target.offsetWidth) * 100
    ).toFixed(2);

    const nuevoday = this.days * (percentage / 100);

    let mayor = Number((100 - percentage).toFixed(2));
    let menor = Number(percentage);

    if (this.progress.coord_min === 0 && this.progress.coord_max === 0) {
      this.progress.coord_max = mayor;
    }

    if (this.progress.coord_max > mayor) {
      this.progress.coord_max = mayor;
    }

    if (this.progress.coord_max < mayor) {
      this.progress.coord_min = menor;
    }
    const validateContent = () => {
      if (this.progress.coord_min === 0) {
        return percentage;
      }
      if (this.progress.coord_min > 0 && this.progress.coord_max > 0) {
        return (
          100 -
          (Number(this.progress.coord_min) + Number(this.progress.coord_min))
        );
      }
      return 0;
    };

    let contenido = validateContent();
  }
}
