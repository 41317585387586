import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loading-type-1",
  templateUrl: "./type-1.html",
  styleUrls: ["./type-1.scss"],
})
export class Type1ComponentComponent implements OnInit {
  @Input() view: boolean = true;
  @Input() seconds: number = 0;
  @Input() estimate: number = 0;
  @Input() percent: number = 0;
  constructor() {}

  ngOnInit(): void {}
}
