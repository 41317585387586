import { environment } from "../../../environments/environment";

export class Uris {
  public static API = environment.APIURL;
  public static API_ENDPOINT = environment.API_ENDPOINT;
  public static SOCKET = environment.SOCKET;
  public static FRONT = environment.FRONT;

  public static LOGIN = `${Uris.API_ENDPOINT}auth/login/`;
  public static LOGOUT = `${Uris.API_ENDPOINT}auth/logout/`;
  public static LOGOUTALL = `${Uris.API_ENDPOINT}auth_tokens/close_all/`;

  public static USERS = `${Uris.API_ENDPOINT}users/`;
  public static GROUPS = `${Uris.API_ENDPOINT}groups/`;

  public static CLIENTS = `${Uris.API_ENDPOINT}clients/`;
  public static REGION = `${Uris.API_ENDPOINT}region/`;
  public static CONFIG = `${Uris.API_ENDPOINT}config/`;


  public static FARM_CLIENTS = `${Uris.API_ENDPOINT}farms/client/`;
  public static FARM = `${Uris.API_ENDPOINT}farms/`;
  public static FEEDER = `${Uris.API_ENDPOINT}feeders/`;
  public static FEEDERSTATUS = `${Uris.API_ENDPOINT}feeders-status/`;

  public static FOODS = `${Uris.API_ENDPOINT}foods/`;
  public static FOODS_BRANDS = `${Uris.API_ENDPOINT}food-brands/`;

  public static FEEDER_BRAND = `${Uris.API_ENDPOINT}feeder-brands/`;
  public static DISPENSERS = `${Uris.API_ENDPOINT}dispensers/`;
  public static PONDS = `${Uris.API_ENDPOINT}ponds/`;
  public static TRAY_TEMPLATE = `${Uris.API_ENDPOINT}tray-template/`;
  public static TRAY_RECORD = `${Uris.API_ENDPOINT}tray-record/`;
  public static TRAY_CATALOG = `${Uris.API_ENDPOINT}tray-catalog/`;

  public static CHANGE_PASSWORD = `${Uris.API_ENDPOINT}users/change_password/`;
  public static CLIENT_CONTACTS = `${Uris.API_ENDPOINT}contact-client/`;

  public static PASSWORD_RESET = `${Uris.API_ENDPOINT}password_reset/send_request/`;
  public static PASSWORD_RESET_CONFIRM = `${Uris.API_ENDPOINT}password_reset/confirm_request/`;

  public static PRODUCTS = `${Uris.API_ENDPOINT}product/`;
  public static PLANTINGS = `${Uris.API_ENDPOINT}plantings/`;
  public static DIET = `${Uris.API_ENDPOINT}diets/`;
  public static GATEWAYFEED = `${Uris.API_ENDPOINT}gateway/feed/`;
  public static GATEWAYFEEDMANUAL = `${Uris.API_ENDPOINT}feeding-reports/`;

  public static GATEWAY = `${Uris.API_ENDPOINT}gateway/`;
  public static GATEWAYFRAMES = `${Uris.API_ENDPOINT}gateway/frames/feeders/`;
  public static GATEWAYFRAMESFEEDER = `${Uris.API_ENDPOINT}gateway/frames/feeder/`;
  public static GATEWAYFRAMESWATERWISE = `${Uris.API_ENDPOINT}gateway/frames/waterwise/`;
  public static GATEWAYFRAMESWATERWISESMASIVE = `${Uris.API_ENDPOINT}gateway/frames/waterwises/`;

  public static WEATHER = `${Uris.API_ENDPOINT}weather/climate/`;
  public static ALERTS = `${Uris.API_ENDPOINT}alert/`;
  public static REPORTS = `${Uris.API_ENDPOINT}reports/`;

  public static FEEDINGREPORTS = `${Uris.API_ENDPOINT}feeding-reports/`;
  public static HARVEST = `${Uris.API_ENDPOINT}harvests/`;
  public static SUBCLIENTS = `${Uris.API_ENDPOINT}subclients/`;

  public static PARAMETRIC = `${Uris.API_ENDPOINT}parametric-records/`;
  public static PARAMETRIC_EVENT = `${Uris.API_ENDPOINT}parametric-records-event/`;
  public static PARAMETRIC_PREFERENCE = `${Uris.API_ENDPOINT}parametric-preference/`;

  public static PARAMETRIC_ALERTS = `${Uris.API_ENDPOINT}parametric-alerts/`;
  public static BIOMETRICS = `${Uris.API_ENDPOINT}biometrics/`;
  public static PROJECTION = `${Uris.API_ENDPOINT}projection/`;

  public static WIZZARD = `${Uris.API_ENDPOINT}feeder-wizzard/`;

  public static DISCLAIMERS = `${Uris.API_ENDPOINT}disclaimers/`;
  public static FAQS = `${Uris.API_ENDPOINT}faqs/`;
  public static FAQCATEGORIES = `${Uris.API_ENDPOINT}faq-categories/`;
  public static SUGGESTIONS = `${Uris.API_ENDPOINT}suggestions/`;
  public static VERSIONING = `${Uris.API_ENDPOINT}versioning/`;
  public static CONTACT = `${Uris.API_ENDPOINT}contact/send_email/`;

  public static EDUCATIONPOST = `${Uris.API_ENDPOINT}education-post/`;
  public static EDUCATIONATTACHMENTS = `${Uris.API_ENDPOINT}education-attachments/`;
  public static EDUCATIONMEDIA = `${Uris.API_ENDPOINT}education-media/`;
  public static EDUCATIONCATEGORIES = `${Uris.API_ENDPOINT}education-categories/`;
  public static EDUCATIONLABELS = `${Uris.API_ENDPOINT}education-label/`;

  public static BLOGPOST = `${Uris.API_ENDPOINT}blog-post/`;
  public static BLOGCATEGORIES = `${Uris.API_ENDPOINT}blog-categories/`;
  public static BLOGCOMMENTS = `${Uris.API_ENDPOINT}blog-comments/`;

  public static TASK = `${Uris.API_ENDPOINT}task/`;
  public static TASKTEMPLATES = `${Uris.API_ENDPOINT}task-templates/`;

  public static PREFERENCES = `${Uris.API_ENDPOINT}preferences/`;
  public static SEASONS = `${Uris.API_ENDPOINT}seasons/`;
  public static FIREBASE = `${Uris.API_ENDPOINT}firebase/`;

  public static BUGLOG = `${Uris.API_ENDPOINT}bug_log/`;

  public static FIREBASENOTIFICATION = `${Uris.API_ENDPOINT}firebase-notification/`;
  public static APK = `${Uris.API_ENDPOINT}apk/`;

  public static WATERWISE = `${Uris.API_ENDPOINT}waterwise/`;
  public static WATERWISE_LOG = `${Uris.API_ENDPOINT}waterwise/logs/`;

  public static CALIBRATION = `${Uris.API_ENDPOINT}calibration/`;

  public static DASHBOARD = `${Uris.API_ENDPOINT}dashboard/farm/`;
  public static NOTIFICATIONS = `${Uris.API_ENDPOINT}notifications/`;

  public static SENSORS_HISTORY = `${Uris.API_ENDPOINT}sensors-status-history/`;
  public static SENSORS_STATUS = `${Uris.API_ENDPOINT}sensors-status/`;
  public static HYDRO_SENSORS = `${Uris.API_ENDPOINT}hydro-sensors/`;

  public static MONITORING_REPORTS = `${Uris.API_ENDPOINT}monitoring-reports/`;
  public static MONITORING_ALERT_TYPES = `${Uris.API_ENDPOINT}monitoring-alert-types/`;

  public static PONDS_COMPARISON = `${Uris.API_ENDPOINT}pond-comparison-metric/`;
  public static PONDS_DATA_COMPARISON = `${Uris.API_ENDPOINT}ponds/pond_comparison/`;
  public static PONDS_DATA_COMPARISON_GRAPH = `${Uris.API_ENDPOINT}ponds/pond_comparison_graph/`;

}
