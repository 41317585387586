import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FramesService } from "../../../routes/services/frames.service";
import { GlobalFunction } from "../../../routes/services/globalFuntion";
import { StorageService } from "../../../routes/services/storage.service";

@Component({
  selector: "app-panel-alerts-list",
  templateUrl: "./alerts.html",
  styleUrls: ["../panel-alerts.scss"],
})
export class PanelAlertListComponent implements OnInit {
  @Input() list;
  @Input() reload;

  @Input("feeder") feeder;
  @Input("filters") filters = {
    name: "",
    statusFeeders: "",
    pondSelected: "",
  };
  @Input("time_zone") farm_time_zone;
  @Output("check") check = new EventEmitter();
  @Output("individual") individual = new EventEmitter();

  validateChecked = false;

  constructor(
    public framesService: FramesService,
    public globalFunction: GlobalFunction,
    public storageService: StorageService
  ) {}

  ngDoCheck(): void {
    this.validateChecked = this.list.some((item) => item.checked);
  }
  ngOnInit(): void {}

  validateListStatus = (current) => {
    const validateAlert = (alerts) => {
      const { statusFeeders } = this.filters;
      if (statusFeeders === "loading") {
        const alertsLoading = alerts.filter(({ data }) => {
          return !data;
        });
        return alertsLoading;
      } else if (statusFeeders === "done") {
        const alertDone = alerts.filter(({ data }) => {
          return data;
        });
        return alertDone;
      }
      return alerts;
    };

    this.list = current.map((feeder) => {
      return {
        ...feeder,
        alerts: validateAlert(feeder.alerts),
      };
    });
  };
  countAlerts(feeder) {
    return feeder.alerts.filter((item) => item.data).length;
  }
  checkFeeder(idx, feeder) {
    this.check.emit({ idx, feeder });
  }

  individualAction(value) {
    const { data, feeder } = value;
    const idFeeder = `?id_in=${feeder.id}`;

    this.framesService
      .Frame(data.alert === "monitor" ? "status" : data.alert, idFeeder)
      .subscribe((resp) => {
        this.individual.emit(value);
      });
  }
}
