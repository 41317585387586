import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FramesDeviceDinamicComponent } from "./frames_device_dinamic";
import { ViewDeviceComponent } from "./view_device/view_device";
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { IconBatteryModule } from "../icon_battery/icon_battery.module";
import { ImageColorModule } from "../image_color/image_color.module";
import { LoagingDeviceComponent } from "./loading_device/loading_device";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    IconBatteryModule,
    ImageColorModule,
    ReactiveFormsModule
  ],
  providers: [
    FramesDeviceDinamicComponent,
    ViewDeviceComponent,
    LoagingDeviceComponent,
  ],
  declarations: [
    FramesDeviceDinamicComponent,
    ViewDeviceComponent,
    LoagingDeviceComponent,
  ],
  exports: [
    FramesDeviceDinamicComponent,
    ViewDeviceComponent,
    LoagingDeviceComponent,
    IconBatteryModule,
  ],
  schemas: [],
})
export class FrameDeviceDinamicModule {}
