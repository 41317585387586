import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AgmMap } from "@agm/core";
import { Router } from "@angular/router";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { gateway, transparent,waterwise_icons } from "../../routes/services/icons";

@Component({
  selector: "app-map-dynamic",
  templateUrl: "./map.html",
  styleUrls: ["./map.scss"],
})
export class MapaComponent implements OnInit {
  @ViewChild(AgmMap) map: any;
  @Output() clickFeederMap = new EventEmitter();
  @Output() clickMap = new EventEmitter();
  @Output() clickMapPolygon = new EventEmitter();
  @Output() clickFeederActionAlert = new EventEmitter();
  @Output() clickFeederActionSensor = new EventEmitter();

  /* Granjas con coordenadas */
  @Input() farm: null | any = null;
  /* Estanques con alimentadores */
  @Input() ponds: null | any = null;
  /* Solo coordenadas de estanque */
  @Input() ponds_coordinates: null | any = null;
  /* Solo alimentadores */
  @Input() feeders: null | any = null;

  @Input() feeders_ordering: null | any[] = null;

  @Input() marker_coordinates: null | any = null;

  @Input() feeders_gateway: null | any = null;

  @Input() default_color = "";

  @Input() type_info_window:
    | "feeder"
    | "sensors"
    | "gateway"
    | "waterwise"
    | "feeder_alerts"
    | "feeder_sweep"
    | "feeder_ordering"
    | "feeders_gateway" = "feeder";
  @Input() marker = {
    lat: 0,
    lng: 0,
  };

  @Input() mode: "" | "simple" | "complete" = "";
  @Input() height = "100%";
  @Input() width = "100%";
  @Input() view_markers = true;

  @Input() restriction = true;

  @Input() go_view_map: null | { lat: number; lng: number } = null;

  @Input() icon_marker: "" | "gateway" | "waterwise" = "";
  @Input() list_waterwise = [];


  transparent = transparent;

  lat = 25.8284395;
  lng = -108.9410592;
  zoom: number = 15;

  validateIcons = false;
  mapRestriction: any = null;

  constructor(public Router: Router, public globalFunction: GlobalFunction) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["go_view_map"] && changes["go_view_map"].currentValue) {
      const { lat, lng } = changes["go_view_map"].currentValue;
      this.lat = Number(lat);
      this.lng = Number(lng);
      this.zoom = 15;
    }
    if (changes["farm"] && changes["farm"].currentValue) {
      this.set_restriccion(changes["farm"].currentValue.coordinates);
    }
    if (
      changes["ponds_coordinates"] &&
      changes["ponds_coordinates"].currentValue.length > 0
    ) {
      this.set_restriccion(changes["ponds_coordinates"].currentValue);
    }
  }

  ngOnInit(): void {
    if (this.restriction) {
      if (this.ponds_coordinates) {
        this.set_restriccion(this.ponds_coordinates);
      } else if (this.farm) {
        this.set_restriccion(this.farm.coordinates);
      } else if (this.ponds) {
        if (this.ponds.length === 1) {
          this.set_restriccion(this.ponds[0].coordinates);
        } else {
          let feeders: any = [];
          this.ponds.forEach((item) => {
            feeders = [...feeders, ...item.coordinates];
          });
          if (feeders.length > 0) {
            this.mapRestriction = {
              latLngBounds: this.globalFunction.getMapBounds(feeders),
              strictBounds: false,
            };
          }
        }
      } else if (this.marker_coordinates) {
        this.set_restriccion(this.marker_coordinates);
      } else {
      }
    } else {
      if (this.farm) {
        if (this.farm.coordinates && this.farm.coordinates.length > 0) {
          const { lat, lng } = this.globalFunction.centerPolygon(
            this.farm.coordinates
          );
          this.lat = lat;
          this.lng = lng;
          this.zoom = 15;
        }
      }
      this.mapRestriction = null;
    }
  }
  set_restriccion = (coordinates) => {
    this.mapRestriction = {
      latLngBounds: this.globalFunction.getMapBounds(coordinates),
      strictBounds: false,
    };
    if (coordinates && coordinates.length > 0) {
      const { lat, lng } = this.globalFunction.centerPolygon(coordinates);
      this.lat = lat;
      this.lng = lng;
    }
    this.zoom = 15;
  };

  onMapReady(map) {
    this.map = map;
  }
  changeZoom(zoom) {
    this.validateIcons = zoom >= 17;
  }

  validateColor(item, type) {
    const verde = "#4caf50";
    const gris = "#9b9b9b";
    const rojo = "#FF0000";
    const is_simple = this.mode === "simple";
    if (this.default_color && type === "line") {
      return this.default_color;
    }
    if (is_simple) {
      if (item.is_alerted && type === "inside") {
        return rojo;
      }
      return !item.is_planted ? gris : verde;
    } else {
      if (
        item.linked_feeders &&
        item.linked_feeders.length > 0 &&
        type === "inside"
      ) {
        let alerts = item.linked_feeders.some(
          (item) =>
            item.alert_battery ||
            item.alert_comm ||
            item.alert_panel ||
            item.alert_reset ||
            item.alert_dispenser ||
            item.alert_sprinkle ||
            item.alert_reset
        );
        if (alerts) return rojo;
      }
      return item.planting && !item.is_done ? verde : gris;
    }
  }
  validate_color_ponds(linked_feeders) {
    const verde = "#4caf50";
    const rojo = "#FF0000";

    if (linked_feeders && linked_feeders.length > 0) {
      let alerts = linked_feeders.some(
        (item) =>
          item.alert_battery ||
          item.alert_comm ||
          item.alert_panel ||
          item.alert_reset ||
          item.alert_dispenser ||
          item.alert_sprinkle ||
          item.alert_reset
      );
      if (alerts) return rojo;
    }
    return verde;
  }
  clickPolygon = async (event, value) => {
    this.clickMapPolygon.emit({ event, value });
  };
  centerPolygon(array) {
    return this.globalFunction.centerPolygon(array);
  }
  clickMarker(item) {
    this.clickFeederMap.emit(item);
  }
  mapClick(event) {
    this.clickMap.emit(event);
  }
  onClickFeederActionAlert(event) {
    this.clickFeederActionAlert.emit(event);
  }
  onClickFeederAction(event) {
    this.clickFeederActionSensor.emit(event);
  }
  dragMarket(event) {
    this.clickFeederMap.emit(event);
  }
  validateColordb(feeder) {
    const { status_power, status_db } = feeder;
    const colors = {
      green: "#008000",
      yellow: "#FFFF00",
      red: "#FF0000",
    };
    if (status_power) {
      if (status_db >= 0 && status_db <= 70) {
        return colors.green;
      }
      if (status_db > 70 && status_db < 90) {
        return colors.yellow;
      }
      return colors.red;
    }
    return colors.red;
  }
  validateIconMarker() {
    let icons = {
      gateway: gateway,
      waterwise: waterwise_icons.sano,
    };
    return icons[this.icon_marker] ? icons[this.icon_marker] : "";
  }
}
