import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loader-panel-alert",
  templateUrl: "./loader.html",
  styleUrls: ["../../panel-alerts.scss"],
})
export class LoaderPanelAlertComponent implements OnInit {
  @Input() data;

  constructor() {}

  ngOnInit(): void {}
}
