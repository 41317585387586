import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { TranslatorService } from "../../core/translator/translator.service";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
@Injectable({
  providedIn: "root",
})
export class GlobalFunction {
  constructor(
    public translatorService: TranslatorService,
    public toastr: ToastrService,
    public location: Location,
    public router: Router
  ) {}

  handleError(e) {
    this.animation("error", e.status, e.error.detail);
    e.error.message === "Invalid token" && this.router.navigateByUrl("/login");
    e.error.message === "Invalid token" ||
      (e.error.message === "Token inválido" &&
        this.router.navigateByUrl("/login"));
    return throwError(e);
  }

  validateCoordinates(value) {
    let array = value;
    if (
      array &&
      array.length > 2 &&
      array[0].lat !== array[array.length - 1].lat
    ) {
      let i = array[0];
      array.push(i);
    }
    return array;
  }

  insidePolygon = (x, y, polygon) => {
    const vertices = polygon.length;
    let j = vertices - 1;
    let inside = false;

    for (let i = 0; i < vertices; i += 1) {
      if (
        (polygon[i].lng < y && polygon[j].lng >= y) ||
        (polygon[j].lng < y && polygon[i].lng >= y)
      ) {
        if (
          polygon[i].lat +
            ((y - polygon[i].lng) / (polygon[j].lng - polygon[i].lng)) *
              (polygon[j].lat - polygon[i].lat) <
          x
        ) {
          inside = !inside;
        }
      }
      j = i;
    }

    return inside;
  };

  randomCoordinates = (polygon: any = []) => {
    let minLat = polygon[0].lat;
    let maxLat = polygon[0].lat;
    let minLng = polygon[0].lng;
    let maxLng = polygon[0].lng;

    for (const point of polygon) {
      if (point.lat < minLat) minLat = point.lat;
      if (point.lat > maxLat) maxLat = point.lat;
      if (point.lng < minLng) minLng = point.lng;
      if (point.lng > maxLng) maxLng = point.lng;
    }

    let randomCoordinate;
    do {
      randomCoordinate = {
        lat: minLat + (maxLat - minLat) * Math.random(),
        lng: minLng + (maxLng - minLng) * Math.random(),
      };
    } while (
      !this.insidePolygon(randomCoordinate.lat, randomCoordinate.lng, polygon)
    );

    return randomCoordinate;
  };

  config: any = { progressBar: true };

  animation(type: "success" | "error" | "warning", text, title) {
    this.toastr[type](title, text, this.config);
  }

  agregarCaracter = (cadena, caracter, pasos) => {
    let cadenaConCaracteres = "";
    const long = cadena ? cadena.length : 0;
    for (let i = 0; i < long; i += pasos) {
      if (i + pasos < long) {
        cadenaConCaracteres += cadena.substring(i, i + pasos) + caracter;
      } else {
        cadenaConCaracteres += cadena.substring(i, long);
      }
    }
    return cadenaConCaracteres;
  };

  optionSwalAlert = async (label) => {
    let bool = false;

    const { value } = await Swal.fire({
      title: `${this.translatorService.translate("general.warning")}`,
      text: label,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${this.translatorService.translate(
        "general.accept"
      )}`,
      cancelButtonText: `${this.translatorService.translate("general.cancel")}`,
    });

    if (value) {
      bool = true;
    } else {
      Swal.close();
    }
    return bool;
  };

  validateSoloAdmin() {
    let localUser: string | null = localStorage.getItem("USER");
    let user: any = localUser ? <any>JSON.parse(localUser) : "";

    if ((user && user.user_type === -1) || user.user_type === 0) {
      return true;
    }
    if ((user && user.user_type === 1) || user.user_type === 2) {
      return false;
    }
    return false;
  }

  validateViewplanting() {
    let localUser = localStorage.getItem("USER");
    let user: any = localUser ? <any>JSON.parse(localUser) : "";

    if (user && (user.user_type === 1 || user.user_type === 2)) {
      return true;
    } else {
      return false;
    }
  }

  validateContentPlanting(pond) {
    return pond.planting ? true : false;
  }

  validateSuccess(response) {
    this.animation("success", "Éxito", response);
  }

  getItemById = (array, id) => {
    let items = array.filter((item) => {
      return Number(item.id) === Number(id);
    });
    return items.length === 1 ? items[0] : items;
  };

  validateBack(value) {
    let array = value;
    if (array.length === 3) {
      array.pop();
      array.pop();
    } else if (array.length >= 4) {
      array.splice(array.length - 1, 1);
      array.pop();
      array.push(array[0]);
    } else if (array.length === 1) {
      array.pop();
      array.splice(array.length - 1, 1);
    } else if (array.length > 1) {
      array.pop();
    }
    return value;
  }

  validateRepeat(selectedFeeders, list, status) {
    let array = selectedFeeders;
    for (let i = 0; i < list.length; i++) {
      list[i] = { ...list[i], status: status };
      if (status) {
        array.push(list[i]);
      } else {
        for (let j = 0; j < selectedFeeders.length; j++) {
          if (selectedFeeders[j]) {
            if (selectedFeeders[j].id === list[i].id) {
              array.splice(j, 1);
            }
          }
        }
      }
    }
    let hash = {};
    let noRepat = array.filter((current) => {
      let exists = !hash[current.id];
      hash[current.id] = true;
      return exists;
    });
    return noRepat;
  }

  validateNoRepeatByPropiety(array, propiety) {
    let hash = {};
    let noRepat = array.filter((current) => {
      let exists = !hash[current[propiety]];
      hash[current[propiety]] = true;
      return exists;
    });
    return noRepat;
  }

  ChangeListValue = (listArray, item) => {
    for (let i = 0; i < listArray.length; i++) {
      if (Number(listArray[i].id) === Number(item.id)) {
        listArray[i] = item;
      }
    }
    return listArray;
  };

  public downloadFIle(file_data, filename, extension) {
    const source = `data:application/${extension};base64,${file_data}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${filename}.${extension}`;
    link.click();
  }

  order(array: any) {
    let newArray = array.sort((a: any, b: any) => {
      if (a.number === b.number) {
        return 0;
      }
      // nulls sort after anything else
      else if (a.number === null) {
        return 1;
      } else if (b.number === null) {
        return -1;
      } else {
        return a.number < b.number ? -1 : 1;
      }
    });
    return newArray;
  }

  validateNumMayor(array: any, propiety: string) {
    let number = array.length > 0 ? array[0][propiety] : 0;
    array.forEach((item: any) => {
      if (item[propiety]) {
        if (item[propiety] > number) {
          number = item[propiety];
        }
      }
    });
    return number + 1;
  }

  centerPolygon(coords) {
    let sumLat = 0;
    let sumLng = 0;
    let lat = 0;
    let lng = 0;
    if (coords && coords.length > 0) {
      for (let i = 0; i < coords.length; i++) {
        let point = coords[i];
        let x = point.lat;
        let y = point.lng;
        sumLat += x;
        sumLng += y;
      }

      lat = sumLat / coords.length;
      lng = sumLng / coords.length;
    }
    return { lat, lng };
  }

  convertDate(date) {
    return date
      ? `${moment(date, ["HH"]).format("YYYY-MM-DD")} ${moment(date).format(
          "h:mm a"
        )}`
      : "";
  }

  convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  setDataFeeders = (feeders, socket, section?: any) => {
    let feederChanged = [];
    let test: Array<{ type: string; value: any }> = [];
    const insertTimeZone = (date, time, element) => {
      const { farm } = element;
      if (farm) {
        let dateTime = new Date(`${date}T${time}+0000`);
        if (farm.timezone && date && time) {
          let timezone = momentTz.tz(dateTime, farm.timezone);

          return {
            date: timezone.format("YYYY-MM-DD"),
            time: timezone.format("hh:mm:ss"),
          };
        }
      }

      return { date, time };
    };

    let value_frames = {
      FEEDER_STATUS: "monitor",
      BATTERY: "battery",
      PANEL: "panel",
      RESET: "reset",
      EMPTIED: "empty",
      VERSION: "version",
      SIGNAL: "signal",
      FEEDING_OK: "refeed",
      ON: "turn_off",
      OFF: "turn_on",
    };

    if (socket.data) {
      let {
        mac,
        type,
        battery,
        dispenser,
        sprinkler,
        panel,
        date,
        power,
        version,
        db,
        time,
        emptied,
        spin_time,
        low_speed,
        high_speed,
        speed_enabled,
        orbital_enabled,
        speed,
        port,
        dispenser_alert,
        sprinkler_current,
        dispenser_current,
        feed_rate,
        state,
        frequencies,
        frequencies_mode,
        current_value,
        last_value,
        total_food,
        lf_block,
        hydro_sensor_state,
        fuzzy_logic_state,
        action,
        dispenser_alert_enabled,
        hours,
      } = socket.data;
      const validateType = frames.includes(type);

      /* Agregar el nuevo frame en el array de frames */

      const hasOwnPropertyInSocket = (propiety) =>
        socket.data.hasOwnProperty(propiety);

      if (mac && validateType) {
        const setValue = (element: any, type) => {
          const sensors = [
            "SENSOR",
            "SENSORSSTATUS",
            "SENSORSSTATE",
            "SETPOINTSENSORSOK",
            "SENSORWATER",
            "SENSORENVIRONMENT",
            "SENSORHUMIDITY",
          ];
          /* ESTO SE HIZO PORQUE CAMBIARON LOS FRAMES EN VARIOS Y SI LLEGA UNO SE REEDIRIGE AL QUE ESTABA ANTES */
          const dispenser_frames = ["ALERTDISPENSER"];
          const sprinkler_frames = ["ALERTSPRINKLER"];

          if (sensors.includes(type)) type = "SENSORS";
          if (dispenser_frames.includes(type)) type = "DISPENSER";
          if (sprinkler_frames.includes(type)) type = "SPRINKLER";

          switch (type) {
            case "RESET":
              test.push({
                type: "reset",
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                status_dispenser: null,
                status_sprinkler: null,
                status_dispenser_opening: null,
                gateway_error: [],
                alert_sprinkle: false,
                alert_dispenser: false,
                alert_comm: false,
                alert_dispenser_opening: false,
                condition: true,
              };
            case "FEEDER_STATUS":
              const validateTimeZone = insertTimeZone(date, time, element);

              let objFeederStatus: any = new Object({
                ...element,
                gateway_error: [],
                sensors: element.sensors ? element.sensors : {},
                alert_comm: false,
                condition: true,
                alert_panel: panel < 11.5,
                alert_battery: battery < 11.5,
              });
              if (section) {
                section.refeed &&
                  test.push({
                    type: "refeed",
                    value: {
                      feeder: objFeederStatus,
                      nuevo: { ...socket.data, status: "success" },
                    },
                  });
                section.monitor &&
                  test.push({
                    type: "monitor",
                    value: {
                      feeder: objFeederStatus,
                      nuevo: {
                        ...socket.data,
                        ...validateTimeZone,
                        status: "success",
                      },
                    },
                  });
              }
              let propietySensorsHydro = [
                {
                  label: "power",
                  value: hydro_sensor_state,
                  propiety: "hydro_sensor_state",
                },
              ];
              /* Objeto de sensores */
              let propietySensors = [
                "sensors_enabled",
                "set_point_enabled",
                "frequencies_mode",
              ];
              /* PROPIEDADES DE LA ALIMENTADORA */
              let propietySpeed = [
                "orbital_enabled",
                "speed_enabled",
                "status_battery",
              ];
              /* Propiedades que no son iguales al objeto de la alimentadora */
              let propietyOld = [
                {
                  label: "status_battery",
                  value: battery,
                  propiety: "battery",
                },
                {
                  label: "alert_dispenser",
                  value: dispenser,
                  propiety: "dispenser",
                },
                {
                  label: "alert_sprinkle",
                  value: sprinkler,
                  propiety: "sprinkler",
                },
                { label: "status_panel", value: panel, propiety: "panel" },
                { label: "status_date", value: date, propiety: "date" },
                { label: "status_time", value: time, propiety: "time" },
                { label: "status_power", value: power, propiety: "power" },

                { label: "status_port", value: port, propiety: "port" },
                {
                  label: "status_dispenser_alert",
                  value: dispenser_alert,
                  propiety: "dispenser_alert",
                },
                {
                  label: "status_lf_block",
                  value: fuzzy_logic_state,
                  propiety: fuzzy_logic_state,
                },
              ];
              propietySensorsHydro.forEach(({ value, label, propiety }) => {
                if (hasOwnPropertyInSocket(propiety)) {
                  objFeederStatus[label] = value;
                }
              });
              propietySensors.forEach((prop) => {
                if (hasOwnPropertyInSocket(prop)) {
                  objFeederStatus.sensors[prop] = socket.data[prop];
                }
              });
              propietySpeed.forEach((prop) => {
                if (hasOwnPropertyInSocket(prop)) {
                  objFeederStatus[prop] = socket.data[prop];
                }
              });

              propietyOld.forEach(({ value, label, propiety }) => {
                if (hasOwnPropertyInSocket(propiety)) {
                  objFeederStatus[label] = value;
                }
              });
              return objFeederStatus;
            case "SPRINKLER":
              let objSprinker = {};
              let sprinkler_alerts = element.sprinkler_alerts
                ? element.sprinkler_alerts
                : 0;
              if (element.port_updated) {
                sprinkler_alerts++;
              }
              if (socket.data.sprinkler >= 0) {
                objSprinker["status_sprinkler"] = sprinkler;
              }
              return {
                ...element,
                sprinkler_alerts,
                gateway_error: [],
                alert_sprinkle: true,
                alert_comm: false,
                condition: true,
                ...objSprinker,
              };
            case "FREQUENCIES_RESPONSE":
              return {
                ...element,
                alert_comm: false,
                sensors: {
                  ...element.sensors,
                  condition: true,
                  frequencies: frequencies ? frequencies : [],
                  frequencies_mode,
                },
              };
            case "VERSION":
              test.push({
                type: "version",
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                alert_comm: false,
                gateway_error: [],
                status_version: version,
                condition: true,
              };
            case "FEEDING_OK":
              return {
                ...element,
                gateway_error: [],
                status_ration: true,
                alert_comm: false,
                condition: true,
              };
            case "NETWORK_DISCOVERY":
              test.push({
                type: "signal",
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                alert_comm: false,
                gateway_error: [],
                status_db: db,
                condition: true,
              };
            case "FEED_NOT_OK":
              return {
                ...element,
                gateway_error: [],
                status_ration: false,
                condition: true,
              };
            case "DISPENSER":
              let objDispenser = {};
              let isOpening = socket.data.hasOwnProperty("is_opening");
              if (isOpening) {
                if (socket.data.dispenser_opening) {
                  objDispenser["status_dispenser_opening"] =
                    socket.data.dispenser_opening;
                }
                if (socket.data.dispenser) {
                  objDispenser["status_dispenser"] = dispenser;
                } else {
                  objDispenser["status_dispenser"] = null;
                }
                if (socket.data.is_opening) {
                  objDispenser["alert_dispenser_opening"] = true;
                  objDispenser["alert_dispenser"] = false;
                } else {
                  objDispenser["alert_dispenser"] = true;
                  objDispenser["alert_dispenser_opening"] = false;
                }
              }

              return {
                ...element,
                gateway_error: [],
                alert_comm: false,
                ...objDispenser,
                condition: true,
              };
            case "ALERT_RESET":
              return {
                ...element,
                gateway_error: [],
                alert_reset: true,
                alert_comm: false,
                condition: true,
              };
            case "PANEL":
              test.push({
                type: "panel",
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                gateway_error: [],
                alert_panel: panel < 11.5,
                status_panel: panel,
                alert_comm: false,
                condition: true,
              };
            case "ON":
              let objOn = {};
              if (socket.data.hasOwnProperty("emptied")) {
                test.push({
                  type: "empty",
                  value: {
                    feeder: element,
                    nuevo: { ...socket.data, status: "success" },
                  },
                });
                objOn = { status_emptied: emptied };
              }
              if (socket.data.hasOwnProperty("power")) {
                test.push({
                  type: "FEEDER_ON",
                  value: {
                    feeder: element,
                    nuevo: {
                      ...socket.data,
                      type: "FEEDER_ON",
                      status: "success",
                    },
                  },
                });
                objOn = { status_power: power };
              }
              if (socket.data.hasOwnProperty("spin_time")) {
                test.push({
                  type: "turn_on_spin_time",
                  value: {
                    feeder: element,
                    nuevo: {
                      ...socket.data,
                      type: "turn_on_spin_time",
                      status: "success",
                    },
                  },
                });
                objOn = { status_spin_time: spin_time };
              }
              if (socket.data.hasOwnProperty("orbital_enabled")) {
                objOn = {
                  status_high_speed: high_speed,
                  status_low_speed: low_speed,
                  status_speed_mode: orbital_enabled ? 2 : 0,
                  view_mode_speed: orbital_enabled ? "orbital" : "normal",
                };
              }
              if (socket.data.hasOwnProperty("speed_enabled")) {
                objOn = {
                  ...element,
                  status_speed_mode: speed_enabled ? 1 : 0,
                };
              }

              return {
                ...element,
                alert_comm: false,
                gateway_error: [],
                ...objOn,
                condition: true,
              };
            case "OFF":
              let objOff = {};
              if (socket.data.hasOwnProperty("emptied")) {
                test.push({
                  type: "OFF",
                  value: {
                    feeder: element,
                    nuevo: { ...socket.data, status: "success" },
                  },
                });
                objOff = {
                  alert_comm: false,
                  status_ration: false,
                  status_emptied: emptied,
                };
              }
              if (socket.data.hasOwnProperty("power")) {
                test.push({
                  type: "FEEDER_OFF",
                  value: {
                    feeder: element,
                    nuevo: {
                      ...socket.data,
                      type: "FEEDER_OFF",
                      status: "success",
                    },
                  },
                });
                objOff = {
                  status_power: power,
                };
              }
              if (socket.data.hasOwnProperty("spin_time")) {
                test.push({
                  type: "turn_off_spin_time",
                  value: {
                    feeder: element,
                    nuevo: {
                      ...socket.data,
                      type: "turn_off_spin_time",
                      status: "success",
                    },
                  },
                });
                objOff = { status_spin_time: spin_time };
              }
              if (socket.data.hasOwnProperty("speed_enabled")) {
                objOff = {
                  ...element,
                  status_speed_mode: speed_enabled ? 1 : 0,
                };
              }

              return {
                ...element,
                ...objOff,
                gateway_error: [],
                condition: true,
                status_ration: false,
              };
            case "BATTERY":
              test.push({
                type: "battery",
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                gateway_error: [],
                alert_battery: battery < 11.5,
                status_battery: battery,
                alert_comm: false,
                condition: true,
              };
            case "SIGNAL":
              test.push({
                type: "signal",
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                gateway_error: [],
                status_db: db,
                condition: true,
              };
            case "SPEED_CONFIG":
              return {
                ...element,
                alert_comm: false,
                status_speed_mode: speed_enabled ? 1 : 0,
                condition: true,
              };
            case "ORBITAL_SPEED":
              return {
                ...element,
                alert_comm: false,
                condition: true,
                status_high_speed: high_speed,
                status_low_speed: low_speed,
                status_speed_mode: orbital_enabled ? 2 : 0,
                view_mode_speed: orbital_enabled ? "orbital" : "normal",
              };
            case "SPEED_RESPONSE":
              const validateBooleanSpeed = (speed_enabled, orbital_enabled) => {
                if (speed_enabled) {
                  return 1;
                }
                if (orbital_enabled) {
                  return 2;
                }
                if (!speed_enabled && !orbital_enabled) {
                  return 0;
                }
              };
              const { status_speed_mode, status_version } = element;

              const validateMode =
                validateBooleanSpeed(speed_enabled, orbital_enabled) === 1 ||
                status_version === "NAS0.5"
                  ? "normal"
                  : "orbital";

              return {
                ...element,
                alert_comm: false,
                condition: true,
                view_mode_speed: validateMode,
                status_speed: speed,
                status_speed_mode: validateBooleanSpeed(
                  speed_enabled,
                  orbital_enabled
                ),
                status_high_speed: high_speed,
                status_low_speed: low_speed,
              };
            case "EMPTIED":
              test.push({
                type: "empty",
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                alert_comm: false,
                condition: true,
                status_emptied: emptied,
              };
            case "SENSORSENSIBILITY":
              if (
                hasOwnPropertyInSocket("dispenser") &&
                hasOwnPropertyInSocket("sprinkler")
              ) {
                test.push({
                  type: "CHANGE_SETTING_FRAME",
                  value: {
                    feeder: element,
                    nuevo: {
                      ...socket.data,
                      type: "CHANGE_SETTING_FRAME",
                      status: "success",
                    },
                  },
                });
              }
              return {
                ...element,
                status_dispenser_current: dispenser,
                status_sprinkler_current: sprinkler,
              };
            case "ANALOG_READING_RESPONSE":
              let objAnalog = { ...element };
              if (
                hasOwnPropertyInSocket("port") &&
                hasOwnPropertyInSocket("dispenser_alert")
              ) {
                test.push({
                  type: "ANALOG_READING",
                  value: {
                    feeder: element,
                    nuevo: {
                      ...socket.data,
                      type: "ANALOG_READING",
                      status: "success",
                    },
                  },
                });
                objAnalog.status_port = port;
                objAnalog.dispenser_alert = dispenser_alert;
              }

              const validateModeFrecuencies = (
                { frequencies_mode },
                socket
              ) => {
                const { first, second, third, fourth, fifth } = socket;
                if (frequencies_mode === 0) {
                  return null;
                }
                if (frequencies_mode === 1) {
                  return [];
                }
                if (frequencies_mode === 2) {
                  return [first, second, third];
                }
                if (frequencies_mode === 3) {
                  return [first, second, third, fourth, fifth];
                }
              };
              if (hasOwnPropertyInSocket("first")) {
                objAnalog.sensors.frequencies = validateModeFrecuencies(
                  objAnalog.sensors,
                  socket.data
                );
                test.push({
                  type: "ANALOG_READING",
                  value: {
                    feeder: { ...objAnalog },
                    nuevo: {
                      ...socket.data,
                      type: "ANALOG_READING",
                      frecuency_mode: true,
                      status: "success",
                    },
                  },
                });
              }
              return { ...objAnalog };
            case "CURRENT":
              test.push({
                type,
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                alert_comm: false,
                condition: true,
                sprinkler_current,
                dispenser_current,
              };
            case "FEED_RATE":
              test.push({
                type,
                value: {
                  feeder: element,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
              return {
                ...element,
                alert_comm: false,
                condition: true,
                feed_rate,
              };
            case "SENSORS":
              let temp: any = this.validateSensorsFrame(
                element,
                socket,
                "feeder"
              );
              temp.test.forEach((tempo) => {
                test.push(tempo);
              });

              return { ...temp.feeder };
            case "SETPOINTPOWER":
              let objFeederSetPoints: any = {};
              if (socket.data.hasOwnProperty("state")) {
                objFeederSetPoints = {
                  ...element,
                  sensors: {
                    ...element.sensors,
                    set_point_enabled: state,
                  },
                };
                test.push({
                  type: "SET_POINTS",
                  value: {
                    feeder: objFeederSetPoints,
                    nuevo: {
                      ...socket.data,
                      status: "success",
                      type: "SET_POINTS",
                    },
                  },
                });
                return objFeederSetPoints === null
                  ? { ...element }
                  : { ...objFeederSetPoints, condition: true };
              }
              if (!socket.data.hasOwnProperty("state")) {
                test.push({
                  type: "CONFIRM_POINTS",
                  value: {
                    feeder: element,
                    nuevo: {
                      ...socket.data,
                      status: "success",
                      type: "CONFIRM_POINTS",
                    },
                  },
                });
              }

              return { ...element, condition: true };
            case "SENSOR_FREQUENCIES_RESPONSE":
              test.push({
                type,
                value: {
                  feeder: element,
                  nuevo: {
                    ...socket.data,
                    status: "success",
                    type,
                  },
                },
              });

              return { ...element, alert_comm: false, condition: true };
            case "SENSOR_HYDRO_RESPONSE":
              let objHydro = {};
              const props = [
                "DO",
                "SAT",
                "PH",
                "EC",
                "TDS",
                "SAL",
                "SG",
                "ORP",
              ];
              props.forEach((hydro) => {
                if (Object.prototype.hasOwnProperty.call(socket.data, hydro)) {
                  objHydro[hydro] = socket.data[hydro];
                }
              });
              let hydro = element.sensor_hydro
                ? { ...element.sensor_hydro }
                : {};
              return {
                ...element,
                sensor_hydro: {
                  ...hydro,
                  ...objHydro,
                },
              };
            case "SENSOR_HYDRO_CONFIGURATION":
              return {
                ...element,
                sensor_hydro: {
                  ...element.sensor_hydro,
                  frequency: hours,
                },
              };
            case "WEIGHTSENSORDATA":
              return {
                ...element,
                weight_sensor: {
                  ...element.weight_sensor,
                  current_value,
                  last_value,
                  total_food,
                },
              };
            case "WEIGHTSENSORPOWER":
              return {
                ...element,
                weight_sensor: {
                  ...element.weight_sensor,
                  power: state,
                },
              };
            case "LFBLOCK":
              return {
                ...element,
                status_lf_block: lf_block,
              };
            case "SENSORHYDROSTATE":
              return {
                ...element,
                sensor_hydro: {
                  ...element.sensor_hydro,
                  power: state,
                },
              };
            case "ALERT_COMM":
              const { request } = socket.data;
              const validate_alert_comm = (request) => {
                if (element.alerts) {
                  const index = element.alerts.findIndex(
                    (item) => item.alert === value_frames[request.type]
                  );
                  /* { alert: prop, data: null, timeInitial: time } */

                  element.alerts[index] = {
                    ...element.alerts[index],
                    alert_comm: true,
                    alert: value_frames[request.type],
                  };
                  return {
                    alerts: element.alerts,
                  };
                } else {
                  return {
                    alerts: [],
                  };
                }
              };
              const validate_alert = request
                ? validate_alert_comm(request)
                : {};
              return {
                ...element,
                gateway_error: [],
                status_ration:
                  element.status_ration === null
                    ? false
                    : element.status_ration,
                condition: false,
                alert_comm: true,
                ...validate_alert,
              };
            case "DISPENSERALERTENABLED":
              test.push({
                type: "CHANGE_ALERT_DISPENSER",
                value: {
                  feeder: {
                    ...element,
                    status_dispenser_alert_enabled: dispenser_alert_enabled,
                  },
                  nuevo: {
                    ...socket.data,
                    type: "CHANGE_ALERT_DISPENSER",
                    status: "success",
                  },
                },
              });
              return {
                ...element,
                status_dispenser_alert_enabled: dispenser_alert_enabled,
              };
            case "SENSOR_HYDRO_CONFIGURATION_HOURLY":
              return {
                ...element,
                sensor_hydro: {
                  ...element.sensor_hydro,
                  frequency: [1],
                },
              };
            case "SENSOR_HYDRO_CONFIGURATION_3_HOURS":
              return {
                ...element,
                sensor_hydro: {
                  ...element.sensor_hydro,
                  frequency: [3, ...hours],
                },
              };
            case "SENSOR_HYDRO_CONFIGURATION_5_HOURS":
              return {
                ...element,
                sensor_hydro: {
                  ...element.sensor_hydro,
                  frequency: [5, ...hours],
                },
              };
            default:
              return {
                ...element,
                condition: true,
              };
          }
        };
        try {
          let feederIndex: number = -1;
          let listMac = Array.isArray(mac) ? mac : [mac];
          listMac.forEach((itemMac) => {
            feederIndex = feeders.findIndex((feed) => feed.mac === itemMac);

            if (feederIndex !== -1) {
              feeders[feederIndex] = setValue(feeders[feederIndex], type);
            }
          });
        } catch (error) {}
      }
      if (mac) {
        feederChanged = feeders.filter((item) => mac === item.mac);
      }
      return { feeders, test, feederChanged, socket };
    }
    return { feeders, test, feederChanged, socket };
  };

  setDataWaterwises = (waterwise, socket) => {
    let feederChanged = [];
    let test: Array<{ type: string; value: any }> = [];

    if (socket.data) {
      let { mac, type, battery, state, db, power, panel, lf_block } =
        socket.data;

      /* Agregar el nuevo frame en el array de frames */
      const validateType = frames.includes(type);

      if (mac && validateType) {
        const setValue = (element: any, type) => {
          const sensors = [
            "SENSOR",
            "SENSORSSTATUS",
            "SENSORSSTATE",
            "SETPOINTSENSORSOK",
            "SENSORWATER",
            "SENSORENVIRONMENT",
            "SENSORHUMIDITY",
          ];
          if (sensors.includes(type)) {
            type = "SENSORS";
          }
          switch (type) {
            case "SENSORS":
              let temp: any = this.validateSensorsFrame(
                element,
                socket,
                "waterwise"
              );
              temp.test.forEach((tempo) => {
                test.push(tempo);
              });
              return { ...temp.waterwise };
            case "SETPOINTPOWER":
              let objFeederSetPoints: any = {};
              if (socket.data.hasOwnProperty("state")) {
                objFeederSetPoints = {
                  ...element,
                  sensors: {
                    ...element.sensors,
                    set_point_enabled: state,
                  },
                };
                test.push({
                  type: "SET_POINTS",
                  value: {
                    waterwise: objFeederSetPoints,
                    nuevo: {
                      ...socket.data,
                      status: "success",
                      type: "SET_POINTS",
                    },
                  },
                });
                return objFeederSetPoints === null
                  ? { ...element }
                  : { ...objFeederSetPoints, condition: true };
              }
              if (!socket.data.hasOwnProperty("state")) {
                test.push({
                  type: "CONFIRM_POINTS",
                  value: {
                    waterwise: element,
                    nuevo: {
                      ...socket.data,
                      status: "success",
                      type: "CONFIRM_POINTS",
                    },
                  },
                });
              }

              return { ...element, condition: true };
            case "SENSOR_FREQUENCIES_RESPONSE":
              test.push({
                type,
                value: {
                  waterwise: element,
                  nuevo: {
                    ...socket.data,
                    status: "success",
                    type,
                  },
                },
              });

              return { ...element, alert_comm: false, condition: true };
            case "SENSOR_HYDRO_RESPONSE":
              let objHydro = {};
              const props = [
                "DO",
                "SAT",
                "PH",
                "EC",
                "TDS",
                "SAL",
                "SG",
                "ORP",
              ];
              props.forEach((hydro) => {
                if (Object.prototype.hasOwnProperty.call(socket.data, hydro)) {
                  objHydro[hydro] = socket.data[hydro];
                }
              });
              let hydro = element.sensor_hydro
                ? { ...element.sensor_hydro }
                : {};
              return {
                ...element,
                sensor_hydro: {
                  ...hydro,
                  ...objHydro,
                },
              };
            case "SENSOR_HYDRO_CONFIGURATION":
              let hydrofeeder = element.sensor_hydro
                ? { ...element.sensor_hydro }
                : {};
              return {
                ...element,
                sensor_hydro: {
                  ...hydrofeeder,
                  frequency: socket.data.args || [],
                },
              };

            case "BATTERY":
              return {
                ...element,
                gateway_error: [],
                alert_battery: battery < 11.5,
                battery,
                alert_comm: false,
                condition: true,
              };
            case "PANEL":
              return {
                ...element,
                gateway_error: [],
                alert_panel: panel < 11.5,
                panel,
                alert_comm: false,
                condition: true,
              };

            case "SIGNAL":
              return {
                ...element,
                gateway_error: [],
                status_db: db,
                alert_comm: false,
                condition: true,
              };
            case "ON":
              return {
                ...element,
                alert_comm: false,
                gateway_error: [],
                power,
                condition: true,
              };

            case "OFF":
              return {
                ...element,
                power,
                gateway_error: [],
                condition: true,
                status_ration: false,
              };
            case "LFBLOCK":
              return {
                ...element,
                fuzzy_logic: lf_block,
              };
            default:
              return element;
          }
        };

        try {
          let feederIndex: number = -1;
          let listMac = Array.isArray(mac) ? mac : [mac];
          listMac.forEach((itemMac) => {
            feederIndex = waterwise.findIndex((feed) => feed.mac === itemMac);

            if (feederIndex !== -1) {
              waterwise[feederIndex] = setValue(waterwise[feederIndex], type);
            }
          });
        } catch (error) {}

        feederChanged = waterwise.filter((item) => mac === item.mac);
      }
      return { waterwise, test, feederChanged };
    }
    return { waterwise, test, feederChanged };
  };
  setDataPonds = (ponds, socket, section?: any, testSection?) => {
    let test: Array<{ type: string; value: any }> = [];
    let error: Array<any> = [];
    let { type, mac, date, time } = socket.data;
    if (socket.data) {
      let feeder: any = {};
      let validateMac = Array.isArray(mac) ? mac : [mac];
      let countFeeders = 0;
      ponds.forEach((item) => {
        if (item.linked_feeders.length > 0) {
          countFeeders = countFeeders + item.linked_feeders.length;
          const condition = (feeder) => validateMac.indexOf(feeder.mac) !== -1;
          item.linked_feeders.some(condition) &&
            (feeder = item.linked_feeders.find(condition));
        }
      });

      try {
        switch (type) {
          case "RESET":
            test.push({
              type: "reset",
              value: {
                feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;

          case "FEEDER_STATUS":
            if (section) {
              if (section.monitor) {
                const validateTimeZone = (valueFeeder) => {
                  const { farm } = valueFeeder;
                  if (farm) {
                    let formatDate = `${date}T${time}`;
                    let item = momentTz.tz(formatDate, farm.timezone).utc();
                    let cal = item["_tzm"] / 60;
                    () => item.add(cal, "h");
                    if (farm.timezone && item) {
                      return item.format("YYYY-MM-DD hh:mm a");
                    }
                    return moment(formatDate).format("YYYY-MM-DD hh:mm a");
                  }
                };

                test.push({
                  type: "monitor",
                  value: {
                    feeder,
                    nuevo: {
                      ...socket.data,
                      time: validateTimeZone({ ...feeder }),
                      status: "success",
                    },
                  },
                });
              }
            }
            break;
          case "SPRINKLER":
            break;

          case "VERSION":
            test.push({
              type: "version",
              value: {
                feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;

          case "FEEDING_OK":
            test.push({
              type: "refeed",
              value: {
                feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;

          case "NETWORK_DISCOVERY":
            test.push({
              type: "signal",
              value: {
                feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;

          case "PANEL":
            test.push({
              type: "panel",
              value: {
                feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;

          case "ON":
            if (socket.data.hasOwnProperty("emptied")) {
              test.push({
                type: "empty",
                value: {
                  feeder,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
            }
            if (socket.data.hasOwnProperty("power")) {
              test.push({
                type: "FEEDER_ON",
                value: {
                  feeder,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
            }
            break;

          case "OFF":
            if (socket.data.hasOwnProperty("emptied")) {
              test.push({
                type: "OFF",
                value: {
                  feeder,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
            }
            if (socket.data.hasOwnProperty("power")) {
              test.push({
                type: "FEEDER_OFF",
                value: {
                  feeder,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
            }

            break;
          case "BATTERY":
            test.push({
              type: "battery",
              value: {
                feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;

          case "SIGNAL":
            test.push({
              type: "signal",
              value: {
                feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;
          case "EMPTIED":
            test.push({
              type: "empty",
              value: {
                feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;
          case "ANALOG_READING_RESPONSE":
            if (
              socket.data.hasOwnProperty("dispenser_sensor") ||
              socket.data.hasOwnProperty("sprinkler_sensor")
            ) {
              test.push({
                type: "CHANGE_SETTING_FRAME",
                value: {
                  feeder,
                  nuevo: {
                    ...socket.data,
                    type: "CHANGE_SETTING_FRAME",
                    status: "success",
                  },
                },
              });
            } else {
              test.push({
                type: "ANALOG_READING_RESPONSE",
                value: {
                  feeder,
                  nuevo: { ...socket.data, status: "success" },
                },
              });
            }
            break;
          case "CURRENT":
            test.push({
              type,
              value: {
                feeder: feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;
          case "FEED_RATE":
            test.push({
              type,
              value: {
                feeder: feeder,
                nuevo: { ...socket.data, status: "success" },
              },
            });
            break;
          case "SENSORS":
            let temp: any = this.validateSensorsFrame(feeder, socket, "feeder");
            temp.test.forEach((tempo) => {
              test.push(tempo);
            });
            break;
          case "SETPOINTS":
            let objFeederSetPoints = {};
            if (socket.data.hasOwnProperty("set_point_enabled")) {
              objFeederSetPoints = {
                ...feeder,
                sensors: {
                  ...feeder.sensors,
                  set_point_enabled: socket.data.set_point_enabled,
                },
              };
              test.push({
                type: "SET_POINTS",
                value: {
                  feeder: objFeederSetPoints,
                  nuevo: {
                    ...socket.data,
                    status: "success",
                    type: "SET_POINTS",
                  },
                },
              });
              break;
            }
            if (!socket.data.hasOwnProperty("set_point_enabled")) {
              test.push({
                type: "CONFIRM_POINTS",
                value: {
                  feeder,
                  nuevo: {
                    ...socket.data,
                    status: "success",
                    type: "CONFIRM_POINTS",
                  },
                },
              });
              break;
            }

          case "SENSOR_FREQUENCIES_RESPONSE":
            test.push({
              type: "SENSOR_FREQUENCIES_RESPONSE",
              value: {
                feeder,
                nuevo: {
                  ...socket.data,
                  status: "success",
                  type: "SENSOR_FREQUENCIES_RESPONSE",
                },
              },
            });
            break;
          case "ALERT_Communication":
            error.push(feeder);
            break;
          default:
            break;
        }
        return { test, error };
      } catch (error) {
        return { test, error };
      }
    }
  };

  validateSensorsFrame(element, socket, type: "feeder" | "waterwise") {
    const { sensor_enabled } = socket.data;
    let test: Array<any> = [];
    let objFeederSensors: any = null;
    let Allpropiety = [
      /* Humedad */
      "hum",
      "hum_max",
      "hum_max_time",
      "hum_min",
      "hum_min_time",
      /* Temperatura ambiente */
      "temp",
      "temp_max",
      "temp_max_time",
      "temp_min",
      "temp_min_time",
      /* Temperatura agua */
      "water_temp",
      "water_temp_max",
      "water_temp_max_time",
      "water_temp_min",
      "water_temp_min_time",
    ];

    let propietyMinimal = ["hum", "temp", "water_temp"];

    if (socket.data.hasOwnProperty("sensor_enabled")) {
      objFeederSensors = {
        ...element,
        sensors: {
          ...element.sensors,
          sensors_enabled: sensor_enabled,
        },
      };
      test.push({
        type: "SENSORS_ENABLED",
        value: {
          [type]: objFeederSensors,
          nuevo: { ...socket.data, status: "success", type: "SENSORS_ENABLED" },
        },
      });

      return {
        [type]: objFeederSensors === null ? element : objFeederSensors,
        test,
      };
    }

    const validateAllItems = () => {
      let bool = true;
      let tempSocket = { ...socket.data };
      let deletTempSocket = ["mac", "name", "type", "version"];
      deletTempSocket.forEach((element) => {
        delete tempSocket[element];
      });
      for (const key of Allpropiety) {
        if (!tempSocket.hasOwnProperty(key)) {
          bool = false;
        }
      }
      return bool;
    };
    const validateMinimalItems = () => {
      let tempSocket = { ...socket.data };
      let bool = true;
      for (const key of propietyMinimal) {
        if (!tempSocket.hasOwnProperty(key)) {
          bool = false;
        }
      }
      return bool;
    };

    if (validateAllItems()) {
      let tempSensors = {};
      Allpropiety.forEach((prop) => {
        tempSensors[prop] = socket.data[prop];
      });

      /*   water_temp_min, water_temp_max, temp_min, temp_max,
      hum_min, hum_max */
      objFeederSensors = {
        ...element,
        sensors: {
          ...element.sensors,
          ...tempSensors,
        },
      };
      test.push({
        type: "REQUEST_SENSORS_ALL_ITEMS",
        value: {
          [type]: objFeederSensors,
          nuevo: {
            ...socket.data,
            status: "success",
            type: "REQUEST_SENSORS_ALL_ITEMS",
          },
        },
      });

      return {
        [type]: objFeederSensors === null ? element : objFeederSensors,
        test,
      };
    }
    if (validateMinimalItems()) {
      let tempSensors = {};
      propietyMinimal.forEach((element) => {
        tempSensors[element] = socket.data[element];
      });
      objFeederSensors = {
        ...element,
        sensors: {
          ...element.sensors,
          ...tempSensors,
        },
      };

      return {
        [type]: objFeederSensors === null ? element : objFeederSensors,
        test,
      };
    }
  }

  detectBrowserName() {
    const getApp = () => {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "edge";
        case agent.indexOf("opr") > -1 && !!(<any>window).opr:
          return "opera";
        case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
          return "chrome";
        case agent.indexOf("trident") > -1:
          return "ie";
        case agent.indexOf("firefox") > -1:
          return "firefox";
        case agent.indexOf("safari") > -1:
          return "safari";
        default:
          return "other";
      }
    };
    const getSystem = () => {
      var ua = navigator.userAgent;
      let apps = ["windows", "linux", "mac"];

      let find = apps.find((app) => {
        let regex = new RegExp(app, "i");
        return regex.test(ua);
      });
      return find ? find : "device";
    };
    return `${getSystem()} ( ${getApp()} )`;
  }

  validateColorImage(
    feeder,
    type: "battery" | "panel" | "alert_sprinkle" | "alert_dispenser",
    success = "#27c24c",
    warning = "#ff902b",
    danger = "#ff0000",
    color_default = "#204349"
  ) {
    let {
      status_battery,
      alert_battery,
      status_panel,
      alert_panel,
      alert_sprinkle,
      sprinkler_alerts,
    } = feeder;
    if (type === "battery") {
      if (alert_battery) return danger;
      if (status_battery > 11.5 && status_battery <= 12.5) return warning;
      if (status_battery > 12.5) return success;
      if (status_battery <= 11.5) return danger;
    }
    if (type === "panel") {
      if (alert_panel) return danger;
      if (status_panel > 12 && status_panel <= 15) return warning;
      if (status_panel > 15) return success;
      if (status_panel <= 12) return danger;
    }
    if (type === "alert_sprinkle") {
      if (sprinkler_alerts > 0) return warning;
      if (alert_sprinkle) return danger;
      return color_default;
    }
    if (type === "alert_dispenser") {
      return feeder[type] ? danger : success;
    }

    return danger;
  }

  setQueryFilter(formValue: any) {
    let query_filter: any = localStorage.getItem("query_filter");
    let normaljson: any = JSON.parse(query_filter);
    let obj = {};
    if (query_filter) {
      for (const key in formValue) {
        if (Object.keys(normaljson).some((item) => item === key)) {
          if (key !== "name") {
            obj[key] = normaljson[key];
          } else {
            obj[key] = "";
          }
        } else {
          obj[key] = normaljson[key] ? normaljson[key] : "";
        }
      }

      return obj;
    } else {
      return formValue;
    }
  }

  includeArraySettings(name: String, local: string) {
    let localdata: string | null = localStorage.getItem(local);
    let array: any = localdata ? JSON.parse(localdata) : null;

    if (array) {
      const condition = array.some((item) => item === name);
      return condition ? false : true;
    }
    if (!localdata) return true;
  }

  buildHardcoreTable(dataGlobal) {
    const { index, data, columns } = dataGlobal;

    let columnsConvert = columns.map((item) => this.convertUnixToDate(item));

    let ponds = index.map((item) => (item[0] ? item[0] : item));
    let unicPonds = Array.from(new Set(ponds));
    let pondFeeders: any = unicPonds.map((item, idx) => {
      /* Feeders solo de un estanque  */
      let feeders = index
        .filter((pond) => pond[0] === item)
        .map((item) => item[1]);

      return {
        pond: item,
        feeders,
        columns: [],
      };
    });

    let dataFeeder: Array<any> = [];
    /*  estanques unicos   */
    unicPonds.forEach((pond) => {
      let feedr: Array<any> = index.filter((item) => pond === item[0]);
      dataFeeder.push(feedr.map((item) => item[1]));
    });

    let arrayColumn: any = [];
    /* Vamos a crear un array para cada pond  */

    dataFeeder.forEach((listFeeders, idx) => {
      /* listFeeders =  ['Estanque 2', 'B51393'] */
      let arr = listFeeders.map((feeder, idxFeeder) => {
        /* Sacamos el index  del alimentador*/
        let indexFeeder = index.findIndex((item) => item[1] === feeder);
        let newColumn = data[indexFeeder].map((item) =>
          item > 0 ? Number(item.toFixed(2)) : item
        );
        let sumAcum = newColumn.reduce((sum, item) => sum + item, 0);
        return [...newColumn, Number(sumAcum.toFixed(2))];
      });
      arrayColumn.push(arr);
    });

    let accum: Array<any> = [];
    /* ACUMULATED */

    arrayColumn.forEach((list, idxColumn) => {
      let arry: Array<any> = [];
      list.forEach((feeder, idx) => {
        feeder.forEach((feed, idxFeeder) => {
          let num = arry[idxFeeder] ? arry[idxFeeder] : 0;
          arry[idxFeeder] = (
            Number(num) + Number(arrayColumn[idxColumn][idx][idxFeeder])
          ).toFixed(2);
        });
      });

      accum.push(arry);
    });

    arrayColumn.forEach((item: any, idx) => {
      pondFeeders[idx] = {
        ...pondFeeders[idx],
        columns: item,
        accumulated: accum[idx],
      };
    });

    return { data: pondFeeders, dates: columnsConvert };
  }

  getPlantingsCycleList(plantings) {
    let arrayCycleSet = new Set<string>();

    plantings.forEach((planting) => {
      const dateObject = new Date(planting.date);
      let name = "";
      if (planting.season) {
        name = planting.season.name;
      }
      const year = dateObject.getFullYear().toString();
      const string = name + " " + year;

      arrayCycleSet.add(string);
    });

    return Array.from(arrayCycleSet);
  }

  formatCycleList(cycles) {
    let arrayCycleSet = new Set<string>();

    cycles.forEach((cycle) => {
      const string = cycle.season_name + " " + cycle.year;
      arrayCycleSet.add(string);
    });

    return Array.from(arrayCycleSet);
  }
  validateTextPopAlertSprinker(feeder) {
    const { status_sprinkler, alert_sprinkle, sprinkler_alerts } = feeder;
    if ((feeder && alert_sprinkle) || status_sprinkler || sprinkler_alerts) {
      return `${`${
        alert_sprinkle || status_sprinkler
          ? `Alerta de aspersor ${status_sprinkler ? status_sprinkler : ""}`
          : ""
      } \n
             ${
               sprinkler_alerts && this.validateSoloAdmin()
                 ? `Contador de alertas: ${sprinkler_alerts}`
                 : ""
             }`}`;
    } else {
      return "Activo";
    }
  }
  validateTextPopAlertDispenser(feeder) {
    const {
      status_dispenser_opening,
      alert_dispenser_opening,
      alert_dispenser,
      status_dispenser,
    } = feeder;
    if (feeder) {
      if (alert_dispenser) {
        return `${
          alert_dispenser
            ? `Alerta de atoron ${status_dispenser ? status_dispenser : ""} `
            : ""
        }`;
      }
      if (alert_dispenser_opening) {
        return `${
          alert_dispenser_opening
            ? `Alerta de apertura ${
                status_dispenser_opening ? status_dispenser_opening : ""
              }`
            : ""
        }`;
      }
      if (!alert_dispenser_opening && !alert_dispenser) {
        return "Activo";
      }
    }
  }

  validateTextAlertBatteryPanel(searchGraph) {
    const { resample_rule, value_rule } = searchGraph;
    const validateLimit = (min, max) => value_rule > min && value_rule <= max;
    if (resample_rule === "W")
      return validateLimit(0, 6)
        ? ""
        : "Este valor debe de ser mayor que 0 o menor igual que 6";

    if (resample_rule === "D")
      return validateLimit(0, 64)
        ? ""
        : "Este valor debe de ser mayor que 0 o menor igual que 64";

    if (resample_rule === "H" || resample_rule === "M")
      return validateLimit(0, 12)
        ? ""
        : "Este valor debe de ser mayor que 0 o menor igual que 12";
    if (resample_rule === "T")
      return validateLimit(0, 32)
        ? ""
        : "Este valor debe de ser mayor que 0 o menor igual que 32";
  }

  validateButtonSubmitBatteryPanel(searchData) {
    const { resample_rule, value_rule } = searchData;
    const validateLimit = (min, max) => value_rule > min && value_rule <= max;

    if (resample_rule === "M") return validateLimit(0, 12);
    if (resample_rule === "W") return validateLimit(0, 6);
    if (resample_rule === "D") return validateLimit(0, 64);
    if (resample_rule === "H") return validateLimit(0, 12);
    if (resample_rule === "T") return validateLimit(0, 32);
  }

  validateVersion = ({ status_version }: any, version: number) => {
    let convert = Number(status_version.substr(3, status_version.length));
    return convert >= version;
  };

  copyText(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
  validateAlertSensorWithFeeders = (linked_feeders) => {
    try {
      if (linked_feeders && linked_feeders.length > 0) {
        return linked_feeders.every((feeder) => {
          if (Object.prototype.hasOwnProperty.call(feeder, "sensors")) {
            const {
              alert_hum_max,
              alert_hum_min,
              alert_temp_max,
              alert_temp_min,
              alert_water_temp_max,
              alert_water_temp_min,
            } = feeder.sensors;
            return (
              alert_hum_max &&
              alert_hum_min &&
              alert_temp_max &&
              alert_temp_min &&
              alert_water_temp_max &&
              alert_water_temp_min
            );
          }
        });
      }
    } catch (error) {
      return false;
    }
    return false;
  };

  validateActiveSensorsWithFeeder = (linked_feeders) => {
    if (linked_feeders && Array.isArray(linked_feeders)) {
      return linked_feeders.some(
        (feeder) =>
          feeder.sensors &&
          feeder.sensors.sensors_enabled === true &&
          feeder.sensors.hum !== null &&
          feeder.sensors.temp !== null
      );
    }
    return false;
  };

  getMapBounds(polygon: any[]): {
    north: number;
    south: number;
    east: number;
    west: number;
  } {
    let north = -Infinity;
    let south = Infinity;
    let east = -Infinity;
    let west = Infinity;
    const offset = 0.001;
    if (polygon && polygon.length > 0) {
      polygon.forEach((point) => {
        if (point.lat > north) north = point.lat + offset;
        if (point.lat < south) south = point.lat - offset;
        if (point.lng > east) east = point.lng + offset;
        if (point.lng < west) west = point.lng - offset;
      });
    }

    return { north, south, east, west };
  }
  differentFields = (old, edit) => {
    if (Object.keys(old).length !== 0) {
      return Object.keys(old).some((key) => {
        if (Object.prototype.hasOwnProperty.call(edit, key)) {
          if (JSON.stringify(old[key]) !== JSON.stringify(edit[key])) {
            return true;
          }
        }
        return false;
      });
    }
    return true;
  };
  sortNameAdvance(arr) {
    return arr.sort((a, b) => {
      const a_num = a.name.match(/\d+/g);
      const b_num = b.name.match(/\d+/g);

      if (!a_num || !b_num) return true;
      return a_num[0] - b_num[0];
    });
  }
  convertUnixToDate(timestamp) {
    try {
      const date = new Date(timestamp);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    } catch (error) {
      return "n/a";
    }
  }
}

export const configModal = {
  backdrop: true,
  ignoreBackdropClick: false,
  keyboard: false,
  class: "modal-xl modal-config",
};

export const frames = [
  "RESET",
  "FEEDER_STATUS",
  "SPRINKLER",
  "VERSION",
  "FEEDING_OK",
  "NETWORK_DISCOVERY",
  "FEED_NOT_OK",
  "ALERTDISPENSER",
  "ALERTSPRINKLER",
  "DISPENSER",
  "ALERT_RESET",
  "ALERT_COMM",
  "ALERT_Communication",
  "PANEL",
  "ON",
  "OFF",
  "BATTERY",
  "SIGNAL",
  "SPEED_CONFIG",
  "ORBITAL_SPEED",
  "SPEED_RESPONSE",
  "EMPTIED",
  "ANALOG_READING_RESPONSE",
  "CURRENT",
  "FEED_RATE",
  "SETPOINTS",
  "SENSOR",
  "SENSOR_FREQUENCIES_RESPONSE",
  "SENSOR_HYDRO_RESPONSE",
  "SENSORSSTATUS",
  "SENSORSSTATE",
  "SETPOINTSENSORSOK",
  "SETPOINTPOWER",
  "SENSORWATER",
  "SENSORENVIRONMENT",
  "SENSORHUMIDITY",
  "WEIGHTSENSORDATA",
  "LFBLOCK",
  "SENSOR_HYDRO_ACTION",
  "DISPENSERALERTENABLED",
  "SENSORHYDROSTATE",
  "SENSORSENSIBILITY",
  "SENSOR_HYDRO_CONFIGURATION",
  "SENSOR_HYDRO_CONFIGURATION_HOURLY",
  "SENSOR_HYDRO_CONFIGURATION_3_HOURS",
  "SENSOR_HYDRO_CONFIGURATION_5_HOURS",
];
/* Agregar todas las rutas del admin y cliente para poder almacenarlas
al objeto de preferencias */
export const urlAdmin = [
  "/home",
  "/administrador",
  "/clientes",
  "/inicio-alimentadores",
  "/alimentadoras",
  "/warehouse-feeders",
  "/inicio-alimento",
  "/plantillas-de-charolas",
  "/alerts",
  "/mantenimiento",
  "/supervision",
  "/bugs",
  "historial-de-movimientos",
  "/notificaciones",
  "/apks",
  "/calibration",
  "/waterwise",
  "/wizzard-alimentadores",
  "/alimentadoras/ordering",
  "/blog",
  "/educacion",
  "/client-notifications",
  "/regiones",
];

export const urlClient = [
  "/dashboard",
  "/subClientes",
  "/granja",
  "/plantillas-de-charolas",
  "/estanque/configuracion",
  "/ciclos",
  "/dietas",
  "/alerts",
  "/reports",
  "/weather",
  "/alimentadoras",
  "/biometrias",
  "/feeding-analisis",
  "/tasks",
  "/bugs",
  "/historial-de-movimientos",
  "/sensores",
  "/waterwise",
  "/alimentadoras/ordering",
  "/permissions",
  "/granja/sweep-testing",
  "/comparacion-estanques",
  "/blog",
  "/educacion",
  "/device-waterwise",
  "/device",
  "/client-notifications",
  "/charolas",
];

export const ExampleTable = [
  {
    pla_id: 1,
    pla_pond_id: 1,
    pla_hectares: 8.0,
    pla_planting_date: "2020-03-25",
    pla_initial_weight: 0.5,
    pla_total_planting: 1254545.0,
    pla_planting_per_m2: 15.7,
    pla_larva_origin: "Aquapacific",
    bio_id: 1,
    bio_biometric_date: "2020-04-01T07:00:00",
    bio_week: 1.0,
    bio_days: 7.0,
    bio_survival: "0.98",
    bio_actual_population: 1229454.1,
    bio_actual_density_by_hec: 15.368176250000001,
    bio_real_density_by_hec_with_harvest: 15.368176250000001,
    bio_previous_weight: 0.5,
    bio_current_weight: 0.8,
    bio_weight_increment: 0.30000000000000004,
    bio_increment_avg_3_week: 0.30000000000000004,
    bio_ips: 0.8,
    bio_total_biomass: 983.5632800000002,
    bio_biomass_kg_div_hec: 122.94541000000002,
    bio_increment_bio_div_ha: 44.53634750000002,
    bio_actual_biomass: 983.5632800000002,
    fee_weekly_food: 650.0,
    fee_total_food_auto: 650.0,
    fee_total_food_manual: 0.0,
    fee_food_by_day_and_hec: 11.607142857142858,
    fee_accumulated_food: 650.0,
    fee_accumulated_food_by_hec: 81.25,
    fee_fcas: 1.8243525695500729,
    fee_fca: 0.6608624103982409,
    fee_bw: 0.09440891577117727,
    har_kg: 0.0,
    har_weight: 0.0,
    har_kg_per_hec: 0.0,
    har_org_m2_prec: 0.0,
    har_accumulated_kg: 0.0,
    har_accumulated_kg_per_hec: 0.0,
    har_org_m2_accumulated_m2: 0.0,
  },
  {
    pla_id: 1,
    pla_pond_id: 1,
    pla_hectares: 8.0,
    pla_planting_date: "2020-03-25",
    pla_initial_weight: 0.5,
    pla_total_planting: 1254545.0,
    pla_planting_per_m2: 15.7,
    pla_larva_origin: "Aquapacific",
    bio_id: 2,
    bio_biometric_date: "2020-04-08T06:00:00",
    bio_week: 2.0,
    bio_days: 14.0,
    bio_survival: "0.95",
    bio_actual_population: 1191817.75,
    bio_actual_density_by_hec: 14.897721875,
    bio_real_density_by_hec_with_harvest: 11.564388541666666,
    bio_previous_weight: 0.8,
    bio_current_weight: 1.5,
    bio_weight_increment: 0.7,
    bio_increment_avg_3_week: 0.5,
    bio_ips: 0.75,
    bio_total_biomass: 1787.726625,
    bio_biomass_kg_div_hec: 223.465828125,
    bio_increment_bio_div_ha: 500.520418125,
    bio_actual_biomass: 1387.726625,
    fee_weekly_food: 1850.0,
    fee_total_food_auto: 1700.0,
    fee_total_food_manual: 150.0,
    fee_food_by_day_and_hec: 33.035714285714285,
    fee_accumulated_food: 2500.0,
    fee_accumulated_food_by_hec: 312.5,
    fee_fcas: 0.4620191137582076,
    fee_fca: 1.3984241018953332,
    fee_bw: 0.14783340505750664,
    har_kg: 400.0,
    har_weight: 1.5,
    har_kg_per_hec: 50.0,
    har_org_m2_prec: 3.3333333333333335,
    har_accumulated_kg: 400.0,
    har_accumulated_kg_per_hec: 50.0,
    har_org_m2_accumulated_m2: 3.3333333333333335,
  },
  {
    pla_id: 1,
    pla_pond_id: 1,
    pla_hectares: 8.0,
    pla_planting_date: "2020-03-25",
    pla_initial_weight: 0.5,
    pla_total_planting: 1254545.0,
    pla_planting_per_m2: 15.7,
    pla_larva_origin: "Aquapacific",
    bio_id: 3,
    bio_biometric_date: "2020-04-15T06:00:00",
    bio_week: 3.0,
    bio_days: 21.0,
    bio_survival: "0.92",
    bio_actual_population: 1154181.4000000001,
    bio_actual_density_by_hec: 14.427267500000001,
    bio_real_density_by_hec_with_harvest: 8.093934166666667,
    bio_previous_weight: 1.5,
    bio_current_weight: 2.8,
    bio_weight_increment: 1.2999999999999998,
    bio_increment_avg_3_week: 0.7666666666666666,
    bio_ips: 0.9333333333333333,
    bio_total_biomass: 3231.7079200000003,
    bio_biomass_kg_div_hec: 403.96349000000004,
    bio_increment_bio_div_ha: 780.4976618750001,
    bio_actual_biomass: 1813.0412533333333,
    fee_weekly_food: 2950.0,
    fee_total_food_auto: 2950.0,
    fee_total_food_manual: 0.0,
    fee_food_by_day_and_hec: 52.67857142857143,
    fee_accumulated_food: 5450.0,
    fee_accumulated_food_by_hec: 681.25,
    fee_fcas: 0.4724549707351421,
    fee_fca: 1.9374049326656635,
    fee_bw: 0.13040428833945222,
    har_kg: 600.0,
    har_weight: 2.5,
    har_kg_per_hec: 75.0,
    har_org_m2_prec: 3.0,
    har_accumulated_kg: 1000.0,
    har_accumulated_kg_per_hec: 125.0,
    har_org_m2_accumulated_m2: 6.333333333333334,
  },
];
