import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GlobalFunction } from "../../routes/services/globalFuntion";

@Component({
  selector: "app-upload-files",
  templateUrl: "./upload_files.html",
  styleUrls: ["./upload_files.scss"],
})
export class UploadFilesComponent {
  @Input("title") title = "Arrastra y suelta el archivo aquí";
  files: any[] = [];
  @Input() files_limit = -1;
  @Output() upload_files = new EventEmitter();
  @Input() accept = "";
  constructor(public globalFunction: GlobalFunction) {}
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 25;
          }
        }, 200);
      }
    }, 1000);
  }

  prepareFilesList(files: Array<any>) {
    const validate_accepts = (file) => {
      const accepts = this.accept;
      return file.name.includes(accepts);
    };

    for (const item of files) {
      const validate_accept = validate_accepts(item);
      if (validate_accept || this.accept === "") {
        item.progress = 0;
        this.files.push(item);
      }
      this.uploadFilesSimulator(0);
      if (!validate_accept) {
        this.globalFunction.animation(
          "error",
          "Archivo no valido",
          `solo accepta con extensiones ${this.accept}`
        );
      }
    }
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  uploadFiles() {
    this.upload_files.emit({ files: this.files });
  }
}
