import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loading-type-2",
  templateUrl: "./type-2.html",
  styleUrls: ["./type-2.scss"],
})
export class Type2ComponentComponent implements OnInit {
  @Input() view: boolean = true;
  @Input() seconds: number = 0;
  @Input() estimate: number = 0;
  @Input() percent: number = 0;
  @Input() circle = {
    width:'30px',
    radius: 30,
    titleFontSize: 20,
    unitsFontSize: 20,
  };
  constructor() {}

  ngOnInit(): void {}
}
