import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../routes/services/auth.service";
@Injectable({
  providedIn: "root",
})
export class GuardGuard implements CanActivate {
  constructor(public Auth: AuthService, public router: Router) {}
  canActivate(): boolean {
    if (this.Auth.readToken()) {
      return true;
    } else {
      this.router.navigateByUrl("/login");
      return false;
    }
  }
}
