import { Component, OnInit } from "@angular/core";
import { StorageService } from "../routes/services/storage.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  constructor(public storageService: StorageService) {}
  ngOnInit() {
    if (this.storageService.localValue) {
      let getQuery = this.storageService.getQueryFilters({
        font_size: "",
      });
      
      if (getQuery && getQuery.font_size) {
        this.storageService.changeFont("default", getQuery.font_size);
      } else {
        this.storageService.changeFont("default");
      }
    }
  }
}
