import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { IndexedDBService } from "../../../routes/services/db/indexedDb.service";
import { name_db } from "../../../routes/services/db/constants";

@Component({
  selector: "app-loading-time",
  templateUrl: `./loading_time.html`,
  styleUrls: ["../loading.scss"],
})
export class LoadingTimeComponent implements OnInit {
  @Input() petition: boolean = true;
  @Input() url: string = "";
  @Input() type: 1 | 2 = 1;

  view: boolean = true;

  estimate:number = 0;
  percent:number = 0;

  seconds: number = 0;
  interval: any = null;

  circumference: number = 2 * Math.PI * 70;
  constructor(public indexedDBService: IndexedDBService) {}
  ngOnDestroy(): void {
    this.cancelTimer();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["petition"] && !changes["petition"].currentValue) {
      this.percent = 100;
      this.cancelTimer();
    }

    if (
      changes["url"] ||
      (changes["petition"] && changes["petition"].currentValue)
    ) {
      this.seconds = 0;
      this.view = true;
      this.TimerDetectEstimate();
    }
  }
  ngOnInit(): void {
    this.TimerDetectEstimate();
  }
  TimerDetectEstimate = async () => {
    if (this.url) {
      this.indexedDBService.getByIndex("urls", "url", this.url).subscribe(
        (resp: any) => {
          if (resp) {
            this.estimate = resp.seconds;
            this.startTimer();
          } else {
            this.estimate = 4;
            this.startTimer();
          }
        },
        (err) => {
          this.estimate = 4;
          this.startTimer();
        }
      );
    }
  };
  startTimer(): void {
    this.percent = 0;
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.seconds += 1;
        const calculate = (this.seconds / this.estimate) * 100;
        this.percent = Math.min(Math.max(calculate, 0), 100);
      }, 1000);
    }
  }

  cancelTimer(): void {
    if (this.interval) {
      this.view = false;
      clearInterval(this.interval);
      this.interval = null;
    }
  }
  getBackground(progress: number): string {
    const angle = (progress / 100) * 360;
    return `conic-gradient(#007bff ${angle}deg, #e6e6e6 ${angle}deg)`;
  }
}
