import { GatewayService } from "../../routes/services/gateway.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-gateways",
  templateUrl: "./gateways.component.html",
  styleUrls: ["./gateways.component.scss"],
})
export class GatewaysComponent implements OnInit {
  @Input() farm: any;

  gatewayForm: FormGroup;

  gateway: any;

  selectedGateway: any;
  editGatewayFlag = false;
  newGateway = false;

  constructor(
    public GlobalFunction: GlobalFunction,
    public gatewayService: GatewayService,
  ) {
    this.gatewayForm = new FormGroup({
      farm: new FormControl("", Validators.required),
      name: new FormControl("", Validators.required),
    });
  }

  ngOnInit(): void {
    this.gatewayForm.get("farm").setValue(this.farm.id);
    this.gateway = this.farm.gateway;
  }

  createGateway(value) {
    let obj = {
      name: value.name,
      farm: value.farm,
    };

    if (this.editGatewayFlag) {
      this.gatewayService
        .patchGateway(this.selectedGateway.id, obj)
        .subscribe((res) => {
          this.gateway = res;
          this.newGateway = false;
          this.editGatewayFlag = false;
          this.selectedGateway = null;
        });
    } else {
      this.gatewayService.postGateway(obj).subscribe((res) => {
        this.gateway = res;
        this.newGateway = false;
      });
    }
  }

  deleteGateway(gatewayID) {
    this.gatewayService.deleteGateway(gatewayID).subscribe((res) => {
      this.gateway = null;
    });
  }

  editGateway(gateway) {
    this.newGateway = true;
    this.editGatewayFlag = true;
    this.selectedGateway = gateway;

    this.gatewayForm.setValue({
      farm: gateway.farm,
      name: gateway.name,
    });
  }
}
