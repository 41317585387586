import { Injectable } from "@angular/core";
import introJs from "intro.js";
import { TranslatorService } from "../translator/translator.service";
import {
  alerts,
  alertSection,
  biometry,
  climate,
  farmMap,
  feeders,
  feedSection,
  foodResumen,
  harvestPond,
  multipleFeed,
  parametry,
  parametryAlertSection,
  preharvestPond,
} from "./help";

export interface Options {
  steps: String;
  nextLabel: String;
  prevLabel: String;
  skipLabel: String;
  doneLabel: number;
  hidePrev: String;
  hideNext: String;
  nextToDone: String;
  tooltipPosition: String;
  tooltipClass: String;
  highlightClass: String;
  exitOnEsc: boolean;
  exitOnOverlayClick: boolean;
  showStepNumbers: boolean;
  keyboardNavigation: boolean;
  showButtons: boolean;
  showBullets: boolean;
  showProgress: boolean;
  scrollToElement: boolean;
  scrollTo: boolean;
  scrollPadding: boolean;
  overlayOpacity: number;
  disableInteraction: boolean;
}
@Injectable({
  providedIn: "root",
})
export class HelpService {
  introJS = introJs();
  currentHelp: any = [];
  activeTutorial = false;

  public elements: any = {
    alerts: [alertSection],
    farmPonds: [
      farmMap,
      multipleFeed,
      harvestPond,
      preharvestPond,
      {
        label: "help.ponds.title",
        icon: "fas fa-layer-group",
        content: [
          {
            element: "#alerts",
            title: "Alertas",
            intro:
              "La cantidad de alimentadores que tienen alertas encendidas.",
            position: "bottom",
          },
          {
            element: "#ration",
            title: "Ración",
            intro: "Demuestra si el estanque tiene ración.",
            position: "bottom",
          },
          {
            element: "#trays",
            title: "Charolas",
            intro:
              "Botón para ver las charolas, configuraciones y hacer registros de charoleo.",
            position: "bottom",
          },
          {
            element: "#parametrics",
            title: "Parametrías",
            intro:
              "Ve todos las parametrías registradas, haz nuevas y cambia la configuración.",
            position: "bottom",
          },
          {
            element: "#power",
            title: "Power",
            intro: "Enciende o apaga todos los alimentadores del estanque.",
            position: "bottom",
          },
        ],
      },
    ],
    farmFeeders: [
      farmMap,
      feedSection,
      alertSection,
      {
        label: "help.pond-controls.title",
        icon: "fas fa-square",
        content: [
          {
            element: "#name",
            title: "Nombre",
            intro: "Nombre de estanque",
            position: "bottom",
          },
          {
            element: "#change",
            title: "Navegar",
            intro: "Cambiar de estanque",
            position: "bottom",
          },
          {
            element: "#link",
            title: "Vincular/Desvincular",
            intro: "Vincula o desvincula alimentadores de estanque.",
            position: "bottom",
          },
          {
            element: "#return",
            title: "Regresar",
            intro: "Pantalla anterior, dónde está la lista de estanques.",
            position: "bottom",
          },
          {
            element: "#power-pond",
            title: "Power",
            intro: "Encender y apagar los alimentadores del estanque.",
            position: "bottom",
          },
        ],
      },
    ],
    alertaParametrias: [parametryAlertSection],
    dashboard: [foodResumen, biometry, alerts, parametry, feeders, climate],
    diet: [
      {
        label: "help.create-sow.title",
        icon: "fas fa-square",
        content: [
          {
            element: "#diet-name",
            title: "Paso 1",
            intro: "Establece el nombre de la siembra.",
            position: "bottom",
          },
          {
            element: "#diet-farm",
            title: "Paso 2",
            intro: "Establece la granja a la cual pertenecerá esa dieta.",
            position: "bottom",
          },
          {
            element: "#diet-percentaje",
            title: "Paso 3",
            intro:
              "La dieta funciona las 24hrs se debe de establecer, de cada hora el porcentaje del alimento que se suministrará",
            position: "bottom",
          },
          {
            element: "#diet-cada",
            title: "Paso 4",
            intro:
              "También se debe de colocar cada cuandos minutos deberá soltar el alimento.",
            position: "bottom",
          },
          {
            element: "#diet-frec",
            title: "Paso 5",
            intro:
              "Esto indica la frecuencia que se estará soltando el alimento en los 60 minutos.",
            position: "bottom",
          },
          {
            element: "#diet-graph",
            title: "Paso 6",
            intro:
              "De una forma más visual verás en la gráfica como se está representando la dieta.",
            position: "bottom",
          },
        ],
      },
    ],
    sowing: [
      {
        label: "help.sow-controls.title",
        icon: "fas fa-square",
        content: [
          {
            element: "#sow-controls",
            title: "Opciones",
            intro: "Editar la siembra, precosecha o cosecha la siembra.",
            position: "bottom",
          },
        ],
      },
    ],
    trays: [
      {
        label: "help.registry-trays.title",
        icon: "fas fa-square",
        content: [
          {
            element: "#tray-template",
            title: "Paso inicial",
            intro:
              "Si aún no haz creado una plantilla de charoleo, será lo primero por hacer de lo contrario no podrás registrar una charola.",
            position: "bottom",
          },
          {
            element: "#tray-amount",
            title: "Paso 1",
            intro: "Establece la cantidad de charolas que tiene el estanque.",
            position: "bottom",
          },
          {
            element: "#tray-config",
            title: "Paso 2",
            intro: "Selecciona una configuración.",
            position: "bottom",
          },
          {
            element: "#tray-units",
            title: "Paso 3",
            intro: "Coloca la unidades por cada charola.",
            position: "bottom",
          },
          {
            element: "#tray-date",
            title: "Paso 4",
            intro: "Establece fecha y hora de cuando se realizó el charoleo.",
            position: "bottom",
          },
          {
            element: "#tray-finish",
            title: "Paso 4",
            intro: "Finaliza el proceso y registra.",
            position: "bottom",
          },
        ],
      },
    ],
    parametry: [
      {
        label: "help.registry-parametry.title",
        icon: "fas fa-wrench",
        content: [
          {
            element: "#parametry-registry",
            title: "Paso inicial",
            intro: "Abre el registro haciendo click en este botón.",
            position: "bottom",
          },
          {
            element: "#parametry-data",
            title: "Paso 1",
            intro:
              "Establece los valores de oxigeno, salinidad, temperatura y el evento.",
            position: "bottom",
          },
          {
            element: "#parametry-date",
            title: "Paso 2",
            intro: "Fecha del registro",
            position: "bottom",
          },
          {
            element: "#parametry-hour",
            title: "Paso 3",
            intro: "Hora de cuando se registró.",
            position: "bottom",
          },
          {
            element: "#parametry-finish",
            title: "Paso 4",
            intro: "Finaliza el registro dando click este botón",
            position: "bottom",
          },
        ],
      },
      {
        label: "help.config-parametry.title",
        icon: "fas fa-cogs",
        content: [
          {
            element: "#parametry-config",
            title: "Paso inicial",
            intro: "Abre la configuración haciendo click en este botón.",
            position: "bottom",
          },
          {
            element: "#parametry-configdata",
            title: "Paso 1",
            intro:
              "Establece los valores minimos y máximos de oxigeno, salinidad y temperatura, esto es para que cuando los registro pasen de estos valores se generen alarmas.",
            position: "bottom",
          },
          {
            element: "#parametry-configfinish",
            title: "Paso 2",
            intro:
              "Finaliza y guarda la configuración haciendo click en este botón.",
            position: "bottom",
          },
        ],
      },
      {
        label: "help.data-table.title",
        icon: "fas fa-table",
        content: [
          {
            element: "#parametry-1",
            title: "Minimo",
            intro: "El minimo de la configuración establecida.",
            position: "bottom",
          },
          {
            element: "#parametry-2",
            title: "Dato seteado",
            intro:
              "Este dato es el que se colocó, el color verde significa que está dentro del rango, el rojo sale del rango y amarillo es justo el valor minimo o máximo de la configuración.",
            position: "bottom",
          },
          {
            element: "#parametry-3",
            title: "Máximo",
            intro: "El máximo de la configuración establecida.",
            position: "bottom",
          },
        ],
      },
    ],
  };

  constructor(public translatorService: TranslatorService) {}

  runHelp(content, option: Options | {}) {
    this.activeTutorial = true;

    this.introJS
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        showProgress: true,
        steps: content,
        ...option,
      })
      .start();
  }

  setHelps(type: any) {
    if (type !== "") {
      this.introJS.setOptions(this.elements[type]);
      this.currentHelp = this.elements[type];
    } else {
      this.introJS.setOptions([]);
      this.currentHelp = [];
    }
  }

  runOther(content: any, finish: boolean) {
    if (finish) {
      this.activeTutorial = false;
    }

    this.introJS.exit();

    this.introJS.setOptions({
      exitOnOverlayClick: false,
      disableInteraction: false,
      showProgress: true,
      steps: content,
    });

    setTimeout(() => {
      this.introJS.start();
    }, 1000);
  }
}
