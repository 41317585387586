import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-minute-timer",
  templateUrl: "./minute_timer.html",
  styleUrls: ["./minute_timer.scss"],
})
export class MinuteTimerComponent implements OnInit, OnDestroy {
  @Input() minutes: number = 0;
  @Output("reset_timer") reset_timer = new EventEmitter();
  @Input() class = "";
  @Input() start = "";
  @Input() type: "increase" | "decrease" = "decrease";
  @Input() tooltip = ''
  seconds: number = 0;
  interval: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.start && changes.start.currentValue) {
      if (changes.start.currentValue) {
        this.startTimer();
      } else {
        this.resetTimer();
      }
    }
  }
  ngOnInit(): void {}

  startTimer() {
    this.interval = null;
    if (this.type === "decrease") {
      this.interval = setInterval(() => {
        if (this.minutes === 0 && this.seconds === 0) {
          this.resetTimer();
          // Puedes agregar una acción o sonido de alarma aquí cuando el temporizador llega a cero.
        } else if (this.seconds === 0) {
          this.minutes--;
          this.seconds = 59;
        } else {
          this.seconds--;
        }
      }, 1000); // Actualiza el temporizador cada segundo
    } else {
      this.interval = setInterval(() => {
        if (this.seconds < 59) {
          this.seconds++;
        } else {
          this.seconds = 0;
          this.minutes++;
        }
      }, 1000); // Incrementa el tiempo cada segundo
    }
  }

  resetTimer() {
    this.minutes = 1; // Cambia el número de minutos aquí
    this.seconds = 0;

    this.reset_timer.emit();
    clearInterval(this.interval);
  }

  ngOnDestroy() {
    this.resetTimer();
  }
}
