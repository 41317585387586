import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-qr",
  templateUrl: "./qr.html",
  styleUrls: ["./qr.scss"],
})
export class QrComponent implements OnInit {
  @Input() type: "url" | "canvas" | "img" = "url";
  @Input() value: string = "";
  constructor() {}

  ngOnInit(): void {}
}
