import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { Router } from "@angular/router";
import * as momentTz from "moment-timezone";
import { FramesService } from "../../../routes/services/frames.service";
import { GlobalFunction } from "../../../routes/services/globalFuntion";
import { NgbModal, NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { StorageService } from "../../../routes/services/storage.service";
import { sensors } from "../../../routes/Clients/device/constants";

@Component({
  selector: "app-sensors-value",
  templateUrl: "./sensors_value.html",
  styleUrls: ["./sensors_value.scss"],
  providers: [NgbPopoverConfig],
})
export class SensorsValueComponent implements OnInit {
  @Input() data;
  @Input() type;
  @Output("submit") submit = new EventEmitter();
  @ViewChild("modalconfigSensed") modalconfigSensed;
  @Input() enabled = false;

  loading_data: "done" | "loading" | "error" | "" = "";

  socketSubsciption: Subscription;

  sensors = sensors.filter(item => !item.is_hydro);
  constructor(
    public Router: Router,
    public framesService: FramesService,
    public globalFunction: GlobalFunction,
    public modalService: NgbModal,
    config: NgbPopoverConfig,
    public storageService: StorageService
  ) {
    config.container = "body";
    config.placement = "auto";
    config.popoverClass = "border-pop";
  }
  ngOnInit(): void {
    const frames = [
      "SENSOR",
      "SENSOR_HYDRO_RESPONSE",
      "ALERT_Communication",
      "ALERT_COMM",
    ];

    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (msg.data && frames.includes(msg.data.type)) {
          const set_value = () => {
            if (this.data) {
              let result = this.globalFunction.setDataFeeders([this.data], msg);
              const exist = result.feederChanged.find(
                (item: any) => item.id === this.data.id
              );
              if (exist) {
                this.loading_data = "done";
                if (
                  msg.data.type === "ALERT_Communication" ||
                  msg.data.type === "ALERT_COMM"
                ) {
                  this.loading_data = "error";
                }
              }
            }
          };
          if (
            msg.data.type === "SENSOR" &&
            !Object.prototype.hasOwnProperty.call(msg.data, "sensor_enabled")
          ) {
            set_value();
          }
          if (msg.data.type !== "SENSOR") {
            set_value();
          }
        }
      });
  }

  changeEnabledDisabled(value) {
    /* FUNCION PARA ACTIVAR Y DESACTIVAR SENSORES  */
    this.submit.emit({ type: "ENABLE_DISABLE_SENSORS", value });
  }
  validateError(resp) {
    if (resp && resp.errors) {
      this.globalFunction.animation("error", "Gateway desconectado", "Error");
    } else {
      this.globalFunction.animation(
        "success",
        `Se solicitó correctamente`,
        "Ok"
      );
    }
  }
  getSensorsAmbiental(type: "hydro" | "sensors") {
    const query = `?id_in=${this.data.id}`;

    this.framesService
      .Frame(
        type === "hydro" ? "sensor_hydro_request_status" : "request_sensors",
        query
      )
      .subscribe((resp) => {
        this.loading_data = "loading";
        this.validateError(resp);
      });
  }

  insertTimezone(prop) {
    if (this.type === "individual") {
      const { sensors, farm } = this.data;

      if (farm && farm.timezone && sensors) {
        // Utiliza el formato personalizado para analizar la fecha y hora
        const dateTime = momentTz(sensors[prop]).tz(farm.timezone);
        /* DD/MM/YYYY, :ss */
        const timeFormatted = dateTime.format("HH:mm");
        return timeFormatted;
      }
    }

    return "";
  }

  goSensors() {
    this.Router.navigateByUrl(`/granja/sensores/${1}/${this.data.id}`);
  }

  
  configModal = {
    backdrop: true,
    ignoreBackdropClick: false,
    keyboard: false,
    centered: true,
    size: "lg",
  };
  Modal = (template: TemplateRef<any>, type) => {
    switch (type) {
      case "show":
        this.modalService.open(template, this.configModal);
        break;
      case "hide":
        this.modalService.dismissAll(``);
        break;
      default:
    }
  };
  viewConfig() {
    this.Modal(this.modalconfigSensed, "show");
  }
  getTypeSensors(event) {
    const query = `?id_in=${this.data.id}`;

    if (event.type === "SENSORS") {
      this.framesService
        .FramePost("set_points", query, event.value)
        .subscribe((resp) => {
          this.validateError(resp);
        });
    }
    if (event.type === "SENSORS_SET_POINTS") {
      this.framesService
        .FramePost("set_points_state", query, {
          set_point_enabled: event.value,
        })
        .subscribe((resp) => {
          this.validateError(resp);
        });
    }
  }

  validateColorAlert(alert: "hum" | "water_temp" | "temp", value) {
    let obj = {
      warning: "#c29834",
      danger: "#cf2a27",
      succes: "#26af76",
    };

    if (this.data.sensors) {
      const sensors = this.data.sensors;
      const min = sensors[`alert_${alert}_min`];
      const max = sensors[`alert_${alert}_max`];
      const warning_value = Number(max - min) / 5;
      if (value && min && max) {
        const warning_min = min + warning_value;
        const warning_max = max - warning_value;
        if (value < min || value > max) {
          return obj.danger;
        }
        if (
          (value > min && value < warning_min) ||
          (value < max && value > warning_max)
        ) {
          return obj.warning;
        }
        if (value > min && value < max) {
          return obj.succes;
        }
      }
    }

    return obj.succes;
  }
  turOffOnAqualogger = (data) => {
    const { id, power } = data;
    const type = power ? "turn_off" : "turn_on";
    this.framesService.waterwiseFrames(id, type).subscribe((resp) => {});
  };
}
