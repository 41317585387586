import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { NgModule } from "@angular/core";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppComponent } from "./app.component";

import { CoreModule } from "./core/core.module";
import { LayoutModule } from "./layout/layout.module";
import { RoutesModule } from "./routes/routes.module";
import { ToastrModule } from "ngx-toastr";

import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { InterceptConection } from "./interceptors/conection.interceptor";
import { CancelInterceptor } from "./interceptors/http.interceptor";
import { NgxIndexedDBModule, DBConfig } from "ngx-indexed-db";
import { name_db, tables_db, version_db } from "./routes/services/db/constants";
import { TimingInterceptor } from "./interceptors/timing.interceptor";

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
const dbConfig: DBConfig = {
  name: name_db,
  version: version_db,
  objectStoresMeta: tables_db,
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    RoutesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptConection,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CancelInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
