import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";

export class InterceptConection implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!window.navigator.onLine) {
      const error = {
        status: 0,
        error: {
          network: "Verifica tu conexion de internet!",
        },
        statusText: "Check Connectivity!",
      };
      return throwError(new HttpErrorResponse(error));
    } else {
      return next.handle(req);
    }
  }
}
