import { Component, Input, OnInit } from "@angular/core";
import * as ExcelJS from "exceljs";
import moment from "moment";

@Component({
  selector: "app-view-excel",
  templateUrl: "./view-excel.html",
  styleUrls: ["./view-excel.scss"],
})
export class ViewExcelComponent implements OnInit {
  @Input() base64 = {
    data: "",
    name: "",
    extension: "",
  };
  rowData: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.viewFile();
  }

  viewFile = async () => {
    if (this.base64.data) {
      const file = await this.base64toFile(
        this.base64.data,
        this.base64.extension
      );
      await this.readExcelFile(file);
    }
  };

  base64toFile(base64String: string, filename: string): File {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    // Crea un objeto File a partir del Blob
    const file = new File([blob], filename, {
      type: "application/octet-stream",
    });
    return file;
  }
  async readExcelFile(file: any) {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);
    const format_date = (val) => {
      let obj = {
        "yyyy-mm-dd": "YYYY-MM-DD",
        "h:mm:ss": "HH:mm:ss",
      };

      return obj[val] ? obj[val] : "----";
    };

    workbook.eachSheet((sheet) => {
      const sheetData: any = [];
      sheet.eachRow((row) => {
        const rowData: any = [];

        row.eachCell({ includeEmpty: true }, (cell: any) => {
          if (cell.numFmt) {
            if (cell.numFmt === "yyyy-mm-dd" || cell.numFmt === "h:mm:ss") {
              const date = moment(cell.text)
                .utc()
                .format(format_date(cell.numFmt));
              rowData.push({ style: cell.style, text: date });
            }

            if (cell.numFmt === "#,##0.00") {
              const val = cell.value ? Number(cell.value).toFixed(2) : 0;
              rowData.push({ style: cell.style, text: val });
            }
          } else {
            rowData.push({ style: cell.style, text: cell.value });
          }

          // Usa cell.text para extraer el texto de la celda
        });

        sheetData.push(rowData);
      });
      this.rowData.push(...sheetData);
    });
  }

  validateFill(data, prop) {
    try {
      if (data && data.style && data.style.fill && data.style.fill[prop]) {
        const color = data.style.fill[prop].argb.slice(0, 6);
        if (color === "00ffff") {
          return "#fff";
        }
        if (color === "002854") {
          return "#c0cad1";
        }
        if(color === '002043'){
          return "#a2c6ec"
        }
        return "#" + color;
      }
    } catch (error) {}
    return "";
  }
  validateFont(data, prop) {
    try {
      if (data && data.style && data.style.font && data.style.font[prop]) {
        return data.style.font[prop];
      }
    } catch (error) {}
    return "";
  }
}
