import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "../../routes/services/auth.service";

@Component({
  selector: "app-models-3d",
  templateUrl: "./models3d.html",
  styleUrls: ["./models3d.scss"],
})
export class Models3dComponent implements OnInit {
  fileHtml;
  @Input() url = "";

  constructor(
    public authService: AuthService,
    public http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}
  ngAfterContentInit(): void {
    /*   this.http
    .get(`assets/models3d/feeder.html`, { responseType: "text" })
    .subscribe((res) => {
      this.fileHtml = this.sanitizer.bypassSecurityTrustHtml(res);
    }); */
  }
  ngOnInit(): void {}
}