import { StorageService } from "../../routes/services/storage.service";
import { ClientsService } from "../../routes/services/clients.service";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { FarmService } from "../../routes/services/farm.service";
import { Subscription } from "rxjs";
import moment from "moment";
import {
  EventEmitter,
  HostListener,
  Component,
  ViewChild,
  OnInit,
  Output,
  Input,
} from "@angular/core";

@Component({
  selector: "app-panel-alerts",
  templateUrl: "./panel-alerts.html",
  styleUrls: ["./panel-alerts.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class PanelAlertsComponent implements OnInit {
  @ViewChild("panelAlerts") public panelAlerts;
  @Output("closed") closed = new EventEmitter();

  @Input("type") type: "pond" | "feeder" = "feeder";
  /* LOS ESTATUS Y ALERTAS DE LIST SIEMPRE VAN A CAMBIAR */
  @Input() list: any = [];
  input_ponds: any = [];

  /* variable para saber cuando da click en alguna accion */
  action = false;
  TemporalList: any = [];

  modalRef: null | any = null;
  close = false;
  /* para saber cuando esta inicializado el componente y poder validar */
  initialValue = false;

  socketSubsciption: Subscription;
  /* Filtros */
  filters: filters = {
    name: "",
    statusFeeders: "",
    pondSelected: "",
  };
  timezone = "";

  reloadData = false;
  constructor(
    public globalFunction: GlobalFunction,
    public storageService: StorageService,
    public clientService: ClientsService,
    public farmService: FarmService,
    private modalService: NgbModal,
    config: NgbModalConfig,
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    config.size = "xl";
    config.centered = true;
    config.animation = false;
  }
  ngOnDestroy(): void {
    this.socketSubsciption.unsubscribe();
  }

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const myDiv: any = document.querySelector(`[id='panel-alerts']`);
    const validateClassSwall = () => {
      const views = [
        "swal2-header",
        "swal2-container",
        "swal2-cancel",
        "swal2-popup",
        "swal2-title",
        "swal2-html-container",
        "swal2-icon-content",
        "swal2-icon",
        "swal2-confirm",
        "swal2-actions",
      ];
      const some = views.some((item) => targetElement.classList.contains(item));
      return some;
    };

    if (
      this.initialValue &&
      targetElement.id !== "menu-alert-ponds" &&
      targetElement.id !== "check-feeder" &&
      this.modalRef !== null &&
      !validateClassSwall() &&
      myDiv &&
      !myDiv.contains(targetElement) &&
      !this.close
    ) {
      this.closeModal();
      event.stopPropagation();
    }
  }

  returnLoadings = (item, prop?, value?: "" | "loading" | "done" | "error") => {
    const validateAlert = () => {
      const time = moment().format("HH:mm:ss");
      let alerts = item.alerts;
      if (value && value === "loading") {
        if (alerts.length === 0) {
          return [{ alert: prop, data: null, timeInitial: time }];
        } else {
          /* Validacion para eliminar las alertas turn on cuando se haga la peticion contraria */
          const validateAlerts = () => {
            if (prop && prop === "turn_off") {
              let turn_on = alerts.findIndex(
                (item) => item.alert === "turn_on"
              );
              if (turn_on >= 0) alerts.splice(turn_on, 1);
            }
            if (prop && prop === "turn_on") {
              let turn_off = alerts.findIndex(
                (item) => item.alert === "turn_off"
              );
              if (turn_off >= 0) alerts.splice(turn_off, 1);
            }
            if (prop && prop === "turn_off_spin_time") {
              let turn_on_spin_time = alerts.findIndex(
                (item) => item.alert === "turn_on_spin_time"
              );
              if (turn_on_spin_time >= 0) alerts.splice(turn_on_spin_time, 1);
            }
            if (prop && prop === "turn_on_spin_time") {
              let turn_off_spin_time = alerts.findIndex(
                (item) => item.alert === "turn_off_spin_time"
              );
              if (turn_off_spin_time >= 0) alerts.splice(turn_off_spin_time, 1);
            }

            return alerts;
          };

          /* Funcion para saber si hay alertas  */

          let array = validateAlerts().filter((item) => item.alert === prop);
          if (array.length > 0) {
            return alerts.map((item) => {
              if (item.alert === prop) {
                return { alert: prop, data: null, timeInitial: time };
              } else {
                return { ...item };
              }
            });
          } else {
            return [...alerts, { alert: prop, data: null, timeInitial: time }];
          }
        }
      }

      return [];
    };
    let obj = prop
      ? {
          loadings: {
            ...item.loadings,
            [prop]: value,
          },
          alerts: validateAlert(),
        }
      : {
          loadings: {
            monitor: "",
            battery: "",
            panel: "",
            reset: "",
            empty: "",
            version: "",
            signal: "",
            refeed: "",
            turn_off: "",
            turn_on: "",
            turn_off_spin_time: "",
            turn_on_spin_time: "",
          },
          alerts: [] /* Todas las alertas se solicitaron */,
          condition: true /* Gateway desconectado */,
          collapse_feeders: false /* Acordeon de alimentadores */,
          checked: false /* Alimentadoras seleccionadas */,
        };
    return {
      ...item,
      ...obj,
    };
  };
  ngOnInit(): void {
    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (this.type === "feeder") {
          const feeders = this.globalFunction.setDataFeeders(this.list, msg);
          if (feeders.feederChanged.length > 0) {
            this.detectIndexFeeder(msg, feeders.feederChanged);
          }
        }
      });
    if (
      (this.storageService.localValue.farm_id &&
        this.storageService.localValue.mode) ||
      !this.globalFunction.validateSoloAdmin()
    ) {
      const { farm_id } = this.storageService.localValue;
      this.farmService
        .getFarm(farm_id, `?fields=timezone`)
        .subscribe((resp) => {
          this.timezone = resp.timezone;
        });
    }

    if (this.list) {
      if (this.type === "feeder") {
        this.TemporalList = this.list.map((item) =>
          this.returnLoadings(item, "", "")
        );
        this.list = this.list.map((item) => this.returnLoadings(item, "", ""));
      } else {
        this.input_ponds = this.list;
        this.TemporalList = this.list.map((item) => {
          return {
            ...item,
            linked_feeders: item.linked_feeders.map((feeder) => {
              return this.returnLoadings(feeder, "", "");
            }),
          };
        });
        this.list = this.list.map((item) => {
          return {
            ...item,
            linked_feeders: item.linked_feeders.map((feeder) => {
              return this.returnLoadings(feeder, "", "");
            }),
          };
        });
      }
    }
  }

  setManualFrames = () => {
    if (this.type === "feeder") {
      let frames = this.list.map((item, idx) => {
        return {
          type: "receive.frame",
          gateway: 114,
          data: {
            type: "ALERT_COMM",
            mac: item.mac,
            name: item.name,
            version: "NAS0.5",
            request: {
              id: 17618,
              name: "FeederStatusRequest",
              type: "FEEDERSTATUS",
            },
            expected: {
              frame_type: 144,
              header: "4d",
            },
            msg: "Failed after 5 attempts",
          },
        };
        /*   if (idx === 0) {
          return {
            type: "receive.frame",
            gateway: 71,
            data: {
              mac: item.mac,
              name: item.name,
              version: "NAS0.0",
              type: "ALERT_COMM",
            },
            original_raw_frame: "",
          };
        } else {
          return {
            type: "receive.frame",
            gateway: 114,
            data: {
              battery: item.status_battery,
              date: "0023-10-18",
              lat: 0,
              lng: 0,
              panel: item.status_panel,
              time: "16:55:00",
              dispenser: false,
              led: false,
              power: true,
              sprinkler: false,
              type: "FEEDER_STATUS",
              mac: item.mac,
              name: item.name,
              version: "NAS0.3",
            },
          };
        } */
      });
      frames.forEach((frame) => {
        const feeders = this.globalFunction.setDataFeeders(this.list, frame);

        if (feeders.feederChanged.length > 0) {
          this.detectIndexFeeder(frame, feeders.feederChanged);
        }
      });
    }
  };
  detectIndexFeeder(socket, feederChanged, pond?) {
    if (socket.data) {
      const { data } = socket;
      const isFeeder = this.type === "feeder";

      const validateSocket = (socketType, alert, index) => {
        const validateNameAlert = () => {
          if (data.type === "ON" || data.type === "OFF") {
            if (Object.prototype.hasOwnProperty.call(data, "power")) {
              return data.power ? "turn_on" : "turn_off";
            } else if (
              Object.prototype.hasOwnProperty.call(data, "spin_time")
            ) {
              return data.spin_time
                ? "turn_on_spin_time"
                : "turn_off_spin_time";
            }
          }
          return alert;
        };
        const isLoading = () => {
          if (isFeeder) {
            return this.list[index].loadings[validateNameAlert()] === "loading";
          } else if (pond >= 0) {
            return (
              this.list[pond].linked_feeders[index].loadings[
                validateNameAlert()
              ] === "loading"
            );
          }
          return false;
        };

        return data.type === socketType && isLoading();
      };
      const AlertFeeders = [
        {
          name: "monitor",
          socket: "FEEDER_STATUS",
        },
        {
          name: "battery",
          socket: "BATTERY",
        },
        {
          name: "panel",
          socket: "PANEL",
        },
        {
          name: "version",
          socket: "VERSION",
        },
        {
          name: "signal",
          socket: "SIGNAL",
        },
        {
          name: "reset",
          socket: "RESET",
        },
        {
          name: "turn_on",
          socket: "ON",
        },
        {
          name: "turn_off",
          socket: "OFF",
        },
        {
          name: "refeed",
          socket: "FEEDING_OK",
        },
      ];

      // Funcion para calcular el tiempo transcurridos
      const generateTime = (initial) => {
        const finish = moment().format("HH:mm:ss");
        const entryHour = moment(initial ? initial : "", "hh:mm:ss");
        const exitHour = moment(finish, "hh:mm:ss");
        const duration = moment.duration(exitHour.diff(entryHour));
        return `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
      };
      const validateIndexFeeder = (feeder) => {
        if (isFeeder) {
          return this.list.findIndex((item) => item.id === feeder.id);
        } else if (pond >= 0) {
          return this.list[pond].linked_feeders.findIndex(
            (item) => item.id === feeder.id
          );
        }
        return "";
      };
      const validateAlert = (index) => {
        if (isFeeder) {
          return this.list[index].alerts;
        } else if (pond >= 0) {
          return this.list[pond].linked_feeders[index].alerts;
        }
        return [];
      };

      // Alimentadores que se cambiaron
      if (data.type && feederChanged) {
        feederChanged.forEach((feeder) => {
          const index = validateIndexFeeder(feeder);

          if (index >= 0) {
            let alerts = validateAlert(index);
            // Validamos la alerta que llega del socket para saber si coincide con
            // la que tenemos activa
            const findAlert = AlertFeeders.find(({ socket, name }) => {
              return validateSocket(socket, name, index);
            });

            if (!feeder.condition) {
              alerts.forEach((alert) => {
                if (isFeeder) {
                  this.list[index].loadings[alert.alert] = "error";
                  this.list[index].alerts = this.list[index].alerts.map(
                    (item) => {
                      return !item.data
                        ? { ...item, error: true }
                        : { ...item };
                    }
                  );
                  this.list[index].condition = true;
                } else if (pond >= 0) {
                  this.list[pond].linked_feeders[index].loadings[alert.alert] =
                    "error";
                  this.list[pond].linked_feeders[index].alerts = this.list[
                    pond
                  ].linked_feeders[index].alerts.map((item) => {
                    return !item.data ? { ...item, error: true } : { ...item };
                  });
                  this.list[pond].linked_feeders[index].condition = true;
                }
              });
            } else {
              if (findAlert) {
                const indexAlert = alerts.findIndex(
                  (item) => item.alert === findAlert.name
                );
                if (data && data.type) {
                  if (indexAlert >= 0) {
                    const timeInitial = alerts[indexAlert]
                      ? alerts[indexAlert].timeInitial
                      : "";

                    if (data.type !== "ON" && data.type !== "OFF") {
                      let alert = {
                        ...alerts[indexAlert],
                        data: { ...data },
                        time: generateTime(timeInitial),
                      };
                      if (isFeeder && index >= 0) {
                        this.list[index].loadings[findAlert.name] = "done";
                        this.list[index].alerts[indexAlert] = alert;
                      } else if (pond >= 0) {
                        this.list[pond].linked_feeders[index].loadings[
                          findAlert.name
                        ] = "done";

                        this.list[pond].linked_feeders[index].alerts[
                          indexAlert
                        ] = alert;
                      }
                    }

                    if (data.type === "ON" || data.type === "OFF") {
                      // Validamos si en el objeto tiene la propiedad power para saber si encendio o apago
                      const hasPower = Object.prototype.hasOwnProperty.call(
                        data,
                        "power"
                      );

                      if (hasPower) {
                        const turn_on_off = data.power ? "turn_on" : "turn_off";
                        const timeInitial = alerts[indexAlert]
                          ? alerts[indexAlert].timeInitial
                          : "";
                        if (index >= 0) {
                          alerts[indexAlert] = {
                            data: { ...data },
                            alert: turn_on_off,
                            time: generateTime(timeInitial),
                          };
                        }
                        // Vamos a quitar las alertas de turn_off cuando se encienda
                        if (isFeeder) {
                          this.list[index].loadings[turn_on_off] = "done";
                          this.list[index].loadings[
                            data.power ? "turn_off" : "turn_on"
                          ] = "done";

                          this.list[index].alerts = alerts;
                        } else if (pond >= 0) {
                          this.list[pond].linked_feeders[index].loadings[
                            turn_on_off
                          ] = "done";
                          this.list[pond].linked_feeders[index].loadings[
                            data.power ? "turn_off" : "turn_on"
                          ] = "done";

                          this.list[pond].linked_feeders[index].alerts = alerts;
                        }
                      }
                      // validamos si existe spin_time para saber si el usuario esta activo o desacivo la rotacion
                      const hasSpinTime = Object.prototype.hasOwnProperty.call(
                        data,
                        "spin_time"
                      );
                      if (hasSpinTime) {
                        const turn_on_off_spin = data.spin_time
                          ? "turn_on_spin_time"
                          : "turn_off_spin_time";

                        const validate_find_spin_on_off = () => {
                          if (isFeeder) {
                            return alerts.findIndex(
                              (item) =>
                                item.alert === turn_on_off_spin &&
                                this.list[index].loadings[turn_on_off_spin] ===
                                  "loading"
                            );
                          } else if (pond >= 0) {
                            return alerts.findIndex(
                              (item) =>
                                item.alert === turn_on_off_spin &&
                                this.list[pond].linked_feeders[index].loadings[
                                  turn_on_off_spin
                                ] === "loading"
                            );
                          }
                        };

                        const find_spin_on_off = validate_find_spin_on_off();

                        if (index >= 0) {
                          alerts[find_spin_on_off] = {
                            data: { ...data },
                            alert: turn_on_off_spin,
                            time: generateTime(
                              alerts[find_spin_on_off].timeInitial
                            ),
                          };
                        }
                        if (isFeeder) {
                          this.list[index].loadings[
                            data.spin_time
                              ? "turn_on_spin_time"
                              : "turn_off_spin_time"
                          ] = "done";
                          this.list[index].alerts = alerts;
                        } else if (pond >= 0) {
                          this.list[pond].linked_feeders[index].loadings[
                            data.spin_time
                              ? "turn_on_spin_time"
                              : "turn_off_spin_time"
                          ] = "done";
                          this.list[pond].linked_feeders[index].alerts = alerts;
                        }
                      }
                    }
                  }
                }
              }

              const hasEmptied = Object.prototype.hasOwnProperty.call(
                data,
                "emptied"
              );

              if (hasEmptied) {
                if (index >= 0) {
                  const indexAlertEmptied = alerts.findIndex(
                    (item) => item.alert === "empty"
                  );

                  const timeInitial = alerts[indexAlertEmptied]
                    ? alerts[indexAlertEmptied].timeInitial
                    : "";
                  alerts[indexAlertEmptied] = {
                    data: { ...data },
                    alert: "empty",
                    time: generateTime(timeInitial),
                  };
                }
                // Vamos a quitar las alertas de turn_off cuando se encienda
                if (isFeeder) {
                  this.list[index].alerts = alerts;
                } else if (pond >= 0) {
                  this.list[pond].linked_feeders[index].alerts = alerts;
                }
              }
            }
            if (this.type === "feeder") {
              const indexTemp = this.TemporalList.findIndex(
                (val) => val.id == feeder.id
              );
              if (indexTemp >= 0) {
                this.TemporalList[indexTemp] = feeder;
              }
            } else {
              this.list.forEach((pond, idxPond) => {
                const indexTemp = pond.linked_feeders.findIndex(
                  (val) => val.id == feeder.id
                );
                if (indexTemp >= 0) {
                  this.list[idxPond].linked_feeders[indexTemp] = feeder;
                }
              });
            }
          }
          // Validacion para saber si llega una respuesta del socket
        });

        /* Si tenemos un filtro no deberia de filtrar de nuevo los alimentadores
          esto evita que se carguen de nuevo */
        if (isFeeder) {
          this.filterItems(false, this.TemporalList);
        } else {
          /*  const list_feeder = this.list[pond].linked_feeders; */
          let feeders: any = [];
          this.TemporalList.forEach((linked) => {
            feeders = [...feeders, ...linked.linked_feeders];
          });
          this.filterItems(false, feeders);
        }
      }
    }
  }
  exitFeederPond(event) {
    this.detectIndexFeeder(event.msg, event.feeders, event.pond_id);
  }
  ngAfterViewInit(): void {
    this.openModal();
  }

  openModal = async () => {
    const ref = await this.modalService.open(this.panelAlerts);
    await ref.shown.subscribe(() => {
      this.modalRef = ref;
      setTimeout(() => {
        this.initialValue = true;
      }, 100);
    });
  };

  closeModal = async () => {
    this.close = true;

    if (!this.globalFunction.validateSoloAdmin()) {
      let bool = await this.globalFunction.optionSwalAlert(
        "¿Seguro que quieres cerrar el panel de alerta?"
      );
      if (bool && this.close) {
        this.modalService.dismissAll();
        this.modalRef = null;
        this.closed.emit(false);
      } else {
        setTimeout(() => {
          this.close = false;
        }, 300);
      }
    } else {
      this.modalService.dismissAll();
      this.modalRef = null;

      this.closed.emit(false);
    }
  };

  submitMenu({ type, error }) {
    /* Validamos a los equipos que se les enviara la alerta */
    const validateErrors = (err) => {
      let objError = {
        condition: false,
        loadings: {
          monitor: "",
          battery: "",
          panel: "",
          reset: "",
          empty: "",
          version: "",
          signal: "",
          refeed: "",
          turn_off: "",
          turn_on: "",
          turn_off_spin_time: "",
          turn_on_spin_time: "",
        },
      };
      const validateErrorLoadingFeeders = (item) => {
        if (err.error) {
          /* Este error llega cunado se ejecuta el refeed la alerta se muestra en un alert */
          if (err.error.non_field_error) {
            return { ...item };
          } else {
            return {
              ...item,
              ...objError,
              alerts: item.alerts.filter((alert) => alert.data),
            };
          }
        } else if (err.errors[item.mac]) {
          /* AGREGAR VALIDACION PARA QUE EL BOTON SE PONGA EN ROJO */

          return {
            ...item,
            ...objError,
            alerts: item.alerts.filter((alert) => alert.data),
            loadings: { ...item.loadings, [type]: "error" },
          };
        } else {
          return { ...item, condition: true, text_condition: "" };
        }
      };
      if (this.type === "feeder") {
        this.list = this.list.map((item) => {
          return validateErrorLoadingFeeders(item);
        });
      } else {
        this.list.forEach((pond, idx) => {
          this.list[idx].linked_feeders = this.list[idx].linked_feeders.map(
            (feeder) => {
              return validateErrorLoadingFeeders(feeder);
            }
          );
        });
      }
    };

    /* Validar solo las alimentadoras que estan seleccionadas */

    const validateLoading = (feeder, def, type) => {
      /* VALIDACION PARA MOSTRAR LOADINGS SOLO A LAS QUE SE SELECCIONARON ENCENDER APAGAR */
      if (feeder && type === "turn_on") {
        return !feeder.status_power
          ? this.returnLoadings(feeder, type, "loading")
          : def;
      }
      if (feeder && type === "turn_off") {
        return feeder.status_power
          ? this.returnLoadings(feeder, type, "loading")
          : def;
      }
      if (feeder && type === "turn_on_spin_time") {
        return !feeder.status_spin_time
          ? this.returnLoadings(feeder, type, "loading")
          : def;
      }
      /* VALIDACION PARA MOSTRAR LOADINGS SOLO A LAS QUE SE SELECCIONARON ENCENDER ROTACION O APAGAR*/

      if (feeder && type === "turn_off_spin_time") {
        return feeder.status_spin_time
          ? this.returnLoadings(feeder, type, "loading")
          : def;
      }
      if (feeder && type) {
        return this.returnLoadings(feeder, type, "loading");
      }

      return def;
    };

    const setValueListLoadings = (array) => {
      if (this.type === "feeder") {
        return array.map((item) => {
          const temp = this.TemporalList.find((temp) => temp.id === item.id);
          return validateLoading(temp, item, type);
        });
      } else {
        return array.map((pond) => {
          const linked_feeders = pond.linked_feeders.map((feeder) => {
            const exist = this.TemporalList.some((temp) =>
              temp.linked_feeders.some((item) => item.id === feeder.id)
            );

            return exist
              ? this.returnLoadings(feeder, type, "loading")
              : { ...feeder };
          });
          return {
            ...pond,
            linked_feeders,
          };
        });
      }
    };

    if (this.type === "feeder") {
      const checked = this.list.filter((item: any) => item.checked);
      if (checked.length > 0) {
        this.list = this.list.map((item) => {
          const temp = this.TemporalList.find((temp) => temp.id === item.id);
          if (item.checked) {
            return temp ? this.returnLoadings(item, type, "loading") : item;
          } else {
            return item;
          }
        });
      } else {
        this.list = setValueListLoadings(this.list);
      }
    } else {
      let checked = 0;
      this.list.forEach((pond) => {
        checked =
          pond.linked_feeders.filter((item) => item.checked).length + checked;
      });
      if (checked > 0) {
        this.list.forEach((pond, pondIdx) => {
          pond.linked_feeders.forEach((feeder, idx) => {
            if (feeder.checked) {
              this.list[pondIdx].linked_feeders[idx] = this.returnLoadings(
                feeder,
                type,
                "loading"
              );
            }
          });
        });
      } else {
        this.list = setValueListLoadings([...this.list]);
      }
    }

    error && validateErrors(error);

    if (this.type === "feeder") {
      this.filterItems(false, this.TemporalList);
    } else {
      let feeders: any = [];
      this.TemporalList.forEach((pond) => {
        feeders = [...feeders, ...pond.linked_feeders];
      });
      this.filterItems(false, feeders);
      this.action = !this.action;
    }
  }
  checkFeeder = async ({ idx, feeder }) => {
    if (this.type === "feeder") {
      this.list[idx].checked = !this.list[idx].checked;
    } else {
      this.list.forEach((item, idx) => {
        let findFeeder = item.linked_feeders.findIndex(
          (feed) => feed.id === feeder.id
        );
        if (findFeeder >= 0) {
          let linked_feeders = this.list[idx].linked_feeders;
          linked_feeders[findFeeder].checked =
            !linked_feeders[findFeeder].checked;

          this.list[idx] = {
            ...this.list[idx],
            linked_feeders,
          };
        }
      });
    }
    this.filterItems();
  };

  validateFilter = (feeder) => {
    const { statusFeeders } = this.filters;
    let type = statusFeeders;

    const validateloadingDone = () => {
      let bool = false;
      const { loadings } = feeder;
      for (const key in loadings) {
        const element = loadings[key];
        element === type && (bool = true);
      }
      if (bool && feeder.condition && type === "done") {
        return bool && feeder.condition;
      }
      if (bool && feeder.condition && type === "loading") {
        return bool && feeder.condition;
      }
      if (bool && feeder.condition && type === "error") {
        return bool && feeder.condition;
      }
      return bool;
    };
    if (!type) return true;
    if (type) return validateloadingDone();
  };

  validateFeederWithFilter = (list, status, solo_selected = []) => {
    const { name, pondSelected } = this.filters;
    /* AGREGAR VALIDACION PARA MOSTRAR SOLO LAS ALERTAS 
    QUE ESTAN EN ESTATUS Considerar e el campo 
    data = null en el array de alerts  */

    /* En este punto los valores ya estan actualizados, cuando sea solo_selected
    necesitamos solo cambiar esos valores */

    const filter_selected = (feeder) => {
      if (solo_selected.some((item: any) => item.id === feeder.id)) {
        return true;
      }
      return false;
    };
    const filter_normal = (feeder) => {
      const validateName = name
        ? feeder.name.toLowerCase().indexOf(name.toLowerCase()) > -1
        : true;

      const validateStatus = status ? this.validateFilter(feeder) : true;
      return validateName && validateStatus;
    };
    if (this.type === "feeder") {
      return list.filter(
        solo_selected.length > 0 ? filter_selected : filter_normal
      );
    } else {
      const pond = list.filter((pond) => {
        const validatePond = pondSelected
          ? pond.id === Number(pondSelected)
          : true;
        return validatePond;
      });

      return pond
        .map((pond) => {
          const linked_feeders = pond.linked_feeders.filter(
            solo_selected.length > 0 ? filter_selected : filter_normal
          );
          if (linked_feeders.length > 0) {
            return {
              ...pond,
              linked_feeders,
            };
          } else {
            return false;
          }
        })
        .filter((item) => item);
    }
  };

  filterItems = async (status = true, solo_selected = []) => {
    /* DEBEMOS DE FILTRAR TODOS LOS ITEMS QUE ESTAN CONSIDERANDO SU ESTATUS
        ESTANQUE Y NOMBRE */
    let list = [...this.list];
    if (list.length > 0) {
      this.TemporalList = await this.validateFeederWithFilter(
        list,
        status,
        solo_selected
      );
      if (this.filters.statusFeeders && this.filters.pondSelected) {
        this.action = !this.action;
      }
    }

    this.reloadData = !this.reloadData;
  };
  individual(value) {
    const { data, feeder } = value;

    /* VALIDACION PARA MANDAR LOADINGS CUANDO ES UNA FUNCION INDIVIDUAL */
    if (this.type === "feeder") {
      const indexFeeder = this.list.findIndex((item) => item.id === feeder.id);
      if (indexFeeder >= 0) {
        this.list[indexFeeder].loadings[data.alert] = "loading";
        const indexAlert = this.list[indexFeeder].alerts.findIndex(
          (item) => item.alert === data.alert
        );
        if (indexAlert >= 0) {
          const time = moment().format("HH:mm:ss");
          this.list[indexFeeder].alerts[indexAlert] = {
            ...this.list[indexFeeder].alerts[indexAlert],
            timeInitial: time,
            data: null,
          };
        }
      }
    } else {
      this.list.forEach((pond, idx) => {
        const indexFeeder = pond.linked_feeders.findIndex(
          (item) => item.id === feeder.id
        );
        if (indexFeeder >= 0) {
          this.list[idx].linked_feeders[indexFeeder].loadings[data.alert] =
            "loading";
        }
        const indexAlert = this.list[idx].linked_feeders[
          indexFeeder
        ].alerts.findIndex((item) => item.alert === data.alert);
        if (indexAlert >= 0) {
          const time = moment().format("HH:mm:ss");
          this.list[idx].linked_feeders[indexFeeder].alerts[indexAlert] = {
            ...this.list[idx].linked_feeders[indexFeeder].alerts[indexAlert],
            timeInitial: time,
            data: null,
          };
        }
      });
      this.action = !this.action;
    }
    this.reloadData = !this.reloadData;
  }
  submitEmpty(val) {
    /* val.empty indica si esta vaciando o no*/

    this.close = val.empty ? val.empty : false;
    const exist_mac = (mac) => {
      if (val.error && val.error.errors) {
        return Object.prototype.hasOwnProperty.call(val.error.errors, mac);
      }
      return false;
    };
    const validate_error_text = (mac) => {
      if (val.error && val.error.errors) {
        /* Tenemos que validar si el alimentador esta encendido 
        ya que es obligatorio para ejecutar esta accion */
        if (Object.prototype.hasOwnProperty.call(val.error.errors, mac)) {
          const validate_power = val.feeders.some((item) => !item.status_power);

          if (validate_power) {
            return "Deberias de encender el equipo";
          }
          return val.error.errors[mac].toString();
        }
      }
      return "Error en el alimentador";
    };
    const time = moment().format("HH:mm:ss");

    if (this.type === "feeder") {
      this.list.forEach((feeder, idx) => {
        const feedIndex = val.feeders.some((feed) => feed.id === feeder.id);
        if (feedIndex) {
          if (exist_mac(feeder.mac)) {
            this.list[idx].condition = false;
            this.list[idx].text_condition = validate_error_text(feeder.mac);
          } else {
            this.list[idx].condition = true;
            this.list[idx].text_condition = "";
            const indexAlert = this.list[idx].alerts.findIndex(
              (item) => item.alert === val.type
            );
            if (indexAlert >= 0) {
              this.list[idx].alerts[indexAlert] = {
                ...this.list[idx].alerts[indexAlert],
                timeInitial: time,
                data: null,
              };
            } else {
              this.list[idx].alerts.push({
                alert: val.type,
                timeInitial: time,
                data: null,
              });
            }
          }
        }
      });
    } else {
      this.list.forEach((pond, idx) => {
        /* Solo las alimentadoras que existen en el array */
        pond.linked_feeders.forEach((feeder, idxFeeder) => {
          const feedIndex = val.feeders.some((feed) => feed.id === feeder.id);
          if (feedIndex) {
            if (exist_mac(feeder.mac)) {
              this.list[idx].linked_feeders[idxFeeder].condition = false;
              this.list[idx].linked_feeders[idxFeeder].text_condition =
                validate_error_text(feeder.mac);
            } else {
              this.list[idx].linked_feeders[idxFeeder].condition = true;
              this.list[idx].linked_feeders[idxFeeder].text_condition = "";
              const indexAlert = this.list[idx].linked_feeders[
                idxFeeder
              ].alerts.findIndex((item) => item.alert === val.type);
              if (indexAlert >= 0) {
                this.list[idx].linked_feeders[idxFeeder].alerts[indexAlert] = {
                  ...this.list[idx].linked_feeders[idxFeeder].alerts[
                    indexAlert
                  ],
                  timeInitial: time,
                  data: null,
                };
              } else {
                this.list[idx].linked_feeders[idxFeeder].alerts.push({
                  alert: val.type,
                  timeInitial: time,
                  data: null,
                });
              }
            }
          }
        });
      });
    }
    if (this.type === "feeder") {
      this.filterItems(false, this.TemporalList);
    } else {
      let feeders: any = [];
      this.TemporalList.forEach((pond) => {
        feeders = [...feeders, ...pond.linked_feeders];
      });
      this.filterItems(false, feeders);
      this.action = !this.action;
    }
  }
}

interface filters {
  name: String | "";
  statusFeeders: "error" | "done" | "loading" | "";
  pondSelected: string;
}
