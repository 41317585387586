import { Injectable } from "@angular/core";

@Injectable()
export class SettingsService {
  private user: any;
  private app: any;
  private layout: any = {
    isFixed: true,
    isCollapsed: false,
    isBoxed: false,
    offsidebarOpen: false,
    isCollapsedText: false,
    asideHover: false,
    theme: null,
    viewAnimation: "ng-fadeInUp",
  };
  public settingsPonds = {
    status: false,
    listSelected: [],
  };
  public SettingsPanel = {
    status: false,
    type: "",
  };
  public GatewayForm = {
    name: null,
    status: false,
    gateway: null,
    farm: null,
    coordinates: {
      id: "",
      lat: "",
      lng: "",
    },
  };
  public TesteoGateway = {
    status: false,
    farm: null,
  };

  constructor() {
    // App Settings
    // -----------------------------------
    this.app = {
      name: "NAS",
      year: new Date().getFullYear(),
    };
  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }
  getUserSetting(name) {
    return name ? this.user[name] : this.user;
  }
  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }

  setAppSetting(name, value) {
    if (typeof this.app[name] !== "undefined") {
      this.app[name] = value;
    }
  }
  setUserSetting(name, value) {
    if (typeof this.user[name] !== "undefined") {
      this.user[name] = value;
    }
  }
  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== "undefined") {
      return (this.layout[name] = value);
    }
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }
  closeAll() {
    this.settingsPonds = {
      status: false,
      listSelected: [],
    };
    this.GatewayForm = {
      name: null,
      status: false,
      gateway: null,
      farm: null,
      coordinates: {
        id: "",
        lat: "",
        lng: "",
      },
    };
    this.TesteoGateway = {
      status: false,
      farm: null,
    };
  }
}
