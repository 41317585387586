import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  CalendarOptions,
  DateSelectArg,
  EventApi,
} from "@fullcalendar/angular";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import esLocale from "@fullcalendar/core/locales/es";
import enLocale from "@fullcalendar/core/locales/es-us";
import * as moment from "moment";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.html",
  styleUrls: ["./calendar.scss"],
})
export class CalendarComponent implements OnInit {
  @Input("planting") planting = null;
  @Input("planned_end_date") planned_end_date = "";
  @Output() clickDate = new EventEmitter()
  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    locale: esLocale,
    locales: [esLocale, enLocale],
    headerToolbar: {
      left: "",
      center: "title",
      right: "prev,next today",
    },

    contentHeight: 350,
    handleWindowResize: true,
    events: [],
    dateClick: this.dateClick.bind(this),
    /*

        initialView: 'dayGridMonth',
         select: this.handleDateSelect.bind(this),
       eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        stickyHeaderDates: true
        */
  };
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["planting"] && changes["planting"].currentValue) {
      const { date, planned_end_date } = changes["planting"].currentValue;

      const planting = {
        start: date,
        end: planned_end_date,
        color: "#008000",
        display: "background",
        rendering: "background",
        allDay: true,
      };

      const current = {
        start: planned_end_date,
        end: moment(new Date()).format("YYYY-MM-DD"),
        color: "#ff9f89",
        display: "background",
        rendering: "background",
        allDay: true,
      };

      this.calendarOptions.events = [planting, current];
    }
    if (
      changes["planned_end_date"] &&
      changes["planned_end_date"].currentValue
    ) {
      if (this.planting) {
        const { date, planned_end_date } = this.planting;

        const planting = {
          start: date,
          end: changes["planned_end_date"].currentValue,
          color: "#008000",
          display: "background",
          rendering: "background",
          allDay: true,
        };

        const current = {
          start: planned_end_date,
          end: moment(new Date()).format("YYYY-MM-DD"),
          color: "#ff9f89",
          display: "background",
          rendering: "background",
          allDay: true,
        };

        this.calendarOptions.events = [planting, current];
      }
    }
  }
  constructor() {}

  ngOnInit(): void {}

  currentEvents: EventApi[] = [];

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt("Please enter a new title for your event");
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  }

  dateClick(arg: any) {
    this.clickDate.emit(arg.dateStr)
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
}
