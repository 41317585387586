import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subscription } from "rxjs";
import { StorageService } from "../../../routes/services/storage.service";
@Component({
  selector: "app-test-feed",
  templateUrl: "./test.html",
  styleUrls: ["./test.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class TestFeedComponent implements OnInit {
  @ViewChild("testAlert") public testAlert;
  @Output("closed") closed = new EventEmitter();
  @Output("feed") feed = new EventEmitter();
  @Output("refeed") refeed = new EventEmitter();

  @Input() view = false;
  @Input() ponds: any = [];
  @Input() status_server = "";
  @Input() mode = true;
  temportalPonds: any = [];
  filteredPonds: any = [];

  modalRef: null | any = null;

  initialValue = false;
  filters = {
    status: "",
    name: "",
  };
  socketInfoSubsciption: Subscription;
  constructor(
    private modalService: NgbModal,
    config: NgbModalConfig,
    public storageService: StorageService
  ) {
    config.keyboard = true;
    config.size = "xl";
    config.centered = true;
    config.animation = false;
  }
  ngOnDestroy(): void {
    this.socketInfoSubsciption.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status_server) {
      this.changeDataPonds(changes.status_server.currentValue, "server");
    }
  }

  ngAfterViewInit(): void {
    if (this.view) this.openModal();
  }
  ngOnInit(): void {
    if (this.ponds) {
      this.temportalPonds = this.ponds;
    }

    this.socketInfoSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        /*       server: "",
              feeder_status: "",
              ration_status: "", */
        const address = msg.address;
        if (msg.data) {
          const { type, mac } = msg.data;
          if (mac && type === "FEEDING_OK") {
            this.changeDataPonds("done", "ration_status", mac);
          }
          if (mac && type === "FEED_NOT_OK") {
            this.changeDataPonds("error", "ration_status", mac);
          }
          if (mac && type && type === "FEEDER_STATUS") {
            this.changeDataPonds("done", "feeder_status", mac);
          }
        }
        if (address && address.type === "ALERT_COMM") {
          this.changeDataPonds("error", "feeder_status", address.mac);
          this.changeDataPonds("error", "ration_status", address.mac);
        }
      });
  }

  changeDataPonds(status, prop, mac?) {
    this.temportalPonds = this.temportalPonds.map((item) => {
      let linked_feeders = item.linked_feeders;
      if (prop === "server" || mac) {
        linked_feeders = item.linked_feeders.map((feeder) => {
          let feed = feeder;
          if ((mac && mac === feeder.mac) || prop === "server") {
            feed.status_refeed[prop] = status ? status : "";
          }
          return feed;
        });
      }
      return {
        ...item,
        linked_feeders,
      };
    });
    this.filteredPonds = this.temportalPonds;
  }

  openModal = async () => {
    const ref = await this.modalService.open(this.testAlert);

    await ref.result.then(
      (item) => {
        this.modalRef = ref;
        setTimeout(() => {
          this.initialValue = true;
        }, 100);
      },
      (err) => {
        this.closed.emit(false);
      }
    );
  };
  closeModal = async () => {
    this.modalService.dismissAll();
    this.modalRef = null;
    this.closed.emit(false);
  };

  register(type: "feed" | "refeed") {
    if (type === "feed") {
      this.feed.emit();
    } else {
      this.refeed.emit();
    }
  }
  filterPonds = () => {
    const { name, status } = this.filters;
    const filter_name = (feeder) => {
      return name
        ? feeder.name.toLowerCase().indexOf(name.toLowerCase()) > -1
        : true;
    };
    const filter_status = (feeder) => {
      const { server, feeder_status, ration_status } = feeder.status_refeed;
      return status
        ? server.server === status ||
            feeder_status === status ||
            ration_status === status
        : true;
    };
    let temporal = [...this.temportalPonds];
    temporal.forEach((pond, idx) => {
      temporal[idx] = {
        ...pond,
        linked_feeders: pond.linked_feeders.filter((feeder) => {
          return filter_name(feeder) && filter_status(feeder);
        }),
      };
    });
    this.filteredPonds = temporal;
  };
  validateData = () => {
    return this.filteredPonds.some((item) => item.linked_feeders.length > 0);
  };
}
