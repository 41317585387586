import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GlobalFunction } from "../../../../routes/services/globalFuntion";
import { TranslatorService } from "../../../../core/translator/translator.service";

@Component({
  selector: "app-monitor-alert",
  templateUrl: "./monitor.html",
  styleUrls: ["./monitor.scss"],
})
export class MonitorAlertsComponent implements OnInit {
  @Input() alert;
  @Input() feeder;
  @Output("individualFeeder") individualFeeder = new EventEmitter();
  @Input("timezone") timezone;
  constructor(
    public globalFunction: GlobalFunction,
    public translatorService: TranslatorService
  ) {}

  ngOnInit(): void {}
  individualAction(alert) {
    this.individualFeeder.emit({ data: alert, feeder: this.feeder });
  }

  validateFrecuencyMode = (frecuency) => {
    let obj = {
      0: "Desactivado",
      1: "Cada hora",
      2: "Cada 3 horas",
      3: "Cada 5 horas",
    };
    return obj[frecuency];
  };

  validateActive(val, type = "") {
    if (val) {
      return "bg-alert-success";
    } else if (type === "power" || type === "dispenser") {
      return "bg-alert-danger";
    } else {
      return "bg-alert-secondary";
    }
  }

  validateText(alert) {
    switch (alert) {
      case "monitor":
        return this.translatorService.translate("testing.alarm-test");
      case "battery":
        return this.translatorService.translate("testing.battery-test");
      case "panel":
        return this.translatorService.translate("testing.panel-test");
      case "signal":
        return this.translatorService.translate("testing.request-signal");
      case "reset":
        return this.translatorService.translate("testing.alerts-reset");
      case "version":
        return this.translatorService.translate("testing.request-version");
      case "refeed":
        return this.translatorService.translate("testing.resend-ration");
      case "empty":
        return this.translatorService.translate("testing.empty-feeder");
      case "turn_on":
        return "Encender";
      case "turn_off":
        return "Apagar";
      case "turn_off_spin_time":
        return "Desactivar rotación";
      case "turn_on_spin_time":
        return "Activar rotación";

      default:
        return this.translatorService.translate("testing.alarm-test");
    }
  }
  validateColorImage = (data, type, success, warning, danger) => {
    let { dispenser, sprinkler, panel, battery } = data;

    if (type === "battery") {
      if (battery > 12.5) {
        return success;
      }
      if (battery > 11.5 && battery <= 12.5) {
        return warning;
      }
      if (battery <= 11.5) {
        return danger;
      }
    }
    if (type === "panel") {
      if (panel > 15) {
        return success;
      }
      if (panel > 12 && panel <= 15) {
        return warning;
      }
      if (panel <= 12) {
        return danger;
      }
    }
    if (type === "sprinkler") {
      return sprinkler ? danger : success;
    }
    if (type === "dispenser") {
      return dispenser ? danger : success;
    }

    return danger;
  };
  validateAlert(alert) {
    let bool = true;
    if (alert.hasOwnProperty("battery")) {
      if (alert.battery <= 11.5) {
        return false;
      }
    }
    if (alert.hasOwnProperty("panel")) {
      if (alert.panel <= 12) {
        return false;
      }
    }
    if (alert.hasOwnProperty("dispenser")) {
      return !alert.dispenser;
    }
    if (alert.hasOwnProperty("sprinkler")) {
      return !alert.sprinkler;
    }

    return bool;
  }
  convertTimeZone(alert) {
    if (alert && alert.data && alert.data.time && alert.data.date) {
      const { time, date } = alert.data;
      return `${date} ${time}`;
    }
    return "";
  }
}
