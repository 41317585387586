import { FeedingReportsService } from "../../routes/services/feeding-reports.service";
import { PermissionsService } from "../../routes/services/permissions.service";
import { StorageService } from "../../routes/services/storage.service";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { FeedersService } from "../../routes/services/feeder.service";
import { FramesService } from "../../routes/services/frames.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { Subscription } from "rxjs";
import * as moment from "moment";
import {
  EventEmitter,
  HostListener,
  Component,
  ViewChild,
  OnInit,
  Output,
  Input,
} from "@angular/core";

@Component({
  selector: "app-ration-modal",
  templateUrl: "./ration-modal.component.html",
  styleUrls: ["./ration-modal.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class RationModalComponent implements OnInit {
  @Output("closed") closed = new EventEmitter();
  @ViewChild("modalRation") public modalRation;

  feederAlert: any;
  @Input() feederID: any;
  feeder;
  socketSubsciption: Subscription;
  @Input() pond_id: any;

  options = {};
  dataGraph = [{ data: [85, 72, 78, 75, 77, 75], label: "Crude oil prices" }];
  labelsGraph = ["January", "February", "March", "April", "May", "June"];

  lineColors = [
    {
      borderColor: "rgba(0,0,0,0.1)",
      backgroundColor: "rgba(207, 0, 15, 0.09)",
    },
  ];

  view: "diet" | "food" | "speed" | "" = "";
  permissions = {
    view_virtual_weight: true,
  };
  constructor(
    public feedingReportService: FeedingReportsService,
    public permissionsService: PermissionsService,
    public globalFunction: GlobalFunction,
    public storageService: StorageService,
    public feederService: FeedersService,
    public framesService: FramesService,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    config.size = "xl";
    config.centered = true;
    config.animation = false;
  }
  /* para saber cuando esta inicializado el componente y poder validar */
  initialValue = false;
  modalRef: null | any = null;
  close = false;

  loading_data_pond = true;

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const myDiv: any = document.querySelector(`[id='ration-modal']`);
    const validateClassSwall = () => {
      const views = [
        "swal2-header",
        "swal2-container",
        "swal2-cancel",
        "swal2-popup",
        "swal2-title",
        "swal2-html-container",
        "swal2-icon-content",
        "swal2-icon",
        "swal2-confirm",
        "swal2-actions",
      ];
      const some = views.some((item) => targetElement.classList.contains(item));
      return some;
    };

    if (
      this.initialValue &&
      targetElement.id !== "menu-alert-ponds" &&
      targetElement.id !== "check-feeder" &&
      this.modalRef !== null &&
      !validateClassSwall() &&
      myDiv &&
      !myDiv.contains(targetElement) &&
      !this.close
    ) {
      this.closeModal();
      event.stopPropagation();
    }
  }

  openModal = async () => {
    const ref = await this.modalService.open(this.modalRation);
    await ref.shown.subscribe(() => {
      this.modalRef = ref;
      setTimeout(() => {
        this.initialValue = true;
      }, 100);
    });
  };

  closeModal() {
    this.modalService.dismissAll();
    this.modalRef = null;

    this.closed.emit("");
  }

  ngOnDestroy(): void {
    this.socketSubsciption.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.openModal();
  }

  ngOnInit(): void {
    const validate = (prop) => {
      return this.permissionsService.validatePermissionsExistSubclient(prop);
    };
    const validateClient = (prop) => {
      return this.permissionsService.validatePermissionsExistClient(prop);
    };
    this.permissions = {
      view_virtual_weight:
        validateClient("view_virtual_weight") &&
        validate("view_virtual_weight"),
    };
    this.feederID && this.getFeedingReport(this.feederID);
    this.pond_id && this.getPondData();
    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (msg) {
          this.getFeeder(msg);
        }
      });
  }
  getFeeder = async (msg) => {
    if (msg.data && msg.data.mac === this.feeder.mac) {
      if (msg.data.type === "SPEED_RESPONSE") {
        const { high_speed, low_speed, speed, speed_enabled, orbital_enabled } =
          msg.data;
        const validateSpeedMode = () => {
          if (speed_enabled) return 1;
          if (orbital_enabled) return 2;
          if (!speed_enabled && !orbital_enabled) return 0;
        };
        this.feeder = {
          ...this.feeder,
          status_high_speed: high_speed,
          status_low_speed: low_speed,
          status_speed: speed,
          status_speed_mode: validateSpeedMode(),
        };
      }
    }
  };

  getSpeed() {
    this.framesService
      .individualFrames(this.feeder.id, "request_speed")
      .subscribe((resp) => {});
  }
  getFeedingReport = async (item) => {
    const fields = [
      "id",
      "name",
      "feeding_details",
      "dispenser",
      "virtual_weight",
      "status_version",
      "status_speed_mode",
      "view_mode_speed",
      "status_speed",
      "status_low_speed",
      "status_high_speed",
    ];
    let feeder = await this.feederService
      .GetFeederID(item, `?fields=${fields}`)
      .toPromise();

    if (feeder) {
      this.feeder = feeder;
      this.feeder.view_speed = false;
      const validateView_mode_speed = () => {
        const { status_speed_mode } = feeder;
        if (status_speed_mode === 1) return "normal";
        return "orbital";
      };
      this.feeder.view_mode_speed = validateView_mode_speed();
    }
  };
  getPondData = async () => {
    const fields_feeding = [
      "id",
      "diet",
      "food",
      "amountKgs",
      "planting",
      "user",
      "created_date",
    ];
    this.loading_data_pond = true;

    try {
      const query = `?pond_id=${this.pond_id}&page=1&page_size=1&ordering=-date&type=0&projection=false&fields=${fields_feeding}`;
      let reports = await this.feedingReportService.getFeedingReports(query).toPromise();

      if (reports) {
        this.loading_data_pond = false;

        this.feederAlert = reports.results.length > 0 ? reports.results[0] : "";
        if (this.feederAlert.diet) {
          this.setData(this.feederAlert.diet);
        }
      }
    } catch (error) {}
    if (!this.pond_id) {
      this.feederAlert = "";
      this.loading_data_pond = false;
    }
  };
  constructdataGraph(value) {
    let Labels: Array<any> = [];
    let Data: Array<any> = [];

    for (let i = 1; i < 25; i++) {
      let horaInitial = moment(new Date(2018, 11, 24, 0, 0, 0, 0)).format("HH");
      Labels.push(`${Number(horaInitial) + i - 1}:00`);
    }

    Data = [
      {
        data: value.percentages.map((item) => {
          return Number(item) * 100;
        }),
        name: "porcentajes",
        type: "bar",
        color: "#30646c",
      },
      {
        data: value.intervals.map((item) => {
          return item;
        }),
        name: "Intervalos",
        type: "bar",
        color: "#00adac",
      },
    ];
    return { Labels, Data };
  }

  setData(value) {
    this.view = "diet";
    this.dataGraph = this.constructdataGraph(value).Data;
    this.labelsGraph = this.constructdataGraph(value).Labels;
  }
  validateVersionSpeed(feeder) {
    if (feeder) {
      return (
        this.globalFunction.validateVersion(feeder, 0.5) &&
        this.storageService.validateSoloAdmin()
      );
    }
    return false;
  }
  validateFeedRate = (feeder_ration: number, percentage: number) => {
    try {
      const gram = feeder_ration * 1000;
      const percent = percentage / 100;
      const total = gram * percent;
      return total;
    } catch (error) {
      return 0;
    }
  };
}
