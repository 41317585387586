import { Component, Input, OnInit } from "@angular/core";
import { RegionService } from "../../../routes/services/region.service";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GlobalFunction } from "../../../routes/services/globalFuntion";

@Component({
  selector: "app-config-fuzzy-logic",
  templateUrl: "./fuzzy_logic.html",
  styleUrls: ["./fuzzy_logic.scss"],
})
export class ConfigFuzzyLogicComponent implements OnInit {
  @Input() region_id: null | number = null;

  configForm: FormGroup;
  testForm: FormGroup;
  levelKeys = ["very_low", "low", "normal", "high", "very_high"];
  subLevelKeys = ["low", "normal", "high"];
  config_selected = "Temperatura";
  name = "";
  configuration = null;
  config = [
    {
      title: "Temperatura",
      prop: "temperature_terms",
      very_low: {
        low: "",
        normal: "",
        high: "",
      },
      low: {
        low: "",
        normal: "",
        high: "",
      },
      normal: {
        low: "",
        normal: "",
        high: "",
      },
      high: {
        low: "",
        normal: "",
        high: "",
      },
      very_high: {
        low: "",
        normal: "",
        high: "",
      },
    },
    {
      title: "Oxigeno",
      prop: "oxygen_terms",
      very_low: {
        low: "",
        normal: "",
        high: "",
      },
      low: {
        low: "",
        normal: "",
        high: "",
      },
      normal: {
        low: "",
        normal: "",
        high: "",
      },
      high: {
        low: "",
        normal: "",
        high: "",
      },
      very_high: {
        low: "",
        normal: "",
        high: "",
      },
    },
    {
      title: "Ajuste",
      prop: "modifier_terms",
      very_low: {
        low: "",
        normal: "",
        high: "",
      },
      low: {
        low: "",
        normal: "",
        high: "",
      },
      normal: {
        low: "",
        normal: "",
        high: "",
      },
      high: {
        low: "",
        normal: "",
        high: "",
      },
      very_high: {
        low: "",
        normal: "",
        high: "",
      },
    },
  ];
  resultTest:any = null;

  constructor(
    private fb: FormBuilder,
    public regionService: RegionService,
    public globalFunction: GlobalFunction
  ) {}

  ngOnInit(): void {
    if (this.region_id) {
      this.getConfig();
    }
    this.testForm = this.testForm = this.fb.group({
      temperature: this.fb.control("", Validators.required),
      oxygen: this.fb.control("", Validators.required),
      feeding_kgs: this.fb.control(1),
    });

    this.configForm = this.fb.group({
      configurations: this.fb.array([]),
      name: this.fb.control("", Validators.required),
    });

    this.config.forEach((configItem) => {
      const configGroup = this.fb.group({
        title: [configItem.title],
        very_low: this.createLevelGroup(configItem.very_low),
        low: this.createLevelGroup(configItem.low),
        normal: this.createLevelGroup(configItem.normal),
        high: this.createLevelGroup(configItem.high),
        very_high: this.createLevelGroup(configItem.very_high),
      });
      this.configurations.push(configGroup);
    });
  }

  get configurations(): FormArray {
    return this.configForm.get("configurations") as FormArray;
  }

  createLevelGroup(levelData: any): FormGroup {
    return this.fb.group({
      low: [levelData.low, Validators.required],
      normal: [levelData.normal, Validators.required],
      high: [levelData.high, Validators.required],
    });
  }

  setValueInputs = (resp) => {
    this.config.forEach((config, idx) => {
      const levels = ["very_low", "low", "normal", "high", "very_high"];
      const subLevels = ["low", "normal", "high"];

      const value = levels.reduce((acc, level, i) => {
        acc[level] = subLevels.reduce((subAcc, subLevel, j) => {
          subAcc[subLevel] = resp ? resp[config.prop][i][j] : "";
          return subAcc;
        }, {});
        return acc;
      }, {});

      this.configurations.controls[idx].setValue({
        title: config.title,
        ...value,
      });
      this.configForm.controls["name"].setValue(resp.name);
    });
  };
  getConfig() {
    this.regionService.getConfigFuzzyLogic(this.region_id).subscribe((resp) => {
      if (resp && resp.name) {
        this.configuration = resp;
        this.setValueInputs(resp);
      }
    });
  }

  transformarConfiguracion() {
    const construct_value = (val) => {
      const levels = ["very_low", "low", "normal", "high", "very_high"];
      const subLevels = ["low", "normal", "high"];
      let value: any = levels.map((item) => []);
      levels.forEach((level, idx) => {
        subLevels.forEach((sublevel, idxSub) => {
          value[idx][idxSub] = val[level][sublevel];
        });
      });
      return value;
    };

    const body = {
      name: this.configForm.value.name,
      oxygen_terms: construct_value(this.configForm.value.configurations[1]),
      temperature_terms: construct_value(
        this.configForm.value.configurations[0]
      ),
      modifier_terms: construct_value(this.configForm.value.configurations[2]),
    };

    return body;
  }

  setConfig() {
    const body = this.transformarConfiguracion();
    this.regionService
      .setConfigFuzzyLogic(this.region_id, body)
      .subscribe((resp) => {
      });
  }
  deleteConfig = async () => {
    let bool = await this.globalFunction.optionSwalAlert(
      `¿Seguro que quieres eliminar esta configuración?`
    );

    bool &&
      this.regionService
        .deleteConfigFuzzyLogic(this.region_id)
        .subscribe((resp) => {
          this.setValueInputs("");
        });
  };
  viewTitle = (val) => {
    let obj = {
      very_low: "Muy bajo",
      low: "Bajo",
      normal: "Medio",
      high: "Alta",
      very_high: "Muy alta",
    };
    return obj[val] ? obj[val] : "";
  };
  testConfig() {
    const { modifier_terms, oxygen_terms, temperature_terms } =
      this.transformarConfiguracion();

    this.regionService
      .testConfigFuzzyLogic({
        ...this.testForm.value,
        config: {
          modifier_terms,
          oxygen_terms,
          temperature_terms,
        },
      })
      .subscribe((resp) => {
        const { modifier, new_feeding_kgs, old_feeding_kgs } = resp;
        this.resultTest = { modifier, new_feeding_kgs, old_feeding_kgs };
      });
  }
}
