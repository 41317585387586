import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-battery",
  template: `<div
      class="position-relative "
      [style.width]="width"
      [style.height]="height"
    >
      <div
        class="content"
        [placement]="placement"
        container="body"
        [ngbTooltip]="toltipContent"
      >
        <div class="battery" [style.width]="width" [style.height]="height">
          <div
            class="stuffed"
            [style.width.%]="percent"
            [style.background]="color"
          ></div>
        </div>
      </div>
    </div>
    <ng-template #toltipContent let-greeting="greeting">
      <div class="d-flex flex-column" style="width:123px;">
        <div class="d-flex">Porcentaje: {{ percent }} %</div>
        <div class="d-flex">Valor : {{ value }}</div>
      </div>
    </ng-template> `,

  styleUrls: ["./icon_battery.scss"],
})
export class IconBatteryComponent implements OnInit {
  @Input() value = 0;
  @Input() height = "20px";
  @Input() width = "20px";
  @Input() color = "#FF0000";
  @Input() font_size = "2px";
  @Input() dark = false;
  @Input() placement = "auto";

  percent: number | string = 0;

  constructor() {}

  ngOnChanges(): void {
    this.validatePercent();
  }
  ngOnInit(): void {
    this.validatePercent();
  }
  validatePercent = () => {
    let value = this.value;
    let maxBattery = 13.8;
    let minBattery = 11.5;
    let amplitud = maxBattery - minBattery;

    if (value >= minBattery && value <= maxBattery) {
      this.percent = (((value - minBattery) / amplitud) * 100).toFixed(2);
    }
    if (value > maxBattery) {
      this.percent = 100;
    }
    if (value <= minBattery) {
      this.percent = 0;
    }
  };
}
