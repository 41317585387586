import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class ConfigurationDeviceService {
  constructor(
    public storageService: StorageService,
    public toastr: ToastrService,
    private http: HttpClient,
    public router: Router
  ) {}

  url = (type: "feeder" | "waterwise" | "farm" | "pond") => {
    switch (type) {
      case "feeder":
        return Uris.FEEDER;
      case "waterwise":
        return Uris.WATERWISE;
      case "farm":
        return Uris.FARM;
      case "pond":
        return Uris.PONDS;
      default:
        return "";
    }
  };

  getConfiguration(
    id: number | "",
    type: "feeder" | "waterwise" | "farm" | "pond",
    query = ""
  ) {
    const multi = query
      ? `multi_configuration/${query}`
      : `${id}/configuration/`;
    return this.http
      .get(`${this.url(type)}${multi}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteConfiguration(
    id: number | "",
    type: "feeder" | "waterwise" | "farm" | "pond",
    query = ""
  ) {
    const multi = query && (type == 'feeder' || type === 'waterwise')
      ? `multi_configuration/${query}`
      : `${id}/configuration/`;
    return this.http
      .delete(`${this.url(type)}${multi}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "",
            "Se removio la configuraciòn correctamente"
          );
          return resp;
        })
      );
  }

  patchConfiguration(
    id: number | "",
    type: "feeder" | "waterwise" | "farm" | "pond",
    body = {},
    query = ""
  ) {
    const multi = query
      ? `multi_configuration/${query}`
      : `${id}/configuration/`;
    return this.http
      .patch(`${this.url(type)}${multi}`, body, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "",
            `Se guardo la configuración correctamente`
          );
          return resp;
        })
      );
  }
}
