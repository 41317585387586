// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APIURL: "https://api.n-a-s.mx/v1/",
  API_ENDPOINT: "https://api.n-a-s.mx/v1/",
  SOCKET: "wss://api.n-a-s.mx/ws/front-end/",
  LOCAL_APIURL: "http://127.0.0.1:8000/v1/",
  LOCAL_API_ENDPOINT: "http://127.0.0.1:8000/v1/",
  LOCAL_SOCKET: "ws://127.0.0.1:8000/ws/front-end/",
  firebaseConfig: {
    apiKey: "AIzaSyB89wUtJ7hhtQn_0bSvky2Mfy37MxgZOnc",
    authDomain: "nas-firebase-dev.firebaseapp.com",
    projectId: "nas-firebase-dev",
    storageBucket: "nas-firebase-dev.firebasestorage.app",
    messagingSenderId: "740706149169",
    appId: "1:740706149169:web:8855d6b940f07d292e4610",
    measurementId: "G-RBS964W3TH"
  },
  FRONT: "https://n-a-s.mx/",
  LOCAL_FRONT: "http://127.0.0.1:8000/",
  apikeyMap: 'AIzaSyBRUQFdOWloItOyLBch9N-OMscn6uq4A58'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
