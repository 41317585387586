import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { StorageService } from "../routes/services/storage.service";
import { Router } from "@angular/router";

@Injectable()
export class CancelInterceptor implements HttpInterceptor {
  constructor(public storageService: StorageService, public router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedRequest = request.clone({
      headers: request.headers.delete("destroy_petition"), 
    });

    const destroy_petition =
      request.headers.get("destroy_petition") === "false";

    if (destroy_petition) {
      return next.handle(clonedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          return this.storageService.validateError(error);
        })
      );
    }

    return next.handle(clonedRequest).pipe(
      takeUntil(this.storageService.destroy$),
      catchError((error: HttpErrorResponse) => {
        return this.storageService.validateError(error);
      })
    );
  }
}
