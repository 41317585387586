import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FramesService } from "../../../../routes/services/frames.service";
import Swal from "sweetalert2";
import { GlobalFunction } from "../../../../routes/services/globalFuntion";
import { StorageService } from "../../../../routes/services/storage.service";

@Component({
  selector: "app-menu-empty",
  templateUrl: "./empty.html",
  styleUrls: ["../../panel-alerts.scss"],
})
export class MenuEmptyComponent implements OnInit {
  @Input() list: any[] = [];
  @Input() type = "feeder";
  @Input() disabled = false;
  @Input() reload;

  optionsTime = [
    { label: "0", value: "" },
    { label: "1", value: "0:01:00" },
    { label: "5", value: "0:05:00" },
    { label: "10", value: "0:10:00" },
    { label: "15", value: "0:15:00" },
    { label: "20", value: "0:20:00" },
    { label: "25", value: "0:25:00" },
    { label: "30", value: "0:30:00" },
    { label: "35", value: "0:35:00" },
    { label: "40", value: "0:40:00" },
    { label: "45", value: "0:45:00" },
    { label: "50", value: "0:50:00" },
    { label: "55", value: "0:55:00" },
    { label: "60", value: "1:00:00" },
  ];
  select_option_emptied: "kg" | "time" | "manual" = "manual";
  amount_kgs = 0;
  kg_max = 0;
  time_empty = 0;
  view_time = false;
  @Output("submitFrameEmpty") submitFrameEmpty = new EventEmitter();

  constructor(
    public framesService: FramesService,
    public storageService: StorageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.list &&
      changes.list.currentValue &&
      changes.list.currentValue.length > 1
    ) {
      this.list = [...changes.list.currentValue];
    }
  }

  ngOnInit(): void {}

  validateKgmax() {
    const validateMenorMayor = (array, object, propiety) => {
      return array.sort((a, b) => {
        if (
          a[object] &&
          a[object][propiety] &&
          b[object] &&
          b[object][propiety]
        ) {
          return a[object][propiety] - b[object][propiety];
        }
        return a - b;
      });
    };

    if (this.type === "feeder") {
      let feeders = validateMenorMayor(this.list, "virtual_weight", "left_kgs");
      if (feeders.length > 0) {
        const virtual_weight = feeders[0].virtual_weight;
        if (virtual_weight && virtual_weight.left_kgs) {
          this.kg_max = virtual_weight.left_kgs;
        }
      }
    } else {
      /* Agregar objeto virtual_weight en la lista de estanques */
      let feeders: Array<any> = [];
      this.list.forEach((pond: any) => {
        feeders = [...feeders, ...pond.linked_feeders];
      });
      feeders = validateMenorMayor(feeders, "virtual_weight", "left_kgs");
      if (feeders.length > 0) {
        const virtual_weight = feeders[0].virtual_weight;
        if (virtual_weight && virtual_weight.left_kgs) {
          this.kg_max = virtual_weight.left_kgs;
        }
      }
    }
  }

  validateOffOn(propiety) {
    let countOn = 0;
    let countOFF = 0;

    if (this.type === "feeder") {
      const checked = this.list.filter((item: any) => item.checked);
      if (checked.length > 0) {
        checked.forEach((item) => {
          item[propiety] ? countOn++ : countOFF++;
        });
      } else {
        this.list.forEach((item) => {
          item[propiety] ? countOn++ : countOFF++;
        });
      }
    } else {
      this.list.forEach((pond: any) => {
        const checked = pond.linked_feeders.filter((item: any) => item.checked);
        if (checked.length > 0) {
          checked.forEach((item) => {
            item[propiety] ? countOn++ : countOFF++;
          });
        } else {
          pond.linked_feeders.forEach((item) => {
            item[propiety] ? countOn++ : countOFF++;
          });
        }
      });
    }

    return { countOn, countOFF };
  }
  validateColorButtonEmpty() {
    let bool = false;
    if (this.type === "feeder") {
      bool = this.list.every((item: any) => item.status_emptied);
      return bool;
    } else {
      let feeders: any[] = [];
      this.list.forEach((pond: any) => {
        feeders = [...feeders, ...pond.linked_feeders];
      });
      bool = feeders.every((item) => item.status_emptied);
      return bool;
    }
  }
  returnTimeEmpty(value) {
    let time = this.optionsTime.find((item) => item.label === value.toString());
    return time ? time.value : `00:${value}:00`;
  }

  submitEmpty = async (empty) => {
    if (this.select_option_emptied === "manual") {
      this.time_empty = 0;
    }
    if (!empty) {
      this.view_time = false;
    }
    let validateObjEmpty: any = {};
    if (this.select_option_emptied === "kg") {
      validateObjEmpty.amount_kgs = this.amount_kgs;
    }
    if (this.select_option_emptied === "time" && this.time_empty !== 0) {
      validateObjEmpty.schedule = this.returnTimeEmpty(this.time_empty);
    }
    const validateQueryEmpty = () => {
      if (this.type === "feeder") {
        const checked = this.list.filter((item: any) => item.checked);
        if (checked.length > 0) {
          const list = checked.filter((item: any) => {
            return empty ? item.status_emptied : !item.status_emptied;
          });

          return list;
        } else {
          const list = this.list.filter((item: any) => {
            return empty ? item.status_emptied : !item.status_emptied;
          });

          return list;
        }
      } else {
        let feeders: any = [];
        this.list.forEach((value: any) => {
          feeders = [...feeders, ...value.linked_feeders];
        });
        let list = [];
        const checked = feeders.filter((item: any) => item.checked);
        if (checked.length > 0) {
          list = checked.filter((item: any) => {
            return empty ? item.status_emptied : !item.status_emptied;
          });
          return list;
        } else {
          list = feeders.filter((item: any) => {
            return empty ? item.status_emptied : !item.status_emptied;
          });

          return list;
        }
      }
    };
    const emptied = validateQueryEmpty();
    if (
      this.time_empty !== 0 ||
      this.select_option_emptied === "manual" ||
      this.select_option_emptied === "kg"
    ) {
      if (emptied.length > 0) {
        this.confirmAndExecuteAction(
          validateQueryEmpty(),
          validateObjEmpty,
          empty
        );
      }
    }
  };

  async confirmAndExecuteAction(data: any, validateObjEmpty, value) {
    const validateQueryEmptyList = data;
    const numberOfFeedersToEmpty = validateQueryEmptyList.length;
    const petition = async () => {
      try {
        const data = await this.framesService
          .FramePost(
            "empty",
            `?id_in=${validateQueryEmptyList.map((feeder: any) => feeder.id)}`,
            validateObjEmpty
          )
          .toPromise();
        this.submitFrameEmpty.emit({
          data,
          feeders: validateQueryEmptyList,
          empty: value,
        });
        if (data && Object.prototype.hasOwnProperty.call(data, "errors")) {
          this.view_time = false;
        } else {
          if (!value) {
            this.view_time = true;
          }
        }
      } catch (error) {
      }
    };
    if (this.storageService.validateSoloAdmin()) {
      petition();
    } else {
      const confirmation = await Swal.fire({
        title: "Confirmar acción",
        text: `¿Seguro que deseas vaciar ${numberOfFeedersToEmpty} alimentadores?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sí, vaciar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        customClass: {
          container: "custom-swal-container",
        },
      });

      if (confirmation.isConfirmed) {
        petition();
      }
    }
  }
  validateTypeTimer = () => {
    if (this.select_option_emptied === "manual") {
      return "increase";
    }
    if (
      this.select_option_emptied === "time" ||
      (this.validateOffOn("status_emptied").countOFF === 0 && this.view_time)
    ) {
      return "decrease";
    }

    return "decrease";
  };
}
