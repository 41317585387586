/* ADMINISTRADOR */

export const Dashboard = {
  text: "sidebar.inicio",
  link: "/home",
  img: "home.svg",
  icon: "",
  available: true,
};

export const Admins = {
  text: "sidebar.admins",
  link: "/administrador",
  img: "admins.svg",
  icon: "",
  available: true,
};

export const Clients = {
  text: "sidebar.clientes",
  link: "/clientes",
  img: "clients.svg",
  height: "16px",
  icon: "",
  available: true,
};

export const FeedersControls = {
  text: "sidebar.alimentadores",
  icon: "",
  img: "feeders.svg",
  submenu: [
    {
      icon: "",
      text: "sidebar.dosifiers-brands",
      link: "/inicio-alimentadores",
      img: "feeders_admon.svg",
      available: true,
    },
    {
      icon: "",
      text: "sidebar.control-feeders",
      link: "/alimentadoras",
      img: "feeders_control.svg",
      available: true,
    },
    {
      icon: "",
      text: "sidebar.feeders-creation",
      link: "/warehouse-feeders",
      img: "feeders_plant.svg",
      available: true,
    },
  ],
};

export const Food = {
  icon: "",
  img: "food.svg",
  text: "sidebar.alimentos",
  link: "/inicio-alimento",
  available: true,
};

export const TrayTemplates = {
  icon: "",
  img: "trays.svg",
  text: "sidebar.charolas",
  link: "/plantillas-de-charolas",
  available: true,
};

export const Maintenance = {
  text: "sidebar.monitoreo",
  link: "",
  icon: "",
  img: "monitor.svg",
  submenu: [
    {
      icon: "",
      text: "sidebar.alertas",
      link: "/alerts",
      img: "alerts.svg",
      available: true,
    },
    {
      text: "sidebar.reports",
      link: "/monitoring-reports",
      img: "",
      height: "16px",
      icon: "fas fa-file-contract",
      available: true,
    },
    {
      icon: "",
      img: "eye.svg",
      text: "supervision",
      link: "/supervision",
      available: true,
    },
  ],
};

export const MovementsHistorical = {
  text: "sidebar.movimientos",
  link: "/historial-de-movimientos",
  icon: "",
  img: "movements.svg",
  available: true,
};

export const MainNavigation = {
  text: "Main Navigation",
  heading: true,
};

export const Apks = {
  text: "sidebar.apks",
  link: "/apks",
  img: "",
  height: "16px",
  icon: "fa fa-mobile",
  available: true,
};

export const Notifications = {
  icon: "fas fa-bell",
  text: "sidebar.notifications",
  link: "/notificaciones",
  img: "",
  available: true,
};

export const Calibration = {
  icon: "",
  text: "sidebar.calibration",
  link: "/calibration",
  img: "calibration.svg",
  available: true,
};