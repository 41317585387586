import { SettingsService } from "../../../../../core/settings/settings.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-network-gateway",
  templateUrl: "./gateway.html",
  styleUrls: ["./gateway.scss"],
})
export class NetworkGatewayComponent implements OnInit {
  data: any;

  constructor(public Settings: SettingsService) {}

  ngOnInit(): void {
    this.data = this.Settings.GatewayForm;
  }

  closed() {
    this.Settings.GatewayForm = {
      ...this.Settings.GatewayForm,
      farm: null,
      status: false,
      gateway: null,
    };
  }
}
