import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ViewVirtualWeightComponent } from "./virtual_weight/virtual_weight";
import { ContentViewDeviceComponent } from "./content";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SpeedViewFeederComponent } from "./speed/speed";
import { SensorsValueComponent } from "./sensors_value/sensors_value";
import { SensorHydroComponent } from "./sensors_value/hydro/hydro";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { VirtualWeightModule } from "../virtual_weight/virtual_weight.module";
import { LoadingModule } from "../loading/loading.module";
import { TranslateModule } from "@ngx-translate/core";
import { ImageColorModule } from "../image_color/image_color.module";
import { EchartModule } from "../echart/echart.module";
import { SettingFeedersModule } from "../settings-feeders/settings-feeders.module";
import { ConfigSensorsDeviceModule } from "../config_sensors_device/config_sensors_device.module";


@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VirtualWeightModule,
    LoadingModule,
    TranslateModule,
    ImageColorModule,
    EchartModule,
    SettingFeedersModule,
    ConfigSensorsDeviceModule
  ],
  providers: [
    ContentViewDeviceComponent,
    ViewVirtualWeightComponent,
    SpeedViewFeederComponent,
    SensorsValueComponent,
    SensorHydroComponent,
  ],
  declarations: [
    ContentViewDeviceComponent,
    ViewVirtualWeightComponent,
    SpeedViewFeederComponent,
    SensorsValueComponent,
    SensorHydroComponent,
  ],
  exports: [
    ContentViewDeviceComponent,
    ViewVirtualWeightComponent,
    SpeedViewFeederComponent,
    SensorsValueComponent,
    SensorHydroComponent,
  ],

})
export class ViewContentDeviceModule {}
