import { Component, Input, OnInit } from "@angular/core";
import {
  waterwise_icons,
  waterwise_tolva_icons,
} from "../../../routes/services/icons";
import { Router } from "@angular/router";

@Component({
  selector: "app-markers-waterwise",
  templateUrl: "./marker_waterwise.html",
})
export class MarkerWaterwiseComponent implements OnInit {
  @Input() waterwise;
  @Input() label: boolean = false;

  lat = 0;
  lng = 0;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.lat = this.validateLatLng().lat;
    this.lng = this.validateLatLng().lng;
  }

  validateLatLng() {
    if (this.waterwise && this.waterwise.lat) {
      return { lat: this.waterwise.lat, lng: this.waterwise.lng };
    }
    return { lat: this.waterwise.status_lat, lng: this.waterwise.status_lng };
  }

  iconsValidators(waterwise) {
    const { battery, panel, power } = waterwise;
    const alert_limit_battery = battery <= 11.5;
    const alert_limit_panel = panel <= 12;
    const validatePeligro = alert_limit_battery || alert_limit_panel;
    const validate_icon = (type: "peligro" | "apagado" | "sano") => {
      if (Number(waterwise.device_type) !== 1) {
        return waterwise_tolva_icons[type];
      }
      return waterwise_icons[type];
    };
    if (validatePeligro) {
      return validate_icon("peligro");
    }
    if (!power) {
      return validate_icon("apagado");
    }

    return validate_icon("sano");
  }
  markerClick(waterwise) {
    this.router.navigateByUrl(`/device/waterwise/${waterwise.id}`);
  }
}
