import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  alerta,
  apagado,
  peligro,
  sano,
  azul,
  gateway,
} from "../../../routes/services/icons";

@Component({
  selector: "app-markers-map",
  templateUrl: "./marker.html",
})
export class MarkerAlertComponent implements OnInit {
  @Output() clickFeederAlert = new EventEmitter();
  @Output() clickFeeder = new EventEmitter();
  @Output() clickButtonActionSensor = new EventEmitter();
  @Output() draggableMarker = new EventEmitter();

  @Input() view = true;
  @Input() feeder;
  @Input() type:
    | "feeder"
    | "sensors"
    | "gateway"
    | "feeder_alerts"
    | "feeders_gateway"
    | "feeder_ordering"
    | "feeder_sweep" = "feeder";
  lat = 0;
  lng = 0;
  @Input() draggable = false;
  antenaURL = gateway;
  icon_feeder = sano;

  constructor() {}

  ngOnInit(): void {
    this.lat = this.validateLatLng().lat;
    this.lng = this.validateLatLng().lng;
  }

  markerClick() {
    this.clickFeeder.emit(this.feeder);
  }

  setValueFeederTest(value) {
    this.clickFeederAlert.emit(value);
  }
  validateLatLng() {
    if (this.feeder && this.feeder.lat) {
      return { lat: this.feeder.lat, lng: this.feeder.lng };
    }
    return { lat: this.feeder.status_lat, lng: this.feeder.status_lng };
  }

  iconsValidators(feeder) {
    if (
      this.type === "feeder_sweep" ||
      this.type === "feeder" ||
      this.type === "feeder_alerts"
    ) {
      const {
        status_battery,
        status_panel,
        alert_battery,
        alert_comm,
        alert_dispenser,
        alert_panel,
        alert_sprinkle,
        status_power,
        status,
        alert_reset,
        sprinkler_alerts,
      } = feeder;
      /* 11.89 */
      const alert_limit_battery = status_battery <= 11.5;
      const alert_limit_panel = status_panel <= 12;

      const validateAlert =
        sprinkler_alerts > 0 ||
        (status_panel > 12 && status_panel <= 15) ||
        (status_battery > 11.5 && status_battery <= 12.5 && status_power);

      const validatePeligro =
        alert_dispenser ||
        alert_sprinkle ||
        alert_comm ||
        alert_battery ||
        alert_reset ||
        alert_panel ||
        alert_limit_battery ||
        alert_limit_panel;

      const validateSano =
        !alert_dispenser &&
        !alert_sprinkle &&
        !alert_comm &&
        !alert_battery &&
        !alert_panel &&
        !alert_reset &&
        status_power;

      if (status) {
        return azul;
      }
      if (validatePeligro) {
        return peligro;
      }
      if (!status_power) {
        return apagado;
      }
      if (validateAlert) {
        return alerta;
      }
      if (validateSano) {
        return sano;
      }

      return peligro;
    }
    if (this.type === "sensors") {
      if (
        this.validateNormalSensors(feeder) ||
        this.validateHydroSensors(feeder)
      ) {
        return sano;
      }
      return apagado;
    }
    if (this.type === "feeder_ordering") {
      return azul;
    }

    return apagado;
  }
  validateNormalSensors(feeder) {
    return (
      feeder &&
      feeder.sensors &&
      feeder.sensors.sensors_enabled &&
      feeder.sensors.hum !== null
    );
  }

  validateHydroSensors(feeder) {
    return (
      feeder &&
      feeder.sensor_hydro &&
      feeder.sensor_hydro.power !== null &&
      feeder.sensor_hydro.DO !== null
    );
  }
  onClickButtonActionSensor() {
    this.clickButtonActionSensor.emit(this.feeder);
    /*    this.Router.navigateByUrl(`/granja/sensores/1/${this.feeder.id}`); */
  }
  dragEndMarker(event) {
    this.draggableMarker.emit({ event, value: this.feeder });
  }
}
