import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FramesService } from "../../../routes/services/frames.service";
import { GlobalFunction } from "../../../routes/services/globalFuntion";
import { Subscription } from "rxjs";
import { StorageService } from "../../../routes/services/storage.service";
import moment from "moment";

@Component({
  selector: "app-panel-alerts-list-pond",
  templateUrl: "./alerts.html",
  styleUrls: ["./alerts.scss", "../panel-alerts.scss"],
})
export class PanelAlertListPondsComponent implements OnInit {
  @Input("list") list;
  @Input("feeder") feeder;
  @Input() type;
  @Input() time_zone;
  @Input() filters_alerts;
  @Input() action;
  @Output("check") check = new EventEmitter();
  @Output() individualPetition = new EventEmitter();
  @Output("exist_feeder") exist_feeder = new EventEmitter();

  filtered_ponds: any = [];
  socketSubsciption: Subscription;
  constructor(
    public framesService: FramesService,
    public globalFunction: GlobalFunction,
    public storageService: StorageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.action) {
      const list = this.list;
      this.filtered_ponds = [...list];
    }
    if (changes.filters_alerts) {
      const list = this.list;
      this.filtered_ponds = [...list];
      if (list.length > 0) {
        let pond = list[0];
        this.pondSelected = pond;
      }
    }
    /* if (changes.filters_alerts) {
      this.filtered_ponds = [...this.list];
      this.filters();
    } */
  }
  ngOnInit(): void {
    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        this.list.forEach((ponds, idxPonds) => {
          const linked_feeders = ponds.linked_feeders.map((item) => item);
          const feeders = this.globalFunction.setDataFeeders(
            linked_feeders,
            msg
          );
          if (feeders.feederChanged.length > 0) {
            this.detectIndexFeeder(msg, feeders.feederChanged, idxPonds);
            this.exist_feeder.emit({
              msg,
              feeders: feeders.feederChanged,
              pond_id: idxPonds,
            });
          }
        });
      });
  }

  detectIndexFeeder(socket, feederChanged, pond?) {
    if (socket.data) {
      const { data } = socket;

      const validateSocket = (socketType, alert, index) => {
        const validateNameAlert = () => {
          if (data.type === "ON" || data.type === "OFF") {
            if (Object.prototype.hasOwnProperty.call(data, "power")) {
              return data.power ? "turn_on" : "turn_off";
            } else if (
              Object.prototype.hasOwnProperty.call(data, "spin_time")
            ) {
              return data.spin_time
                ? "turn_on_spin_time"
                : "turn_off_spin_time";
            }
          }
          return alert;
        };
        const isLoading = () => {
          return (
            this.list[pond].linked_feeders[index].loadings[
              validateNameAlert()
            ] === "loading"
          );
        };

        return data.type === socketType && isLoading();
      };
      const AlertFeeders = [
        {
          name: "monitor",
          socket: "FEEDER_STATUS",
        },
        {
          name: "battery",
          socket: "BATTERY",
        },
        {
          name: "panel",
          socket: "PANEL",
        },
        {
          name: "version",
          socket: "VERSION",
        },
        {
          name: "signal",
          socket: "SIGNAL",
        },
        {
          name: "reset",
          socket: "RESET",
        },
        {
          name: "turn_on",
          socket: "ON",
        },
        {
          name: "turn_off",
          socket: "OFF",
        },
        {
          name: "refeed",
          socket: "FEEDING_OK",
        },
      ];

      // Funcion para calcular el tiempo transcurridos
      const generateTime = (initial) => {
        const finish = moment().format("HH:mm:ss");
        const entryHour = moment(initial ? initial : "", "hh:mm:ss");
        const exitHour = moment(finish, "hh:mm:ss");
        const duration = moment.duration(exitHour.diff(entryHour));
        return `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
      };
      const validateIndexFeeder = (feeder) => {
        return this.list[pond].linked_feeders.findIndex(
          (item) => item.id === feeder.id
        );
      };
      const validateAlert = (index) => {
        return this.list[pond].linked_feeders[index].alerts;
      };

      // Alimentadores que se cambiaron
      if (data.type && feederChanged) {
        feederChanged.forEach((feeder) => {
          const index = validateIndexFeeder(feeder);

          if (index >= 0) {
            let alerts = validateAlert(index);
            // Validamos la alerta que llega del socket para saber si coincide con
            // la que tenemos activa
            const findAlert = AlertFeeders.find(({ socket, name }) => {
              return validateSocket(socket, name, index);
            });

            if (!feeder.condition) {
              alerts.forEach((alert) => {
                if (pond >= 0) {
                  this.list[pond].linked_feeders[index].loadings[alert.alert] =
                    "error";
                  this.list[pond].linked_feeders[index].alerts = this.list[
                    pond
                  ].linked_feeders[index].alerts.map((item) => {
                    return !item.data ? { ...item, error: true } : { ...item };
                  });
                  this.list[pond].linked_feeders[index].condition = true;
                }
              });
            } else {
              if (findAlert) {
                const indexAlert = alerts.findIndex(
                  (item) => item.alert === findAlert.name
                );
                if (data && data.type) {
                  if (indexAlert >= 0) {
                    const timeInitial = alerts[indexAlert]
                      ? alerts[indexAlert].timeInitial
                      : "";

                    if (data.type !== "ON" && data.type !== "OFF") {
                      let alert = {
                        ...alerts[indexAlert],
                        data: { ...data },
                        time: generateTime(timeInitial),
                      };
                      if (pond >= 0) {
                        this.list[pond].linked_feeders[index].loadings[
                          findAlert.name
                        ] = "done";

                        this.list[pond].linked_feeders[index].alerts[
                          indexAlert
                        ] = alert;
                      }
                    }

                    if (data.type === "ON" || data.type === "OFF") {
                      // Validamos si en el objeto tiene la propiedad power para saber si encendio o apago
                      const hasPower = Object.prototype.hasOwnProperty.call(
                        data,
                        "power"
                      );

                      if (hasPower) {
                        const turn_on_off = data.power ? "turn_on" : "turn_off";
                        const timeInitial = alerts[indexAlert]
                          ? alerts[indexAlert].timeInitial
                          : "";
                        if (index >= 0) {
                          alerts[indexAlert] = {
                            data: { ...data },
                            alert: turn_on_off,
                            time: generateTime(timeInitial),
                          };
                        }
                        if (pond >= 0) {
                          this.list[pond].linked_feeders[index].loadings[
                            turn_on_off
                          ] = "done";
                          this.list[pond].linked_feeders[index].loadings[
                            data.power ? "turn_off" : "turn_on"
                          ] = "done";

                          this.list[pond].linked_feeders[index].alerts = alerts;
                        }
                      }
                      // validamos si existe spin_time para saber si el usuario esta activo o desacivo la rotacion
                      const hasSpinTime = Object.prototype.hasOwnProperty.call(
                        data,
                        "spin_time"
                      );
                      if (hasSpinTime) {
                        const turn_on_off_spin = data.spin_time
                          ? "turn_on_spin_time"
                          : "turn_off_spin_time";

                        const validate_find_spin_on_off = () => {
                          if (pond >= 0) {
                            return alerts.findIndex(
                              (item) =>
                                item.alert === turn_on_off_spin &&
                                this.list[pond].linked_feeders[index].loadings[
                                  turn_on_off_spin
                                ] === "loading"
                            );
                          }
                        };

                        const find_spin_on_off = validate_find_spin_on_off();

                        if (index >= 0) {
                          alerts[find_spin_on_off] = {
                            data: { ...data },
                            alert: turn_on_off_spin,
                            time: generateTime(
                              alerts[find_spin_on_off].timeInitial
                            ),
                          };
                        }
                        if (pond >= 0) {
                          this.list[pond].linked_feeders[index].loadings[
                            data.spin_time
                              ? "turn_on_spin_time"
                              : "turn_off_spin_time"
                          ] = "done";
                          this.list[pond].linked_feeders[index].alerts = alerts;
                        }
                      }
                    }
                  }
                }
              }

              const hasEmptied = Object.prototype.hasOwnProperty.call(
                data,
                "emptied"
              );

              if (hasEmptied) {
                if (index >= 0) {
                  const indexAlertEmptied = alerts.findIndex(
                    (item) => item.alert === "empty"
                  );

                  const timeInitial = alerts[indexAlertEmptied]
                    ? alerts[indexAlertEmptied].timeInitial
                    : "";
                  alerts[indexAlertEmptied] = {
                    data: { ...data },
                    alert: "empty",
                    time: generateTime(timeInitial),
                  };
                }
                // Vamos a quitar las alertas de turn_off cuando se encienda
                if (pond >= 0) {
                  this.list[pond].linked_feeders[index].alerts = alerts;
                }
              }
            }

            this.list.forEach((pond, idxPond) => {
              const indexTemp = pond.linked_feeders.findIndex(
                (val) => val.id == feeder.id
              );
              if (indexTemp >= 0) {
                this.list[idxPond].linked_feeders[indexTemp] = feeder;
              }
            });
          }
          // Validacion para saber si llega una respuesta del socket
        });
      }
    }
  }
  /*  */

  filters() {
    const { name, statusFeeders, pondSelected } = this.filters_alerts;
    const filter_name = (feeder) => {
      return name
        ? feeder.name.toLowerCase().indexOf(name.toLowerCase()) > -1
        : true;
    };
    const filter_status = (feeder) => {
      const loadings = [
        "monitor",
        "battery",
        "panel",
        "reset",
        "empty",
        "version",
        "signal",
        "refeed",
        "turn_off",
        "turn_on",
        "turn_off_spin_time",
        "turn_on_spin_time",
      ];

      return loadings.some((item) => feeder.loadings[item] === statusFeeders);
    };
    const validatePond = (pond) => {
      return pondSelected ? pond.id === Number(pondSelected) : true;
    };

    let temporal = [...this.list];
    const validate_temporal = (pond, idx) => {
      temporal[idx] = {
        ...pond,
        linked_feeders: pond.linked_feeders.filter((feeder) => {
          return (
            filter_name(feeder) && filter_status(feeder) && validatePond(pond)
          );
        }),
      };
    };
    temporal.forEach((pond, idx) => {
      validate_temporal(pond, idx);
    });

    if (pondSelected) {
      this.filtered_ponds = temporal.filter(
        (pond) => pond.id === Number(pondSelected)
      );
    } else {
      this.filtered_ponds = temporal;
    }
  }

  viewBox(e) {
    const boxes = document.querySelectorAll(`[id='box']`);

    const checkBoxes = () => {
      const triggerBottom = (window.innerHeight / 5) * 4;
      if (boxes.length > 0) {
        boxes.forEach((box, idx) => {
          const boxTop = box.getBoundingClientRect().top;

          if (boxTop < triggerBottom) {
            box.classList.add("animate__animated");
            box.classList.add("animate__fadeIn");
          } else {
            box.classList.remove("animate__animated");
            box.classList.remove("animate__fadeIn");
          }
        });
      }
    };

    checkBoxes();
  }

  countAlerts(feeder) {
    return feeder.alerts.filter((item) => item.data).length;
  }
  checkFeeder(idx) {
    this.check.emit(idx);
  }
  pondSelected;
  selectPond(pond) {
    this.pondSelected = pond;
    this.scrollTo(pond.id);
  }

  scrollTo(section) {
    const selector: any = document.querySelector(`[id='Section${section}']`);
    if (selector) {
      selector.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }
  currentSection = "";

  onSectionChange(sectionId: any) {
    if (sectionId) {
      let id = sectionId.replace("Section", "");
      let pond = this.list.find((item) => item.id === Number(id));
      this.pondSelected = pond;
      this.currentSection = sectionId;
    }
  }
  individualAction(value) {
    this.individualPetition.emit(value);
  }
  validateView = (pond) => {
    return this.pondSelected && pond.id === this.pondSelected.id;
  };
}
