import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { StorageService } from "../../routes/services/storage.service";

@Component({
  selector: "app-automatic-value",
  templateUrl: "./automatic_value.html",
  styleUrls: ["./automatic_value.scss"],
})
export class AutomaticValueComponent implements OnInit {
  interval: "c_hours" | "3_days" | "5_days" | "" = "c_hours";
  @Input() type: "individual" | "masive_feeders" | "masive_ponds";
  @Input() data: Array<any>;
  hoursSelected: any = [];
  socketSubsciption: Subscription;
  hours = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  @Output("submit") submit = new EventEmitter();

  constructor(public storageService: StorageService) {}
  ngAfterContentInit(): void {
    if (this.type === "individual" || this.type === "masive_feeders") {
      if (this.data.length === 1) {
        let value = this.data[0].sensors;
        if (value) {
          const { frequencies, frequencies_mode } = value;

          if (frequencies) {
            this.hoursSelected = frequencies;
          }
          if (frequencies_mode) {
            this.interval = this.setIntervalMode(frequencies_mode);
          }
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.socketSubsciption.unsubscribe();
  }
  ngOnInit(): void {
    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (msg.data && msg.data.type) {
          const { type, frequencies, frequencies_mode } = msg.data;
          let { mac, sensors } = this.data[0];
          if (
            this.type === "individual" ||
            (this.type === "masive_feeders" && this.data.length === 1)
          ) {
            if (mac === msg.data.mac) {
              if (type == "FREQUENCIES_RESPONSE") {
                this.hoursSelected = frequencies ? frequencies : [];
                if (frequencies_mode >= 0) {
                  this.interval = this.setIntervalMode(frequencies_mode);
                }
              }

              if (type === "FEEDER_STATUS") {
                if (frequencies_mode >= 0) {
                  this.interval = this.setIntervalMode(frequencies_mode);
                }
              }
            }
          }
        }
      });
  }
  setIntervalMode(frequencies_mode: Number) {
    if (frequencies_mode === 0) {
      return "";
    }
    if (frequencies_mode === 1) {
      return "c_hours";
    }
    if (frequencies_mode === 2) {
      return "3_days";
    }
    if (frequencies_mode === 3) {
      return "5_days";
    }
    return "";
  }

  SubmitInput() {
    this.submit.emit({
      type: "CHANGE_TIME_DAYS",
      value: { hours: this.hoursSelected, interval: this.interval },
    });
    /* Cada hora solo mandar peticion */
    /* Cada 3 hours : [ integer 0 al 24 ] */
    /* Cada 5 hours : [ integer 0 al 24 ] */
  }

  changeSelectHours(val) {
    if (val) {
      if (this.interval === "3_days" && this.hoursSelected.length < 3) {
        this.hoursSelected.push(val);
      }
      if (this.interval === "5_days" && this.hoursSelected.length < 5) {
        this.hoursSelected.push(val);
      }
      this.hoursSelected.sort((a, b) => a - b);
    }
  }

  validatechangedays() {
    if (this.hoursSelected) {
      if (this.hoursSelected.length === 5) {
        this.hoursSelected.splice(3, 5);
      }
      if (this.hoursSelected.length === 4) {
        this.hoursSelected.splice(3, 4);
      }
    }
  }
  deleteHour(i) {
    this.hoursSelected.splice(i, 1);
  }
  filterSelectItem(array) {
    let ejemplo = this.hoursSelected;
    let newArray = [...array];

    array.forEach((item, idx) => {
      ejemplo.forEach((ejem) => {
        if (item === Number(ejem)) newArray.splice(idx, 1);
      });
    });

    return newArray;
  }
}
