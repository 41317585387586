import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class FeedingReportsService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  getFeedingReports(query) {
    return this.http
      .get(`${Uris.FEEDINGREPORTS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getAccumulatedFeeding(query: string) {
    return this.http
      .get(
        `${Uris.FEEDINGREPORTS}accumulated-feeding/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getAverageFeeding(query: string) {
    return this.http
      .get(
        `${Uris.FEEDINGREPORTS}average-feeding/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getAverageDailyFeeding(query: string) {
    return this.http
      .get(
        `${Uris.FEEDINGREPORTS}average-daily-feeding/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getAverageMonthlyFeeding(query: string) {
    return this.http
      .get(
        `${Uris.FEEDINGREPORTS}average-monthly-feeding/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getAlerts(query: string) {
    return this.http
      .get(`${Uris.ALERTS}reports/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getReports(query: string) {
    return this.http
      .get(`${Uris.REPORTS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getReportsAlertAdmin(value: any, query: string) {
    return this.http
      .post(
        `${Uris.REPORTS}admin-alerts/${query}`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
