import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Uris } from "./Uris";
import { map, catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { StorageService, localValue } from "./storage.service";
import { permissions_client } from "../Clients/permissions/groups/add/permissions";

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  all_permissions: any = null;
  client_permissions: any = null;

  constructor(
    private http: HttpClient,
    public router: Router,
    public toastr: ToastrService,
    public storageService: StorageService
  ) {}

  validatePermissionsExistSubclient = (permission) => {
    const user = JSON.parse(localStorage.getItem("USER") || "") || null;
    const validate_all_permissions = () => {
      if (this.all_permissions) {
        /* Valida si los permisos existen en algun subcliente */
        const validate = Object.prototype.hasOwnProperty.call(
          this.all_permissions,
          permission
        );
        if (validate) {
          const farm_id = this.storageService.localValue.farm_id;
          return this.all_permissions[permission].some(
            (item) => Number(item) === Number(farm_id)
          );
        }
        return false
      }
      return true;
    };
    /* validamos si existe en el cliente primero para poder usarlo */
    /* consideramos los valores del cliente permissions_client si no los tiene el cliente no se mostrara
    y si no los tiene el subcliente */
    /* Todos los permisos que debemos de considerar del cliente */

    /* Existe el permiso en los clientees que debemos de considerar */
    const exist_permission_local_client = permissions_client.find(
      (item) => item.codename === permission
    );

    if (
      !this.storageService.validateSoloAdmin() &&
      user &&
      user.is_subclient &&
      user.use_permissions
    ) {
      /* El permisos existe en los que debemos de considerar */
      if (exist_permission_local_client) {
        /* Validamos permisos que tienen los subclientes */
        if (exist_permission_local_client.include_subclient) {
          const exist_in_client = Object.prototype.hasOwnProperty.call(
            this.client_permissions,
            permission
          );
          /* el permiso existe dentro de los permisos del cliente */
          if (exist_in_client) {
            /* Validamos permisos personales */
            return validate_all_permissions();
          } else {
            return false;
          }
        }
        /* Validamos permisos que no se consideran en el subcliente */
        if (!exist_permission_local_client.include_subclient) {
          const exist_in_client = Object.prototype.hasOwnProperty.call(
            this.client_permissions,
            permission
          );
          /* validacion de permisos solo del cliente */
          return exist_in_client;
        }
      } else {
        /* permisos que no existen en el cliente */
        return validate_all_permissions();
      }
    }
    return true;
  };

  validatePermissionsExistClient = (permission) => {
    const user = JSON.parse(localStorage.getItem("USER") || "") || null;
    if (
      this.all_permissions &&
      !this.storageService.validateSoloAdmin() &&
      user
    ) {
      const validate = Object.prototype.hasOwnProperty.call(
        this.all_permissions,
        permission
      );
      if (!user.is_subclient) {
        return validate;
      }
    }
    return true;
  };

  validatePermissions = (permission) => {
    const user = JSON.parse(localStorage.getItem("USER") || "") || null;
    if (this.all_permissions && user) {
      const validate = Object.prototype.hasOwnProperty.call(
        this.all_permissions,
        permission
      );
      return validate;
    }
    return false;
  };

  getAvailablePermissions(query) {
    return this.http
      .get(
        `${Uris.USERS}available_permissions/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getGroupById(id, query) {
    return this.http
      .get(`${Uris.GROUPS}${id}/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  groups(body) {
    return this.http
      .post(`${Uris.GROUPS}`, body, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  groupsChangeUserPermissions(id, body) {
    return this.http
      .put(
        `${Uris.USERS}${id}/change_user_permissions/`,
        body,
        this.storageService.headersSoloAdmin()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  groupsChangeUser(id, body) {
    return this.http
      .put(
        `${Uris.GROUPS}${id}/change_users/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  patchGroup(id, body) {
    return this.http
      .patch(`${Uris.GROUPS}${id}/`, body, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  listgroups(query) {
    return this.http
      .get(`${Uris.GROUPS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  deletegroups(id) {
    return this.http
      .delete(`${Uris.GROUPS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  userPermissions() {
    return this.http
      .get(`${Uris.USERS}all_permissions/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getClientPermissionsWithSubclient() {
    return this.http
      .get(`${Uris.USERS}client_permissions/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
