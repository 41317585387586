import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { NgxIndexedDBService } from "ngx-indexed-db";

@Injectable()
export class TimingInterceptor implements HttpInterceptor {
  constructor(private dbService: NgxIndexedDBService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const startTime = performance.now();

    return next.handle(req).pipe(
      tap((event) => {
        if (event.type === 4) {
          try {
            const endTime = performance.now();
            const timeTaken = endTime - startTime;
            const seconds = Number((timeTaken / 1000).toFixed(2));

            this.dbService.getAll("urls").subscribe(
              (db) => {
                const exist: any = db.find(
                  (value_db: any) => value_db.url === req.url
                );
                if (exist) {
                  this.dbService
                    .update("urls", {
                      id: exist.id,
                      url: req.url,
                      seconds,
                    })
                    .subscribe((resp) => {});
                } else {
                  this.dbService
                    .bulkAdd("urls", [{ url: req.url, seconds }])
                    .subscribe((resp) => {});
                }
              },
              async (err) => {}
            );
          } catch (error) {}

          /*    console.log(`La petición a ${req.url} tomó ${timeTaken} seconds`); */
        }
      })
    );
  }
}
