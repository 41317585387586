import { PermissionsService } from "../../routes/services/permissions.service";
import { TranslatorService } from "../../core/translator/translator.service";
import { StorageService } from "../../routes/services/storage.service";
import { GatewayService } from "../../routes/services/gateway.service";
import { SettingsService } from "../../core/settings/settings.service";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { FramesService } from "../../routes/services/frames.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FoodsService } from "../../routes/services/foods.service";
import { DietService } from "../../routes/services/diet.service";
import { HelpService } from "../../core/help/help.service";
import { POND } from "../plantings/interfaces";
import { EventEmitter } from "@angular/core";
import * as moment from "moment";
import {
  SimpleChanges,
  TemplateRef,
  Component,
  ViewChild,
  OnInit,
  Output,
  Input,
} from "@angular/core";

@Component({
  selector: "app-feed",
  templateUrl: "./feed.component.html",
  styleUrls: ["./feed.component.scss"],
})
export class FeedComponent implements OnInit {
  @ViewChild("modalAlertPlanting") modalAlertPlanting;
  @ViewChild("modalCosecha") modalCosecha;
  @ViewChild("modalSiembra") modalSiembra;

  @Input() DataPonds: POND | Array<POND> | any;
  @Input() DataForm: any;

  @Output("closedFeed") closedFeeds = new EventEmitter();
  @Output("submitFeed") submitFeed = new EventEmitter();
  @Output("submitHarvest") submitHarvest = new EventEmitter();

  @Input() ButtonClosed: Boolean = false;
  @Input() typeView: String = "";
  @Input() default_manual: Boolean = false;

  @ViewChild("test") public ModalTesting;
  @ViewChild("modalDiet") public ModalDiet;

  FeedForm: FormGroup = new FormGroup({
    kg: new FormControl("", Validators.required),
    diet: new FormControl(""),
    food: new FormControl("", Validators.required),
    planned_end_date: new FormControl(""),
    planned_days: new FormControl(""),
  });
  diets = [];
  foods = [];
  @Input() header = true;

  manual = false;

  kgPond;
  kgHec;
  modalAlert = false;
  modalAliasDate = false;
  element: HTMLElement;

  manualDate: any;
  loadingFeeder = false;
  feedPonds = [];
  view_response = false;
  submit_response = false;

  permissions = {
    view_harvest: true,
    add_plantings: true,
  };

  dataGraph = [{ data: [85, 72, 78, 75, 77, 75], label: "Crude oil prices" }];
  labelsGraph = ["January", "February", "March", "April", "May", "June"];

  constructor(
    public permissionsService: PermissionsService,
    public translatorService: TranslatorService,
    public PostFeedsService: GatewayService,
    public settingService: SettingsService,
    public storageService: StorageService,
    public GlobalFunction: GlobalFunction,
    public frameService: FramesService,
    public FoodsService: FoodsService,
    public DietService: DietService,
    public helpService: HelpService,
    public dietService: DietService,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    config.size = "xl";
    config.centered = true;
    config.animation = false;
  }
  food_selected: any = null;
  ngOnDestroy(): void {
    /* Almacenar la variable de modal de test */
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["default_manual"]) {
      this.manual = changes["default_manual"].currentValue;
    }
    if (changes["DataForm"]) {
      this.FeedForm.setValue({
        ...this.FeedForm.value,
        ...changes["DataForm"].currentValue,
      });
      this.kgPond = Number(changes["DataForm"].currentValue.kg);

      if (changes["DataForm"].currentValue.food) {
        console.log(changes["DataForm"].currentValue.food);
        this.select_food(changes["DataForm"].currentValue.food);
      }
    }
    if (changes["DataPonds"]) {
      this.DataPonds = changes["DataPonds"].currentValue;
    }

    this.calculateKgHA(this.DataPonds, 1);
  }

  ngOnInit(): void {
    const validate_subclient = (prop) => {
      return this.permissionsService.validatePermissionsExistSubclient(prop);
    };

    const keys_validate_subclient = ["view_harvest", "add_plantings"];

    keys_validate_subclient.forEach((key) => {
      this.permissions[key] = validate_subclient(key);
    });

    if (this.storageService.localValue) {
      const personal_web = this.storageService.localValue;
      this.DietService.getDiets("").subscribe((resp) => {
        this.diets = resp;
      });
      const { farm_id } = personal_web;

      const queryFarm = farm_id ? `?linked_farm=${farm_id}` : "";
      this.FoodsService.getFoods(queryFarm).subscribe((resp) => {
        this.foods = resp;
      });
    }

    if (this.helpService.activeTutorial) {
      const content = [
        {
          element: "#manual_feed",
          title: "Alimentar manualmente",
          intro:
            "Eso es por si los alimentadores no tienen conexión con los servidores, y no se pierda la información.",
          position: "top",
        },
        {
          element: "#kg_feed",
          title: "Kilogramos a alimentar",
          intro:
            "Puedes indicar cuandos kg serán por el estanque(Ambos datos son intercambiables).",
          position: "top",
        },
        {
          element: "#kgHec",
          title: "Kilogramos a alimentar",
          intro:
            "O si lo prefieres indica cuandos kg serán por la hectarea (Ambos datos son intercambiables).",
          position: "top",
        },
        {
          element: "#diet_feed",
          title: "Dieta",
          intro:
            "Las dietas indican la forma en la que se distriburá el alimento, a lo largo del día.",
          position: "top",
        },
        {
          element: "#food_feed",
          title: "Alimento",
          intro:
            "El alimento que se le dará al estanque y que está depositado en la tolva.",
          position: "top",
        },
        {
          element: "#refeed_feed",
          title: "Reenviar racion",
          intro: "Envía la ultima configuración registrada.",
          position: "bottom",
        },
        {
          element: "#test_feed",
          title: "Test",
          intro: "Verifica si hay conectividad con la alimentadora.",
          position: "bottom",
        },
        {
          element: "#send_feed",
          title: "Enviar racion",
          intro:
            "Finaliza el proceso y envía la configuración de racion que haz realizado.",
          position: "bottom",
        },
      ];

      this.helpService.runOther(content, true);
    }
  }

  ngAfterContentInit(): void {
    this.typeView === "info" &&
      setTimeout(() => {
        if (this.validateHarvestDate()) {
          this.modalAlert = true;
        }
        console.log("validacion de recursos!!");

        if (this.validateAliasDate() && this.validateAliasDate().label) {
          this.modalAliasDate = true;
        }
        let inputs1 = <HTMLInputElement>document.getElementById("food_feed");
        let inputs2 = <HTMLInputElement>document.getElementById("diet_feed");
        if (inputs1 && inputs2) {
          inputs1.addEventListener("keydown", (event: any) => {
            event.preventDefault();
          });

          inputs2.addEventListener("keydown", (event: any) => {
            event.preventDefault();
          });
        }
      }, 500);
  }
  view_button_close = true;
  validateMonths = () => {
    const pond = this.DataPonds[0];
    try {
      if (pond.planting) {
        if (this.validateTextByMonthLimit(pond.planting.date, pond.planting.planned_end_date)) {
          return false
        }
      }
    } catch (error) {
      return true
    }
  }
  validateHarvestDate() {
    const pond = this.DataPonds[0];

    try {
      if (pond.planting) {
        let fecha1: any = moment(new Date(), "YYYY-MM-DD");
        let fecha2 = moment(
          `${pond.planting.planned_end_date}T01:00:00.112276-07:00`,
          "YYYY-MM-DD"
        );
        const days = fecha2.diff(fecha1, "days");
        if (this.validateTextByMonthLimit(pond.planting.date, pond.planting.planned_end_date)) {
          this.view_button_close = false;
          return "Tu fecha estimada de cosecha no puede ser mayor a 10 meses"
        }
        if (!pond.planting.planned_end_date && !pond.is_done) {
          return "";
        }
        if (days < 0 && !pond.is_done) {
          this.view_button_close = false;
          /* muestra alerta que debes de cosechar  */
          return `Tu fecha estimada de cosecha lleva ${
            days < 0 ? days * -1 : days
          } dias de retraso, puedes extender la fecha aproximada de cosecha o si ya concluiste tu siembra puedes proceder a cosechar.`;
        }
        if (days === 0 && !pond.is_done) {
          /* muestra alerta que debes de cosechar  */
          return `Tu fecha estimada de cosecha es hoy, puedes extender la fecha aproximada de cosecha o si ya concluiste tu siembra puedes proceder a cosechar.`;
        }
        if (days > 0 && days < 7 && !pond.is_done) {
          /* muestra alerta que debes de cosechar  */
          return `Tu fecha estimada de cosecha se aproxima en ( ${days} ) dias, puedes extender la fecha aproximada de cosecha o si ya concluiste tu siembra puedes proceder a cosechar. `;
        }
      }
    } catch (error) {
      return "";
    }
    return "";
  }

  /* cosechar y extender */
  validateAliasDate(): any {
    try {
      let pond = this.DataPonds[0];
      if (pond.planting) {
        const { season, planned_end_date, is_harvested } = pond.planting;
       
        if (
          (!season || season === null) &&
          !planned_end_date &&
          !is_harvested
        ) {
          return {
            label:
              "La siembra no tiene temporada ni fecha estimada de cosecha asignada.",
            value: 1,
          };
        }
        if ((!season || season === null) && planned_end_date && !is_harvested) {
          return {
            label: "La siembra aún no tiene temporada asignada.",
            value: 2,
          };
        }
        if (season && !planned_end_date && !is_harvested) {
          return {
            label:
              "La siembra aún no tiene una fecha estimada de cosecha asignada.",
            value: 3,
          };
        }
        if (season && planned_end_date) {
          return {
            label: "",
            value: 0,
          };
        }
        if (pond.is_done) {
          return {
            label: "",
            value: 0,
          };
        }
      }
    } catch (error) {
      return {
        label: "",
        value: 0,
      };
    }
  }

  validateTextByMonthLimit = (date, planned_end_date) => {
    if (date && planned_end_date) {
      const targetDate = moment(planned_end_date);
      return !targetDate.isBetween(
        moment(date),
        moment(date).clone().add(10, "months"),
        "M",
        "[]"
      );
    }
    return false;
  };

  plantingSelected;
  goCosechas(type) {
    this.type = type;
    const length = this.DataPonds.length > 0;

    if (length && this.DataPonds[0].planting) {
      this.plantingSelected = this.DataPonds[0].planting;

      this.Modal(this.modalCosecha, "show");
    }

    this.modalAlert = false;
    this.modalAliasDate = false;
  }

  type = "";
  goPlanting(type?) {
    const length = this.DataPonds.length > 0;

    if ((length && this.DataPonds[0].planting) || !this.DataPonds[0].planting) {
      if (type !== "") {
        this.plantingSelected = this.DataPonds[0].planting;
      }
      this.Modal(this.modalSiembra, "show");
    }
    this.type = type;
    this.modalAlert = false;
    this.modalAliasDate = false;
  }

  closedClick() {
    this.closedFeeds.emit("hola");
  }

  changeManual(value) {
    if (value) {
      this.FeedForm.setValue({
        ...this.FeedForm.value,
        diet: "-1",
        kg: 0,
      });
    } else {
      this.FeedForm.setValue({
        ...this.FeedForm.value,
        diet: "",
        kg: 0,
      });
    }

    this.kgPond = 0;
    this.kgHec = 0;
    // this.manual = !this.manual;
  }

  textPonds(value) {
    let text = "";
    if (value) {
      if (value.name) {
        text = value.name;
      } else if (value.length > 0) {
        let array = value.map((resp) => resp.name);
        text = array.toString();
      } else if (value.length === 0) {
        text = "";
      }
    }
    return text;
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  calculateKgHA(ponds, type) {
    if (ponds.length > 0) {
      if (type === 2) {
        /* Kilogramos por estanque  */
        this.kgPond = this.kgHec * ponds[0].hectares;
        this.kgPond = this.kgPond.toFixed(2);
      } else {
        /* Kilogramos por hectarea */
        this.kgHec = this.kgPond / ponds[0].hectares;
        this.kgHec = this.kgHec.toFixed(2);
      }
    }
  }

  validatePonds(kgha, ponds) {
    if (ponds) {
      if (ponds.length > 1) {
        return this.translatorService.translate("general.undefined");
      } else {
        return ponds.length === 1
          ? Number(kgha) / ponds[0].hectares
          : this.translatorService.translate("general.select-pond");
      }
    }
  }

  Modal = async (template: TemplateRef<any>, type) => {
    switch (type) {
      case "show":
        const ref = await this.modalService.open(template);
        await ref.shown.subscribe();
        break;
      case "hide":
        this.modalService.dismissAll(``);
        if (this.validateHarvestDate()) {
          this.modalAlert = true;
        }
        break;
      default:
    }
  };

  textError(e) {
    let { error, statusText } = e;
    let arrayErrors: Array<any> = error ? Object.values(error) : [];
    for (let i = 0; i < arrayErrors.length; i++) {
      if (arrayErrors[i] === true) {
        return `${statusText.toString()}`;
      } else {
        return `${arrayErrors[i].toString()}`;
      }
    }
  }

  validateButtonDisabled() {
    try {
      const length = this.DataPonds && this.DataPonds.length > 0;
      if (length && this.DataPonds[0].planting) {
        let pond = this.DataPonds[0];
        let fecha1 = moment(new Date());
        let fecha2 = moment(
          `${pond.planting.planned_end_date}T10:00:00.112276-07:00`
        );

        let days = fecha2.diff(fecha1, "days");
        if (!pond.planting) {
          return true;
        }
        if (pond.is_done) {
          return true;
        }
        if (!pond.planting.planned_end_date && !pond.is_done) {
          return false;
        }

        if (days >= 0 && !pond.is_done) {
          return false;
        }
        if (days < 0 && !pond.is_done) {
          return true;
        }
      }
      if (length && !this.DataPonds[0].planting) {
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  checkKey(e: any) {
    e.preventDefault();
    let array = ["kgPond", "kgHec", "kg_feed", "diet_feed", "food_feed"];
    const validate = (key) => {
      let index = array.findIndex((item) => e.target.id === item);
      if (key === 39) {
        let next = array[index + 1];
        if (this.DataPonds.length === 1 && next === "kg_feed") {
          next = "diet_feed";
        }
        if (!next) {
          next = "kgPond";
        }

        let nu: any = <HTMLInputElement>document.getElementById(next);
        nu.focus();
      }
      if (key === 37) {
        let previus = array[index - 1];
        if (this.DataPonds.length === 1 && previus === "kg_feed") {
          previus = "kgHec";
        }
        if (!previus) {
          previus = "food_feed";
        }
        let nu: any = <HTMLInputElement>document.getElementById(previus);
        nu.focus();
      }
    };
    // left arrow
    if (e.keyCode == "37") {
      validate(37);
    }
    // right arrow
    if (e.keyCode == "39") {
      validate(39);
    }
  }
  status_server = "";
  viewModalResponse = async (status) => {
    if (this.view_response) {
      /* refeed */

      this.DataPonds = await this.DataPonds.map((pond) => {
        const feeders_on = pond.linked_feeders.filter(
          (feeder) => feeder.status_power && !feeder.alert_comm
        );
        return {
          ...pond,
          linked_feeders: feeders_on.map((feeder) => {
            return {
              ...feeder,
              status_refeed: {
                server: status,
                feeder_status: "loading",
                ration_status: "loading",
              },
            };
          }),
        };
      });
      this.submit_response = true;
      this.status_server = status;
    }
  };
  mode_test = false;
  register() {
    this.mode_test = true;
    const value = this.FeedForm.value;
    this.viewModalResponse("loading");
    if (this.manual) {
      this.DataPonds.forEach((item) => {
        let obj = {
          food: Number(value.food),
          amountKgs: Number(value.kg),
          pond: item.id,
          created_date: this.manualDate,
        };

        this.PostFeedsService.postManualFeed(obj).subscribe(
          (resp) => {
            this.viewModalResponse("done");
          },
          (err) => {
            this.viewModalResponse("error");
          }
        );
      });
    } else {
      let obj = {
        diet: Number(value.diet),
        food: Number(value.food),
        kge: Number(value.kg),
        ponds: this.DataPonds.map((item) => {
          return item.id;
        }),
        datetime: moment().format(),
      };
      this.loadingFeeder = true;

      this.PostFeedsService.sendFeedFrame(obj).subscribe(
        (resp) => {
          this.submitFeed.emit(this.DataPonds);
          this.viewModalResponse("done");

          this.feedPonds = this.DataPonds;
          this.loadingFeeder = false;
        },
        (err) => {
          this.viewModalResponse("error");
        }
      );
    }
  }
  refeed = async () => {
    const item = this.DataPonds;

    let array = item.map((item) => {
      return item.id;
    });
    this.viewModalResponse("loading");
    this.frameService.refeed({ ponds: array }).subscribe(
      (resp) => {
        this.GlobalFunction.animation(
          "success",
          "Se ha reenviado la ración",
          "Correctamente"
        );
        this.submitFeed.emit(this.DataPonds);
        this.viewModalResponse("done");
      },
      (err) => {
        this.viewModalResponse("error");
      }
    );
  };
  testStatus = () => {
    this.mode_test = false;
    this.viewModalResponse("");
    const ponds_id = this.DataPonds.map((item) => item.id);

    if (ponds_id.length > 0) {
      this.frameService
        .Frame("status", `?pond_id=${ponds_id}`)
        .subscribe((item) => {
          this.viewModalResponse("done");
        });
    }
  };

  closedTest(event) {
    this.submit_response = event;
    this.status_server = "";

    this.DataPonds = this.DataPonds.map((pond) => {
      return {
        ...pond,
        linked_feeders: pond.linked_feeders.map((feeder) => {
          return {
            ...feeder,
            status_refeed: {
              server: "",
              feeder_status: "",
              ration_status: "",
            },
          };
        }),
      };
    });
  }
  submitHarvests = (event) => {
    this.modalService.dismissAll(``);
    this.submitHarvest.emit(event);
  };

  setData() {
    console.log(this.FeedForm.value);
    const diet = this.FeedForm.value.diet;
    if (diet) {
      this.dietService.getDiet(this.FeedForm.value.diet).subscribe((resp) => {
        this.dataGraph = this.constructdataGraph(resp).Data;
        this.labelsGraph = this.constructdataGraph(resp).Labels;
        this.Modal(this.ModalDiet, "show");
      });
    }
  }
  constructdataGraph(value) {
    let Labels: Array<any> = [];
    let Data: Array<any> = [];

    for (let i = 1; i < 25; i++) {
      let horaInitial = moment(new Date(2018, 11, 24, 0, 0, 0, 0)).format("HH");
      Labels.push(`${Number(horaInitial) + i - 1}:00`);
    }
    Data = [
      {
        data: value.percentages.map((item) => {
          return Number(item) * 100;
        }),
        name: "porcentajes",
        type: "bar",
        color: "#30646c",
      },
      {
        data: value.intervals.map((item) => {
          return item;
        }),
        name: "Intervalos",
        type: "bar",
        color: "#00adac",
      },
    ];
    return { Labels, Data };
  }
  select_food = (id) => {
    console.log(id);

    this.food_selected = this.foods.find(
      (item: any) => Number(item.id) === Number(id)
    );
    console.log(this.food_selected);
  };
}
