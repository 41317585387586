import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class ObservationService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router,
  ) { }

  getFeederObservations(feederId: any) {
    return this.http
      .get(`${Uris.FEEDER}${feederId}/observations/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      )
  }

  createFeederObservation(feederId: any, obj: any) {
    return this.http
      .post(`${Uris.FEEDER}${feederId}/observations/`, obj, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      )
  }

  solveFeederObservation(feederId: any, observationId: any) {
    return this.http
      .post(`${Uris.FEEDER}${feederId}/observations/${observationId}/solve/`, {}, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      )
  }
}
