import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FramesService } from "../../routes/services/frames.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";

@Component({
  selector: "app-speed",
  templateUrl: "./speed.html",
  styleUrls: ["./speed.scss"],
})
export class SpeedComponent implements OnInit {
  /* Solo cuando es individual */
  @Input() feeder;
  @Input() indexFeeder;

  /* Solo cuando es masivo */
  @Input() lisFeeders: Array<any> = [];
  @Input() type: "individual" | "masive" | "masive_farm";

  @Input() farm_id;
  @Output() sendData: EventEmitter<any> = new EventEmitter();

  @Input() header = true;

  dataMasive = {
    status_high_speed: 0,
    status_low_speed: 0,
    status_speed: 0,
    view_speed: false,
    view_mode_speed: "orbital",
  };

  optionSpeed = [37.5, 50, 62.5, 75, 87.5, 100];

  constructor(
    public framesService: FramesService,
    public globalFunction: GlobalFunction
  ) {}

  ngOnInit(): void {
    if (this.lisFeeders && this.lisFeeders.length) {
      const feeder = this.lisFeeders.find(
        (item) =>
          this.globalFunction.validateVersion(item, 0.5) &&
          (item.status_speed_mode === 1 || item.status_speed_mode === 2)
      );
      if (feeder) {
        const {
          status_high_speed,
          status_low_speed,
          status_speed,
          status_speed_mode,
        } = feeder;
        let text = {
          0: "none",
          1: "normal",
          2: "orbital",
        };

        this.dataMasive = {
          ...this.dataMasive,
          status_high_speed,
          status_low_speed,
          status_speed,
          view_mode_speed: text[status_speed_mode],
        };
      } else {
        this.dataMasive = {
          ...this.dataMasive,
          status_speed: 100,
          view_mode_speed: "none",
        };
      }
    }
  }

  validateCheckSpeed() {
    if (this.type === "individual") {
      let feeder = this.feeder;
      const { view_mode_speed, status_speed_mode, view_speed } = feeder;
      /* status_speed_mode 0 = Apagado - 1 = normal - 2 orbital */
      const NoneSpeed = status_speed_mode === 0;
      const SpeedNormal =
        view_mode_speed === "normal" && status_speed_mode === 1;
      const SpeedOrbital =
        view_mode_speed === "orbital" && status_speed_mode === 2;

      /* orbital o normal encendido */
      if (SpeedOrbital) {
        if (SpeedNormal) {
          return false;
        }
        return true;
      }
      if (SpeedNormal) {
        if (SpeedOrbital) {
          return false;
        }
        return true;
      }
      if (NoneSpeed) {
        return false;
      }
    } else {
      const feeder = this.dataMasive;

      return (
        feeder.view_mode_speed === "normal" ||
        feeder.view_mode_speed === "orbital"
      );
    }
  }

  changeStatusSpeed(value) {
    const { view_mode_speed, status_speed_mode, view_speed, id } =
      this.type === "individual" ? this.feeder : this.dataMasive;

    const speed = view_mode_speed === "normal";

    let query = ``;
    if (this.type === "individual") {
      query = `?id_in=${id}`;
    }
    if (this.type === "masive") {
      query = `?id_in=${this.lisFeeders.map((item) => item.id)}`;
    }
    if (this.type === "masive_farm") {
      query = `?farm_id=${this.farm_id}`;
    }
    /* Peticion para activar velocidad normal */
    const { status_high_speed, status_low_speed, status_speed } =
      this.dataMasive;

    if (speed && value) {
      this.framesService.Frame(`enable_speed`, query).subscribe((resp) => {
        this.feeder.enable_speed = true;
        this.feeder.orbital_enabled = false;
      });
    }
    /* Peticion para desactivar velocidad normal */
    if (speed && !value) {
      this.framesService.Frame("disable_speed", query).subscribe((resp) => {});
    }
    /* Peticion para activar velocidad orbital */

    if (!speed && value) {
      let obj = {
        high: status_high_speed,
        low: status_low_speed,
        orbital_enabled: true,
      };

      this.framesService
        .Frame(`enable_orbital`, query, obj)
        .subscribe((resp) => {});
    }

    /* Peticion para desactivar velocidad orbital */
    if (!speed && !value) {
      this.framesService
        .Frame("disable_orbital", query)
        .subscribe((resp) => {});
    }
  }

  changeSpeed() {
    let feeder = this.type === "individual" ? this.feeder : this.dataMasive;
    const { status_speed_mode, id, view_mode_speed, status_version } = feeder;

    /* Cuando sea una version 5 mostrar que sea una velocidad normal pero el objeto sera como si fuera orbital */
    /*   (change)="changeSpeed($event.target.value,feeder,'status_high_speed')" */

    let obj = {};
    if (this.type === "individual") {
      let petition: "set_orbital_speed" | "set_speed" =
        view_mode_speed === "orbital" || status_version === "NAS0.5"
          ? "set_orbital_speed"
          : "set_speed";

      /* Version 5 hardcodeada */
      if (status_version === "NAS0.5") {
        obj = {
          high: feeder["status_speed"],
          low: feeder["status_speed"],
          orbital_enabled: true,
        };
      } else {
        if (view_mode_speed === "orbital") {
          obj = {
            high: feeder["status_high_speed"],
            low: feeder["status_low_speed"],
            orbital_enabled: true,
          };
        } else {
          obj = {
            speed: feeder["status_speed"],
          };
        }
      }

      this.framesService
        .individualFrames(petition, id, obj)
        .subscribe((resp) => {});
    }

    let petition: "set_orbital_speed" | "set_speed" =
      view_mode_speed === "orbital" || status_version === "NAS0.5"
        ? "set_orbital_speed"
        : "set_speed";
    if (this.type === "masive") {
      const { status_high_speed, status_low_speed, status_speed } =
        this.dataMasive;

      const feederVersion5 = this.lisFeeders.filter(
        (item) => item.status_version === "NAS0.5"
      );

      const feederNormal = this.lisFeeders.filter(
        (item) => item.status_version !== "NAS0.5"
      );
      if (feederVersion5.length > 0) {
        if (view_mode_speed === "orbital") {
          obj = {
            high: status_high_speed,
            low: status_low_speed,
            orbital_enabled: true,
          };
        } else {
          obj = {
            high: status_speed,
            low: status_speed,
            orbital_enabled: true,
          };
        }

        this.framesService
          .FrameGatewayMasivePost(
            "set_orbital_speed",
            `?id_in=${feederVersion5.map((item) => item.id)}`,
            obj
          )
          .subscribe((resp) => {});
      }

      if (feederNormal.length > 0) {
        if (view_mode_speed === "orbital") {
          obj = {
            high: feeder["status_high_speed"],
            low: feeder["status_low_speed"],
            orbital_enabled: true,
          };
        } else {
          obj = {
            speed: feeder["status_speed"],
          };
        }

        this.framesService
          .FrameGatewayMasivePost(
            petition,
            `?id_in=${feederNormal.map((item) => item.id)}`,
            obj
          )
          .subscribe((resp) => {});
      }
    }
    if (this.type === "masive_farm") {
      if (view_mode_speed === "orbital") {
        obj = {
          high: feeder["status_high_speed"],
          low: feeder["status_low_speed"],
          orbital_enabled: true,
        };
      } else {
        obj = {
          speed: feeder["status_speed"],
        };
      }
      this.framesService
        .FrameGatewayMasivePost(petition, `?farm_id=${this.farm_id}`, obj)
        .subscribe((resp) => {});
    }
  }

  validateSpeedButton() {
    let feeder = this.type === "individual" ? this.feeder : this.dataMasive;

    const {
      status_high_speed,
      status_low_speed,
      status_speed,
      view_mode_speed,
    } = feeder;

    const high_speed = Number(status_high_speed);
    const low_speed = Number(status_low_speed);

    if (view_mode_speed === "orbital" && high_speed > low_speed) {
      if (
        high_speed >= 37.5 &&
        low_speed >= 37.5 &&
        high_speed <= 100 &&
        low_speed <= 100
      ) {
        return false;
      }
    }
    if (view_mode_speed === "normal" && status_speed >= 37.5) {
      return false;
    }

    return true;
  }

  validateViewSpeed() {
    if (this.type === "individual") {
      const { view_mode_speed } = this.feeder;
      return view_mode_speed === "orbital";
    }
    if (this.type === "masive" || this.type === "masive_farm") {
      const { view_mode_speed } = this.dataMasive;
      return view_mode_speed === "orbital";
    }
  }

  validateInputsSpeed(
    input:
      | "status_high_speed"
      | "status_low_speed"
      | "status_speed"
      | "view_mode_speed"
      | "option_type"
  ) {
    if (this.type === "individual") {
      return this.feeder[input];
    }
    if (this.type === "masive" || this.type === "masive_farm") {
      return this.dataMasive[input];
    }
  }

  changeInput(target, input) {
    let value = input === "view_mode_speed" ? target : target.value;

    if (this.type === "individual") {
      this.feeder[input] = value;
    }
    if (this.type === "masive" || this.type === "masive_farm") {
      this.dataMasive[input] = value;
    }
  }

  validateVersion() {
    if (this.type === "individual") {
      return this.globalFunction.validateVersion(this.feeder, 0.5);
    }
    if (this.type === "masive" || this.type === "masive_farm") {
      return true;
    }
  }

  nameInput() {
    if (this.type === "individual") {
      return `speed_${this.feeder ? this.feeder.name : this.type}`;
    }
    if (this.type === "masive" || this.type === "masive_farm") {
      return `speed_masive`;
    }
  }

  getSpeed() {
    this.framesService
      .FrameGatewayPost("request_speed", `?id_in=${this.feeder.id}`, {})
      .subscribe((resp) => {});
  }
  buttonGetSpeed() {
    if (this.lisFeeders && this.lisFeeders.length) {
      const feeder = this.lisFeeders.find((item) =>
        this.globalFunction.validateVersion(item, 0.5)
      );
      if (feeder) {
        const {
          status_high_speed,
          status_low_speed,
          status_speed,
          status_speed_mode,
        } = feeder;
        let text = {
          0: "speed",
          2: "orbital",
        };
        this.dataMasive = {
          ...this.dataMasive,
          status_high_speed,
          status_low_speed,
          status_speed,
          view_mode_speed: text[status_speed_mode]
            ? text[status_speed_mode]
            : 0,
        };
      }
    }
  }
}
