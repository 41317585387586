import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StorageService } from "../../../../../routes/services/storage.service";

@Component({
  selector: "app-button-basic",
  templateUrl: "./basic.html",
  styleUrls: ["../../../panel-alerts.scss"],
})
export class ButtonBasicComponent implements OnInit {
  @Input() icon = "";
  @Input() label = "";
  @Input() action = "";
  @Input() status = "";
  @Input() count = 0;
  @Input() done = 0;
  @Input() disabled = false;

  @Output("submitMenu") submitMenu = new EventEmitter();

  constructor(public storageService: StorageService) {}

  ngOnInit(): void {}

  onclick() {
    if (this.count > 0) {
      this.submitMenu.emit(this.action);
    }
  }

  validateButtonClassStatus() {
    if (this.count === 0) {
      return "";
    }
    if (this.status === "loading") return "bg-secondary";
    if (this.status === "warning") return "bg-warning";
    if (
      this.status === "error" ||
      this.action === "turn_off" ||
      this.action === "turn_off_spin_time"
    )
      return "bg-danger";
    if (
      this.status === "success" ||
      this.action === "turn_on" ||
      this.action === "turn_on_spin_time"
    )
      return "bg-success";
    if (this.status === "") return "";
  }
  validateViewButtons() {
    if (
      this.action === "battery" ||
      this.action === "panel" ||
      this.action === "signal" ||
      this.action === "version"
    ) {
      return this.storageService.validateSoloAdmin();
    } else {
      return true;
    }
  }
}
