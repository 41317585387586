import { Component, Injectable, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { CalibrationService } from "../../routes/services/calibration.service";
import { FeedersService } from "../../routes/services/feeder.service";
import { FoodsService } from "../../routes/services/foods.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { ProductsService } from "../../routes/services/products.service";
import { StorageService } from "../../routes/services/storage.service";

@Component({
  selector: "app-calibration-food",
  templateUrl: "./food.html",
  styleUrls: ["./food.scss"],
})
@Injectable({
  providedIn: "root",
})
export class CalibrationFoodComponent implements OnInit {
  @Input() edit: any = false;
  @Input() food: any = null;
  @Input() id: any = null;
  @Input() section: "calibration" | "feed" = "feed";
  @Input() feedRecomended = 0;
  @Input() dispenser: any = null;
  foodForm: FormGroup;
  calibrationsFood: any = [];
  foodProducts = [];
  foodBrands = [];

  loadingSubmit = false;

  batch = moment(new Date()).format("YYYY-MM-DD");
  feedCalibration = 0;
  batchCalibration = "";

  constructor(
    public FoodsService: FoodsService,
    private modalService: NgbModal,
    public FeedersService: FeedersService,
    public ProductService: ProductsService,
    public GlobalFunction: GlobalFunction,
    public CalibrationService: CalibrationService,
    public router: Router,
    public storageService: StorageService
  ) {
    this.foodForm = new FormGroup({
      id: new FormControl(""),
      name: new FormControl("", Validators.required),
      brand: new FormControl("-1", Validators.required),
      description: new FormControl("", Validators.required),
      kg: new FormControl("", Validators.required),
      particle_size: new FormControl("", Validators.required),
      price: new FormControl("", Validators.required),
      product: new FormControl("", Validators.required),
      protein: new FormControl(0, Validators.required),
    });
  }

  ngOnInit(): void {
    this.FoodsService.getFoodBrands("").subscribe((res) => {
      this.foodBrands = res;
    });

    this.getFood();
  }

  getFood = async () => {
    if (this.id !== "none" || this.food) {
      let id = this.food ? this.food.id : this.id;
      if (id) {
        let food = await this.FoodsService.getFood(id).toPromise();
        this.food = food;
        if (food) {
          const {
            brand,
            description,
            kg,
            name,
            particle_size,
            price,
            product,
            calibrations,
            protein,
          } = this.food;
          let obj = {
            id: "",
            name: "",
            description: "",
            brand,
            kg,
            particle_size,
            price,
            product,
            protein: protein > 0 ? protein * 100 : 0,
          };
          const isCalibration = this.router.url === "/calibration";

          if (!isCalibration) {
            this.convertArrayCalibrations();
          } else {
            let disp = calibrations.find(
              (item) => item.dispenser === this.dispenser.id
            );

            if (disp) {
              this.calibrationsFood.push({
                ...disp,
                dispenser: this.dispenser,
              });
              this.feedCalibration = disp.feed_rate;
              this.batchCalibration = disp.batch;
            }
          }

          if (this.edit) {
            obj["id"] = id;
            obj["name"] = name;
            obj["description"] = description;
          }
          if (this.food) {
            this.foodForm.setValue(obj);
          } else {
            this.foodForm.reset();
          }
        }
      }
    }
    this.foodForm.controls["protein"].valid;
    this.getProductsByBrand();
  };

  convertArrayCalibrations = () => {
    const { dispensers, calibrations, uncalibrated } = this.food;
    let uncal = uncalibrated.map((item) => {
      return {
        id: -1,
        batch: "",
        created: "",
        dispenser: item,
        feed_rate: "",
        food: this.food.id,
      };
    });
    let cal = calibrations.map((cal) => {
      return {
        ...cal,
        dispenser: dispensers.find((item) => item.id === cal.dispenser),
      };
    });
    this.calibrationsFood = [...cal, ...uncal];
  };
  getProductsByBrand() {
    this.foodProducts = [];

    this.ProductService.getProducts(
      `?brand=${this.foodForm.value.brand}`
    ).subscribe((res) => {
      this.foodProducts = res;
    });
  }

  register = async () => {
    let value = {
      ...this.foodForm.value,
      protein:
        this.foodForm.value.protein > 0 ? this.foodForm.value.protein / 100 : 0,
    };
    this.loadingSubmit = true;

    let obj: any = {};

    if (this.edit && this.food) {
      obj = {
        ...value,
      };
      delete obj.id;
      await this.FoodsService.patchFood(this.food.id, obj).subscribe(
        (resp: any) => {
          this.loadingSubmit = false;
          this.modalService.dismissAll();
        },
        (err) => {
          this.loadingSubmit = false;
        }
      );
    } else {
      obj = {
        ...value,
        feed_rate: 1,
      };
      delete obj.id;
      await this.FoodsService.postFood(obj).subscribe(
        (resp: any) => {
          this.edit = true;
          this.food = resp;
          this.id = resp.id;
          this.loadingSubmit = false;
          this.convertArrayCalibrations();
        },
        (err) => {
          this.loadingSubmit = false;
        }
      );
    }
  };
  submitCalibration = () => {
    if (this.dispenser && this.food && this.feedRecomended && this.batch) {
      let obj = {
        feed_rate: this.feedRecomended,
        batch: this.batch,
        dispenser: this.dispenser.id,
        food: this.food.id,
      };

      this.register();

      if (
        this.edit &&
        this.calibrationsFood.length > 0 &&
        this.calibrationsFood[0].id !== -1
      ) {
        this.CalibrationService.patchCalibration(
          this.calibrationsFood[0].id,
          obj
        ).subscribe((resp) => {
          this.getFood();
        });
      } else {
        this.CalibrationService.postCalibration(obj).subscribe((item) => {
          this.getFood();
        });
      }
    }
  };
  editCalibration = (item, idx?) => {
    let obj = {
      feed_rate: item.feed_rate,
      batch: item.batch,
      dispenser: item.dispenser.id,
      food: item.food,
    };

    if (item.id >= 0) {
      this.CalibrationService.patchCalibration(item.id, obj).subscribe(
        (resp) => {}
      );
    } else {
      this.CalibrationService.postCalibration(obj).subscribe((resp) => {
        this.calibrationsFood[idx] = {
          ...this.calibrationsFood[idx],
          id: resp.id,
        };
      });
    }
  };
}
