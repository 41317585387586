import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-custom-select",
  templateUrl: "./custom_select.html",
  styleUrls: ["./custom_select.scss"],
})
export class CustomSelectComponent {
  @Input() id: string = "default";
  @Input() label: string = "";
  @Input() options: string[] = ["Opción 1", "Opción 2", "Opción 3"];
  showOptions: boolean = false;
  @Output() change_input = new EventEmitter();
  @Input() width: string = "100%";
  @Input() class_options: string = "";
  @Input() icon = "fas fa-caret-down";

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const myDiv: any = document.querySelector(`[id=${this.id}]`);
    if (!myDiv.contains(targetElement) && this.showOptions) {
      this.showOptions = false;
      event.stopPropagation();
    }
  }

  selectOption(option: string): void {
    this.label = option;
    this.change_input.emit(option);
    this.showOptions = false;
  }
  viewOptions() {
    this.showOptions = !this.showOptions;
  }
}
