import { SettingsService } from "../../../../core/settings/settings.service";
import { Component, Injectable, OnInit, TemplateRef } from "@angular/core";
import { ClientsService } from "../../../services/clients.service";
import { NetworkService } from "../../../services/network.service";
import { StorageService } from "../../../services/storage.service";
import { FramesService } from "../../../services/frames.service";
import { GlobalFunction } from "../../../services/globalFuntion";
import { GatewayService } from "../../../services/gateway.service";
import { FarmService } from "../../../services/farm.service";
import { FormControl, FormGroup } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import * as moment from "moment";

@Component({
  selector: "app-network-config",
  templateUrl: "./network-config.component.html",
  styleUrls: ["./network-config.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class NetworkConfigComponent implements OnInit {
  farmID: string = "";
  clientID: string = "";

  client: any = {};
  farm: any = {};

  gateways: any = [];
  farmCoordinates: any = [];

  query: string = "";

  configModal = {
    backdrop: true,
    ignoreBackdropClick: false,
    keyboard: false,
    class: "modal-xl modal-config pos-modal",
  };

  searchGraph: FormGroup;
  netWorkConfigSubsciption: Subscription;

  labelsBytes: Array<any> = [];
  dataBytes: Array<any> = [];

  list = 0;
  page = 1;
  count = 0;
  page_size = 10;
  buttonsSize = [25, 50, 100];

  constructor(
    private networkService: NetworkService,
    private clientsService: ClientsService,
    public storageService: StorageService,
    public globalFunction: GlobalFunction,
    private modalService: BsModalService,
    public framesService: FramesService,
    public farmService: FarmService,
    public gatewayService: GatewayService,
    public setting: SettingsService,
    private route: ActivatedRoute
  ) {
    this.searchGraph = new FormGroup({
      resample_rule: new FormControl("W"),
      value_rule: new FormControl(1),
      start_date: new FormControl(""),
      finish_date: new FormControl(""),
    });
  }

  ngOnDestroy(): void {
    this.netWorkConfigSubsciption.unsubscribe();
  }

  ngOnInit(): void {
    let client = this.route.snapshot.paramMap.get("clientID");
    let farm = this.route.snapshot.paramMap.get("farm");

    if (client && farm) {
      this.clientID = client;
      this.farmID = farm;

      this.query = `?farm_id=${this.farmID}`;
      this.getData(this.clientID, this.farmID);
    }

    this.netWorkConfigSubsciption = this.storageService
      .watchStorage("modalNetworkConfig")
      .subscribe((data: string) => {
        data && this.getLinkedGateways();
      });

    this.getLinkedGateways();
  }

  getLinkedGateways() {
    this.gatewayService
      .getLinkedGateways(`?farm_id=${this.farmID}`)
      .subscribe((resp) => {
        this.gateways = resp;
      });
  }

  getData(clientID, farmID) {
    this.clientsService.getClient(clientID).subscribe((resp: any) => {
      this.client = resp;
    });

    this.farmService.getFarm(farmID).subscribe((resp: any) => {
      this.farm = resp;
      this.farmCoordinates = resp.coordinates;
    });
  }

  Modal = (template: TemplateRef<any>, type) => {
    switch (type) {
      case "show":
        this.modalService.show(template, this.configModal);
        break;
      case "hide":
        this.modalService._hideModal(1);
        break;
      default:
    }
  };

  setNetworkFarm() {
    this.networkService
      .postDiscoverNetwork(this.farm.id)
      .subscribe((resp) => {});
  }

  validateTextAlert() {
    /* searchGraph.value */
    return this.globalFunction.validateTextAlertBatteryPanel(
      this.searchGraph.value
    );
  }

  validateButtonSubmit() {
    return this.globalFunction.validateButtonSubmitBatteryPanel(
      this.searchGraph.value
    );
  }

  chageSelect(t) {
    t === "M" && this.searchGraph.controls["value_rule"].setValue("");
  }

  getGraph = async () => {
    const { resample_rule, value_rule, start_date, finish_date } =
      this.searchGraph.value;
    let gateways = this.gateways.map((item) => item.id);
    let query = `?page=${this.page}&page_size=${
      this.page_size
    }&gateway_id_in=${gateways}&resample_rule=${value_rule}${resample_rule}${
      finish_date && start_date
        ? `&date_created=${start_date},${finish_date}`
        : ""
    }`;

    await this.framesService
      .stats_graph(query)
      .subscribe(({ data, page, count }) => {
        this.page = page;
        this.count = count;
        this.list = data.length;

        const labels = data.map(
          (item: any) =>
            `${moment(item.date).format("YYYY-MM-DD")} ${moment(
              item.date
            ).format("h:mm a")}`
        );
        this.labelsBytes = labels;
        const data_total_recv = data.map((item: any) =>
          item.value.total_recv.toFixed(2)
        );
        const data_total_sent = data.map((item: any) =>
          item.value.total_sent.toFixed(2)
        );

        this.dataBytes = [
          {
            data: data_total_recv,
            type: "line",
            name: "Bytes recibidos",
            color: "#30646c",
          },
          {
            data: data_total_sent,
            type: "line",
            name: "Bytes enviados",
            color: "#00adac",
          },
        ];
      });
  };

  getpagination(page) {
    this.page = page;
    this.getGraph();
  }

  changeSize(value) {
    this.page_size = value;
    this.getGraph();
  }
}
