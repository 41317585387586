import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class PondService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router,
  ) {}

  getPonds(query: string = "", id?: any) {
    const url = id ? `${Uris.PONDS}${id}/${query}` : `${Uris.PONDS}${query}`;

    return this.http
      .get(url, this.storageService.headers())
      .pipe(map((resp: any) => resp));
  }

  deletePond(id) {
    return this.http
      .delete(`${Uris.PONDS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Estanque eliminado correctamente`
          );
          return resp;
        })
      );
  }

  getFeedingBehaviorGraph(id, query) {
    return this.http
      .get(
        `${Uris.PONDS}${id}/feeding-behavior-graph/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postClientPond(value) {
    return this.http
      .post(`${Uris.PONDS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Estanque ${resp.name} creado correctamente`
          );
          return resp;
        })
      );
  }

  patchClientPond(value, id, alert: boolean = false) {
    return this.http
      .patch(
        `${Uris.PONDS}${Number(id)}/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          alert &&
            this.storageService.animation(
              "success",
              "Éxito",
              `Estanque ${resp.name} editado correctamente`
            );
          return resp;
        })
      );
  }

  trayData(id: any) {
    return this.http
      .get(
        `${Uris.PONDS}${id}/tray_data/`,
        this.storageService.headersSoloAdmin()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  averagePondSensors(id: any) {
    return this.http
      .get(
        `${Uris.PONDS}${id}/sensor-record-avg/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  pondsComparison(query: string) {
    return this.http
      .get(`${Uris.PONDS_COMPARISON}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  createTemplateMetric(body: any) {
    return this.http
      .post(`${Uris.PONDS_COMPARISON}`, body, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  patchTemplateMetric(id: number, body: any) {
    return this.http
      .patch(
        `${Uris.PONDS_COMPARISON}${id}/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getDataComparison(query: string) {
    return this.http
      .get(
        `${Uris.PONDS_DATA_COMPARISON}${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getDataComparisonGraph(query: string) {
    return this.http
      .get(
        `${Uris.PONDS_DATA_COMPARISON_GRAPH}${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
