import { ClientsService } from "./../../routes/services/clients.service";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { BehaviorSubject } from "rxjs";
import { TranslatorService } from "../../core/translator/translator.service";
import { PlantingsService } from "../../routes/services/plantings.service";
import { POND } from "./interfaces";
import * as moment from "moment";

@Component({
  selector: "app-plantings",
  templateUrl: "./plantings.html",
  styleUrls: ["./plantings.scss"],
})
export class plantingsComponent implements OnInit {
  @Input() DataPonds: POND | Array<POND> | any;
  @Input() idPlanting: any = '';
  @Input() reload: boolean = true;
  @Input("type") type: "alias" | "cosecha";
  @Output("submit") submitPlantings = new EventEmitter();
  @Output("closed") ClosedPlantings = new EventEmitter();
  @Input() ButtonClosed: Boolean = false;
  temporal_date = "";

  sowForm: FormGroup;

  grubM2 = 0;
  grubPond = 0;

  Text = new BehaviorSubject<String>("");

  loadingStatus = true;

  sowError = false;
  sowErrorMessage: any = {};
  plantings = [];

  seasons = [];

  constructor(
    public clientService: ClientsService,
    public translatorService: TranslatorService,
    public plantingsService: PlantingsService,
    private modalService: BsModalService
  ) {
    this.sowForm = new FormGroup({
      planting_per_m2: new FormControl(""),
      type: new FormControl("", Validators.required),
      date: new FormControl("", Validators.required),
      larva_origin: new FormControl("", Validators.required),
      initial_weight: new FormControl("", Validators.required),
      grubPond: new FormControl(""),
      planned_end_date: new FormControl("", Validators.required),
      planned_days: new FormControl(""),
      alias: new FormControl(""),
      season: new FormControl("", Validators.required),
    });
  }
  ngDoCheck(): void {
    if (this.DataPonds) this.textPonds(this.DataPonds);
  }

  ngOnChanges(changes: any) {
    /*     if (changes.DataPonds) {
      this.Text.next(this.textPonds(changes.DataPonds.currentValue));
    } */
  }

  getGrub(value, type, pond) {
    if (type === 1) {
      this.sowForm.controls.grubPond.setValue(
        Number(value) * 10000 * pond[0].hectares
      );
    } else {
      this.sowForm.controls.planting_per_m2.setValue(
        Number(value) / pond[0].hectares / 10000
      );
    }
  }

  ngAfterContentInit(): void {
    this.clientService.getSeasons("?ordering=id").subscribe((res) => {
      this.seasons = res.filter((item) => item.enable);
    });
    if (this.idPlanting) {
      this.plantingsService.getPlanting(this.idPlanting).subscribe(
        (resp) => {
          if (resp.pond) {
            this.plantingsService
              .getPlantings(`?pond_id=${resp.pond.id}`)
              .subscribe((resp) => {
                let siembras = resp
                  .filter((item) => item.alias)
                  .map((item) => item.alias);
                this.plantings = siembras;
              });
          }
          let season = "";
          if (resp.season) {
            season = resp.season.id;
          }
          let {
            planned_end_date,
            planting_per_m2,
            initial_weight,
            larva_origin,
            type,
            date,
            planned_days,
            alias,
          } = resp;
          this.DataPonds = [resp.pond];
          
          const formatDate = (date) => moment(date).format("YYYY-MM-DD");
          this.temporal_date = formatDate(date);

          this.sowForm.setValue({
            grubPond: planting_per_m2 * 10000 * resp.pond.hectares,
            planting_per_m2,
            type,
            date: date ? formatDate(date) : "",
            planned_end_date: planned_end_date
              ? formatDate(planned_end_date)
              : "",
            larva_origin,
            initial_weight,
            alias,
            season,
            planned_days: planned_days ? planned_days : "",
          });

          this.loadingStatus = false;
        },
        (error: any) => {
          this.sowError = true;
          this.sowErrorMessage = { message: error.message, name: error.name };
        }
      );
    } else {
      this.loadingStatus = false;
    }
  }
  ngOnInit(): void {}
  textButton = "";
  register(value) {
    let obj = {
      ...value,
      type: Number(value.type),
      planned_end_date: value.planned_end_date,
    };
    const ponds = this.DataPonds.map((item) => {
      return item.id;
    });
    if (this.idPlanting) {
      this.plantingsService
        .patchPlanting(this.idPlanting, obj)
        .subscribe((resp) => {
          this.ClosedPlantings.emit({
            ...obj,
            ponds,
          });
        });
    } else {
      let body = {
        ...obj,
        ponds,
      };

      this.plantingsService.postPlanting(body).subscribe((resp) => {
        this.sowForm.reset();
        this.ClosedPlantings.emit(body);
      });
    }
  }

  closedClick() {
    this.ClosedPlantings.emit(true);
    this.modalService._hideModal(1);
  }
  textPonds(value) {
    let text = "";

    if (value && value.name) {
      text = value.name;
    }
    if (value && value.length > 0) {
      let array = value.map((resp) => resp.name);
      text = array.toString();
    }

    return text;
  }
  calculatelarvas(planting_per_m2, POND) {
    if (POND) {
      if (POND.length > 1) {
        return this.translatorService.translate("general.undefined");
      } else {
        return POND.length === 1
          ? Number(planting_per_m2) * Number(POND[0].hectares)
          : this.translatorService.translate("general.select-pond");
      }
    }
  }
  changePlanned(type) {
    let fecha1 = moment(`${this.sowForm.value.date}T00:00:00.000000-07:00`);

    if (type === "planned_end_date") {
      let fecha2 = moment(`${this.sowForm.value[type]}T00:00:00.000000-07:00`);

      this.sowForm.controls["planned_days"].setValue(
        fecha2.diff(fecha1, "days")
      );
    } else {
      let new_date = moment(fecha1)
        .add(this.sowForm.value["planned_days"], "days")
        .format("YYYY-MM-DD");

      this.sowForm.controls["planned_end_date"].setValue(new_date);
    }
  }
  validateDisabled(input) {
    /* Debemos validar que solo tenga  */
    /* null habilita el disabled  */

    if (
      this.type === "cosecha" &&
      input !== "planned_end_date" &&
      input !== "planned_days"
    ) {
      return true;
    }

    if (this.type === "alias" && input !== "alias") {
      return true;
    }

    if (!this.type) {
      return null;
    }
  }
  validateTextByMonthLimit = () => {
    const planned_end_date = this.sowForm.controls["planned_end_date"].value;
    const date = this.sowForm.controls["date"].value;
    if (date && planned_end_date) {
      const targetdate = moment(date);
      const targetPlannedEndDate = moment(planned_end_date);
      return !targetPlannedEndDate.isBetween(
        targetdate,
        targetdate.clone().add(10, "months"),
        "M",
        "[]"
      );
    }
    return false;
  };
  validateTextBy7DaysLimit = () => {
    const date = this.temporal_date;
    if(this.idPlanting === ''){
      return true
    }
    if (date) {
      const targetDate = moment(date); 
      const currentDate = moment(); 
      const sevenDaysLater = currentDate.clone().add(7, "days"); 
      return targetDate.isBefore(sevenDaysLater, "day"); 
    }
  
    return false; 
  };
}
