import { Component, Input, OnInit } from "@angular/core";
import { PermissionsService } from "../../../routes/services/permissions.service";

@Component({
  selector: "view-virtual-weight",
  templateUrl: "./virtual_weight.html",
  styleUrls: ["./virtual_weight.scss"],
})
export class ViewVirtualWeightComponent implements OnInit {
  @Input() feeder = null;
  permissions = {
    view_virtual_weight: true,
  };
  constructor(public permissionsService: PermissionsService) {}

  ngOnInit(): void {
    const validate = (prop) => {
      return this.permissionsService.validatePermissionsExistSubclient(prop);
    };
    const validateClient = (prop) => {
      return this.permissionsService.validatePermissionsExistClient(prop);
    };

    this.permissions = {
      view_virtual_weight:
        validateClient("view_virtual_weight") &&
        validate("view_virtual_weight"),
    };
  }

  onClickVirtualWeight(feeder) {
  }
}
