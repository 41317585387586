import { TranslatorService } from "../../../../../../core/translator/translator.service";
import { SettingsService } from "../../../../../../core/settings/settings.service";
import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { ClientsService } from "../../../../../services/clients.service";
import { NetworkService } from "../../../../../services/network.service";
import { GatewayService } from "../../../../../services/gateway.service";
import { NetworkConfigComponent } from "../../network-config.component";
import { GlobalFunction } from "../../../../../services/globalFuntion";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-gateway-installation",
  templateUrl: "./installation.component.html",
  styleUrls: ["./installation.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class GatewayInstallationComponent implements OnInit {
  @ViewChild("modal") public Modaladmin;

  gatewayID: any;
  farmCoordinates: any;

  public gatewayMarker = {
    lat: "",
    lng: "",
  };

  constructor(
    public networkComponent: NetworkConfigComponent,
    public translatorService: TranslatorService,
    public GlobalFunction: GlobalFunction,
    public networkService: NetworkService,
    public clientsService: ClientsService,
    public modalService: NgbModal,
    public gatewayService: GatewayService,
    public Setting: SettingsService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const farm: any = this.Setting.GatewayForm.farm;
    const gateway: any = this.Setting.GatewayForm.gateway;
    this.gatewayID = gateway;

    if (farm) {
      this.farmCoordinates = this.GlobalFunction.validateCoordinates(
        farm.coordinates
      );
    }

    /* Obtener el estanque en el que esta vinculado */
    if (this.gatewayID !== "new") {
      this.gatewayService.getGateway(this.gatewayID).subscribe((resp) => {
        this.gatewayMarker = {
          ...resp.installation,
        };

        this.Setting.GatewayForm = {
          ...this.Setting.GatewayForm,
          coordinates: { ...resp.installation },
        };

        resp.installation &&
          this.networkService
            .getGatewayInstallation(`${resp.installation.id}/`)
            .subscribe((instalation) => {});
      });
    }
  }

  submit(value) {
    const obj = {
      gateway: Number(value.gateway),
      lat: this.gatewayMarker.lat,
      lng: this.gatewayMarker.lng,
    };

    this.networkService.postGatewayInstallation(obj).subscribe((res) => {
      this.modalService.dismissAll();
    });
  }

  clickPolygonFarm(value) {
    let { lat, lng } = value.event.latLng;
    this.setValueInstalation(lat(), lng());
  }

  addMakerFarm(value) {
    const { lat, lng } = value.coords;
    this.setValueInstalation(lat, lng);
  }

  setValueInstalation(lat, lng) {
    this.Setting.GatewayForm = {
      ...this.Setting.GatewayForm,
      coordinates: {
        ...this.Setting.GatewayForm.coordinates,
        lat,
        lng,
      },
    };
    this.gatewayMarker = {
      lat,
      lng,
    };
  }
}
