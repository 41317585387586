import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ObservationService } from '../../routes/services/observations.service';

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss']
})
export class ObservationsComponent implements OnInit {

  @Input() selectedFeeder;
  @Input() observationsEdit = true;

  @Output() finishedObservations : EventEmitter<any> = new EventEmitter();

  observations: any;
  newObservation = false;
  selectedObservation: any;

  observationForm: FormGroup;

  constructor(
    private observationService: ObservationService,
    ) {
      this.observationForm = new FormGroup({
        title: new FormControl("", Validators.required),
        description: new FormControl(""),
      });
    }

  ngOnInit(): void {
    this.getObservations();
  }


  getObservations = async () => {
    this.observations = await this.observationService.getFeederObservations(this.selectedFeeder.id).toPromise();
  }

  createObservation() {
    let { title, description } = this.observationForm.value;

    const obj = {
      title: title,
      description: description,
      solved: false
    };

    this.observationService.createFeederObservation(this.selectedFeeder.id, obj).subscribe(res => {
      this.observations.push(res);
      this.newObservation = false;
      this.observationForm.reset();
      this.finishedObservations.emit();
    });
  }

  solveObservation() {
    this.selectedObservation.solved = true;

    this.observationService.solveFeederObservation(this.selectedFeeder.id, this.selectedObservation.id).subscribe(res => {
      this.getObservations();
      this.finishedObservations.emit();
    });
  }

}
