import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  getUserInfo(is_admin = true) {
    return this.http
      .get(
        `${Uris.USERS}info/`,
        is_admin
          ? this.storageService.headersSoloAdmin()
          : this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getUser(id) {
    return this.http
      .get(`${Uris.USERS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getUsersForAdmin(query) {
    return this.http
      .get(`${Uris.USERS}${query}`, this.storageService.headersSoloAdmin())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getUsers(query) {
    return this.http
      .get(`${Uris.USERS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postUser(value) {
    return this.http
      .post(`${Uris.USERS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  patchUser(id, value) {
    return this.http
      .patch(`${Uris.USERS}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  enableDisableUser(id, type) {
    return this.http
      .post(
        `${Uris.USERS}${Number(id)}/${type}/`,
        {},
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteUser(id) {
    return this.http
      .delete(`${Uris.USERS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Usuario eliminado correctamente`
          );
          return resp;
        })
      );
  }

  putUserPermissions(value, id) {
    return this.http
      .put(
        `${Uris.USERS}${Number(id)}/change_permissions/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  addClientsAdminPermission(value, id) {
    return this.http
      .post(
        `${Uris.USERS}${id}/permission_add_clients/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  removeCLientsAdminPermission(value, id) {
    return this.http
      .delete(
        `${Uris.USERS}${Number(id)}/permission_remove_clients/${value}/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getClientsAdminPermission(id) {
    return this.http
      .get(
        `${Uris.USERS}${Number(id)}/permission_get_clients/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getUserMovements(query) {
    return this.http
      .get(
        `${Uris.API_ENDPOINT}table_movement/${query}`,
        this.storageService.headersSoloAdmin()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getUserFramesMovements(query) {
    return this.http
      .get(
        `${Uris.API_ENDPOINT}frame/${query}`,
        this.storageService.headersSoloAdmin()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
