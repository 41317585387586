import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class PlantingsService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router,
  ) {}

  getPlantings(query) {
    return this.http
      .get(`${Uris.PLANTINGS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getPlanting(id) {
    return this.http
      .get(`${Uris.PLANTINGS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postPlanting(value) {
    return this.http
      .post(`${Uris.PLANTINGS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Siembra registrada correctamente`
          );
          return resp;
        })
      );
  }

  patchPlanting(id, value) {
    return this.http
      .patch(`${Uris.PLANTINGS}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Siembra editada correctamente`
          );
          return resp;
        })
      );
  }

  deletePlanting(id) {
    return this.http
      .delete(`${Uris.PLANTINGS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Siembra eliminada correctamente`
          );
          return resp;
        })
      );
  }

  getCycles(query: string): Observable<cycle[]> {
    return this.http
      .get<cycle[]>(
        `${Uris.PLANTINGS}get_cycles/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getPlantingAndHarvesting(query: string) {
    return this.http
      .get(`${Uris.PLANTINGS}reports/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}

export interface cycle {
  year: number;
  season_name: string | null;
}
