import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router,
  ) {}

  // GATEWAY INSTALATION

  updateGateway(id) {
    return this.http
      .post(`${Uris.GATEWAY}update/${id}/`, {}, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Gateway actualizado correctamente`
          );
          return resp;
        })
      );
  }

  disconnectGateway(id) {
    return this.http
      .get(
        `${Uris.GATEWAY}${id}/disconnect_from_server/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Gateway desconectado correctamente`
          );
          return resp;
        })
      );
  }

  postGatewayInstallation(value) {
    return this.http
      .post(
        `${Uris.API_ENDPOINT}gateway-installation/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Instalación registrada correctamente`
          );
          return resp;
        })
      );
  }

  getGatewayInstallation(query) {
    return this.http
      .get(
        `${Uris.API_ENDPOINT}gateway-installation/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteGatewayInstallation(id) {
    return this.http
      .delete(
        `${Uris.API_ENDPOINT}gateway-installation/${id}/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Instalación eliminada correctamente`
          );
          return resp;
        })
      );
  }

  // GATEWAY SIGNAL

  postDiscoverNetwork(farmID) {
    return this.http
      .post(
        `${Uris.GATEWAY}discover-network/${farmID}/`,
        "",
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Petición de señal enviada, esperando respueta.`
          );
          return resp;
        })
      );
  }

  postDiscoverGatewayNetwork(gateway) {
    return this.http
      .post(
        `${Uris.GATEWAY}discover-gateway-network/${gateway}/`,
        "",
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Petición de señal enviada, esperando respueta.`
          );
          return resp;
        })
      );
  }
}
