export const resourses: resousers[] = [
  {
    label: "Humedad",
    prop: "HUM",
    check: true,
    min_value: 20,
    max_value: 20,
  },
  {
    label: "Temperatura estanque",
    prop: "PTEM",
    check: true,
    min_value: 10,
    max_value: 10,
  },
  {
    label: "Temperatura ambiental",
    prop: "TEM",
    check: true,
    min_value: 5,
    max_value: 5,
  },
  {
    label: "Oxigeno disuelto",
    prop: "DO",
    check: true,
    min_value: 1,
    max_value: 1,
  },
  {
    label: "Conductividad eléctrica",
    prop: "EC",
    check: true,
    min_value: 0,
    max_value: 0,
  },
  {
    label: "Saturación",
    prop: "SAT",
    measurementThreshold: "%",
    check: true,
    min_value: 20,
    max_value: 20,
  },
  {
    label: "Gravedad específica",
    prop: "SG",
    check: true,
    min_value: 1000,
    max_value: 1000,
  },
  {
    label: "Salinidad",
    prop: "SAL",
    check: true,
    min_value: 0,
    max_value: 0,
  },
  {
    label: "Solidos totales disueltos",
    prop: "TDS",
    check: true,
    min_value: 0,
    max_value: 0,
  },
  {
    label: "( PH ) Potencial de hidrogeno",
    prop: "PH",
    check: true,
    min_value: 3,
    max_value: 3,
  },
  {
    label: "Potencia de reducción-oxidación",
    prop: "ORP",
    check: true,
    min_value: -200,
    max_value: -200,
  },
];

export interface resousers {
  label: string;
  prop: string;
  measurementThreshold?: string;
  check: boolean;
  min?: number;
  max?: number;
  min_value: number;
  max_value: number;
  preference?:string;
}
export const graph = {
  xAxis: {
    type: "category",
    boundaryGap: false,
    show: false,
    data: [
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
      "Domingo",
    ],
  },
  tooltip: {
    trigger: "axis",

    axisPointer: {
      type: "cross",
    },
  },
  yAxis: [
    {
      type: "value",
      boundaryGap: [0, "0"],
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    {
      name: "30 ºC",
      type: "value",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 15,
      },
    },
  ],
  grid: [
    {
      show: false,
      left: "5%",
      top: 40,
      right: "8%",
      bottom: 40,
    },
  ],
  axisLine: {
    show: false, // Hide full Line
  },
  xisTick: {
    show: false, // Hide Ticks,
  },
  title: [
    {
      text: "",
      left: 5,
      top: 3,
      textStyle: {
        fontSize: 15,
        fontWeight: "bold",
      },
    },
  ],
  series: [
    {
      type: "line",
      smooth: 0.6,
      symbol: "none",
      lineStyle: {
        color: "#204349",
        width: 2,
      },
      data: [200, 560, 750, 580, 250, 300],
    },
  ],
};
