import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientsService } from "./../../routes/services/clients.service";
import { PlantingsService } from "./../../routes/services/plantings.service";
import { Validators } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import moment from "moment";

@Component({
  selector: "app-extended-harvest",
  templateUrl: "./extended-harvest.component.html",
  styleUrls: ["./extended-harvest.component.scss"],
})
export class ExtendedHarvestComponent implements OnInit {
  @Output("closed") closed = new EventEmitter();

  @Input() sowData: any;
  /*
    1 = Ambos casos
    2 = Establecer temporada
    3 = Establefer fecha cosecha estimada
    4 = Extender fecha cosecha estimada */
  @Input() type: any = {
    label: "",
    value: 1,
  };

  plannedDateForm: FormGroup;
  seasonForm: FormGroup;

  seasons = [];

  constructor(
    public clientService: ClientsService,
    public plantingsService: PlantingsService,
    private modalService: NgbModal
  ) {
    this.plannedDateForm = new FormGroup({
      planned_end_date: new FormControl("", Validators.required),
      planned_days: new FormControl(""),
    });

    this.seasonForm = new FormGroup({
      season: new FormControl("", Validators.required),
      alias: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.clientService.getSeasons("?ordering=id").subscribe((res) => {
      this.seasons = res;
    });
    if (this.type.value == 2 || this.type.value === 4) {
      const { planned_days, date, planned_end_date } = this.sowData;
      this.setValuePlannedDate(planned_days, date, planned_end_date);
    }
  }

  changeDate(event) {
    const { planned_days, date } = this.sowData;
    this.setValuePlannedDate(planned_days, date, event);
  }
  setValuePlannedDate(planned_days, date, planned_end_date) {
    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    if (!planned_days) {
      const fecha1 = moment(date);
      const fecha2 = moment(planned_days);
      planned_days = fecha2.diff(fecha1, "days");
    }

    this.plannedDateForm.setValue({
      planned_end_date: formatDate(planned_end_date),
      planned_days: planned_days ? planned_days : 0,
    });
  }

  changePlanned(type) {
    if (type === "planned_end_date") {
      let fecha1 = moment(this.sowData.date);
      let fecha2 = moment(
        `${this.plannedDateForm.value[type]}T10:00:00.112276-07:00`
      );

      this.plannedDateForm.controls["planned_days"].setValue(
        fecha2.diff(fecha1, "days")
      );
    } else {
      let new_date = moment(this.sowData.date)
        .add(this.plannedDateForm.value["planned_days"], "days")
        .format("YYYY-MM-DD");

      this.plannedDateForm.controls["planned_end_date"].setValue(new_date);
    }
  }

  register(values, obj = {}) {
    this.plantingsService.patchPlanting(this.sowData.id, {
      ...values,
      obj,
    }).subscribe((resp) => {
      this.modalService.dismissAll();
      this.closed.emit(resp);
    });
  }
}
