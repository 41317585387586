import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loading-status",
  template: `
    <app-spinner
      *ngIf="status === 'loading'"
      [smallLoader]="true"
      [spinnerColor]="spinnerColor"
    ></app-spinner>
    <i
      *ngIf="status === 'done'"
      class="fa fa-check-circle text-success"
      style="font-size: 12px"
    ></i>
    <i
      *ngIf="status === 'error'"
      class="fa fa-times-circle text-danger"
      style="font-size: 12px"
    ></i>
  `,
  styleUrls: [],
})
export class LoadingStatusComponent implements OnInit {
  @Input() status: "done" | "loading" | "error" | "" = "";
  @Input() spinnerColor: string = "text-primary";

  constructor() {}

  ngOnInit(): void {}
}
