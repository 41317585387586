import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Uris } from "./Uris";
import { map, catchError } from "rxjs/operators";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class FramesService {
  constructor(
    private http: HttpClient,
    public router: Router,
    public storageService: StorageService
  ) {}

  section_sensor_hydro = "sensors_data";

  individualFrames(
    id,
    type:
      | "analog_reading_alert"
      | "battery"
      | "change_dispenser_current"
      | "change_feed_rate"
      | "change_port"
      | "change_sprinkler_current"
      | "disabled_orbital"
      | "disable_speed"
      | "dispenser_alert"
      | "dispenser_sensor"
      | "empty"
      | "enable_speed"
      | "panel"
      | "request_current"
      | "request_sensors_settings"
      | "request_speed"
      | "reset"
      | "sensor_hydro_request_config"
      | "sensor_hydro_request_status"
      | "sensor_hydro_set_config"
      | "sensor_hydro_set_power_off"
      | "sensor_hydro_set_power_on"
      | "sensor_enabled"
      | "set_orbital_speed"
      | "set_points"
      | "set_points_state"
      | "set_speed"
      | "at/signal_request"
      | "sprinkler_sensor"
      | "status"
      | "three_frecuencies"
      | "turn_off"
      | "turn_off_spin_time"
      | "turn_on"
      | "turn_on_spin_time"
      | "version",
    obj = {}
  ) {
    return this.http
      .post(
        `${Uris.GATEWAYFRAMESFEEDER}${id}/${type}/`,
        obj,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          const validate_text = () => {
            let obj = {
              turn_on: "Alimentadora encendida correctamente",
              turn_off: "Alimentadora apagada correctamente",
            };
            return obj[type] ? obj[type] : "";
          };
          const text = validate_text();
          if (text) {
            this.storageService.animation("success", "Éxito", text);
          }
          return resp;
        })
      );
  }

  /* TestPanel */
  refeed(value) {
    return this.http
      .post(`${Uris.GATEWAY}refeed/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  /* --------------------- FRAMES MASIVES ------------------------- */

  FramePost(
    type:
      | "empty" /* Test vaciado de alimentadora */
      | "change_port" /* Cambio de puerto  */
      | "dispenser_alert" /* Cambio de alerta de dispensador */
      | "change_sprinkler_current" /* Cambio de amp de aspersor */
      | "change_dispenser_current" /* Cambio de amp de dispensador */
      | "change_feed_rate" /* tasa de alimentacion */
      | "enable_orbital" /* habilitar normal */
      | "sensors_enabled"
      | "set_points"
      | "set_points_state"
      | "sensor/frequency/set_hourly"
      | "sensor/frequency/set_3_hours"
      | "sensor/frequency/set_5_hours"
      | "dispenser_sensor"
      | "sprinkler_sensor"
      | "sensor_hydro_set_config"
      | "lf_block_on"
      | "lf_block_off",
    query: string,
    body = {}
  ) {
    return this.http
      .post(
        `${Uris.GATEWAYFRAMES}${type}/${query}`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  Frame(
    type:
      | "version" /* Obtener versión de alimentadora */
      | "turn_on" /* Encender alimentadora  */
      | "turn_off" /* Apagar alimentadora  */
      | "status" /* Test Monitor */
      | "at/signal_request" /* Obtener senal */
      | "reset" /* Test reset alertas */
      | "panel" /* Test Panel */
      | "battery" /* Test Bateria */
      | "battery" /* Test Bateria */
      | "turn_off_spin_time" /* Apagar rotacion */
      | "turn_on_spin_time" /* Encender rotacion */
      | "enable_orbital" /* habilitar orbital */
      | "disable_orbital" /* deshabilitar normal */
      | "disable_speed" /* deshabilitar normal */
      | "analog_reading_alert"
      | "enable_speed"
      | "request_current" /* Obtener corrientes  */
      | "sensor/frequency/request"
      | "sensor/frequency/set_hourly"
      | "sensor/frequency/set_3_hours"
      | "sensor/frequency/set_5_hours"
      | "request_frequencies"
      | "request_sensors"
      | "request_sensors_settings"
      | "sensor_hydro_set_power_on"
      | "sensor_hydro_set_power_off"
      | "sensor_hydro_request_status"
      | "sensor_hydro_set_config"
      | "sensor_hydro_request_config"
      | "sensor_weight_turn_on"
      | "sensor_weight_turn_off"
      | "sensor_weight_tara"
      | "sensor_weight_calibration"
      | "sensor_weight_data_request"
      | "sensor_weight_request_frequency_every_minute"
      | "enable_dispenser_alert"
      | "disable_dispenser_alert"
      | "request_dispenser_alert",
    query: any,
    body = {}
  ) {
    return this.http
      .post(
        `${Uris.GATEWAYFRAMES}${type}/${query}`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  waterwiseFrames(
    id,
    type:
      | "version"
      | "battery"
      | "panel"
      | "turn_on"
      | "turn_off"
      | "sensor_hydro_request_status"
      | "sensor_hydro_request_config"
      | "sensor_hydro_set_power_on"
      | "sensor_hydro_set_power_off"
      | "analog_reading_alert"
      | "request_sensors"
      | "sensors_enabled"
      | "sensor/frequency/set_hourly"
      | "sensor/frequency/set_3_hours"
      | "sensor/frequency/set_5_hours"
      | "sensor_hydro_set_config",
    body = {}
  ) {
    return this.http
      .post(
        `${Uris.GATEWAYFRAMESWATERWISE}${id}/${type}/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          const validate_text = () => {
            let obj = {
              turn_on: "Waterwise encendida correctamente",
              turn_off: "Waterwise apagada correctamente",
            };
            return obj[type] ? obj[type] : "";
          };
          const text = validate_text();
          if (text) {
            this.storageService.animation("success", "Éxito", text);
          }
          return resp;
        })
      );
  }

  waterwiseMasiveFrames(
    type:
      | "lf_block_on"
      | "lf_block_off"
      | "sensor/frequency/set_hourly"
      | "sensor/frequency/set_3_hours"
      | "sensor/frequency/set_5_hours",
    query,
    body = {}
  ) {
    return this.http
      .post(
        `${Uris.GATEWAYFRAMESWATERWISESMASIVE}${type}/${query}`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  FrameGatewayPost(
    type: "request_speed" | "set_orbital_speed" | "set_speed",
    id: string,
    obj = {}
  ) {
    let url = `${Uris.GATEWAYFRAMES}${id}/${type}/`;

    return this.http.post(url, obj, this.storageService.headers()).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  FrameGatewayMasivePost(
    type: "request_speed" | "set_orbital_speed" | "set_speed",
    query: string,
    body: any
  ) {
    let url = `${Uris.GATEWAYFRAMES}${type}/${query}`;

    return this.http.post(url, body, this.storageService.headers()).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  stats_graph(query: string) {
    return this.http
      .get(`${Uris.GATEWAY}stats_graph/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  sensors_status(query: string) {
    return this.http
      .get(`${Uris.SENSORS_STATUS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  hydro_sensors_status(query: string) {
    return this.http
      .get(`${Uris.HYDRO_SENSORS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

}
