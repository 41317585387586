import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ECharts, EChartsOption } from "echarts";
import { StorageService } from "../../routes/services/storage.service";

@Component({
  selector: "app-echar",
  templateUrl: "./echar.html",
  styleUrls: ["./echar.scss"],
})
export class EchartComponent implements OnInit {
  @Input() labels: any;
  @Input() data: any;
  @Input() height: any = 200;
  @Input() type: any = "other";
  @Input() options: any = null;
  @Input() smallChart: any = false;

  @Input() reload: Boolean = false;

  echartsInstance: ECharts;

  chartOption: EChartsOption = {
    toolbox: {
      orient: "vertical",
      feature: {
        dataView: {},
        dataZoom: {},
        restore: {},
        magicType: { type: ["line", "bar"] },
        saveAsImage: {},
      },
      /*   tooltip: {
          formatter: function (param) {
            return '<div>' + param.title + '</div>'; // user-defined DOM structure
          },
          show: true,
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);'
        } */
    },
    grid: {
      left: "3%",
      top: "8%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        /*    crossStyle: {
          color: "#999",
        }, */
      },
    },
    legend: {},
    xAxis: {
      type: "category",
      data: [],
      axisPointer: {
        type: "shadow",
      },
    },
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [],
  };
  @Output("clickChart") clickChart = new EventEmitter();

  constructor(public storageService: StorageService) {
    const { theme } = this.storageService.localValue;
    /* Nuevo metodo para cambiar el color de la grafica dependiendo del modo */
    if (theme === "A" || theme === "E" || theme === "G" || theme === "H") {
      /*   this.chartOption.backgroundColor = '#ccc'; */
    }
  }

  ngOnInit(): void {}

  reloadGraph() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reload) {
      this.echartsInstance && this.setValueChart(this.labels, this.data);
    } else if (this.type !== "diet") {
      this.echartsInstance && this.setValueChart(this.labels, this.data);
    } else if (changes.firstChange) {
      this.setValueChart(this.labels, this.data);
    }
    if (changes.options && !changes.firstChange && this.echartsInstance) {
      if (this.echartsInstance) {
        this.actualizarGrafico();
      }
    }
  }

  onChartInit(ec: any) {
    this.echartsInstance = ec;
    /* this.echartsInstance.showLoading(); */

    this.setValueChart(this.labels, this.data);
    /*   setTimeout(() => {
      this.echartsInstance.hideLoading();
    }, 600); */
  }

  validateMinMax(value) {
    let obj = {};

    if (value && value.length === 1) {
      const { data } = value[0];
      obj = {
        max: Math.max(...data),
        min: Math.min(...data),
      };
    }

    return obj;
  }
  setValueChart(labels, data) {
    this.echartsInstance.clear();
    if (this.options) {
      this.actualizarGrafico();
    } else {
      this.chartOption = {
        ...this.chartOption,
        legend: {
          // Try 'horizontal'
          show: this.smallChart ? false : true,
          orient: "horizontal",
          top: 0,
        },
        toolbox: {
          show: this.smallChart ? false : true,
        },
        yAxis: [
          {
            type: "value",
            ...this.validateMinMax(data),
            min: 0,
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        xAxis: {
          type: "category",
          show: this.smallChart ? false : true,
          data: labels,
          axisPointer: {
            type: "shadow",
          },
        },

        series:
          data && data.length > 0
            ? data.map((item) => {
                return {
                  ...item,
                  tooltip: {
                    valueFormatter: (value) => {
                      return value;
                    },
                  },
                };
              })
            : [],
      };
    }
  }
  chartClick(e) {
    this.clickChart.emit(e);
  }
  chartRestore(e) {
    this.setValueChart(this.labels, this.data);
  }
  gaugeClicked(e) {}
  chartDblClick(e) {}
  actualizarGrafico(options?) {
    // Limpia el gráfico antes de actualizar las opciones
    this.echartsInstance.clear();

    this.chartOption = {
      ...this.options,
    };
    // Asigna las nuevas opciones
    this.echartsInstance.setOption(this.chartOption);
  }
}
