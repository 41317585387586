import { Component, HostBinding, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { SettingsService } from "./core/settings/settings.service";
import { AuthService } from "./routes/services/auth.service";
import { StorageService } from "./routes/services/storage.service";
import { PermissionsService } from "./routes/services/permissions.service";
import { Menu } from "./routes/menu";
import { MenuService } from "./core/menu/menu.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @HostBinding("class.layout-fixed") get isFixed() {
    return this.settings.getLayoutSetting("isFixed");
  }
  @HostBinding("class.aside-collapsed") get isCollapsed() {
    return this.settings.getLayoutSetting("isCollapsed");
  }
  @HostBinding("class.layout-boxed") get isBoxed() {
    return this.settings.getLayoutSetting("isBoxed");
  }
  @HostBinding("class.layout-fs") get useFullLayout() {
    return this.settings.getLayoutSetting("useFullLayout");
  }
  @HostBinding("class.offsidebar-open") get offsidebarOpen() {
    return this.settings.getLayoutSetting("offsidebarOpen");
  }
  @HostBinding("class.offsidebar-closed") get offsidebarClosed() {
    return !this.settings.getLayoutSetting("offsidebarOpen");
  }
  @HostBinding("class.aside-collapsed-text") get isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }
  modeSubsciption: Subscription;
  farmSuscription: Subscription;
  constructor(
    public settings: SettingsService,
    public route: Router,
    public AuthService: AuthService,
    public storageService: StorageService,
    public permissionsService: PermissionsService,
    public menuService: MenuService
  ) {}

  ngOnDestroy(): void {
    this.modeSubsciption.unsubscribe();
  }

  ngOnInit() {
    this.modeSubsciption = this.storageService
      .watchStorage("mode")
      .subscribe(async (mode) => {
        await this.setMenu(
          mode === "1" ? this.storageService.localValue.farm_id : null
        );
      });

    this.farmSuscription = this.storageService
      .watchStorage("farmId")
      .subscribe(async (farm_id) => {
        await this.setMenu(farm_id, true);
      });

    document.addEventListener("click", (e) => {
      const target = e.target as HTMLElement;
      if (target.tagName === "A") e.preventDefault();
    });
  }

  setMenu = async (farm_id, redirect = false) => {
    let Localuser: any = localStorage.getItem("USER");
    let user: any = Localuser ? JSON.parse(Localuser) : "";
    if (user) {
      const { mode } = this.storageService.localValue;
      const my_permissions = this.permissionsService.all_permissions;

      const client_permissions =
        this.permissionsService.client_permissions || null;

      let menu = Menu.menu({
        user,
        mode,
        client_permissions,
        my_permissions,
        farm_id,
      });

      // Función para navegar a un enlace
      const goLink = (menu) => {
        if (menu.link) {
          // Si el menú tiene un enlace directo, navega a él
          this.route.navigateByUrl(menu.link);
        } else if (menu.submenu && menu.submenu.length > 0) {
          // Si el menú tiene un submenú, navega al primer enlace válido del submenú
          const firstValidSublink = menu.submenu.find((sub) => sub.link);
          if (firstValidSublink) {
            this.route.navigateByUrl(firstValidSublink.link);
          }
        }
      };

      const isValid = (link) => {
        const currentUrl = this.route.url;
        return currentUrl.includes(link);
      };
      if (redirect) {
        // Buscar el primer elemento del menú que coincida con la URL actual
        const menuItem = menu.find((item) => {
          if (item.submenu) {
            // Si el elemento tiene un submenú, verifica si alguno de sus enlaces es válido
            return item.submenu.some((sub) => isValid(sub.link));
          }
          // Si no tiene submenú, verifica si su propio enlace es válido
          return isValid(item.link);
        });
        if (menuItem) {
          goLink(menuItem);
        } else {
          if (menu.length > 0) {
            if (menu[0].link) {
              goLink(menu[0]);
            } else if (menu[0].submenu && menu[0].submenu.length > 0) {
              goLink(menu[0].submenu[0]);
            }
          }
        }
      }
      this.menuService.push(menu);
    }
  };
}
