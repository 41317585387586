import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { WaterwiseService } from "../../../services/waterwise.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "../../../services/storage.service";
import { ClientsService } from "../../../services/clients.service";
import { GlobalFunction } from "../../../services/globalFuntion";
import { transparent, waterwise_icons } from "../../../services/icons";
import { FarmService } from "../../../services/farm.service";
import { PondService } from "../../../services/pond.service";

@Component({
  selector: "app-waterwise-form",
  templateUrl: "./form.html",
  styleUrls: ["./form.scss"],
})
export class FormWaterwiseComponent implements OnInit {
  @Input("id") id;
  @Input() lat: any = 25.7873277;
  @Input() lng: any = -109.0098591;
  @Input() zoom: any = 15;
  @Input() selectedItems: [] = [];
  @Input() massiveUpdate: boolean = false;

  viewMap = false;
  formWaterwise: FormGroup;

  filteredClients = [];
  clients = [];
  farms = [];
  ponds = [];

  farm: any = null;

  transparent = transparent;
  selectMarker = {
    lat: 0,
    lng: 0,
  };

  loadingClient = false;
  loadingFarms = false;
  loadingPonds = false;

  @Output("petition") submit_petition = new EventEmitter();

  constructor(
    public waterwiseService: WaterwiseService,
    public storageService: StorageService,
    public globalFunction: GlobalFunction,
    public clientService: ClientsService,
    public farmService: FarmService,
    public pondService: PondService
  ) {
    this.formWaterwise = new FormGroup({
      pond_id: new FormControl("", Validators.required),
      name: new FormControl("", Validators.required),
      mac: new FormControl("", Validators.required),
      filteredBusinessName: new FormControl(""),
      device_type: new FormControl(""),
      client_id: new FormControl(""),
      farm_id: new FormControl(""),
      lat: new FormControl(""),
      lng: new FormControl(""),
    });
  }

  ngOnInit(): void {
    let getQuery = this.storageService.getQueryFilters(
      this.formWaterwise.value
    );

    for (const key in getQuery) {
      if (this.formWaterwise.value.hasOwnProperty(key)) {
        this.formWaterwise.controls[key].setValue(getQuery[key]);
      }
    }

    if (!this.id) {
      this.formWaterwise.controls["name"].setValue("");
      this.formWaterwise.controls["mac"].setValue("");
      this.formWaterwise.controls["pond_id"].setValue("");

      if (this.storageService.validateSoloAdmin()) {
        this.getClients();
      }

      getQuery.client_id && this.getFarms();
      getQuery.farm_id && this.getPonds();
    }

    this.id && this.getWaterwise();

    if (this.massiveUpdate && this.selectedItems.length > 0) {
      this.getFarms();
    }
  }

  getWaterwise = async () => {
    this.waterwiseService.getWaterwise(this.id).subscribe((resp) => {
      if (resp.pond) {
        this.formWaterwise.controls["pond_id"].setValue(resp.pond.id);
      }
      if (resp.farm) {
        this.formWaterwise.controls["farm_id"].setValue(resp.farm.id);
      }
      if (resp.client) {
        this.formWaterwise.controls["client_id"].setValue(resp.client.id);
      }

      ["name", "mac", "lat", "lng", "device_type"].forEach((prop) => {
        this.formWaterwise.controls[prop].setValue(resp[prop]);
      });

      let lat = resp.lat;
      let lng = resp.lng;

      this.selectMarker = {
        lat,
        lng,
      };

      const { farm_id, client_id } = this.formWaterwise.value;
      if (this.storageService.isAdmin()) {
        this.getClients();
      }
      client_id && this.getFarms();
      farm_id && this.getPonds();
    });
  };

  getClients = () => {
    this.loadingClient = true;
    this.clientService.getClients().subscribe((resp) => {
      this.loadingClient = false;
      this.clients = resp;
      this.filterClientsList();
    });
  };

  cleanClientsFilteredList() {
    this.formWaterwise.controls["filteredBusinessName"].setValue("");
    this.filteredClients = this.clients;
  }

  filterClientsList() {
    const { filteredBusinessName } = this.formWaterwise.value;

    if (filteredBusinessName) {
      this.filteredClients = this.clients.filter((item: any) => {
        return (
          item.business_name
            .toLowerCase()
            .indexOf(filteredBusinessName.toLowerCase()) > -1
        );
      });
    } else {
      this.filteredClients = this.clients;
    }
  }

  getFarms = () => {
    this.loadingFarms = true;
    const { client_id } = this.formWaterwise.value;
    const fields = ["id", "name", "coordinates"];
    const query_client = this.globalFunction.validateSoloAdmin()
      ? `&client=${client_id}`
      : "";
    this.farmService
      .getFarms(`?fields=${fields}${query_client}`)
      .subscribe((resp) => {
        this.loadingFarms = false;
        this.farms = resp;

        const { farm_id } = this.formWaterwise.value;
        this.farm =
          resp.find((item: any) => Number(item.id) === Number(farm_id)) || null;

        if (this.farm) {
          const { lat, lng } = this.globalFunction.centerPolygon(
            this.farm.coordinates
          );
          this.lat = lat;
          this.lng = lng;
        }
      });
  };

  getPonds = () => {
    this.loadingPonds = true;
    const { farm_id } = this.formWaterwise.value;
    this.farm =
      this.farms.find((item: any) => Number(item.id) === Number(farm_id)) ||
      null;
    if (this.farm && this.farm.coordinates) {
      this.viewMap = true;
      const { lat, lng } = this.globalFunction.centerPolygon(
        this.farm.coordinates
      );
      this.lat = lat;
      this.lng = lng;
    }
    const fields = ["id", "name", "coordinates"];
    this.pondService
      .getPonds(`?farm_id=${farm_id}&fields=${fields}`)
      .subscribe((resp: any) => {
        this.loadingPonds = false;
        this.ponds = resp;
      });
  };

  submitForm = () => {
    const { name, pond_id, mac, lat, lng, client_id, farm_id, device_type } =
      this.formWaterwise.value;
    let obj_client = {};
    if (this.storageService.isAdmin()) {
      obj_client = {
        client: client_id ? Number(client_id) : "",
      };
    }
    let obj = {
      name,
      pond: Number(pond_id),
      farm: Number(farm_id),
      mac,
      lat,
      lng,
      device_type: Number(device_type),
      ...obj_client,
    };
    if (this.massiveUpdate) {
      this.waterwiseService
        .EditMasiveWaterwises(
          {
            device_type: Number(device_type),
            pond: pond_id ? Number(pond_id) : "",
            farm: pond_id ? Number(farm_id) : "",
            ...obj_client,
          },
          `?id_in=${this.selectedItems.map((item: any) => item.id)}`
        )
        .subscribe((resp) => {
          this.submit_petition.emit();
        });
    } else {
      if (this.id) {
        this.waterwiseService.patchWaterwise(this.id, obj).subscribe((resp) => {
          this.submit_petition.emit();
        });
      } else {
        this.waterwiseService.postWaterwise(obj).subscribe((resp) => {
          this.submit_petition.emit();
        });
      }
    }
  };

  textMac(value) {
    return this.globalFunction.agregarCaracter(value, ":", 2);
  }
  dragMarket(event) {
    if (event.coords) {
      const pond = this.ponds.find((item: any) => {
        return this.globalFunction.insidePolygon(
          event.coords.lat,
          event.coords.lng,
          item.coordinates
        );
      });
      if (pond) {
        this.markerDragEnd(pond, { coords: event.coords });
      } else {
        this.formWaterwise.controls["pond_id"].setValue("");
      }
    }
  }
  clickPolygonPond({ event, value }) {
    const exist = this.ponds.some((item: any) => item.id === value.id);
    if (exist) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      this.formWaterwise.controls["lat"].setValue(lat);
      this.formWaterwise.controls["lng"].setValue(lng);

      this.selectMarker = {
        lat,
        lng,
      };
      this.formWaterwise.controls["pond_id"].setValue(value.id);
    }
  }
  changeInputLatLng(type, value) {
    this.selectMarker[type] = value;
  }

  markerDragEnd(pond, { coords: { lat, lng } }) {
    this.formWaterwise.controls["pond_id"].setValue(pond.id);
    this.selectMarker = {
      lat,
      lng,
    };
    this.formWaterwise.controls["lat"].setValue(lat);
    this.formWaterwise.controls["lng"].setValue(lng);
  }

  validateIcon() {
    return waterwise_icons.sano;
  }

  deleteLatLng() {
    this.selectMarker = {
      lat: 0,
      lng: 0,
    };
    this.formWaterwise.controls["lat"].setValue("");
    this.formWaterwise.controls["lng"].setValue("");
  }
}
