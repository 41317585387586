import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";



@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    public storageService: StorageService,
    public toastr: ToastrService,
    private http: HttpClient,
    public router: Router,
  ) {}

  login(value) {
    return this.http
      .post(`${Uris.LOGIN}`, {}, this.headersAuthBase64(value))
      .pipe(
        map((resp: any) => {
          localStorage.setItem("USER", JSON.stringify(resp.user));
          this.saveToken(resp.token);
          this.storageService.animation(
            "success",
            "Éxito",
            `Bienvenido ${resp.user.first_name}`
          );
          return resp;
        })
      );
  }

  logout() {
    return this.http.post(`${Uris.LOGOUT}`, {}, this.headersAuthLogout()).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  logoutAll() {
    return this.http
      .delete(`${Uris.LOGOUTALL}`, this.headersAuthLogout())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  changePassword(value) {
    return this.http
      .post(`${Uris.CHANGE_PASSWORD}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  clientContacts(query) {
    return this.http
      .get(`${Uris.CLIENT_CONTACTS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteClientContact(id) {
    return this.http
      .delete(`${Uris.CLIENT_CONTACTS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Prospecto eliminado correctamente`
          );
          return resp;
        })
      );
  }

  patchClientContact(id, value) {
    return this.http
      .patch(
        `${Uris.CLIENT_CONTACTS}${id}/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `${resp.name} editado correctamente`
          );
          return resp;
        })
      );
  }

  clientContact(value) {
    return this.http
      .post(`${Uris.CLIENT_CONTACTS}`, value, this.headersNotoken())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Nos contactaremos pronto con usted.`
          );
        })
      );
  }

  sendContactEmail(obj) {
    return this.http
      .post(`${Uris.CONTACT}`, obj, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  bugLogs(query) {
    return this.http
      .get(`${Uris.BUGLOG}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  resetPassword(value) {
    return this.http
      .post(`${Uris.PASSWORD_RESET}`, value, this.headersNotoken())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  resetPasswordConfirm(value) {
    return this.http
      .post(`${Uris.PASSWORD_RESET_CONFIRM}`, value, this.headersNotoken())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  readToken() {
    return localStorage.getItem("token");
  }

  generateClientTemporalAccess(id) {
    return this.http
      .get(
        `${Uris.CLIENTS}${id}/generate_temporal_access/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  generateSubclientTemporalAccess(id) {
    return this.http
      .get(
        `${Uris.SUBCLIENTS}${id}/generate_temporal_access/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  private saveToken(idToken: string) {
    localStorage.setItem("token", idToken);
  }

  headersAuthLogout() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.readToken()} `,
    });
    return { headers: headers };
  }

  headersAuthBase64(value) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization:
        "Basic " + `${btoa(`${value.username}:${value.password}`)})`,
    });
    return { headers: headers };
  }

  headersNotoken() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return { headers: headers };
  }
}
