import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-virtual-weight",
  templateUrl: "./virtual_weight.html",
  styleUrls: ["./virtual_weight.scss"],
})
export class VirtualWeightComponent implements OnInit {
  @Input("label") label = "";
  @Input("percent") percent = 0;
  @Input("width") width = 75;
  @Input("height") height = 75;
  @Input() feeder = null;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  validateColor(percent) {
    let convpercent = percent * 100;

    if (percent === 0) return "verde";
    if (percent === null) return "rojo";
    if (convpercent > 0 && convpercent <= 25) return "verde";
    if (convpercent > 25 && convpercent <= 50) return "naranja";
    if (convpercent > 50 && convpercent <= 75) return "amarillo";
    if (convpercent > 75 && convpercent <= 100) return "rojo";
    /*  if (percent === 1) return 'gris'; */
    return "gris";
  }

  validateValue(percent) {
    let convert = percent * 100;
    let cal = convert;
    if (percent === null) return 100;
    if (percent === 0) return 0;

    return percent > 0 ? cal : 100;
  }
  click() {
    this.onClick.emit({
      label: this.label,
      percent: this.percent,
      feeder: this.feeder,
    });
  }
}
