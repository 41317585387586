import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { StorageService } from "../../routes/services/storage.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { Router } from "@angular/router";
import moment from "moment";

@Component({
  selector: "app-time-query",
  templateUrl: "./time-query.html",
  styleUrls: ["./time-query.scss"],
})
export class TimeQueryComponent implements OnInit {
  @Output("queryGenerated") queryGenerated = new EventEmitter();
  @Input("timezone") timezone?: any;
  users: any = [];
  timeGroup: FormGroup;
  ffill: Boolean = false;
  dataAgrupation: "M" | "W" | "D" | "H" = "H";

  validateRequisitions = false;

  loadingInform: boolean = false;

  firstDate: any;
  secondDate: any;

  constructor(
    public globalFunction: GlobalFunction,
    private fb: FormBuilder,
    public router: Router,
    public storageService: StorageService
  ) {
    this.timeGroup = this.fb.group({
      dateOption: "last24", // Default value
      start_date: new FormControl(""),
      finish_date: new FormControl(""),
      user: new FormControl(""),
    });
  }
  ngOnInit(): void {
    const { firstDate, secondDate } = this.last_24_hours();
    this.firstDate = firstDate;
    this.secondDate = secondDate;
    this.datesControls();
  }

  validateTypeSearch(value) {
    switch (value) {
      case "currentCycle":
        return false;
      case "last24":
        return false;
      default:
        return true;
    }
  }

  last_24_hours = () => {
    let now = moment().utc();
    let yesterday = moment().subtract(24, "hours").utc();
    let firstDate = yesterday.format("YYYY-MM-DDTHH:mm:ss") + "Z";
    let secondDate = now.format("YYYY-MM-DDTHH:mm:ss") + "Z";
    return { firstDate, secondDate };
  };
  datesControls() {
    const set_value = (start_date, finish_date) => {
      this.timeGroup.patchValue({
        start_date,
        finish_date,
      });
    };
    this.timeGroup.controls["dateOption"].valueChanges.subscribe(
      (option: string) => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        switch (option) {
          case "last24":
            const { firstDate, secondDate } = this.last_24_hours();
            this.firstDate = firstDate;
            this.secondDate = secondDate;
            break;
          case "specific":
            set_value("", "");
            break;
          case "today":
            set_value(
              moment(today).format("YYYY-MM-DD"),
              moment(tomorrow).format("YYYY-MM-DD")
            );
            break;
          case "last7Days":
            const sevenDaysAgo = new Date(today);
            sevenDaysAgo.setDate(today.getDate() - 7);
            set_value(this.formatDate(sevenDaysAgo), this.formatDate(today));
            break;
          case "thisWeek":
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay() + 1);
            const endOfWeek = new Date(today);
            endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
            set_value(this.formatDate(startOfWeek), this.formatDate(endOfWeek));
            break;
          case "thisMonth":
            const startOfMonth = new Date(
              today.getFullYear(),
              today.getMonth(),
              1
            );
            const endOfMonth = new Date(
              today.getFullYear(),
              today.getMonth() + 1,
              0
            );
            set_value(
              this.formatDate(startOfMonth),
              this.formatDate(endOfMonth)
            );
            break;
          case "currentCycle":
            set_value("", "");
          default:
            break;
        }
      }
    );
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  changeValueAgrupation(val) {
    const agrupations = {
      last24: "H",
      specific: "D",
      today: "H",
      last7Days: "D",
      thisWeek: "D",
      thisMonth: "W",
      currentCycle: "W",
    };

    this.dataAgrupation = agrupations[val.dateOption]
      ? agrupations[val.dateOption]
      : "D";
  }
  generateQuery = async (val?: any, validate_agrupation = false) => {
    if (
      val.dateOption !== "currentCycle" &&
      val.dateOption !== "last24" &&
      !((val.start_date && val.finish_date) || val.user)
    ) {
      this.globalFunction.animation(
        "warning",
        "Alerta",
        "Asegurate de llenar los campos"
      );
      return;
    }
      if (validate_agrupation) this.changeValueAgrupation(val);

    const currentCycle =
      val.dateOption == "currentCycle"
        ? `&actual_cycle=${val.dateOption == "currentCycle"}`
        : "";

    let dateQuery = "";
    switch (val.dateOption) {
      case "last24":
        dateQuery = `&created_at=${this.firstDate},${this.secondDate}`;
        break;
      case "today":
        if (val.start_date) {
          dateQuery = `&created_at=${val.start_date}T00:00:00Z,${val.start_date}T23:59:59Z`;
        }
        break;
      case "currentCycle":
        break;
      default:
        if (val.start_date && val.finish_date) {
          dateQuery = `&created_at=${val.start_date}T00:00:00Z,${val.finish_date}T23:59:59Z`;
        }
        break;
    }

    const is_admin = this.storageService.validateSoloAdmin();
    const query_fill = is_admin ? `&ffill=${this.ffill ? 1 : 0}` : "";

    const agrupationQuery = `?resample_rule=${this.dataAgrupation}`;
    const query = agrupationQuery + currentCycle + dateQuery + query_fill;

    this.queryGenerated.emit(query);
  };
}
