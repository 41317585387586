import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  getProducts(query) {
    return this.http
      .get(`${Uris.PRODUCTS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getProduct(id) {
    return this.http
      .get(`${Uris.PRODUCTS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postProduct(value) {
    return this.http
      .post(`${Uris.PRODUCTS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Producto registrado correctamente`
          );
          return resp;
        })
      );
  }

  patchProduct(id, value) {
    return this.http
      .patch(`${Uris.PRODUCTS}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Producto editado correctamente`
          );
          return resp;
        })
      );
  }

  deleteProduct(id) {
    return this.http
      .delete(`${Uris.PRODUCTS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Producto eliminado correctamente`
          );
          return resp;
        })
      );
  }
}
