export const colors = {
  low_success: "#B6D7A8",
  high_success: "#009e0f",
  low_danger: "#EA9999",
  high_danger: "#CF2A27",
  low_warning: "#F1C232",
  high_warning: "#FF9900",
  default_color: "#204349",
  white: "#ffffff",
};

const validateColorWithSimpleAlert = (value, item, type: "low" | "high") => {
  return value[item.prop]
    ? colors[`${type}_danger`]
    : `${colors[`${type}_success`]}`;
};

const validateHighColorRation = (value, item) => {
  return value[item.prop] ? colors.high_success : colors.high_warning;
};
const validatelowColorRation = (value, item) => {
  return value[item.prop] ? colors.low_success : colors.low_warning;
};

const validateColorBattery = (
  value,
  success,
  warning,
  danger,
  device: "feeder" | "waterwise" = "feeder"
) => {
  const { alert_battery, status_battery } = value;
  if (device === "feeder") {
    if (alert_battery) return danger;
    if (status_battery > 11.5 && status_battery <= 12.5) return warning;
    if (status_battery > 12.5) return success;
    if (status_battery <= 11.5) return danger;
  }
  if (device === "waterwise") {
    const { battery } = value;
    if (battery > 11.5 && battery <= 12.5) return warning;
    if (battery > 12.5) return success;
    if (battery <= 11.5) return danger;
  }
};

const validateColorPanel = (
  value,
  success,
  warning,
  danger,
  device: "feeder" | "waterwise" = "feeder"
) => {
  if (device === "feeder") {
    const { alert_panel, status_panel } = value;
    if (alert_panel) return danger;
    if (status_panel > 12 && status_panel <= 15) return warning;
    if (status_panel > 15) return success;
    if (status_panel <= 12) return danger;
  }
  if (device === "waterwise") {
    const { panel } = value;
    if (panel > 12 && panel <= 15) return warning;
    if (panel > 15) return success;
    if (panel <= 12) return danger;
  }
  return success;
};
const validateColorSprinkler = (value, success, warning, danger) => {
  if (value.alert_sprinkle) {
    return danger;
  }
  if (value.sprinkler_alerts && value.sprinkler_alerts > 0) {
    return warning;
  }
  return success;
};

const validateColorDispenser = (value, success, warning, danger) => {
  if (value.alert_dispenser_opening) {
    return warning;
  } else if (value.alert_dispenser) {
    return danger;
  }
  return success;
};

const tooltipSimpleAlert = (value, item) => {
  return (
    `Estatus de ${item.label.toLowerCase()}: ` +
    (value[item.prop] ? "Inactivo" : "Activo")
  );
};

const tooltipAlertWithSimpleValue = (value, item) => {
  return `${item.label}: ` + value[item.prop];
};
const validateSoloAdmin = () => {
  let localUser: string | null = localStorage.getItem("USER");
  let user: any = localUser ? <any>JSON.parse(localUser) : "";

  if ((user && user.user_type === -1) || user.user_type === 0) {
    return true;
  }
  if ((user && user.user_type === 1) || user.user_type === 2) {
    return false;
  }
  return false;
};
const tooltipAlertSprinkler = (data) => {
  const { status_sprinkler, alert_sprinkle, sprinkler_alerts } = data;
  if (data) {
    if (alert_sprinkle || status_sprinkler || sprinkler_alerts) {
      return `${`${
        alert_sprinkle || status_sprinkler
          ? `Alerta de aspersor ${status_sprinkler ? status_sprinkler : ""}`
          : ""
      } \n
        ${
          sprinkler_alerts && validateSoloAdmin()
            ? `Contador de alertas: ${sprinkler_alerts}`
            : ""
        }`}`;
    }
  }
  return "Activo";
};

const validateUrlDispenser = (data) => {
  const { alert_dispenser_opening, alert_dispenser } = data;
  if (alert_dispenser_opening) {
    return "img/alerts/apertura.svg";
  } else if (alert_dispenser) {
    return "img/alerts/atoron.svg";
  }
  return "img/alerts/dosificador.svg";
};

const tooltipAlertDispenser = (data) => {
  const {
    status_dispenser_opening,
    alert_dispenser_opening,
    alert_dispenser,
    status_dispenser,
  } = data;
  if (data) {
    if (alert_dispenser) {
      return `${
        alert_dispenser
          ? `Alerta de atoron ${status_dispenser ? status_dispenser : ""} `
          : ""
      }`;
    }
    if (alert_dispenser_opening) {
      return `${
        alert_dispenser_opening
          ? `Alerta de apertura ${
              status_dispenser_opening ? status_dispenser_opening : ""
            }`
          : ""
      }`;
    }
    if (!alert_dispenser_opening && !alert_dispenser) {
      return "Activo";
    }
  }
};

const tooltipRation = (data) => {
  const { feeding_details } = data;
  return feeding_details && feeding_details.fail_reason
    ? feeding_details.fail_reason
    : "";
};

export const alerts = [
  {
    label: "Comunicación",
    prop: "alert_comm",
    url_image: "img/icons/senal-blanco.svg",
    validateBackgroundColor: (value, item) => {
      return validateColorWithSimpleAlert(value, item, "low");
    },
    validateColorIcon: (value, item) => {
      return validateColorWithSimpleAlert(value, item, "high");
    },
    tooltip: tooltipSimpleAlert,
  },
  {
    label: "Reinicios",
    prop: "alert_reset",
    icon: "fa fa-bolt",
    validateBackgroundColor: (value, item) => {
      return validateColorWithSimpleAlert(value, item, "low");
    },
    validateColorIcon: (value, item) => {
      return validateColorWithSimpleAlert(value, item, "high");
    },
    tooltip: tooltipSimpleAlert,
  },
  {
    label: "Batería",
    prop: "status_battery",
    validateBackgroundColor: (data) => {
      const { low_success, low_warning, low_danger } = colors;
      return validateColorBattery(data, low_success, low_warning, low_danger);
    },
    validateColorIcon: (data) => {
      const { high_success, high_warning, high_danger } = colors;
      return validateColorBattery(
        data,
        high_success,
        high_warning,
        high_danger
      );
    },
    tooltip: tooltipAlertWithSimpleValue,
  },
  {
    label: "Panel",
    prop: "status_panel",
    url_image: "img/icons/panel.svg",
    validateBackgroundColor: (data) => {
      const { low_success, low_warning, low_danger } = colors;
      return validateColorPanel(data, low_success, low_warning, low_danger);
    },
    validateColorIcon: (data) => {
      const { high_success, high_warning, high_danger } = colors;
      return validateColorPanel(data, high_success, high_warning, high_danger);
    },
    tooltip: tooltipAlertWithSimpleValue,
  },
  {
    label: "Aspersor",
    prop: "status_panel",
    url_image: "img/alerts/aspersor-alert.svg",
    validateBackgroundColor: (data) => {
      const { low_success, low_warning, low_danger } = colors;
      return validateColorSprinkler(data, low_success, low_warning, low_danger);
    },
    validateColorIcon: (data) => {
      const { high_success, high_warning, high_danger } = colors;
      return validateColorSprinkler(
        data,
        high_success,
        high_warning,
        high_danger
      );
    },
    tooltip: tooltipAlertSprinkler,
  },
  {
    label: "Dosificador",
    prop: "status_panel",
    validateImage: validateUrlDispenser,
    validateBackgroundColor: (data) => {
      const { low_success, low_warning, low_danger } = colors;
      return validateColorDispenser(data, low_success, low_warning, low_danger);
    },
    validateColorIcon: (data) => {
      const { high_success, high_warning, high_danger } = colors;
      return validateColorDispenser(
        data,
        high_success,
        high_warning,
        high_danger
      );
    },
    tooltip: tooltipAlertDispenser,
  },
  {
    label: "Racion",
    prop: "status_ration",
    url_image: "img/icons/racion.svg",
    validateBackgroundColor: validatelowColorRation,
    validateColorIcon: validateHighColorRation,
    tooltip: tooltipRation,
  },
];

export const alerts_waterwise = [
  {
    label: "Batería",
    prop: "battery",
    validateBackgroundColor: (data) => {
      const { low_success, low_warning, low_danger } = colors;
      return validateColorBattery(
        data,
        low_success,
        low_warning,
        low_danger,
        "waterwise"
      );
    },
    validateColorIcon: (data) => {
      const { high_success, high_warning, high_danger } = colors;
      return validateColorBattery(
        data,
        high_success,
        high_warning,
        high_danger,
        "waterwise"
      );
    },
    tooltip: tooltipAlertWithSimpleValue,
  },
  {
    label: "Panel",
    prop: "panel",
    url_image: "img/icons/panel.svg",
    validateBackgroundColor: (data) => {
      const { low_success, low_warning, low_danger } = colors;
      return validateColorPanel(data, low_success, low_warning, low_danger);
    },
    validateColorIcon: (data) => {
      const { high_success, high_warning, high_danger } = colors;
      return validateColorPanel(data, high_success, high_warning, high_danger);
    },
    tooltip: tooltipAlertWithSimpleValue,
  },
];

const validateValueSensor = (data, item) => {
  try {
    if (item.is_hydro) {
      return data.sensor_hydro ? data.sensor_hydro[item.prop] : 0;
    }
    return data.sensors ? data.sensors[item.prop] : 0;
  } catch (error) {
    return 0;
  }
};
const validateMinMaxConfigValueSensor = (data, item, type: "min" | "max") => {
  try {
    return data.config ? data.config[`${item.preference}_${type}`] : 0;
  } catch (error) {
    return 0;
  }
};

const validateColorSensor = (data, item, type: "low" | "high") => {
  const validate_sensor = (type: "min" | "max") => {
    return `${item.preference}_${type}`;
  };
  const alert = {
    danger: colors[`${type}_danger`],
    warning: colors[`${type}_warning`],
    success: colors[`${type}_success`],
  };
  try {
    const min = validate_sensor("min")
      ? data.config[validate_sensor("min")]
      : "";
    const max = validate_sensor("max")
      ? data.config[validate_sensor("max")]
      : "";

    if (!min && !max) return alert.danger;

    const val = data[item.is_hydro ? `sensor_hydro` : "sensors"]
      ? data[item.is_hydro ? `sensor_hydro` : "sensors"][item.prop]
      : "";
    const value = val ? val : "";
    const warning_value = Number(max - min) / 5;
    const warning_min = min + warning_value;
    const warning_max = max - warning_value;

    if (!min && !max) {
      return alert.success;
    }
    if (value < min || value > max) {
      return alert.danger;
    }
    if (
      (value > min && value < warning_min) ||
      (value < max && value > warning_max)
    ) {
      return alert.warning;
    }
    if (value > min && value < max) {
      return alert.success;
    }
    return alert.success;
  } catch (error) {
    return alert.danger;
  }
};
export const sensors = [
  {
    number: 1,
    is_hydro: false,
    label: "Temperatura ambiental",
    prop: "temp",
    label_table: "TEMP A",
    measurementThreshold: "°C",
    check: false,
    value: validateValueSensor,
    preference: "TEM",
    default_color: "",
    min: 5,
    max: 50,
    kind: 1,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 2,
    is_hydro: false,
    label: "Temperatura estanque",
    prop: "water_temp",
    label_table: "TEMP E",
    measurementThreshold: "°C",
    check: false,
    preference: "PTEM",
    default_color: "",
    min: 10,
    max: 45,
    kind: 11,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 3,
    is_hydro: false,
    label: "Humedad",
    prop: "hum",
    label_table: "HUM",
    measurementThreshold: "%",
    check: false,
    preference: "HUM",
    default_color: "",
    value: validateValueSensor,
    min: 20,
    max: 100,
    kind: 12,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 4,
    is_hydro: true,
    label: "Oxigeno disuelto",
    default_color: "yellow",
    prop: "DO",
    label_table: "OX",
    measurementThreshold: "mg/L",
    check: false,
    preference: "DO",
    min: 1,
    max: 18,
    kind: 4,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 5,
    is_hydro: true,
    label: "Conductividad eléctrica",
    prop: "EC",
    label_table: "EC",
    default_color: "green",
    measurementThreshold: "µS/cm",
    check: false,
    preference: "EC",
    min: 0,
    max: 100000,
    kind: 7,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 6,
    is_hydro: true,
    label: "Saturación",
    prop: "SAT",
    label_table: "SAT",
    measurementThreshold: "%",
    check: false,
    preference: "SAT",
    default_color: "yellow",
    min: 20,
    max: 130,
    kind: 5,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 7,
    is_hydro: true,
    label: "Gravedad específica",
    prop: "SG",
    label_table: "GE",
    measurementThreshold: "",
    check: false,
    preference: "SG",
    default_color: "green",
    min: 1,
    max: 1.04,
    kind: 9,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 8,
    is_hydro: true,
    label: "Salinidad",
    prop: "SAL",
    label_table: "SAL",
    measurementThreshold: "PSU",
    check: false,
    preference: "SAL",
    default_color: "green",
    min: 0,
    max: 40,
    kind: 3,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 9,
    is_hydro: true,
    label: "Solidos totales disueltos",
    prop: "TDS",
    label_table: "STD",
    measurementThreshold: "mg/L",
    check: false,
    preference: "TDS",
    default_color: "green",
    min: 0,
    max: 40000,
    kind: 8,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 10,
    is_hydro: true,
    label: "Potencial de hidrogeno",
    prop: "PH",
    label_table: "PH",
    measurementThreshold: "",
    check: false,
    preference: "PH",
    default_color: "red",
    min: 3,
    max: 10,
    kind: 6,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
  {
    number: 11,
    is_hydro: true,
    label: "Potencia de reducción-oxidación",
    prop: "ORP",
    label_table: "REDOX",
    measurementThreshold: "mV",
    check: false,
    preference: "ORP",
    default_color: "blue",
    min: -200,
    max: 600,
    kind: 10,
    value: validateValueSensor,
    min_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "min");
    },
    max_value: (data, item) => {
      return validateMinMaxConfigValueSensor(data, item, "max");
    },
    validateLowColorSensor: (data, item) => {
      return validateColorSensor(data, item, "low");
    },
    validateHighColorSensor: (data, item) => {
      return validateColorSensor(data, item, "high");
    },
  },
];
