import { Location } from "@angular/common";
import { Component, OnInit, Input } from "@angular/core";
import { MenuService } from "../../core/menu/menu.service";

@Component({
  selector: "app-title",
  templateUrl: "./title.html",
  styleUrls: ["./title.scss"],
})
export class TitleComponent implements OnInit {
  @Input() title;
  @Input() small;
  @Input() tooltip;
  @Input() svg;

  user;
  current;

  constructor(public menuService: MenuService, public Location: Location) {}

  ngOnInit(): void {
    let localUser = localStorage.getItem("USER") || "";
    let user: any = <any>JSON.parse(localUser);
    this.user = user;

    let location = this.Location.path();
    const menu = this.menuService.getMenu();

    let loc = location.replace(/[0-9+/]/g, "");

    const validateText = (array, text) => {
      const include = (text, link) => text.includes(link);
      const replace = (text) => text.replace("/", "");

      let find = array.find((item) => {
        if (!item.submenu) {
          return include(text, replace(item.link));
        }
        if (item.submenu) {
          return item.submenu.find((item) => {
            return include(text, replace(item.link));
          });
        }
        return item;
      });
      return find;
    };
    let validate = validateText(menu, loc);
    this.current = validate;
  }
}
