import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class ClientsService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  getClients(query = "") {
    return this.http
      .get(`${Uris.CLIENTS}${query}`, this.storageService.headersSoloAdmin())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getClient(id) {
    return this.http
      .get(`${Uris.CLIENTS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postClient(value) {
    return this.http
      .post(`${Uris.CLIENTS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Cliente ${resp.user.username} creado correctamente`
          );
          return resp;
        })
      );
  }

  patchClient(id, value) {
    return this.http
      .patch(`${Uris.CLIENTS}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `${resp.user.username} editado correctamente`
          );
          return resp;
        })
      );
  }

  deleteClient(id, name) {
    return this.http
      .delete(`${Uris.CLIENTS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Cliente ${name} eliminado correctamente`
          );
          return resp;
        })
      );
  }

  getClientFarms(query: string = "", id?: any) {
    const url = id
      ? `${Uris.FARM_CLIENTS}${id}/${query}`
      : `${Uris.FARM_CLIENTS}${query}`;

    return this.http
      .get(url, this.storageService.headers())
      .pipe(map((resp: any) => resp));
  }

  getClientReport(id) {
    return this.http
      .get(`${Uris.CLIENTS}${id}/get_report/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getSeasons(query) {
    return this.http
      .get(`${Uris.SEASONS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getSeason(id) {
    return this.http
      .get(`${Uris.SEASONS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postSeason(obj: any) {
    return this.http
      .post(`${Uris.SEASONS}`, obj, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  patchSeason(obj: any, id: any) {
    return this.http
      .patch(`${Uris.SEASONS}${id}/`, obj, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  deleteSeason(id: any) {
    return this.http
      .delete(`${Uris.SEASONS}${id}/`, this.storageService.headersSoloAdmin())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
