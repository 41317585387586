import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { forkJoin, Observable } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { GlobalFunction } from "../globalFuntion";
import { Table } from "./constants";

@Injectable({
  providedIn: "root",
})
export class IndexedDBService {
  constructor(
    private dbService: NgxIndexedDBService,
    public globalFunction: GlobalFunction
  ) {}

  createOrUpdtate(table: Table, data): Observable<any> {
    return this.dbService.getByKey(table, data.id).pipe(
      concatMap((resultgetByKey: any) => {
        const old: any = resultgetByKey ? { ...resultgetByKey } : {};
        const deepEqual = this.globalFunction.differentFields(old, data);
        if (deepEqual) {
          return this.dbService.update(table, { ...old, ...data }).pipe(
            map(() => {
              return { ...old, ...data };
            })
          );
        } else {
          return new Promise(() => {
            return old;
          });
        }
      })
    );
  }
  /* Funcion para verificar propiedades que cambiaron */
  verifyUpdated(type: Table, array?) {
    try {
      /* Obtenemos lo valores almacenados */
      this.dbService.getAll(type).subscribe((db) => {
        if (db.length > 0) {
          array.forEach((value_array: any) => {
            const exist = db.find(
              (value_db: any) => value_db.id === value_array.id
            );
            if (exist) {
              /* Validamos los registros que son diferentes y editamos */
              if (!this.globalFunction.differentFields(exist, value_array)) {
                this.createOrUpdtate(type, exist).subscribe((item) => {});
              }
            }
          });
        } else {
          this.bulkAdd(type, array).subscribe((resp) => {});
        }
      });
    } catch (error) {}
  }

  bulkAdd(table: Table, value) {
    return this.dbService.bulkAdd(table, value);
  }

  getAllByName(table: Table): Observable<any[]> {
    return this.dbService.getAll(table);
  }

  cleardb(table: Table): Observable<any> {
    return this.dbService.clear(table);
  }

  clearAlldb = async () => {
    const clear_pond = this.cleardb("ponds");
    const clear_ponds_simple = this.cleardb("ponds_simple");

    forkJoin([clear_pond, clear_ponds_simple]).subscribe(
      ([response1]) => {
        // Maneja las respuestas aquí
      },
      (error) => {
        // Maneja errores aquí
      }
    );
  };
  getById(table: Table, id: number) {
    return this.dbService.getByKey(table, id);
  }
  getByIndex(table: Table, index: string, value: string) {
    return this.dbService.getByIndex(table, index, value);
  }
}
