import { StorageService, actions } from "./storage.service";
import { Uris } from "../../routes/services/Uris";
import { Injectable } from "@angular/core";
import { webSocket } from "rxjs/webSocket";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  conectionSocket;
  constructor(public storageService: StorageService) {}

  actions: Array<actions> = [];

  validateToken() {
    const { client, mode } = this.storageService.localValue;
    let tokenUser = localStorage.getItem("token");

    if (mode === 1 && client) {
      return client.token;
    }
    if (!mode) {
      return tokenUser;
    }
  }

  socketNext = async (actions: Array<actions>) => {
    const remove_listener: Array<actions> = [...this.actions].filter((item) => {
      return !actions.some((action) => action.data?.farm === item.data?.farm);
    });
    const mode = this.storageService.localValue.mode;

    if (
      remove_listener.length > 0 &&
      (mode || !this.storageService.validateSoloAdmin())
    ) {
      const next = remove_listener.map((item) => {
        return { ...item, type: "gateways.remove_listener" };
      });
      next.forEach(async (action) => {
        await this.conectionSocket.next(action);
      });
    }
    actions.forEach(async (action) => {
      await this.conectionSocket.next(action);
    });

    this.actions = [...actions];
  };

  unSuscribeSocket() {
    if (this.conectionSocket) {
      this.conectionSocket.unsubscribe();
    }
  }

  connectSocket = async (actions) => {
    this.unSuscribeSocket();
    this.conectionSocket = webSocket({
      url: Uris.SOCKET,
    });

    this.conectionSocket.subscribe({
      next: (msg: any) => {
        this.storageService.setItem("socket", msg);
        console.log(msg);
      },
      error: (err) => {
        this.conectionSocket.unsubscribe();
        setTimeout(() => this.connectSocket(this.actions), 1000);
      },
      complete: () => {},
    });
    this.actions = [...actions];
    this.conectionSocket.next({
      token: this.validateToken(),
      actions,
    });
  };

  setFeedersSocket = (feeders) => {
    if (this.storageService.localValue.mode === 0) {
      this.socketNext([
        {
          type: "listen.all",
        },
        {
          type: "whitelist.set",
          data: {
            addresses: feeders.map((item) => item.mac),
          },
        },
      ]);
    }
  };
}
