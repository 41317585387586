import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(
    public storageService: StorageService,
    public toastr: ToastrService,
    private http: HttpClient,
    public router: Router
  ) {}

  getNotifications(query) {
    return this.http
      .get(`${Uris.NOTIFICATIONS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  markNotificationAsSeen(notification) {
    return this.http
      .post(
        `${Uris.NOTIFICATIONS}mark_as_read/`,
        notification,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteNotification(body: any) {
    return this.http
      .post(`${Uris.NOTIFICATIONS}delete/`, body, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getNotificationsPreferences() {
    let query = "?page=1&page_size=1&ordering=-id";
    return this.http
      .get(
        `${Uris.NOTIFICATIONS}preferences/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postPreferences(preferences) {
    return this.http
      .post(
        `${Uris.NOTIFICATIONS}preferences/`,
        preferences,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Preferencias guardadas correctamente`
          );
          return resp;
        })
      );
  }

  unReadPerFarm(query = "") {
    return this.http
      .get(
        `${Uris.NOTIFICATIONS}unread_per_farm/` + query,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
