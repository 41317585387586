import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { HelpService } from "../../../core/help/help.service";
import { SettingsService } from "../../../core/settings/settings.service";
import { TranslatorService } from "../../../core/translator/translator.service";
import { ClientsService } from "../../services/clients.service";
import { FeedersService } from "../../services/feeder.service";
import { PlantingsService } from "../../services/plantings.service";
import { PermissionsService } from "../../services/permissions.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  farms = [];
  ponds = [];
  pondsSelected = [];

  farmID = "";
  options: any = [
    {
      id: 1,
      text: this.translatorService.translate("clients.settings.feed"),
      validate: true,
    },
    {
      id: 2,
      text: this.translatorService.translate("clients.settings.sow"),
      validate: true,
    },
    {
      id: 3,
      text: this.translatorService.translate("clients.settings.pre-harvest"),
      validate: true,
    },
    {
      id: 4,
      text: this.translatorService.translate("clients.settings.harvest"),
      validate: true,
    },
  ];

  noFarm = false;
  FormSearch: FormGroup;
  selectOption = 0;
  hideOptions = true;
  name = "";

  constructor(
    public translatorService: TranslatorService,
    public plantingsService: PlantingsService,
    public SettingService: SettingsService,
    public FeedersService: FeedersService,
    public clientsService: ClientsService,
    public helpService: HelpService,
    public route: ActivatedRoute,
    public permissionsService: PermissionsService,
    public storageService: StorageService
  ) {
    this.FormSearch = new FormGroup({
      name: new FormControl(""),
      order: new FormControl(""),
    });
  }
  ngDoCheck(): void {
    this.pondsSelected = this.SettingService.settingsPonds.listSelected;
  }

  ngOnInit(): void {
    const validate = (prop) => {
      return this.permissionsService.validatePermissionsExistSubclient(prop);
    };

    const idx_harvest = this.options.findIndex((item) => item.id === 3);
    const idx_preharvest = this.options.findIndex((item) => item.id === 4);
    const idx_feed = this.options.findIndex((item) => item.id === 1);

    this.options[idx_feed].validate = validate("view_feed");
    this.options[idx_harvest].validate = validate("view_harvest");
    this.options[idx_preharvest].validate = validate("view_harvest");
  }

  addStatusPonds(value) {
    let array: any = [];
    for (let i = 0; i < value.length; i++) {
      array.push({ ...value[i], status: false });
    }
    return array;
  }

  showOption(value) {
    this.selectOption = value.id;
  }

  changeHideOption() {
    this.SettingService.settingsPonds.status =
      !this.SettingService.settingsPonds.status;
  }

  validatelinked_feeders(item) {
    if (item.linked_feeders.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  close(value) {
    this.selectOption = 0;
  }
  resetSelected() {
    this.changeHideOption();
  }

  disableButton(item) {
    if (item.planting) {
      if (item.is_done) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  validateOptions() {
    let array = this.options;

    const plantings = (item) => !item.is_done;
    const harvest = (item) => item.is_done;

    if (this.pondsSelected.every(harvest)) {
      return array.filter((item) => item.id === 2);
    }
    if (this.pondsSelected.every(plantings)) {
      return array.filter((item) => item.id !== 2);
    }

    return array;
  }
  submitHarvests = (event) => {
    this.SettingService.settingsPonds.status = false;
    this.storageService.setItem("reload", event);
  };
}
