import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class RegionService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}
  list(query = "") {
    return this.http
      .get(Uris.REGION + query, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  create(value) {
    return this.http
      .post(`${Uris.REGION}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  update(id, value) {
    return this.http
      .patch(`${Uris.REGION}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  addClient(region, body) {
    return this.http
      .post(
        `${Uris.REGION}${region}/add_clients/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  removeClient(region, body) {
    return this.http
      .post(
        `${Uris.REGION}${region}/remove_clients/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  deleteRegion(region) {
    return this.http
      .delete(`${Uris.REGION}${region}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  listClientAssigned(id) {
    return this.http
      .get(Uris.REGION + id + "/clients/", this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getConfigFuzzyLogic(id) {
    return this.http
      .get(
        Uris.REGION + id + "/config/feeding_fuzzy_logic/",
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  setConfigFuzzyLogic(id, body) {
    return this.http
      .post(
        Uris.REGION + id + "/config/feeding_fuzzy_logic/",
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  deleteConfigFuzzyLogic(region_id) {
    return this.http
      .delete(
        Uris.REGION + region_id + "/config/feeding_fuzzy_logic/",
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  testConfigFuzzyLogic(body) {
    return this.http
      .post(
        Uris.CONFIG + "feeding_fuzzy_logic/test_calc_creation/",
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
