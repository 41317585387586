import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { FramesService } from "../../routes/services/frames.service";
import { StorageService } from "../../routes/services/storage.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";

@Component({
  selector: "app-sensed",
  templateUrl: "./sensed.html",
  styleUrls: ["./sensed.scss"],
})
export class SensedComponent implements OnInit {
  @Input() data: Array<any>;
  @Input() view_sensed;
  @Input() type: "individual" | "masive_feeders" | "masive_ponds";
  @Input() view: "individual" | "masive";
  @Input() mode: "default" | "hydro" = "default";

  @Output("submit") submit = new EventEmitter();

  enabledDisabledSensed = false;
  enabledDisabledPoints = false;

  hoursSelected: any = [];
  hours = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  interval: "c_hours" | "3_days" | "5_days" | "" = "c_hours";

  sensed: Array<sendedInterface> = [
    {
      label: "Temperatura del agua",
      unit: "Temperatura",
      min: 0,
      max: 0,
      img: "assets/img/water-temperature.png",
    },
    {
      label: "Temperatura del ambiente",
      unit: "Temperatura",
      min: 0,
      max: 0,
      img: "assets/img/alta-temperatura.png",
    },
    {
      label: "Humedad",
      unit: "Humedad",
      min: 0,
      max: 0,
      img: "assets/img/humidity.png",
    },
    {
      label: "Humedad",
      unit: "Humedad",
      min: 0,
      max: 0,
      button: "Enviar temperaturas",
      class: "btn btn-secondary btn-block mt-2",
      type: "temp",
    },
  ];

  constructor(
    public framesService: FramesService,
    public storageService: StorageService,
    public globalFunction: GlobalFunction
  ) {}

  ngAfterContentInit(): void {
    if (this.mode === "default") {
      if (this.type === "individual" || this.type === "masive_feeders") {
        if (this.data.length >= 1) {
          let value = this.data[0].sensors;

          if (value) {
            const {
              set_point_enabled,
              sensors_enabled,
              alert_hum_min,
              alert_hum_max,
              alert_temp_min,
              alert_temp_max,
              alert_water_temp_min,
              alert_water_temp_max,
              frequencies,
              frequencies_mode,
            } = value;
            this.enabledDisabledSensed = sensors_enabled;
            this.enabledDisabledPoints = set_point_enabled;
            /* Temp Agua */
            this.sensed[0].min = alert_water_temp_min;
            this.sensed[0].max = alert_water_temp_max;
            /* Temp ambiente */
            this.sensed[1].min = alert_temp_min;
            this.sensed[1].max = alert_temp_max;
            /* Humedad */
            this.sensed[2].min = alert_hum_min;
            this.sensed[2].max = alert_hum_max;

            this.interval = this.validateFrecuncy(frequencies);
            this.hoursSelected = frequencies;
          }
        }
      }
    } else {
      if (this.type === "individual" || this.type === "masive_feeders") {
        if (this.data.length >= 1) {
          let value = this.data[0].sensor_hydro;

          if (value) {
            const { frequency, power } = value;
            this.enabledDisabledPoints = power;
            const validate = () => {
              if (frequency) {
                if (frequency.length === 1) {
                  return "c_hours";
                }
                if (frequency.length === 4) {
                  return "3_days";
                }
                if (frequency.length === 6) {
                  return "5_days";
                }
              }
              return "c_hours";
            };
            this.interval = validate();

            this.hoursSelected = frequency;
            this.hoursSelected.splice(0, 1);
          }
        }
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["data"]) {
      const feeder = changes["data"].currentValue;

      if (feeder.sensor_hydro) {
        this.interval = this.validateFrecuncy(feeder.sensor_hydro.frequency);
        this.hoursSelected = feeder.sensor_hydro.frequency;
      }
    }
  }
  ngOnInit(): void {
    /* Si es tipo individual vamos a mandar los valores  */
  }
  validateFrecuncy = (frecuency) => {
    if (frecuency) {
      if (frecuency.length === 0) {
        return "c_hours";
      }
      if (frecuency.length === 3) {
        return "3_days";
      }
      if (frecuency.length === 5) {
        return "5_days";
      }
    }
    return "c_hours";
  };
  validatechangedays(type) {
    this.interval = type;

    if (this.hoursSelected) {
      if (this.hoursSelected.length === 5) {
        this.hoursSelected.splice(3, 5);
      }
      if (this.hoursSelected.length === 4) {
        this.hoursSelected.splice(3, 4);
      }
    }
  }
  filterSelectItem(array) {
    if (this.hoursSelected && this.hoursSelected.length > 0) {
      return array.filter((item) => !this.hoursSelected.includes(item));
    }
    return array;
  }
  deleteHour(i) {
    this.hoursSelected.splice(i, 1);
  }
  changeSelectHours(val) {
    if (val) {
      if (this.interval === "3_days" && this.hoursSelected.length < 3) {
        this.hoursSelected.push(val);
      }
      if (this.interval === "5_days" && this.hoursSelected.length < 5) {
        this.hoursSelected.push(val);
      }
      this.hoursSelected.sort((a, b) => a - b);
    }
  }
  setConfig() {
    if (this.mode === "default") {
      this.setConfigDefault();
    } else {
      this.setConfigHydro();
    }
  }

  setConfigDefault() {
    const feedersQuery = `?id_in=${this.data.map((item) => item.id)}`;
    const validateInterval = (type) => {
      return this.interval && this.interval === type;
    };
    const submitFrame = (obj) => {
      const type = ({
        frequency,
      }):
        | "sensor/frequency/set_3_hours"
        | "sensor/frequency/set_5_hours"
        | "sensor/frequency/set_hourly" => {
        if (frequency == 3) {
          return "sensor/frequency/set_3_hours";
        }
        if (frequency == 5) {
          return "sensor/frequency/set_5_hours";
        }
        return "sensor/frequency/set_hourly";
      };

      this.framesService
        .FramePost(type(obj), feedersQuery, obj)
        .subscribe((resp) => {
          this.submit.emit({ type: "CHANGE_TIME_DAYS", value: resp });
          /* CHANGE_TIME_DAYS */
          if (resp && resp.errors) {
            this.globalFunction.animation(
              "error",
              "Gateway desconectado",
              "Error"
            );
          } else {
            this.globalFunction.animation(
              "success",
              `Se ha mandado la configuración correctamente`,
              "Ok"
            );
          }
        });
    };
    if (validateInterval("c_hours")) {
      submitFrame({
        frequency: 1,
        first: this.hoursSelected[0],
      });
    }
    if (validateInterval("3_days")) {
      submitFrame({
        frequency: this.hoursSelected.length,
        first: this.hoursSelected[0],
        second: this.hoursSelected[1],
        third: this.hoursSelected[2],
      });
    }
    if (validateInterval("5_days")) {
      submitFrame({
        frequency: this.hoursSelected.length,
        first: this.hoursSelected[0],
        second: this.hoursSelected[1],
        third: this.hoursSelected[2],
        fourth: this.hoursSelected[3],
        fifth: this.hoursSelected[4],
      });
    }
  }
  setConfigHydro() {
    const feedersQuery = `?id_in=${this.data.map((item) => item.id)}`;
    const validate_value = () => {
      if (this.interval === "c_hours") {
        return [];
      }
      return this.hoursSelected
        .map((item) => Number(item))
        .sort((a, b) => {
          return a - b;
        });
    };
    const valitate_frequency = () => {
      if (this.interval === "3_days") {
        return 3;
      }
      if (this.interval === "5_days") {
        return 5;
      }
      return 1;
    };
    let obj = {
      frequency: valitate_frequency(),
      values: validate_value(),
    };
    this.framesService
      .FramePost("sensor_hydro_set_config", feedersQuery, obj)
      .subscribe((resp) => {
        this.submit.emit({ type: "CHANGE_TIME_DAYS", value: resp });
        if (resp && resp.errors) {
          this.globalFunction.animation(
            "error",
            "Gateway desconectado",
            "Error"
          );
        } else {
          this.globalFunction.animation(
            "success",
            `Se ha mandado la configuración correctamente`,
            "Ok"
          );
        }
      });
    /* /v1/gateway/frames/feeders/sensor_hydro_set_config/ */
  }
  submitTemperatures(type) {
    let water = this.sensed[0];
    let temp = this.sensed[1];
    let hum = this.sensed[2];

    let obj: any = {
      /* Minimos y maximos  */
      water_temp_min: water.min,
      water_temp_max: water.max,
      temp_min: temp.min,
      temp_max: temp.max,
      hum_min: hum.min,
      hum_max: hum.max,
    };
    this.submit.emit({ type: "SENSORS", value: obj });
    /* PETICION PARA MANDAR TODOS LOS VALORES  */
  }
  validateButton() {
    let water: sendedInterface = this.sensed[0];
    let temp: sendedInterface = this.sensed[1];
    let hum: sendedInterface = this.sensed[2];

    if (
      water.min <= 0 ||
      temp.min <= 0 ||
      hum.min <= 0 ||
      water.max <= 0 ||
      temp.max <= 0 ||
      hum.max <= 0
    ) {
      return true;
    }
    if (
      water.min > 0 &&
      temp.min > 0 &&
      hum.min > 0 &&
      water.max > 0 &&
      temp.max > 0 &&
      hum.max > 0
    ) {
      return false;
    }
  }
  validateSubmit() {}
  changeEnabledDisabled(value) {
    /* FUNCION PARA ACTIVAR Y DESACTIVAR SENSORES  */
    this.submit.emit({ type: "ENABLE_DISABLE_SENSORS", value });
  }

  setPoints(value) {
    this.submit.emit({ type: "SENSORS_SET_POINTS", value });
  }
  toggleOnOffHydro(value) {
    const on_off = value
      ? "sensor_hydro_set_power_on"
      : "sensor_hydro_set_power_off";
    const feedersQuery = `?id_in=${this.data.map((item) => item.id)}`;

    this.framesService.Frame(on_off, feedersQuery).subscribe((resp) => {});
  }

  getConfig() {
    const feedersQuery = `?id_in=${this.data.map((item) => item.id)}`;
    const type =
      this.mode === "default"
        ? "sensor/frequency/request"
        : "sensor_hydro_request_config";

    this.framesService.Frame(type, feedersQuery).subscribe((resp) => {
      this.submit.emit({ type: "SENSOR_FREQUENCY_REQUEST", value: resp });
    });
  }
}
interface sendedInterface {
  label: string;
  unit: string;
  min: number;
  max: number;
  button?: string;
  class?: string;
  type?: string;
  img?: string;
}
