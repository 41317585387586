import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TranslatorService } from "../../../core/translator/translator.service";
import { FramesService } from "../../../routes/services/frames.service";
import { GlobalFunction } from "../../../routes/services/globalFuntion";

@Component({
  selector: "app-menu-panel-alert",
  templateUrl: "./menu.html",
  styleUrls: ["../panel-alerts.scss"],
})
export class MenuPanelAlertComponent implements OnInit {
  @Input() list = [];
  @Input() type = "feeder";
  @Input() reload = false;
  @Input() filters = {
    name: "",
    statusFeeders: "",
    pondSelected: "",
  };
  /* AGREGAR FUNCIONES PARA VALIDAR CADA BOTON */
  firsItems: section[] = [
    {
      icon: "test-alarm.svg",
      label: this.translatorService.translate("testing.alarm-test"),
      action: "monitor",
      count: 0,
      done: 0,
      status: "",
    },
    {
      icon: "bateria-blanco.svg",
      label: this.translatorService.translate("testing.battery-test"),
      action: "battery",
      count: 0,
      done: 0,
      status: "",
    },
    {
      icon: "panel-blanco.svg",
      label: this.translatorService.translate("testing.panel-test"),
      action: "panel",
      count: 0,
      done: 0,
      status: "",
    },
    {
      icon: "reinicio-blanco.svg",
      label: this.translatorService.translate("testing.alerts-reset"),
      action: "reset",
      count: 0,
      done: 0,
      status: "",
    },
  ];

  secondItems: section[] = [
    {
      icon: "temporal-blanco.svg",
      label: this.translatorService.translate("testing.request-version"),
      action: "version",
      count: 0,
      done: 0,
      status: "",
    },
    {
      icon: "senal-blanco.svg",
      label: this.translatorService.translate("testing.request-signal"),
      action: "signal",
      count: 0,
      done: 0,
      status: "",
    },
    {
      icon: "reenviar-blanco.svg",
      label: this.translatorService.translate("testing.resend-ration"),
      action: "refeed",
      count: 0,
      done: 0,
      status: "",
    },
    {
      icon: "power-off-blanco.svg",
      label: this.translatorService.translate("testing.turn-off"),
      action: "turn_off",
      count: 0,
      done: 0,
      status: "",
    },
    {
      icon: "power-blanco.svg",
      label: this.translatorService.translate("testing.turn-on"),
      action: "turn_on",
      count: 0,
      done: 0,
      status: "",
    },
    /*    {
      icon: "assets/img/icons/rotar-blanco.svg",
      label: this.translatorService.translate("testing.turn_off_spin_time"),
      action: "turn_off_spin_time",
      count: 0,
      done: 0,
      status: "",
    },
    {
      icon: "assets/img/icons/rotar-blanco.svg",
      label: this.translatorService.translate("testing.turn_on_spin_time"),
      action: "turn_on_spin_time",
      count: 0,
      done: 0,
      status: "",
    }, */
  ];

  @Output("submitMenu") submitMenu = new EventEmitter();
  @Output() petitionEmpty = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const validateField = (field) => {
        return Object.prototype.hasOwnProperty.call(changes, field);
      };
      if (
        validateField("reload") ||
        validateField("filters") ||
        validateField("list")
      ) {
        this.validateChangeStatus();
      }
    }
  }
  constructor(
    public translatorService: TranslatorService,
    public framesService: FramesService,
    public globalFunction: GlobalFunction
  ) {}

  ngOnInit(): void {}

  testActions(event, array, index) {
    if (array === 1) {
      this.petition(event, array, index);
    } else {
      this.petition(event, array, index);
    }
    /* VERIFICAR EL ERROR CUANDO RECIBE N RESPUESTAS  */
  }

  validateQueryTest(type) {
    const validateFeeders = (array) => {
      /* Validacion de para las alimentadoras encendiadas y apagadas */

      if (type === "turn_off" || type === "turn_on") {
        const turn_off_on = array
          .filter(({ status_power }) =>
            type === "turn_off" ? status_power : !status_power
          )
          .map((item: any) => item.id);
        return `?id_in=${turn_off_on}`;
      } else if (
        type === "turn_off_spin_time" ||
        type === "turn_on_spin_time"
      ) {
        /* Solo para mandar a los dispositivos que requieran rotar */
        const status_spin_time = array
          .filter(({ status_spin_time }) =>
            type === "turn_off_spin_time" ? status_spin_time : !status_spin_time
          )
          .map((item: any) => item.id);
        return `?id_in=${status_spin_time}`;
      } else {
        return `?id_in=${array.map((item: any) => item.id)}`;
      }
    };
    if (this.type === "feeder") {
      if (this.list.length > 0) {
        const checked = this.list.filter((item: any) => item.checked);
        if (checked.length > 0) {
          return validateFeeders(checked);
        } else {
          return validateFeeders(this.list);
        }
      }
    } else {
      if (this.list.length > 0) {
        let feeders: any[] = [];
        this.list.forEach((pond: any) => {
          feeders = [...feeders, ...pond.linked_feeders];
        });
        const checkedFeederPond = feeders.filter((item: any) => {
          return item.checked;
        });
        if (this.list.length > 0) {
          if (checkedFeederPond.length > 0) {
            return validateFeeders(checkedFeederPond);
          } else {
            if (this.filters.statusFeeders || this.filters.name) {
              return `?id_in=${feeders.map((item) => item.id)}`;
            } else if (
              type === "turn_off" ||
              type === "turn_on" ||
              type === "turn_off_spin_time" ||
              type === "turn_on_spin_time"
            ) {
              return validateFeeders(feeders);
            } else {
              return `?pond_id_in=${this.list.map((item: any) => item.id)}`;
            }
          }
        }
      }
    }
    return "";
  }
  petition(
    type:
      | "monitor"
      | "battery"
      | "panel"
      | "reset"
      | "version"
      | "signal"
      | "refeed"
      | "turn_off"
      | "turn_on"
      | "turn_off_spin_time"
      | "turn_on_spin_time",
    array,
    index
  ) {
    let feedersQuery = this.validateQueryTest(type);
    const validateCount = () => {
      if (this.type === "feeder") {
        return this.list.length;
      } else {
        let count = 0;
        this.list.forEach((pond: any) => {
          count = count + pond.linked_feeders.length;
        });
        return count;
      }
    };
    if (array === 1) {
      this.firsItems[index].status = "loading";
      this.firsItems[index].done = 0;
      this.firsItems[index].count = validateCount();
    } else {
      this.secondItems[index].status = "loading";
      this.secondItems[index].done = 0;
      this.secondItems[index].count = validateCount();
    }

    const validateError = (resp) => {
      if (
        resp &&
        (Object.prototype.hasOwnProperty.call(resp, "errors") ||
          Object.prototype.hasOwnProperty.call(resp, "error"))
      ) {
        if (array === 1) {
          this.firsItems[index].status = "error";
        } else {
          this.secondItems[index].status = "error";
        }
        return resp;
      }
      return "";
    };

    switch (type) {
      case "monitor":
        this.framesService.Frame("status", feedersQuery).subscribe((resp) => {
          this.submitMenu.emit({ type, error: validateError(resp) });
        });

        return;
      case "battery":
        this.framesService.Frame(type, feedersQuery).subscribe((resp) => {
          this.submitMenu.emit({ type, error: validateError(resp) });
        });

        return;
      case "panel":
        this.framesService.Frame(type, feedersQuery).subscribe((resp) => {
          this.submitMenu.emit({ type, error: validateError(resp) });
        });

        break;
      case "version":
        this.framesService.Frame(type, feedersQuery).subscribe((resp) => {
          this.submitMenu.emit({ type, error: validateError(resp) });
        });

        break;

      case "signal":
        this.framesService
          .Frame("at/signal_request", feedersQuery)
          .subscribe((resp) => {
            this.submitMenu.emit({ type, error: validateError(resp) });
          });

        return;

      case "reset":
        /* VALIDAR SIN EL SOCKET  */
        this.framesService.Frame(type, feedersQuery).subscribe((resp) => {
          this.submitMenu.emit({ type, error: validateError(resp) });
        });

        break;
      case "refeed":
        /* VALIDAR SIN EL SOCKET  */
        const validatePonds = () => {
          return this.list.map((item: any) => {
            if (this.type === "feeder" && item.pond) {
              const is_object = typeof item.pond === "object";
              return is_object ? item.pond.id : item.pond;
            }

            return item.id;
          });
        };

        let ponds = validatePonds();
        const deleteDuplicate = (lista) => {
          return Array.from(new Set(lista));
        };

        let list = deleteDuplicate(ponds);
        this.framesService
          .refeed({
            ponds: list,
          })
          .subscribe(
            (resp) => {
              this.submitMenu.emit({
                type,
                error: validateError(resp),
              });
            },
            (err) => {
              this.submitMenu.emit({
                type,
                error: validateError(err),
              });
            }
          );

        break;
      case "turn_on":
        this.framesService.Frame(type, feedersQuery).subscribe(
          (resp) => {
            this.submitMenu.emit({
              type,
              error: validateError(resp),
            });
          },
          (err) => {}
        );
        break;
      case "turn_off":
        this.framesService.Frame(type, feedersQuery).subscribe(
          (resp) => {
            this.submitMenu.emit({
              type,
              error: validateError(resp),
            });
          },
          (err) => {}
        );
        break;

      case "turn_off_spin_time":
        this.framesService.Frame(type, feedersQuery).subscribe(
          (resp) => {
            this.submitMenu.emit({
              type,
              error: validateError(resp),
            });
          },
          (err) => {}
        );
        break;
      case "turn_on_spin_time":
        this.framesService.Frame(type, feedersQuery).subscribe(
          (resp) => {
            this.submitMenu.emit({
              type,
              error: validateError(resp),
            });
          },
          (err) => {}
        );
        break;

      default:
        return;
    }
  }

  validateChangeStatus() {
    const first = ["monitor", "battery", "panel", "reset"];
    const second = [
      "version",
      "signal",
      "refeed",
      "turn_off",
      "turn_on",
      /*      "turn_off_spin_time",
      "turn_on_spin_time", */
    ];

    /* Verificar funcion TIene que validar cuando los estatus estan 
      loading ,done,error para mostrar el estatus en el boton */

    const validacteCheck = (check) => {
      /* Validar cuales alimentadores estan en check */
      if (this.type === "feeder") {
        return this.list.filter((item: any) => {
          return check ? item.checked : !item.checked;
        });
      } else {
        let feeders: any[] = [];
        this.list.forEach((pond: any) => {
          feeders = [...feeders, ...pond.linked_feeders];
        });
        return feeders.filter((item: any) => {
          return check ? item.checked : !item.checked;
        });
      }
    };
    const CheckFeeders = validacteCheck(true);

    /* Consideramos solo las alimentadoras seleccionadas */
    let feeders =
      CheckFeeders.length > 0 ? CheckFeeders : validacteCheck(false);

    const convertFeeders = feeders.map((feeder) => {
      return {
        loadings: feeder.loadings,
        status_power: feeder.status_power,
        status_spin_time: feeder.status_spin_time,
      };
    });

    const countAlert = (alert, status) => {
      const validateFeedersLoading = () => {
        return convertFeeders.filter(
          (item: any) => item.loadings[alert] === status
        );
      };
      return validateFeedersLoading();
    };

    const countFeeders = convertFeeders.length;

    first.forEach((alert, idx) => {
      this.firsItems[idx].count = countFeeders;

      const done = countAlert(alert, "done").length;
      const error = countAlert(alert, "error").length;
      const loading = countAlert(alert, "loading").length;

      if (done > 0 && countFeeders > 0 && done === countFeeders) {
        this.firsItems[idx].status = "success";
        this.firsItems[idx].done = countFeeders;
      } else if (error > 0 && countFeeders > 0 && error <= countFeeders) {
        /* Conteo de alertas pendientes */
        this.firsItems[idx].status = "warning";
        this.firsItems[idx].done = done;
      } else if (error > 0 && countFeeders > 0 && error === countFeeders) {
        this.firsItems[idx].status = "error";
        this.firsItems[idx].done = countFeeders - error;
      } else if (loading > 0) {
        /* Loading se mostrara cuando una alerta esta en loading  */
        this.firsItems[idx].status = "loading";
        this.firsItems[idx].done = countFeeders - loading;
      } else {
        this.firsItems[idx].status = "";
        this.firsItems[idx].done = 0;
      }
    });

    second.forEach((alert, idx) => {
      this.secondItems[idx].count = countFeeders;
      const done = countAlert(alert, "done").length;
      const error = countAlert(alert, "error").length;
      const loading = countAlert(alert, "loading").length;
      /* Contamos las alimentadoras que estan encendidas y apagadas */
      if (alert === "turn_on" || alert === "turn_off") {
        const power = convertFeeders.filter(({ status_power }) =>
          alert === "turn_on" ? !status_power : status_power
        ).length;
        this.secondItems[idx].count = power;
      } else if (
        alert === "turn_on_spin_time" ||
        alert === "turn_off_spin_time"
      ) {
        const power = convertFeeders.filter(({ status_spin_time }) =>
          alert === "turn_on_spin_time" ? !status_spin_time : status_spin_time
        ).length;
        this.secondItems[idx].count = power;
      } else {
        this.secondItems[idx].count = countFeeders;
      }

      /* Conteo de alertas pendientes */
      if (done > 0 && countFeeders > 0 && done === countFeeders) {
        this.secondItems[idx].done = Number(countFeeders);
        this.secondItems[idx].status = "success";
      } else if (error > 0 && countFeeders > 0 && error <= countFeeders) {
        this.secondItems[idx].status = "warning";
      } else if (error > 0 && error === countFeeders) {
        this.secondItems[idx].status = "error";
        this.firsItems[idx].done = Number(countFeeders);
      } else if (loading > 0) {
        /* Loading se mostrara cuando una alerta esta en loading  */
        this.secondItems[idx].status = "loading";

        if (alert === "turn_off") {
          this.secondItems[3].status = "loading";
          this.secondItems[4].status = "";
        }
        if (alert === "turn_on") {
          this.secondItems[4].status = "loading";
          this.secondItems[3].status = "";
        }
        this.secondItems[idx].done = Number(countFeeders) - Number(loading);
      } else if (done > 0 && countFeeders > 0) {
        if (alert === "turn_on" || alert === "turn_off") {
          if (alert === "turn_off") {
            this.secondItems[3].status = "";
          }
          if (alert === "turn_on") {
            this.secondItems[4].status = "";
          }
        }
        if (alert === "turn_on_spin_time" || alert === "turn_off_spin_time") {
          if (alert === "turn_off_spin_time") {
            this.secondItems[6].status = "";
          }
          if (alert === "turn_on_spin_time") {
            this.secondItems[5].status = "";
          }
        }
      } else {
        this.secondItems[idx].status = "";
        this.secondItems[idx].done = 0;
      }
    });
  }
  submitEmpty({ data, feeders, empty }) {
    /* MANDAR LOADINS AL ARRAY DE ALERTAS */
    this.petitionEmpty.emit({ type: "empty", error: data, feeders, empty });
  }
  validate_view = (type) => {
    if (
      type === "reset" ||
      type === "empty" ||
      type === "refeed" ||
      type === "turn_off" ||
      type === "turn_on"
    ) {
      let Localuser: any = localStorage.getItem("USER");
      let ObjectUser: any = Localuser ? JSON.parse(Localuser) : "";

      return (
        (ObjectUser.permissions &&
          ObjectUser.permissions &&
          ObjectUser.permissions.alerts_only) ||
        false
      );
    }
    return false;
  };
}

interface section {
  icon: string;
  label: string;
  action: string;
  count: number;
  done: number;
  status: "" | "loading" | "success" | "warning" | "error";
}
