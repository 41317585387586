import { GuardGuard } from "../guard/guard.guard";
import { PreferenceGuard } from "../guard/preferences.guard";

import { LayoutComponent } from "../layout/layout.component";

export const routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "/",
        pathMatch: "full",
      },
      /* Modulo para administradores */
      {
        path: "",
        loadChildren: () =>
          import("./Admin/admin.module").then((m) => m.AdminModule),
        canActivate: [GuardGuard, PreferenceGuard],
      },
      /* Ambos */
      {
        path: "alimentadoras",
        loadChildren: () =>
          import("./Admin/feeders-panel/alimentadora.module").then(
            (m) => m.AlimentadoraModule
          ),
        canActivate: [GuardGuard, PreferenceGuard],
        data: {
          permission: "view_feeder",
        },
      },
      /* Modulo para clientes */
      {
        path: "",
        loadChildren: () =>
          import("./Clients/client.module").then((m) => m.ClientModule),
        canActivate: [GuardGuard, PreferenceGuard],
      },
    ],
  },
  {
    path: "",
    loadChildren: () =>
      import("./Pages/pages.module").then((m) => m.PagesModule),
  },
];
