import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { gauge } from "./constants";
import { FramesService } from "../../../routes/services/frames.service";
import { GlobalFunction } from "../../../routes/services/globalFuntion";
import { Subscription } from "rxjs";
import { StorageService } from "../../../routes/services/storage.service";

@Component({
  selector: "app-speed-view-feeder",
  templateUrl: "./speed.html",
  styleUrls: ["./speed.scss"],
})
export class SpeedViewFeederComponent implements OnInit {
  @Input() speed_bar = false;
  speed = {
    status_speed: gauge,
    status_low_speed: gauge,
    status_high_speed: gauge,
  };

  @Input() feeder: null | any = null;
  enabledDisabledSpeed = false;
  view_speed_orbital = false;
  loading = {
    status_speed: "",
    get_speed: "",
  };

  socketSubsciption: Subscription;
  optionSpeed = [37.5, 50, 62.5, 75, 87.5, 100];

  constructor(
    public framesService: FramesService,
    public globalFunction: GlobalFunction,
    public storageService: StorageService
  ) {}
  ngOnDestroy(): void {
    this.socketSubsciption.unsubscribe();
  }
  ngOnInit(): void {
    this.feeder && this.validateData();

    const frames = [
      "SPEED_CONFIG",
      "ORBITAL_SPEED",
      "SPEED_RESPONSE",
      "ALERT_Communication",
      "ALERT_COMM",
    ];

    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (msg.data && msg.data.type) {
          const type = msg.data.type;
          if (this.feeder && frames.includes(type)) {
            let result = this.globalFunction.setDataFeeders([this.feeder], msg);
            const exist = result.feederChanged.find(
              (item: any) => item.id === this.feeder.id
            );
            let obj = {
              SPEED_CONFIG: "status_speed",
              ORBITAL_SPEED: "status_speed",
              SPEED_RESPONSE: "get_speed",
            };
            if (exist) {
              if (Object.prototype.hasOwnProperty.call(obj, type)) {
                if (this.loading[obj[type]] === "loading") {
                  this.loading[obj[type]] = "done";
                }
              }

              if (type === "ALERT_Communication" || type === "ALERT_COMM") {
                for (const key in obj) {
                  if (this.loading[obj[key]] === "loading") {
                    this.loading[obj[key]] = "error";
                  }
                }
              }
            }
          }
        }
      });
  }

  ngOnChanges(): void {
    this.feeder && this.validateData();
  }

  changeStatusSpeed(enabled) {
    const { id } = this.feeder;
    this.loading.status_speed = "loading";
    const speed_normal = !this.view_speed_orbital;
    /*   this.enabledDisabledSpeed = validateCheckSpeed(1) || validateCheckSpeed(2); */
    let query = `?id_in=${id}`;

    /* Peticion para activar velocidad normal */
    if (speed_normal) {
      if (enabled) {
        /* Peticion para desactivar velocidad normal */
        this.framesService.Frame("disable_speed", query).subscribe(
          (resp) => {
            if (resp && resp.hasOwnProperty("errors")) {
              this.enabledDisabledSpeed = false;
              this.validateGatewayFeeder(resp.errors);
            }
          },
          (err) => {
            this.enabledDisabledSpeed = false;
          }
        );
      } else {
        this.framesService.Frame(`enable_speed`, query).subscribe(
          (resp) => {
            this.feeder.enable_speed = true;
            this.feeder.orbital_enabled = false;
            if (resp && resp.hasOwnProperty("errors")) {
              this.enabledDisabledSpeed = true;
              this.validateGatewayFeeder(resp.errors);
            }
          },
          (err) => {
            this.enabledDisabledSpeed = true;
          }
        );
      }
    } else {
      /* Peticion para desactivar velocidad orbital */
      if (enabled) {
        this.framesService.Frame("disable_orbital", query).subscribe((resp) => {
          if (resp && resp.hasOwnProperty("errors")) {
            this.enabledDisabledSpeed = false;
            this.validateGatewayFeeder(resp.errors);
          }
        });
      } else {
        /* Peticion para activar velocidad orbital */
        const values = ["status_low_speed", "status_high_speed"];

        let obj = { status_low_speed: 0, status_high_speed: 0 };
        values.forEach((val) => {
          obj[val] = this.speed[val].series[1].data[0].value;
        });
        let val = {
          orbital_enabled: true,
          low: obj.status_low_speed,
          high: obj.status_high_speed,
        };
        this.framesService.Frame(`enable_orbital`, query, val).subscribe(
          (resp) => {
            if (resp && resp.hasOwnProperty("errors")) {
              this.enabledDisabledSpeed = false;
              this.validateGatewayFeeder(resp.errors);
            }
          },
          (err) => {
            setTimeout(() => {
              this.enabledDisabledSpeed = false;
            }, 100);
          }
        );
      }
    }
  }

  validateData = () => {
    const validateCheckSpeed = (type: 1 | 2) => {
      const { status_speed_mode } = this.feeder;
      return status_speed_mode === type;
    };
    this.view_speed_orbital = validateCheckSpeed(2);
    this.enabledDisabledSpeed = validateCheckSpeed(1) || validateCheckSpeed(2);
    if (this.view_speed_orbital) {
      const val: Array<"status_low_speed" | "status_high_speed"> = [
        "status_low_speed",
        "status_high_speed",
      ];
      val.forEach((value) => {
        this.setValueChart(value, this.feeder[value]);
      });
    } else {
      this.setValueChart("status_speed", this.feeder["status_speed"]);
    }
  };

  clickChart(e, type) {
    const x = e.event.event.offsetX;
    const totalWidth = e.event.event.target.clientWidth;
    const percentage = (x / totalWidth) * 100;
    const values = [37.5, 50, 62.5, 75, 87.5, 100];
    const range = percentage / ((1 / values.length) * 100);
    let index = Math.round(Number(range));
    if (percentage < 17) {
      index = 0;
    }
    this.setValueChart(type, values[index]);
  }
  setValueChart(
    type: "status_speed" | "status_low_speed" | "status_high_speed",
    value
  ) {
    this.speed[type] = {
      ...this.speed[type],
      series: [
        {
          ...this.speed[type].series[0],
        },
        {
          ...this.speed[type].series[1],
          data: [{ value }],
        },
      ],
    };
  }
  changeInput(type) {
    this.view_speed_orbital = type !== "status_speed";
    const validateCheckSpeed = (type: 1 | 2) => {
      const { status_speed_mode } = this.feeder;
      return status_speed_mode === type;
    };
    if (type === "status_speed") {
      this.enabledDisabledSpeed = validateCheckSpeed(1);
    } else {
      this.enabledDisabledSpeed = validateCheckSpeed(2);
    }
  }
  getSpeed() {
    if (this.feeder) {
      this.framesService
        .individualFrames(this.feeder.id, "request_speed", {})
        .subscribe((resp) => {
          this.loading.get_speed = "loading";
          if (resp && resp.hasOwnProperty("errors")) {
            this.loading.get_speed = "error";
            this.validateGatewayFeeder(resp.errors);
          }
        });
    }
  }
  sendValue() {
    const values = ["status_speed", "status_low_speed", "status_high_speed"];
    let obj = {};
   
    values.forEach((val) => {
      obj[val] = this.speed[val].series[1].data[0].value;
    });
    const { id, status_version } = this.feeder;

    let petition: "set_orbital_speed" | "set_speed" = this.view_speed_orbital
      ? "set_orbital_speed"
      : "set_speed";

    /* Version 5 hardcodeada */
    if (status_version === "NAS0.5") {
      petition = "set_orbital_speed";
      obj = {
        high: obj["status_speed"],
        low: obj["status_speed"],
        orbital_enabled: true,
      };
    } else {
      if (this.view_speed_orbital) {
        obj = {
          high:this.speed_bar ? this.feeder['status_high_speed'] : obj["status_high_speed"],
          low:this.speed_bar ? this.feeder['status_low_speed']  : obj["status_low_speed"],
          orbital_enabled: true,
        };
      } else {
        obj = {
          speed: obj["status_speed"],
        };
      }

      this.framesService
        .individualFrames(id, petition, obj)
        .subscribe((resp) => {
          if (resp && resp.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(resp.errors);
          }
        });
    }
  }
  validateGatewayFeeder(error) {
    for (const key in error) {
      if (Object.prototype.hasOwnProperty.call(error, key)) {
        const msg = error[key];
        this.globalFunction.animation("error", msg, "");
      }
    }
    this.loading.status_speed = "error";
  }
  validateInputsSpeed(
    input:
      | "status_high_speed"
      | "status_low_speed"
      | "status_speed"
      | "view_mode_speed"
      | "option_type"
  ) {
    return this.feeder[input] ? this.feeder[input] : 37.5 ;
  }
}
