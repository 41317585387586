import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { Uris } from "./Uris";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  permissions = false;
  public expandsView = false;
  public preference = { id: "", personal: "", user: "" };
  public view_modules = false;
  public localValue: localValue = {
    /* Configuraciones para el admin  */
    theme: "D",
    mode: "" /* Modo de vista 0 ---> admin 1 ---> cliente */,
    client: "" /* Almacena todo el objeto del cliente que solicito el acceso */,
    language: "spanish" /* lenguaje una vez cambiado */,
    /* Configuraciones para admin y cliente */
    farm_id: "" /* Granja seleccionada si esta en modo cliente */,
    isCollapsedText: false /* Quita los textos del sidebar */,
    current_url: "" /* Almacena la ultima url */,
    mode_table: false /* Muestra listas o tablas */,
    /* Querys almacenados como cliente solo INPUTS */
    query_client: {
      current_url: "",
      pond_id: "",
      orderingAdvance: "",
      planting_id: "",
      harvested_state: "",
      state: "",
    },
    /* Querys almacenados como admin solo INPUTS */
    query_admin: {
      current_url: "",
      client_id: "",
      farm_id: "",
      pond_id: "",
      orderingAdvance: "",
    },
  };

  public checks_parametry_preference = {
    TEM: true,
    PTEM: true,
    HUM: true,
    DO: true,
    EC: true,
    SAT: true,
    SG: true,
    SAL: true,
    TDS: true,
    PH: true,
    ORP: true,
  }

  public size: number = 0;
  public limit = 6;

  public destroy$ = new Subject<void>();

  constructor(
    public router: Router,
    public toastr: ToastrService,
    private http: HttpClient
  ) {}

  public storageObs = {
    preference: new EventEmitter<{ id: number; personal: any; user: number }>(),
    farmId: new EventEmitter<String>(),
    mode: new EventEmitter<String>(),
    socket: new EventEmitter<String>(),
    sidebar_section: new EventEmitter<any>(),
    modalNetworkConfig: new EventEmitter<String>(),
    notifications: new EventEmitter<String>(),
    permissions: new EventEmitter<Boolean>(),
    reload: new EventEmitter<Boolean>(),
  };

  cancelRequests(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.destroy$ = new Subject<void>();
  }
  watchStorage(key: keyof StorageService["storageObs"]): Observable<any> {
    return this.storageObs[key];
  }

  setItem(
    key: keyof StorageService["storageObs"],
    data:
      | { id: number; personal: any; user: number }
      | number
      | string
      | boolean
      | localValue
  ): void {
    this.storageObs[key].emit(data);
  }

  getClientID = () => {
    const { client, mode } = this.localValue;

    let localUSer = localStorage.getItem("USER");
    let user: any = localUSer ? <any>JSON.parse(localUSer) : "";

    /* si es un administrador o subcliente */
    if (!user.client && !client && !mode) {
      return "";
    }

    /* Si es un subcliente usado como admin */
    if (mode && mode === 1 && client && !client.user.client) {
      return "";
    }

    /* Si es un cliente usado como admin */
    if (mode && mode === 1 && !this.isAdmin()) {
      return client.user.client.id;
    }

    /* Si es un cliente */
    if (!mode && !this.isAdmin()) {
      return user.client.id;
    }
  };

  isAdmin() {
    const { mode } = this.localValue;
    let localUser: string | null = localStorage.getItem("USER");
    let user: any = localUser ? <any>JSON.parse(localUser) : "";
    if (user) {
      if (user.user_type === -1 || user.user_type === 0) {
        if (!mode || mode === 0) {
          return true;
        }
      }
    }
    return false;
  }

  saveQueryFilters = async (form, submit = false) => {
    const { mode } = this.localValue;
    const validateQuery = () =>
      (mode === 0 || !mode) && this.validateSoloAdmin()
        ? "query_admin"
        : "query_client";
    let query = this.localValue[validateQuery()];

    let normaljson: any = query ? query : {};

    let filters = {
      ...normaljson,
      ...form,
    };

    /* Eliminamos los que no tengan valores  */
    for (const key in filters) {
      const element = filters[key];
      if (!element || element === "") {
        delete filters[key];
      }
    }
    this.localValue[validateQuery()] = filters;
    if (submit) {
      try {
        await this.editPreferences(this.localValue)?.toPromise();
      } catch (error) {}
    }
  };

  getQueryFilters(form) {
    const { mode } = this.localValue;

    let query = {};
    if ((mode === 0 || !mode) && this.validateSoloAdmin()) {
      query = this.localValue.query_admin
        ? { ...this.localValue.query_admin }
        : {};
    } else {
      let query_client = this.localValue.query_client;
      if (query_client) {
        query_client.client_id && delete query_client.client_id;
        query_client.farm_id && delete query_client.farm_id;

        query = query_client ? { ...query_client } : {};
      }
    }

    let newObject: any = {};
    for (const key in form) {
      if (query.hasOwnProperty(key)) {
        newObject[key] = query[key];
      }
    }

    return newObject;
  }

  changeQueryPropiety(
    props: Array<
      "client_id" | "farm_id" | "pond_id" | "orderingAdvance" | "planting_id"
    >
  ) {
    const { mode } = this.localValue;
    const validateQuery = () =>
      (mode === 0 || !mode) && this.validateSoloAdmin()
        ? "query_admin"
        : "query_client";
    props.forEach((prop) => {
      this.localValue[validateQuery()][prop] = "";
    });

    return { [validateQuery()]: this.localValue[validateQuery()] };
  }

  validateSoloAdmin() {
    let localUser: string | null = localStorage.getItem("USER");
    let user: any = localUser ? <any>JSON.parse(localUser) : "";

    if ((user && user.user_type === -1) || user.user_type === 0) {
      return true;
    }
    if ((user && user.user_type === 1) || user.user_type === 2) {
      return false;
    }
    return false;
  }

  headers(destroy = true) {
    let language = this.localValue.language;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": language === "en" ? language : "es",
      Authorization: `Bearer ${this.readToken()}`,
      destroy_petition: destroy ? "true" : "false",
    });

    return { headers: headers };
  }

  headersSoloAdmin(destroy = true) {
    let language = this.localValue.language;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": language === "en" ? language : "es",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    });
    return { headers: headers };
  }

  headersMulipart() {
    let language = this.localValue.language;
    const headers = new HttpHeaders({
      "Accept-Language": language === "en" ? language : "es",
      Authorization: `Bearer ${this.readToken()}`,
    });
    return { headers: headers };
  }

  readToken(isAdmin?) {
    const { mode, client } = this.localValue;
    if (isAdmin) {
      return localStorage.getItem("token");
    }
    if (mode === 1) {
      let localClient: string | null = client;
      if (localClient) {
        let client = <any>localClient;
        return client.token;
      }
    } else {
      return localStorage.getItem("token");
    }
  }

  validateError(e) {
    let { status, error, statusText } = e;

    let arrayErrors: any = error
      ? Object.values(error.user ? error.user : error)
      : [];
    let arrayKeysErrors = error
      ? Object.keys(error.user ? error.user : error)
      : [];

    if (status === 404) {
      this.animation("error", "Error 404", `No encontrado`);
    } else if (status === 500) {
      this.animation("error", "Error 500", `Server Error (500)`);
    } else {
      for (let i = 0; i < arrayErrors.length; i++) {
        if (arrayErrors[i] === true) {
          this.animation(
            "error",
            status,
            `${arrayKeysErrors[i].toLowerCase()}: ${statusText.toString()}`
          );
        } else {
          this.animation(
            "error",
            status,
            `${arrayKeysErrors[i].toLowerCase()}: ${arrayErrors[i].toString()}`
          );
        }
      }
    }

    if (error) {
      if (error.detail === "Invalid token" || statusText === "Unauthorized") {
        this.router.navigateByUrl("/login");
      }
    }
    return throwError(e);
  }

  animation(type: "success" | "error" | "warning", text, title) {
    switch (type) {
      case "success":
        this.toastr.success(title, text, { progressBar: true });
        break;
      case "error":
        this.toastr.error(title, text, { progressBar: true });
        break;
      case "warning":
        this.toastr.warning(title, text, { progressBar: true });
        break;

      default:
        this.toastr;
        break;
    }
  }

  /*    this.setItem('preference', resp) */

  getPreferences(option?: "not_save", token?) {
    let local_token = token ? token : localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-language": "en-es",
      Authorization: `Bearer ${local_token}`,
    });
    return this.http.get(`${Uris.PREFERENCES}user/`, { headers: headers }).pipe(
      map((resp: any) => {
        if (!option) {
          this.localValue = resp.personal_web;
          this.preference = resp;
        }
        /*       this.storageObs['preference'].emit(resp) */
        return resp;
      })
    );
  }

  editPreferences(value) {
    const { id } = this.preference;

    let token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-language": "en-es",
      Authorization: `Bearer ${token}`,
    });

    if (id && this.router.url !== "/login" && token) {
      return this.http
        .patch(
          `${Uris.PREFERENCES}${id}/`,
          { personal_web: value },
          { headers: headers }
        )
        .pipe(
          map((resp: any) => {
            return resp;
          })
        );
    }
  }

  changeFont(size: "reduce" | "add" | "default", initial_size = 0) {
    const root = document.documentElement;
    const limit = this.limit;

    let val_size = initial_size == 0 ? this.size : initial_size;
    initial_size !== 0 && (this.size = initial_size);
    let default_value = {
      h1: 2.25,
      h2: 1.625,
      h3: 1.4,
      h4: 0.938,
      h5: 0.875,
      p: 0.688,
      span: 0.688,
      button: 0.688,
      small: 0.6,
      b: 1,
      td: 0.688,
      th: 0.688,
      tr: 0.688,
      "form-control": 0.875,
    };

    let results = () => {
      if (size === "default") {
        return initial_size;
      }
      if (this.size === -limit && size === "reduce") {
        return this.size;
      }
      if (this.size === limit && size === "add") {
        return this.size;
      }
      const val = size == "reduce" ? -1 : 1;
      if (size === "reduce" || size === "add") {
        return val_size + val;
      }
      return 0;
    };
    /* Sumar o restar cantidades */
    this.size = results();
    const validate_size = () => {
      if (size === "default") {
        /* debemos saber cuanto se debe de reducir o aumentar*/
        const validateacumm = (val) => {
          return val * 0.05;
        };

        /* Necesitamos saber por cuanto se multiplicara la fuente */
        const val_def = initial_size > 0 ? 1.1 : 0.95;
        if (initial_size < 0) {
          /* convertimos el valor a positivo */
          const convert = initial_size * -1;
          const acum = validateacumm(convert);
          return val_def - acum;
        }
        /* sumamos el % que deberia de estar */
        const acum_poss = validateacumm(initial_size);
        return val_def + acum_poss;
      }
      if (this.size === -limit && size === "reduce") {
        return 1;
      }
      if (this.size === limit && size === "add") {
        return 1;
      }
      return size === "reduce" ? 0.95 : 1.1;
    };
    let value = validate_size();

    Object.keys(default_value).forEach((prop) => {
      const fontSizeBase = getComputedStyle(root)
        .getPropertyValue(`--font-size-${prop}`)
        .replace("rem", "");
      const cal_value = Number(fontSizeBase) * value;
      const val_default = this.size === 0 ? default_value[prop] : cal_value;

      root.style.setProperty(`--font-size-${prop}`, `${val_default}rem`);
    });
    this.saveQueryFilters({
      font_size: this.size,
    });
  }
}

export interface actions {
  type:
    | "listen.all"
    | "gateways.add_listener"
    | "gateways.remove_listener"
    | "waterwises.add_listener"
    | "waterwises.remove_listener"
    | "whitelist.set"
    | "whitelist.add"
    | "whitelist.remove"
    | "ping";
  data?: {
    waterwises?: Array<number>;
    gateways?: Array<number>;
    farm?: number;
    addresses?: Array<string>;
  };
}
export interface localValue {
  theme: "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H";
  mode: number | "";
  client: any;
  farm_id: number | "";
  language: "spanish" | "en" | null;
  isCollapsedText: boolean;
  current_url: string;
  mode_table: boolean;
  query_client: any | "";
  query_admin: any | "";
}
