import { SettingsService } from "../../../../../../core/settings/settings.service";
import { GatewayInstallationComponent } from "../installation/installation.component";
import { ClientsService } from "../../../../../services/clients.service";
import { StorageService } from "../../../../../services/storage.service";
import { GatewayService } from "../../../../../services/gateway.service";
import { GlobalFunction } from "../../../../../services/globalFuntion";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PondService } from "../../../../../services/pond.service";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-gateway-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class GatewayFormComponent implements OnInit {
  @Input() gatewayID: string = "";
  farmID: string = "";

  gatewayForm: FormGroup;

  selectedGateway: any;
  allPonds: any = null;

  newGateway = false;
  selectedPonds: any[] = [];

  generatedKey: string;

  constructor(
    public modalInstalation: GatewayInstallationComponent,
    public storageService: StorageService,
    public globalFunction: GlobalFunction,
    public clientService: ClientsService,
    public gatewayService: GatewayService,
    public settings: SettingsService,
    public pondService: PondService,
    public route: ActivatedRoute
  ) {
    this.gatewayForm = new FormGroup({
      name: new FormControl("", Validators.required),
      is_farm: new FormControl(false),
      enabled: new FormControl(false),
      test: new FormControl(false),
      farm: new FormControl(""),
      pond: new FormControl(""),
      port: new FormControl(""),
      ip: new FormControl(""),
    });
  }

  ngOnInit() {
    const { gateway, farm } = this.settings.GatewayForm;
    this.gatewayID = gateway ? gateway : "";

    if (farm && farm["id"]) {
      this.farmID = farm["id"];
      this.getData(farm["id"]);
    }
  }

  getData = async (id) => {
    const dataPonds = await this.pondService
      .getPonds(`?farm_id=${id}&fields=id,name`)
      .toPromise();

    if (dataPonds) this.allPonds = dataPonds;

    if (this.gatewayID !== "new") {
      const gateway = await this.gatewayService
        .getGateway(this.gatewayID)
        .toPromise();

      if (gateway) {
        const { name, farm, enabled, test, ip, port } = gateway;

        this.gatewayForm.setValue({
          ...this.gatewayForm.value,
          name,
          farm,
          enabled,
          test,
          ip,
          port,
        });

        this.gatewayForm.setValue({
          ...this.gatewayForm.value,
          is_farm: farm ? true : false,
        });

        this.selectedPonds = this.allPonds.filter((item) =>
          gateway.ponds.map((item) => item.id).includes(item.id)
        );
      }
    }
  };

  getPonds(id) {
    this.pondService.getPonds(`?farm_id=${id}`).subscribe((resp) => {
      this.allPonds = resp;
    });
  }

  createGateway(value) {
    const { is_farm, enabled, test, name, ip, port } = value;
    const { lat, lng } = this.settings.GatewayForm.coordinates;

    let obj = {
      name,
      test,
      enabled,
      ip,
      port,
      installation: {
        lat,
        lng,
      },
    };

    if (!port) delete obj.port;

    const setGatewayResponse = (res: any) => {
      this.gatewayID = res.id;
      this.generatedKey = res.generated_key || this.generatedKey;
      this.settings.GatewayForm = {
        ...this.settings.GatewayForm,
        gateway: res.id,
      };
      this.storageService.setItem("modalNetworkConfig", true);
    };

    const handleGateway = (obj: any, isEdit: boolean) => {
      const serviceMethod = isEdit
        ? this.gatewayService.patchGateway
        : this.gatewayService.postGateway;

      const gateway = this.settings.GatewayForm.gateway;
      

      serviceMethod.call(this.gatewayService, obj, gateway).subscribe((res) => {
        setGatewayResponse(res);
        if (!isEdit) this.newGateway = false;
      });
    };

    obj[is_farm ? "farm" : "ponds"] = is_farm
      ? Number(this.farmID)
      : this.selectedPonds.map((item: any) => item.id);

    handleGateway(obj, this.gatewayID !== "new");
  }

  changevaluefarm() {
    const { farm } = this.settings.GatewayForm;
    if (farm && farm["id"]) {
      this.gatewayForm.controls.farm.setValue(farm["id"]);
      this.gatewayForm.controls.is_farm.setValue(true);
    }
  }

  selectPond(idx: any) {
    this.selectedPonds.push(this.allPonds[idx]);
    this.selectedPonds = this.selectedPonds.filter((item, index) => {
      return this.selectedPonds.indexOf(item) === index;
    });
  }

  validateButton = (value) => {
    const { is_farm } = value;
    const { lat, lng } = this.settings.GatewayForm.coordinates;
    const valid = this.gatewayForm.valid;

    if (!is_farm) {
      return valid && lat !== "" && lng !== "" && this.selectedPonds.length > 0;
    } else {
      return valid && lat !== "" && lng !== "";
    }
  };
}
