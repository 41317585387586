import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FeedersService } from "../../routes/services/feeder.service";
import { Subscription } from "rxjs";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { StorageService } from "../../routes/services/storage.service";
import { FramesService } from "../../routes/services/frames.service";

@Component({
  selector: "app-settings-feeders",
  templateUrl: "./settings-feeders.html",
  styleUrls: ["./settings-feeders.scss"],
})
export class SettingsFeedersComponent implements OnInit {
  @Input() feeder;
  @Input() view_title = true;
  @Output() setValueFeeder = new EventEmitter();
  configuration = {
    flag_monitoring: false,
    status_lf_block: false,
  };

  loading_lf: "done" | "loading" | "error" | "" = "";
  socketSubsciption: Subscription;
  constructor(
    public feederService: FeedersService,
    public globalFunction: GlobalFunction,
    public storageService: StorageService,
    public frameService: FramesService
  ) {}
  ngOnDestroy(): void {
    this.socketSubsciption.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.feeder) {
      this.configuration.status_lf_block =
        changes.feeder.currentValue.status_lf_block;
    }
  }
  ngOnInit(): void {
    if (this.feeder) {
      this.configuration.flag_monitoring = this.feeder.flag_monitoring;
      this.configuration.status_lf_block = this.feeder.status_lf_block;
    }

    const frames = ["LFBLOCK", "ALERT_Communication", "ALERT_COMM"];
    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (msg.data && frames.includes(msg.data.type)) {
          if (this.feeder) {
            let result = this.globalFunction.setDataFeeders([this.feeder], msg);
            const exist = result.feederChanged.find(
              (item: any) => item.id === this.feeder.id
            );
            if (exist) {
              this.loading_lf = "done";
              if (
                msg.data.type === "ALERT_Communication" ||
                msg.data.type === "ALERT_COMM"
              ) {
                this.loading_lf = "error";
              }
            }
          }
        }
      });
  }

  changeFlagMonitor(flag_monitoring) {
    this.feederService
      .EditFeeders(this.feeder.id, { flag_monitoring })
      .subscribe((resp) => {
        this.feeder = resp;
        this.setValueFeeder.emit({ ...resp, flag_monitoring });
      });
  }
  changelfblock() {
    if (this.feeder) {
      this.loading_lf = "loading";
      const { status_lf_block } = this.configuration;
      const block = status_lf_block ? "lf_block_on" : "lf_block_off";
      this.frameService
        .FramePost(block, `?id_in=${this.feeder.id}`)
        .subscribe((resp) => {
          this.loading_lf = "loading";

          if (resp && resp.hasOwnProperty("errors")) {
            for (const key in resp.errors) {
              this.globalFunction.animation("error", "", resp.errors[key]);
            }
            this.configuration.status_lf_block = !status_lf_block;
            this.loading_lf = "error";
          } else {
            this.globalFunction.animation(
              "success",
              "Recomendación de alimentacion",
              `${status_lf_block ? "Activada" : "Desactivada"} correctamente`
            );
          }
        });
    }
  }
}
