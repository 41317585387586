export const sano = {
  url: "../../../assets/img/Marcadores/saludable1.png",
  scaledSize: { height: 40, width: 30 },
};
export const alerta = {
  url: "../../../assets/img/Marcadores/alerta1.png",
  scaledSize: { height: 40, width: 30 },
};
export const apagado = {
  url: "../../../assets/img/Marcadores/apagado.png",
  scaledSize: { height: 40, width: 30 },
};
export const peligro = {
  url: "../../../assets/img/Marcadores/peligro1.png",
  scaledSize: { height: 40, width: 30 },
};
export const transparent = {
  url: "../../../assets/img/Marcadores/peligro1.png",
  scaledSize: { height: 0, width: 0, opacity: 0 },
};
export const azul = {
  url: "../../../assets/img/Marcadores/azul.png",
  scaledSize: { height: 40, width: 32 },
};

export const gateway = {
  url: "assets/img/Marcadores/antena.png",
  scaledSize: { height: 40, width: 30 },
};

export const waterwise_icons = {
  sano: {
    url: "../../../assets/img/Marcadores/waterwise.png",
    scaledSize: { height: 32, width: 32 },
  },
  apagado: {
    url: "../../../assets/img/Marcadores/waterwise_apagado.png",
    scaledSize: { height: 32, width: 30 },
  },
  alerta: {
    url: "../../../assets/img/Marcadores/waterwise_alerta.png",
    scaledSize: { height: 32, width: 30 },
  },
  peligro:{
    url: "../../../assets/img/Marcadores/waterwise_peligro.png",
    scaledSize: { height: 32, width: 30 },
  }
};

export const waterwise_tolva_icons = {
  sano: {
    url: "../../../assets/img/Marcadores/tolva.png",
    scaledSize: { height: 25, width: 28 },
  },
  apagado: {
    url: "../../../assets/img/Marcadores/tolva_apagado.png",
    scaledSize: { height: 25, width: 28 },
  },
  alerta: {
    url: "../../../assets/img/Marcadores/tolva_alerta.png",
    scaledSize: { height: 25, width: 28 },
  },
  peligro:{
    url: "../../../assets/img/Marcadores/tolva_peligro.png",
    scaledSize: { height: 25, width: 28 },
  }
};
