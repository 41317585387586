import { Component, HostBinding, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { SettingsService } from "./core/settings/settings.service";
import { AuthService } from "./routes/services/auth.service";
import { StorageService } from "./routes/services/storage.service";
import { PermissionsService } from "./routes/services/permissions.service";
import { Menu } from "./routes/menu";
import { MenuService } from "./core/menu/menu.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @HostBinding("class.layout-fixed") get isFixed() {
    return this.settings.getLayoutSetting("isFixed");
  }
  @HostBinding("class.aside-collapsed") get isCollapsed() {
    return this.settings.getLayoutSetting("isCollapsed");
  }
  @HostBinding("class.layout-boxed") get isBoxed() {
    return this.settings.getLayoutSetting("isBoxed");
  }
  @HostBinding("class.layout-fs") get useFullLayout() {
    return this.settings.getLayoutSetting("useFullLayout");
  }
  @HostBinding("class.offsidebar-open") get offsidebarOpen() {
    return this.settings.getLayoutSetting("offsidebarOpen");
  }
  @HostBinding("class.offsidebar-closed") get offsidebarClosed() {
    return !this.settings.getLayoutSetting("offsidebarOpen");
  }
  @HostBinding("class.aside-collapsed-text") get isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }
  modeSubsciption: Subscription;

  constructor(
    public settings: SettingsService,
    public route: Router,
    public AuthService: AuthService,
    public storageService: StorageService,
    public permissionsService: PermissionsService,
    public menuService: MenuService
  ) {}

  ngOnDestroy(): void {
    this.modeSubsciption.unsubscribe();
  }

  ngOnInit() {
    this.modeSubsciption = this.storageService
      .watchStorage("mode")
      .subscribe(async () => {
        await this.setMenu();
      });

    document.addEventListener("click", (e) => {
      const target = e.target as HTMLElement;
      if (target.tagName === "A") e.preventDefault();
    });
  }

  setMenu = async () => {
    let Localuser: any = localStorage.getItem("USER");
    let ObjectUser: any = Localuser ? JSON.parse(Localuser) : "";
    if (ObjectUser) {
      const { mode } = this.storageService.localValue;
      const permissions = this.permissionsService.all_permissions;

      let menu = Menu.menu(ObjectUser, mode, permissions);
      this.menuService.push(menu);
    }
  };
}
