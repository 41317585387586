import { ObjectStoreMeta } from "ngx-indexed-db";

export const name_db: string = "MyDb";
export const version_db: number = 2;
export type Table = "farms" | "ponds" | "ponds_simple" | "urls";
export const keys_farms = [
  { name: "id", keypath: "id", options: { unique: true } },
  { name: "client", keypath: "client", options: { unique: false } },
  { name: "coordinates", keypath: "coordinates", options: { unique: false } },
  { name: "device_name", keypath: "device_name", options: { unique: false } },
  { name: "gateway", keypath: "gateway", options: { unique: false } },
  { name: "hectares", keypath: "hectares", options: { unique: false } },
  { name: "name", keypath: "name", options: { unique: false } },
  { name: "off_feeders", keypath: "off_feeders", options: { unique: false } },
  { name: "on_feeders", keypath: "on_feeders", options: { unique: false } },
  { name: "subclients", keypath: "subclients", options: { unique: false } },
  {
    name: "task_test_alert",
    keypath: "task_test_alert",
    options: { unique: false },
  },
  { name: "timezone", keypath: "timezone", options: { unique: false } },
  {
    name: "use_refeeding",
    keypath: "use_refeeding",
    options: { unique: false },
  },
];

export const key_ponds = [
  { name: "id", keypath: "id", options: { unique: true } },
  { name: "coordinates", keypath: "coordinates", options: { unique: false } },
  {
    name: "linked_feeders",
    keypath: "linked_feeders",
    options: { unique: false },
  },
  { name: "planting", keypath: "planting", options: { unique: false } },
  { name: "on_feeders", keypath: "on_feeders", options: { unique: false } },
  { name: "off_feeders", keypath: "off_feeders", options: { unique: false } },
  {
    name: "feeder_alerts",
    keypath: "feeder_alerts",
    options: { unique: false },
  },
  { name: "is_done", keypath: "is_done", options: { unique: false } },
  {
    name: "status_ration",
    keypath: "status_ration",
    options: { unique: false },
  },
  { name: "last_ration", keypath: "last_ration", options: { unique: false } },
  { name: "name", keypath: "name", options: { unique: false } },
  { name: "hectares", keypath: "hectares", options: { unique: false } },
  { name: "status", keypath: "status", options: { unique: false } },
  {
    name: "collapse_feeders",
    keypath: "collapse_feeders",
    options: { unique: false },
  },
  { name: "farm", keypath: "farm", options: { unique: false } },
  {
    name: "linked_waterwises",
    keypath: "linked_waterwises",
    options: { unique: false },
  },
  {
    name: "parametric_configuration",
    keypath: "parametric_configuration",
    options: { unique: false },
  },
  {
    name: "latest_parametric_records",
    keypath: "latest_parametric_records",
    options: { unique: false },
  },
  { name: "number", keypath: "number", options: { unique: false } },
  { name: "feed_control", keypath: "feed_control", options: { unique: false } },
  { name: "gateway", keypath: "gateway", options: { unique: false } },
];

export const key_ponds_simple = [
  { name: "id", keypath: "id", options: { unique: true } },
  { name: "name", keypath: "name", options: { unique: false } },
  { name: "coordinates", keypath: "coordinates", options: { unique: false } },
  {
    name: "hydro_sensors_status",
    keypath: "hydro_sensors_status",
    options: { unique: false },
  },
  { name: "is_alerted", keypath: "is_alerted", options: { unique: false } },
  { name: "is_planted", keypath: "is_planted", options: { unique: false } },
  {
    name: "normal_sensors_status",
    keypath: "normal_sensors_status",
    options: { unique: false },
  },
  {
    name: "status_ration",
    keypath: "status_ration",
    options: { unique: false },
  },
  {
    name: "waterwise_status",
    keypath: "waterwise_status",
    options: { unique: false },
  },
];

export const keys_url = [
  { name: "id", keypath: "id", options: { unique: true } },
  { name: "url", keypath: "url", options: { unique: true } },
  { name: "seconds", keypath: "seconds", options: { unique: false } },
];

export const tables_db: ObjectStoreMeta[] = [
  {
    store: "ponds",
    storeConfig: { keyPath: "id", autoIncrement: true },
    storeSchema: key_ponds,
  },
  {
    store: "ponds_simple",
    storeConfig: { keyPath: "id", autoIncrement: true },
    storeSchema: key_ponds_simple,
  },
  {
    store: "urls",
    storeConfig: { keyPath: "id", autoIncrement: true },
    storeSchema: keys_url,
  }
];
