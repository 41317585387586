import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-image-color",
  template: `
    <i
      *ngIf="color"
      [style.width]="width"
      [style.height]="height"
      class="icon-alert c-pointer"
      [style.backgroundColor]="color"
      placement="top"
      [ngbTooltip]="value ? toltipContent : ''"
      [ngStyle]="{
        '-webkit-mask-image': 'url(../../../assets/' + url + ')'
      }"
    ></i>

    <img
      *ngIf="!color"
      [ngbTooltip]="value ? toltipContent : ''"
      [style.width]="width"
      [style.height]="height"
      class="icon-alert c-pointer"
      [src]="'../../../../../../assets/' + url"
      class="img-fluid icon-img"
    />

    <ng-template #toltipContent let-greeting="greeting">
      <div class="d-flex flex-column" *ngIf="value">
        <div class="d-flex">{{ value }}</div>
      </div>
    </ng-template>
  `,
  styleUrls: ["./image_color.scss"],
})
export class ImageColorComponent implements OnInit {
  @Input() url = "img/icons/panel.svg";
  @Input() color = "";
  @Input() height = "20px";
  @Input() width = "20px";
  @Input() value = "";

  constructor() {}

  ngOnInit(): void {}
}
