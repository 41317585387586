import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SettingsService } from "../../core/settings/settings.service";

@Component({
  selector: "app-modals",
  templateUrl: "./modals.html",
  styleUrls: ["./modals.scss"],
})
export class ModalsComponent implements OnInit {
  @ViewChild("settingsPonds") settingsPonds: ElementRef;
  @ViewChild("CreateInstalation") CreateInstalation: ElementRef;

  constructor(public Settings: SettingsService) {}

  count = 0;
  // @HostListener("document:click", ["$event"])
  // clickout(event) {
  //   if (this.Settings.settingsPonds.status) {
  //     if (!this.settingsPonds.nativeElement.contains(event.target)) {
  //       this.count++;
  //       if (this.count > 1) {
  //         this.Settings.settingsPonds = {
  //           status: false,
  //           listSelected: [],
  //         };
  //       }
  //       if (this.count >= 2) {
  //         this.count = 0;
  //       }
  //     }
  //   }
  // }
  ngOnInit(): void {
  }

  validateModal(){
    let arr = [
      this.Settings.settingsPonds.status,
      this.Settings.GatewayForm.status,
      this.Settings.TesteoGateway.status,
      this.Settings.SettingsPanel.status,
    ];
    
    return arr.some(bool => bool == true);
  }
}
