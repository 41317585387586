import { TranslatorService } from "../../core/translator/translator.service";
import { PlantingsService } from "../../routes/services/plantings.service";
import { HarvestsService } from "../../routes/services/harvests.service";
import { GatewayService } from "../../routes/services/gateway.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { FramesService } from "../../routes/services/frames.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FoodsService } from "../../routes/services/foods.service";
import { DietService } from "../../routes/services/diet.service";
import { HelpService } from "../../core/help/help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { POND } from "../plantings/interfaces";
import { EventEmitter } from "@angular/core";
import {
  SimpleChanges,
  Component,
  ViewChild,
  OnInit,
  Output,
  Input,
} from "@angular/core";

@Component({
  selector: "app-harvests",
  templateUrl: "./harvests.component.html",
  styleUrls: ["./harvests.component.scss"],
})
export class HarvestsComponent implements OnInit {
  @Input() DataPonds: Array<POND> | any;
  @Input() DataForm: any;

  @Input() harvestID: any; //En caso que se vaya a editar la precosecha

  @Output("submit") submitPetition = new EventEmitter();
  @ViewChild("test") public ModalTesting;
  @Input() ButtonClosed: Boolean = false;

  harvestForm: FormGroup = new FormGroup({
    size: new FormControl("", Validators.required),
    date: new FormControl("", Validators.required),
    kg: new FormControl(""),
  });
  plantings = [];
  @Input() harvestsStatus; // true si es cosecha, false si es precosecha

  kgPond;
  kgHec;
  textButton = "";
  constructor(
    public translatorService: TranslatorService,
    public plantingsService: PlantingsService,
    public HarvestsService: HarvestsService,
    public PostFeedsService: GatewayService,
    public GlobalFunction: GlobalFunction,
    public framesService: FramesService,
    public FoodsService: FoodsService,
    public DietService: DietService,
    public helpService: HelpService,
    private modalService: NgbModal,
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes["DataForm"]) {
      this.harvestForm.setValue(changes["DataForm"].currentValue);
    }
  }

  ngOnInit(): void {
    if (this.harvestID) {
      this.HarvestsService.getHarvest(this.harvestID).subscribe((res) => {
        this.harvestForm.patchValue({
          size: res.size,
          date: res.date,
          kg: res.kg,
        });

        this.kgPond = res.kg;
        this.kgHec = this.kgPond / res.planting.pond.hectares;
      });
    }

    this.plantingsService.getPlantings("").subscribe((resp) => {
      this.plantings = resp;
    });

    if (this.helpService.activeTutorial) {
      const content = [
        {
          element: "#kg_pond",
          title: "Kilogramos por estanque",
          intro:
            "Cantidad de kilogramos que se sacaron del estanque o los estanques.",
          position: "top",
        },
        {
          element: "#kg_hec",
          title: "Kilogramos por hectárea",
          intro: "Cantidad de kilogramos por hectárea que se sacaron.",
          position: "top",
        },
        {
          element: "#date_sow",
          title: "Fecha de cosecha",
          intro: "Cuando es que se sacó el cultivo.",
          position: "top",
        },
        {
          element: "#size_sow",
          title: "Talla en grams",
          intro: "Talla del camaron que se sacó del estanque.",
          position: "top",
        },
        {
          element: "#finish_sow",
          title: "Finalizar",
          intro: "Termina tu siembra, haciendo click en este botón.",
          position: "top",
        },
      ];

      this.helpService.runOther(content, true);
    }
  }

  closedClick() {
    this.modalService.dismissAll();
  }

  register() {
    const value = this.harvestForm.value;

    this.DataPonds.forEach((item, idx) => {
      const { planting } = item;

      if (planting.id) {
        const obj = {
          date: value.date,
          size: Number(value.size),
          kg: Number(value.kg),
        };

        const type = this.harvestsStatus ? "Cosecha" : "Precosecha";
        if (this.harvestID) {
          this.HarvestsService.editHarvest(this.harvestID, obj, type).subscribe(
            (resp) => {
              this.submitPetition.emit({ value: obj, pond: item });
            }
          );
        } else {
          const harvestObj = {
            planting: planting.id,
            date: value.date,
            size: Number(value.size),
            kg: Number(value.kg),
            is_final: this.harvestsStatus, // true si es cosecha, false si es precosecha
          };
          this.HarvestsService.PostHarvest(harvestObj).subscribe((resp) => {
            this.submitPetition.emit({ value: harvestObj, pond: item });
          });
        }
      } else {
        this.GlobalFunction.animation(
          "error",
          `El estanque ${item.name} no tiene siembra`,
          `Error`
        );
      }
    });
  }

  textPonds(value) {
    let text = "";
    if (value) {
      if (value.name) {
        text = value.name;
      } else if (value.length > 0) {
        let array = value.map((resp) => resp.name);
        text = array.toString();
      } else if (value.length === 0) {
        text = "";
      }
    }
    return text;
  }

  calculateKgHA(pond, type) {
    if (type === 2) {
      this.kgPond = this.kgHec * pond[0].hectares;
    } else {
      this.kgHec = this.kgPond / pond[0].hectares;
    }
  }

  validatePonds(kgha, POND) {
    if (POND) {
      if (POND.length > 1) {
        return this.translatorService.translate("general.undefined");
      } else {
        return POND.length === 1
          ? Number(kgha) / POND[0].hectares
          : this.translatorService.translate("general.select-pond");
      }
    }
  }
}
