import { TranslatorService } from './../../core/translator/translator.service';
import { GlobalFunction } from './../../routes/services/globalFuntion';
import { ClientsService } from './../../routes/services/clients.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-seasons',
  templateUrl: './seasons.component.html',
  styleUrls: ['./seasons.component.scss']
})
export class SeasonsComponent implements OnInit {
  @Input() clientID?: any;


  seasonName: string = '';
  seasonId: any;
  clientSeasons: any = [];

  type: string = 'admin';

  constructor(
    public translatorService: TranslatorService,
    public clientService: ClientsService,
    public globalFunction: GlobalFunction
  ) { }

  ngOnInit(): void {
    let mode: any = localStorage.getItem("mode")

    let user = <any>JSON.parse(localStorage.getItem("USER") || "{}");
    if (user.user_type == -1 || user.user_type == 0) {
      if (mode == 1) {
        this.type = 'client';
        let client = <any>JSON.parse(localStorage.getItem("CLIENT") || "{}");
        this.clientID = client.user.client.id;
      }
    } else {
      this.type = 'client';
    }

    this.getSeasons();
  }

  getSeasons() {
    let query = '?ordering=id';
    if (this.type == 'admin') {
      query = `?client_id=${this.clientID}&ordering=id`;
    }
    this.clientService.getSeasons(query).subscribe(res => {
      this.clientSeasons = res;

    });
  }

  changeSeasonStatus(season) {
    const enable = season.enable;
    this.clientService.patchSeason({ enable }, season.id).subscribe(res => {
      season = res;
    });
  }

  createSeason() {
    let obj = {
      name: this.seasonName,
      client: this.clientID,
      enable: true
    };

    if (this.type == 'admin') {
      obj.client = this.clientID;
    }

    if (!this.seasonId) {
      this.clientService.postSeason(obj).subscribe(res => {

        this.getSeasons();
        this.seasonName = '';
      });
    } else {
      this.clientService.patchSeason(obj, this.seasonId).subscribe(res => {

        this.getSeasons();
        this.seasonName = '';
        this.seasonId = null;
      });
    }
  }

  editSeason(season: any) {
    if (this.seasonId == season.id) {
      this.seasonName = '';
      this.seasonId = null;
    } else {
      this.seasonName = season.name;
      this.seasonId = season.id;
    }
  }

  async deleteSeason(season) {
    let bool = await this.globalFunction.optionSwalAlert(
      `¿${this.translatorService.translate("general.delete-confirm")} ${season.name}?`
    );
    if (bool) {
      this.clientService.deleteSeason(season.id).subscribe(res => {
        this.getSeasons();

      });
    }
  }

}
