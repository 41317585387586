import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FramesService } from "../../routes/services/frames.service";
import { Router } from "@angular/router";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { Subscription } from "rxjs";
import { StorageService } from "../../routes/services/storage.service";

@Component({
  selector: "app-content-view-device",
  templateUrl: "./content.html",
  styleUrls: ["./content.scss"],
})
export class ContentViewDeviceComponent implements OnInit {
  @Input() device;
  options_selects = ["Tolva", "Sensores", "Velocidad"];
  @Input() option_selected: "Tolva" | "Sensores" | "Velocidad" = "Sensores";
  @Input() speed_bar = false;
  @Output() change_option = new EventEmitter();

  enabledDisabledSensed = false;
  sensors_hydro = false;
  animated = "";
  loading_sensors: "done" | "loading" | "error" | "" = "";
  socketSubsciption: Subscription;

  constructor(
    public framesService: FramesService,
    public Router: Router,
    public globalFunction: GlobalFunction,
    public storageService: StorageService
  ) {}
  ngOnDestroy(): void {
    this.socketSubsciption.unsubscribe();
  }
  ngOnInit(): void {
    this.setValueSensors(this.device);
    const frames = [
      "SENSOR",
      "LFBLOCK",
      "SENSORHYDROSTATE",
      "ALERT_Communication",
      "ALERT_COMM",
    ];

    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (msg.data && frames.includes(msg.data.type)) {
          const setValue = () => {
            if (this.device) {
              let result = this.globalFunction.setDataFeeders(
                [this.device],
                msg
              );
              const exist = result.feederChanged.find(
                (item: any) => item.id === this.device.id
              );
              if (exist) {
                this.loading_sensors = "done";
                if (
                  msg.data.type === "ALERT_Communication" ||
                  msg.data.type === "ALERT_COMM"
                ) {
                  this.loading_sensors = "error";
                }
              }
            }
          };
          if (
            msg.data.type === "SENSOR" &&
            Object.prototype.hasOwnProperty.call(msg.data, "sensor_enabled")
          ) {
            setValue();
          }
          if (msg.data.type !== "SENSOR") {
            setValue();
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["option_selected"]) {
      this.animated = "animate__fadeIn";
      this.setValueSensors(this.device);

      setTimeout(() => {
        this.animated = "";
      }, 2000);
    }
    if (changes && changes["feeder"] && changes["feeder"].currentValue) {
      const current = changes["feeder"].currentValue;
      this.setValueSensors(current);
    }
  }

  setValueSensors(current) {
    this.options_selects = this.options_selects.filter((item) => {
      if (item === "Tolva") {
        return current.virtual_weight;
      }
      return true;
    });
    if (this.sensors_hydro) {
      if (current.sensor_hydro) {
        this.enabledDisabledSensed = current.sensor_hydro.power;
      }
    } else {
      if (current.sensors) {
        this.enabledDisabledSensed = current.sensors.sensors_enabled;
      }
    }

    this.device = { ...this.device };
  }
  changeOption(val) {
    this.change_option.emit(val);
  }

  changeEnabledDisabled() {
    let feedersQuery = `?id_in=${this.device.id}`;

    const validate_error = (resp) => {
      if (resp && resp.hasOwnProperty("errors")) {
        this.validateGatewayFeeder(resp.errors);
      }
    };
    if (this.sensors_hydro) {
      const type = this.enabledDisabledSensed
        ? "sensor_hydro_set_power_off"
        : "sensor_hydro_set_power_on";

      this.framesService.Frame(type, feedersQuery).subscribe((resp) => {
        this.loading_sensors = "loading";
        validate_error(resp);
      });
    } else {
      const enabled = this.enabledDisabledSensed;
      this.framesService
        .FramePost("sensors_enabled", feedersQuery, {
          sensors_enabled: !enabled,
        })
        .subscribe((resp) => {
          this.loading_sensors = "loading";
          validate_error(resp);
        });
    }
  }
  validateGatewayFeeder(error) {
    for (const key in error) {
      if (Object.prototype.hasOwnProperty.call(error, key)) {
        const msg = error[key];
        this.globalFunction.animation("error", msg, "");
      }
    }
    this.loading_sensors = "error";
  }
  goSensors(feeder) {
    this.Router.navigateByUrl(`/device/waterwise/${feeder.id}/?view=sensors_graph`);
  }
  change_mode(event) {
    this.sensors_hydro = !event.mode;
    if (this.sensors_hydro) {
      if (this.device.sensor_hydro) {
        this.enabledDisabledSensed = this.device.sensor_hydro.power;
      }
    } else {
      if (this.device.sensors) {
        this.enabledDisabledSensed = this.device.sensors.sensors_enabled;
      }
    }
  }
  getTypeSensors(event) {}
}
