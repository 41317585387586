import { Component, OnInit } from "@angular/core";
import { SettingsService } from "../../core/settings/settings.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";

@Component({
  selector: "app-settings-panel",
  templateUrl: "./settings-panel.html",
  styleUrls: ["./settings-panel.scss"],
})
export class SettingsPanelComponent implements OnInit {
  deleted = [
    "Lista_de_alimentadores",
    "Boton_power",
    "Racion_en_kg",
    "Rac_alerta",
    "Com_alerta",
    "Reinicios_alerta",
    "Battery_alerta",
    "Dispenser_alerta",
    "Panel_alerta",
    "Sprinkle_alerta",
    "Seccion_superior",
    "Vincular_estanque",
    "Desvincular_estanque",
    "Power",
    "Alertas",
    "Kg_max",
    "Temp/Hum",
    "Kg/Alimentador",
    "Velocidad",
  ]
  sections = {
    farm: [
      /*      {
             class: "col-6",
             title: "",
             items: [],
             columns: [
               {
                 class: "col-12",
                 title: "Estanque parte superior",
                 items: [
                   { label: "Seccion_superior", value: true },
                   { label: "Vincular_estanque", value: true },
                   { label: "Desvincular_estanque", value: true },
                   { label: "Power", value: true },
                   { label: "Alertas", value: true },
                   { label: "Kg_max", value: true },
                   { label: "Temp/Hum", value: true },
                   { label: "Kg/Alimentador", value: true },
                   { label: "Velocidad", value: true },
                 ],
               },
               {
                 class: "col-12 mt-2",
                 title: "Lista de alimentadores",
                 items: [
                   { label: "Lista_de_alimentadores", value: true },
                   { label: "Boton_power", value: true },
                   { label: "Racion_en_kg", value: true },
                   { label: "Rac_alerta", value: true },
                   { label: "Com_alerta", value: true },
                   { label: "Reinicios_alerta", value: true },
                   { label: "Battery_alerta", value: true },
                   { label: "Dispenser_alerta", value: true },
                   { label: "Panel_alerta", value: true },
                   { label: "Sprinkle_alerta", value: true },
                 ],
               },
             ],
           }, */
      {
        class: "col-6",
        items: [],
        columns: [
          {
            class: "col-12",
            title: "Mapa",
            items: [
              { label: "Mapa", value: true },
              { label: "Cambio_de_modo", value: true },
              { label: "Expandir", value: true },
              { label: "Poligono_de_estanque", value: true },
              { label: "Alimentadoras", value: true },
              { label: "Poligono_de_granja", value: true },
            ],
          },

        ],
      },
      {
        class: "col-6",
        items: [],
        columns: [
          {
            class: "col-12 ",
            title: "Parametrías",
            items: [
              { label: "Componente_de_alimentación", value: true },
              { label: "Siembra", value: true },
              { label: "Oxi", value: true },
              { label: "Temp", value: true },
              { label: "Sal", value: true },
            ],
          },

        ],

      }
    ],
  };
  constructor(
    public settings: SettingsService,
    public globalFunction: GlobalFunction
  ) { }

  ngOnInit(): void {
    let name = "settings-panel";
    let local: any = localStorage.getItem(name);
    let objectLocal = JSON.parse(local)
    if (local && local.length > 0) {

      if (local[0].label) {
        localStorage.setItem("settings-panel",
          JSON.stringify(local.filter(item => !item.value).map(item => item.label)));
      }

      this.deleted.forEach(deleted => {
        let index = objectLocal.findIndex(item => item === deleted)
        if (index >= 0) objectLocal.splice(index, 1), localStorage.setItem("settings-panel", JSON.stringify(objectLocal));
      });
    }
    objectLocal && this.setValue(objectLocal ? objectLocal : []);

  }
  setValue(arryobj: Array<string>) {
    if (arryobj.length > 0) {
      this.sections[this.settings.SettingsPanel.type].forEach(
        (element, idxSection) => {
          if (element.items) {
            element.items.forEach((item, idxItems) => {
              const condition = (obj) => obj === item.label;
              if (arryobj.some(condition)) {
                this.sections[this.settings.SettingsPanel.type][idxSection].items[
                  idxItems
                ] = { label: arryobj.find(condition), value: false };
              }
            });
          }
          if (element.columns) {
            element.columns.forEach((column, idxColumn) => {
              if (column.items) {
                column.items.forEach((item, idxItem) => {
                  const condition = (obj) => obj === item.label;
                  if (arryobj.some(condition)) {
                    (this.sections[this.settings.SettingsPanel.type][
                      idxSection
                    ].columns[idxColumn].items[idxItem] =
                      { label: arryobj.find(condition), value: false });
                  }
                });
              }
            });
          }
        }
      );
    }
  }




  changeIndividualValue(type: 'column', idxSectionPather, idxColumnSection, idxColumn) {
    let name = "settings-panel";

    let local = localStorage.getItem(name);
    let value = local ? JSON.parse(local) : ''
    let object = this.sections[this.settings.SettingsPanel.type][idxSectionPather].columns[idxColumnSection].items[idxColumn]
    if (value) {
      let arrayNew = []

      if (object.value) {
        arrayNew = value.filter(item => item !== object.label)
        localStorage.setItem("settings-panel", JSON.stringify(arrayNew));
      }
      if (!object.value) {
        localStorage.setItem("settings-panel", JSON.stringify([...value, object.label]));
      }
    }

    if (!value) {
      localStorage.setItem("settings-panel", JSON.stringify([object.label]));
    }

  }
  reemplaceText(text) {
    const reg = /_/g;
    return text.replace(reg, " ");
  }
}
