import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Uris } from "./Uris";
import { map, catchError } from "rxjs/operators";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class FeedersService {
  constructor(
    private http: HttpClient,
    public router: Router,
    public storageService: StorageService
  ) {}

  ListFeedersQuery(query) {
    return this.http
      .get(`${Uris.FEEDER}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  ListAlertsQuery(query) {
    return this.http
      .get(`${Uris.ALERTS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  LinkFeeder(query, body) {
    return this.http
      .post(`${Uris.PONDS}${query}`, body, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadora asignada correctamente`
          );
          return resp;
        })
      );
  }
  FeederDragUpadate(body) {
    return this.http
      .post(`${Uris.PONDS}`, body, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  UnLinkFeeder(query) {
    return this.http
      .delete(`${Uris.PONDS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadora desvinculada correctamente`
          );
          return resp;
        })
      );
  }
  GetFeederID(id, query = "") {
    return this.http
      .get(`${Uris.FEEDER}${id}/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  PostMultipleLinkPonds(value) {
    return this.http
      .post(
        `${Uris.FEEDER}multiple_link_pond/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadora asignada correctamente`
          );
          return resp;
        })
      );
  }

  PostMultipleLinkClient(value) {
    return this.http
      .post(
        `${Uris.FEEDER}multiple_link_client/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadora asignada correctamente`
          );
          return resp;
        })
      );
  }
  PostFeeders(value) {
    return this.http
      .post(`${Uris.FEEDER}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadora ${resp.name} creada correctamente`
          );
          return resp;
        })
      );
  }

  uploadFeederCoordinates(file) {
    return this.http
      .post(`${Uris.FEEDER}drag_update/`, file, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  importFeederFile(file) {
    return this.http
      .post(`${Uris.FEEDER}import/`, file, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  exportFeeder(query) {
    return this.http
      .get(`${Uris.FEEDER}export/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  EditFeeders(id, value) {
    return this.http
      .patch(`${Uris.FEEDER}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadora ${resp.name} editada correctamente`
          );
          return resp;
        })
      );
  }
  EditMasiveFeeders(query, value) {
    return this.http
      .put(
        `${Uris.FEEDER}multiple_update/${query}`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadora ${resp.name} editada correctamente`
          );
          return resp;
        })
      );
  }
  DeleteFeederID(id) {
    return this.http
      .delete(`${Uris.FEEDER}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadora eliminada correctamente`
          );
          return resp;
        })
      );
  }

  /* FEEDERS BRANDS */
  ListFeedersbrand(query) {
    return this.http
      .get(`${Uris.FEEDER_BRAND}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  GetFeederbrandID(id) {
    return this.http
      .get(`${Uris.FEEDER_BRAND}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  PostFeedersbrand(value) {
    return this.http
      .post(`${Uris.FEEDER_BRAND}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Marca de alimentadora ${resp.name} creada correctamente`
          );
          return resp;
        })
      );
  }
  EditFeedersbrand(id, value) {
    return this.http
      .patch(`${Uris.FEEDER_BRAND}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Marca de alimentadora ${resp.name} editada correctamente`
          );
          return resp;
        })
      );
  }

  FeederBrandAddPicture(id, value) {
    return this.http
      .post(
        `${Uris.FEEDER_BRAND}${id}/add_picture/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Se agregó la imágen correctamente`
          );
          return resp;
        })
      );
  }
  FeederBrandListGallery(id) {
    return this.http
      .get(`${Uris.FEEDER_BRAND}${id}/gallery/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  DeleteFeederBrandPicture(id, image) {
    return this.http
      .delete(
        `${Uris.FEEDER_BRAND}${id}/remove_picture/${image}/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Se eliminó la imágen correctamente`
          );
          return resp;
        })
      );
  }
  DeleteFeederbrandID(id) {
    return this.http
      .delete(`${Uris.FEEDER_BRAND}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Marca de alimentadora eliminada correctamente`
          );
          return resp;
        })
      );
  }
  /* DISPENSERS */
  ListDispenser(query) {
    return this.http
      .get(`${Uris.DISPENSERS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  GeDispenserID(id) {
    return this.http
      .get(`${Uris.DISPENSERS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  PostDispenser(value) {
    return this.http
      .post(`${Uris.DISPENSERS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Dosificador ${resp.name} creado correctamente`
          );
          return resp;
        })
      );
  }
  EditDispenser(id, value) {
    return this.http
      .patch(`${Uris.DISPENSERS}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Dosificador ${resp.name} editado correctamente`
          );
          return resp;
        })
      );
  }
  deleteDispenserID(id) {
    return this.http
      .delete(`${Uris.DISPENSERS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Dosificador eliminado correctamente`
          );
          return resp;
        })
      );
  }

  /* POND */
  ListPond(query) {
    return this.http
      .get(`${Uris.PONDS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getPondID(id, query = '') {
    return this.http
      .get(`${Uris.PONDS}${id}/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  PostPond(value) {
    return this.http
      .post(`${Uris.PONDS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Estanque ${resp.name} creado correctamente`
          );
          return resp;
        })
      );
  }
  EditPond(id, value) {
    return this.http
      .patch(`${Uris.PONDS}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Estanque ${resp.name} editado correctamente`
          );
          return resp;
        })
      );
  }
  DeletPondID(id) {
    return this.http
      .delete(`${Uris.PONDS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Estanque ${resp.name} eliminado correctamente`
          );
          return resp;
        })
      );
  }

  GetFeederStatusGraph(id, type: "battery" | "panel", query) {
    return this.http
      .get(
        `${Uris.FEEDER}${id}/graph_${type}/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  GetStatusGraph(type: "battery" | "panel" | "status", query) {
    return this.http
      .get(
        `${Uris.FEEDER}graph_${type}/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
