import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { VirtualWeightComponent } from "../virtual_weight/virtual_weight";
import { ComparationWeightSensorComponent } from "../virtual_weight/comparation_weight_sensor/comparation_weight_sensor"
@NgModule({
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule],
  providers: [
    VirtualWeightComponent,
    ComparationWeightSensorComponent
  ],
  declarations: [
    VirtualWeightComponent,
    ComparationWeightSensorComponent
  ],
  exports: [
    VirtualWeightComponent,
    ComparationWeightSensorComponent
  ],
})
export class VirtualWeightModule {}