import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router,
  ) {}

  saveToken = (body) => {
    return this.http
      .post(Uris.FIREBASE, body, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };

  firebaseTopicSuscribeAll = (body) => {
    return this.http
      .post(
        `${Uris.FIREBASE}topic/subscribe_all/`,
        body,
        this.storageService.headers(false)
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };

  deleteByToken = (token: string) => {
    return this.http
      .delete(
        `${Uris.FIREBASE}by_token/${token}/`,
        this.storageService.headers(false)
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };

  getTokentFirebase = (query) => {
    return this.http
      .get(`${Uris.FIREBASE}${query}`, this.storageService.headers(false))
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };

  sendNotification = (body) => {
    return this.http
      .post(
        `${Uris.FIREBASE}send_notification/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };

  getNotifications = (query) => {
    return this.http
      .get(
        `${Uris.FIREBASENOTIFICATION}${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };

  refreshToken = (id, body) => {
    return this.http
      .post(
        `${Uris.FIREBASE}${id}/refresh_token/`,
        body,
        this.storageService.headers(false)
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };

  deleteNotification = (id) => {
    return this.http
      .delete(
        `${Uris.FIREBASENOTIFICATION}${id}/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };

  patchNotification = (id, body) => {
    return this.http
      .patch(
        `${Uris.FIREBASENOTIFICATION}${id}/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  };
}
