import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(
    public storageService: StorageService,
    public toastr: ToastrService,
    private http: HttpClient,
    public router: Router
  ) {}

  getAlerts(query) {
    return this.http
      .get(
        `${Uris.ALERTS}${query}${query ? "" : "/"}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getSprinklerAlerts(query) {
    return this.http
      .get(
        `${Uris.ALERTS}sprinkler_alerts/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getWaterwiseAlerts(query) {
    return this.http
      .get(
        `${Uris.WATERWISE}alerts/${query}${query ? "" : "/"}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
