export const gauge = {
  animation: false,
  series: [
    {
      type: "gauge",
      startAngle: 180,
      endAngle: 0,
      min: 37.5,
      max: 100,
      splitNumber: 5,
      itemStyle: {
        color: "#234a50",
        shadowColor: "rgba(0,138,255,0.45)",
        shadowBlur: 10,
        shadowOffsetX: 2,
        shadowOffsetY: 2,
      },
      progress: {
        show: true,
        roundCap: true,
        width: 18,
      },
      pointer: {
        show: false,
      },
      axisLine: {
        roundCap: false,
        lineStyle: {
          width: -2,
        },
      },
      axisTick: {
        show: false,
        splitNumber: 5,
        lineStyle: {
          width: 2,
          color: "#999",
        },
      },
      axisLabel: {
        distance: -52,
        color: "#999",
        fontSize: 15,
      },
      detail: {
        show: false,
      },
      data: [
        {
          value: 100,
        },
      ],
    },
    {
      type: "gauge",
      startAngle: 180,
      endAngle: 0,
      min: 37.5,
      max: 100,
      splitNumber: 5,
      itemStyle: {
        color: "#234a50",
        shadowBlur: 15,
      },
      progress: {
        show: true,
        roundCap: true,
        width: 18,
      },
      pointer: {
        icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
        length: "75%",
        width: 16,
        offsetCenter: [0, "5%"],
      },
      axisLine: {
        roundCap: false,
        lineStyle: {
          width: -2,
        },
      },
      axisTick: {
        show: false,
        splitNumber: 2,
        lineStyle: {
          width: 2,
          color: "#999",
        },
      },
      axisLabel: {
        show: false,
        distance: -55,
        color: "#999",
        fontSize: 15,
      },
      detail: {
        show: false,
      },
      data: [
        {
          value: 50,
        },
      ],
    },
  ],
};
