import { Component, Input, OnInit } from "@angular/core";
import { TranslatorService } from "../../../../core/translator/translator.service";
import { FramesService } from "../../../../routes/services/frames.service";

@Component({
  selector: "app-view-alert",
  templateUrl: "./view_alert.html",
  styleUrls: ["./view_alert.scss"],
})
export class ViewAlertComponent implements OnInit {
  @Input() feeder: any = null;
  @Input() mode_test: any = true;

  sections = ["server", "feeder_status"];
  constructor(
    public translatorService: TranslatorService,
    public frameService: FramesService
  ) {}

  ngOnInit(): void {}
  classBorder = (feeder, prop) => {
    let obj = {
      done: "border-success",
      error: "border-danger",
      loading: "border-light",
      "": "",
    };
    if (feeder.status_refeed.server === "error") {
      return obj["error"];
    }
    const value = feeder.status_refeed[prop];
    return obj[value];
  };
  classChecks = (feeder, prop) => {
    let obj = {
      done: "text-success",
      error: "text-danger",
      loading: "",
      "": "",
    };
    if (feeder.status_refeed.server === "error") {
      return obj["error"];
    }
    const value = feeder.status_refeed[prop];
    return obj[value];
  };
  classIcon = (feeder, prop) => {
    let obj = {
      done: "fa-check-circle",
      error: "fa-times-circle",
      "": "",
    };
    if (feeder.status_refeed.server === "error") {
      return obj["error"];
    }
    const value = feeder.status_refeed[prop];
    return obj[value];
  };
  label = (section) => {
    const translate = (label) => {
      return this.translatorService.translate(label);
    };
    let obj = {
      server: translate("testing.server-response"),
      feeder_status: translate("testing.feeders-response"),
    };
    return obj[section];
  };

  frameMonitor(feeder) {
    this.frameService
      .Frame("status", `?id_in=${feeder.id}`)
      .subscribe((item) => {
      });
  }
}
