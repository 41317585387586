import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class WaterwiseService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  getWaterwises(query) {
    return this.http
      .get(`${Uris.WATERWISE}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getWaterwise(id, query = "") {
    return this.http
      .get(`${Uris.WATERWISE}${id}/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postWaterwise(value) {
    return this.http
      .post(`${Uris.WATERWISE}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Waterwise creado correctamente`
          );
          return resp;
        })
      );
  }

  patchWaterwise(id, value) {
    return this.http
      .patch(`${Uris.WATERWISE}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `${resp.name} editado correctamente`
          );
          return resp;
        })
      );
  }
  EditMasiveWaterwises(value,query) {
    return this.http
      .put(`${Uris.WATERWISE}multiple_update/${query}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Waterwises editados correctamente`
          );
          return resp;
        })
      );
  }

  deleteWaterwise(id) {
    return this.http
      .delete(`${Uris.WATERWISE}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Dispositivo eliminado correctamente`
          );
          return resp;
        })
      );
  }

  sendCommandToWaterwise(id, value) {
    return this.http
      .post(
        `${Uris.WATERWISE}${id}/send_command/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getWaterwiseLog(query) {
    return this.http
      .get(`${Uris.WATERWISE_LOG}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  /* WIP: THIS FUNCTION IS FOR SENDING THE FRAME */
  setPointsSensorsAquaLogger(id, body) {
    return this.http
      .post(
        `${Uris.GATEWAYFRAMESWATERWISE}${id}/set_points/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  /* PROMEDIO DE SENSORES */
  getWaterwiseHydroSensorsHistoricalByType(query) {
    return this.http
      .get(
        `${Uris.WATERWISE}hydro_sensors_history/sensor-graph/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getWaterwiseSensorsHistorical(id, query) {
    return this.http
      .get(
        `${Uris.WATERWISE}hydro_sensors_history/${id}/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

 
 

  getWaterwiseHydroSensorsHistoricalGraph(query) {
    return this.http
      .get(
        `${Uris.WATERWISE}hydro_sensors_history/graph/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getWaterwiseSensorsHistoricalGraph(query) {
    return this.http
      .get(
        `${Uris.WATERWISE}sensors_history/graph/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  multipleUpdateWaterwise(query, body) {
    return this.http
      .put(
        `${Uris.WATERWISE}multiple_update/${query}`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getWaterwiseAlerts(query) {
    return this.http
      .get(
        `${Uris.WATERWISE}sensors/alert/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

}
