import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ImageColorComponent } from "./image_color";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
@NgModule({
  imports: [CommonModule,NgbModule],
  providers: [ImageColorComponent],
  declarations: [ImageColorComponent],
  exports: [ImageColorComponent],
  schemas: [],
})
export class ImageColorModule {}
