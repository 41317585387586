import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loader-settings",
  templateUrl: "./loader.component.html",
  styleUrls: ["../settings-modal.component.scss"],
})
export class LoaderComponentSettings implements OnInit {
  @Input() type:
    | "ANALOG_READING"
    | "CURRENT"
    | "FEED_RATE"
    | "SENSORS_ENABLED"
    | "SET_POINTS"
    | "SENSOR_FREQUENCIES_RESPONSE"
    | "CHANGE_ALERT_DISPENSER";
  @Input() feeder;

  constructor() {}

  ngOnInit(): void {
  }

  validateView(feeder: any, label) {
    return feeder.loadings[label];
  }
  validateText(
    type:
      | "ANALOG_READING"
      | "CURRENT"
      | "FEED_RATE"
      | "SENSORS_ENABLED"
      | "SET_POINTS"
      | "SENSOR_FREQUENCIES_RESPONSE"
      | "CHANGE_ALERT_DISPENSER"
  ) {
    const texts = {
      ANALOG_READING: "informacion de cambio de puerto",
      CURRENT: "corriente",
      FEED_RATE: "Tasa de alimentación",
      SENSORS_ENABLED: "Estatus de sensor",
      SET_POINTS: "set points",
      SENSOR_FREQUENCIES_RESPONSE: "frecuencia de sensores",
      CHANGE_ALERT_DISPENSER: "Alerta de dosificador",
    };

    return texts[type] ? texts[type] : "";
  }
}
