import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class DietService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  getDiets(query) {
    return this.http
      .get(`${Uris.DIET}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getDiet(id) {
    return this.http
      .get(`${Uris.DIET}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postDiet(value) {
    return this.http
      .post(`${Uris.DIET}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Dieta registrada correctamente`
          );
          return resp;
        })
      );
  }
  
  patchDiet(id, value) {
    return this.http
      .patch(`${Uris.DIET}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Dieta editada correctamente`
          );
          return resp;
        })
      );
  }

  deleteDiet(id) {
    return this.http
      .delete(`${Uris.DIET}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Dieta eliminada correctamente`
          );
          return resp;
        })
      );
  }
}
