import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class FarmService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router,
  ) {}

  getFarmSummary(id) {
    return this.http
      .get(`${Uris.FARM}${id}/summary/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getPondSummary(id) {
    return this.http
      .get(`${Uris.PONDS}${id}/summary/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  kml2farms(value) {
    return this.http
      .post(`${Uris.FARM}kml2farms/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Coordenadas correctamente insertadas`
          );
          return resp;
        })
      );
  }

  postClientFarm(value) {
    return this.http
      .post(`${Uris.FARM}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Granja ${resp.name} creada correctamente`
          );
          return resp;
        })
      );
  }

  patchClientFarm(value, id) {
    return this.http
      .patch(`${Uris.FARM}${Number(id)}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Granja ${resp.name} editada correctamente`
          );
          return resp;
        })
      );
  }

  getFarms(query) {
    return this.http
      .get(`${Uris.FARM}${query}`, this.storageService.headers(false))
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getFarm(id, query = "") {
    return this.http
      .get(`${Uris.FARM}${id}/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteFarm(id) {
    return this.http
      .delete(`${Uris.FARM}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Granja eliminada correctamente`
          );
          return resp;
        })
      );
  }

  linkFarm(id) {
    return this.http
      .get(`${Uris.FARM}client/${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  unLinkFarm(id) {
    return this.http
      .post(`${Uris.FARM}unlink/${id}/`, {}, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Granja desvinculada de app correctamente`
          );
          return resp;
        })
      );
  }

  automaticTestPreference(farm, body) {
    return this.http
      .post(
        `${Uris.FARM}${farm}/tasks/status_test_alert/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  scheduleTestAlert(farm, body) {
    return this.http
      .post(
        `${Uris.FARM}${farm}/tasks/schedule_test_alert/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  tasksScheduleTestAlert(id, body) {
    return this.http
      .post(
        `${Uris.FARM}${id}/tasks/schedule_test_alert/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Testeo configurado correctamente`
          );
          return resp;
        })
      );
  }

  tasksStatusTestAlert(id, body) {
    return this.http
      .post(
        `${Uris.FARM}${id}/tasks/status_test_alert/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Testeo configurado correctamente`
          );
          return resp;
        })
      );
  }

  uploadPondFile(farmID, file) {
    return this.http
      .post(
        `${Uris.FARM}${farmID}/import_ponds/`,
        file,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimentadores importados exitosamente`
          );
          return resp;
        })
      );
  }

  importRation(id, file) {
    return this.http
      .post(
        `${Uris.FARM}${id}/import_rations/`,
        file,
        this.storageService.headersMulipart()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Raciones importadas correctamente`
          );
          return resp;
        })
      );
  }

  downloadRation(query) {
    return this.http
      .get(`${Uris.PONDS}export-ration/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Se ha descargado correctamente la ración`
          );
          return resp;
        })
      );
  }
}
