export const permissions_mod: permissions[] = [
  {
    label: "Alertas",
    codename: "alert",
    codenames: [],
    check: false,
  },
  {
    label: "Ciclos",
    codename: "plantings",
    codenames: [],
    check: false,
  },
  {
    label: "Análisis de alimentación",
    codename: "data_analysis",
    codenames: [],
    check: false,
  },
  {
    label: "Biometrías",
    codename: "biometric",
    codenames: [],
    check: false,
  },
  {
    label: "Granja",
    codename: "farms",
    codenames: [],
    check: false,
  },
  {
    label: "Dashboard",
    codename: "dashboard",
    codenames: [],
    check: false,
  },
  {
    label: "Sensores",
    codename: "sensors_module",
    codenames: [],
    check: false,
  },
  {
    label: "Parametrías",
    codename: "parametric",
    codenames: [],
    check: false,
  },
  {
    label: "Charolas",
    codename: "tray",
    codenames: [],
    check: false,
  },
  {
    label: "Dietas",
    codename: "diets",
    codenames: [],
    check: false,
  },
  {
    label: "Alimentadoras",
    codename: "feeder",
    codenames: [],
    check: false,
  },
  {
    label: "Temporadas",
    codename: "season",
    codenames: [],
    check: false,
  },
  {
    label: "Estanques",
    codename: "ponds",
    codenames: [],
    check: false,
  },

  /*   {
      label: "permisos no considerados",
      codename: "other",
      codenames: [],
      check: false,
    }, */
  {
    label: "waterwise",
    codename: "aqualogger",
    codenames: [],
    check: false,
  },
  {
    label: "Notificaciones",
    codename: "notifications",
    codenames: [],
    check: false,
  },
  {
    label: "Organigrama",
    codename: "organization_chart",
    codenames: [],
    check: false,
  },
  {
    label: "Clima",
    codename: "weather",
    codenames: [],
    check: false,
  },
];
export const permissions_gen: permissions[] = [
  {
    label: "Testeos",
    codename: "view_tests",
    codenames: [],
    check: false,
  },
  {
    label: "Alimentación",
    codename: "view_feed",
    codenames: [],
    check: false,
  },
  {
    label: "Generar informes",
    codename: "view_reports_generation",
    codenames: [],
    check: false,
  },
  {
    label: "Bascula digital",
    codename: "view_virtual_weight",
    codenames: [],
    check: false,
  },
  /* {
    label: "Bascula física",
    codename: "view_physical_weight",
    codenames: [],
    check: false,
  },
  {
    label: "Editar feed rate",
    codename: "change_feed_rate",
    codenames: [],
    check: false,
  }, */
  {
    label: "Velocidad",
    codename: "speed_module",
    codenames: [],
    check: false,
  },
  {
    label: "Cosecha/precosecha",
    codename: "view_harvest",
    codenames: [],
    check: false,
  },
  {
    label: "Sembrar",
    codename: "add_plantings",
    codenames: [],
    check: false,
  },
  {
    label: "Barrido de testeos",
    codename: "sweep_testing",
    codenames: [],
    check: false,
  },
  {
    label: "Nas 2024",
    codename: "diffuse_logic",
    codenames: [],
    check: false,
  },
];

/* Vamos a declarar los permisos que tendra cada cliente */
export const permissions_client: permissions[] = [
  {
    label: "Alimentadores en mapa",
    codename: "view_feeder_in_map",
    codenames: [],
    check: false,
    include_subclient: false,
  },
  {
    label: "Reedistribucion",
    codename: "view_refeed",
    codenames: [],
    check: false,
    include_subclient: false,
  },
  {
    label: "Test automaticos",
    codename: "view_automatic_test",
    codenames: [],
    check: false,
    include_subclient: false,
  },
  {
    label: "Sensores",
    codename: "sensors_module",
    codenames: [],
    check: false,
    include_subclient: true,
  },
  {
    label: "Bascula digital",
    codename: "view_virtual_weight",
    codenames: [],
    check: false,
    include_subclient: true,
  },
  {
    label: "Velocidad",
    codename: "speed_module",
    codenames: [],
    check: false,
    include_subclient: true,
  },
  {
    label: "Barrido de testeos",
    codename: "sweep_testing",
    codenames: [],
    check: false,
    include_subclient: true,
  },
  {
    label: "Nas 2024",
    codename: "diffuse_logic",
    codenames: [],
    check: false,
    include_subclient: true,
  },
  {
    label: "waterwise",
    codename: "view_waterwise",
    codenames: [],
    check: false,
    include_subclient: true,
  },
  /* {
    label: "Bascula física",
    codename: "view_physical_weight",
    codenames: [],
    check: false,
  }, */
  /*   {
    label: "Eliminacion de grupo",
    codename: "speed",
    codenames: [],
    check: false,
  },
  {
    label: "Editar feed rate",
    codename: "change_feed_rate",
    codenames: [],
    check: false,
  }, */
];

export interface permissions {
  label: string;
  codename: string;
  codenames: Array<any>;
  check: boolean;
  include_subclient?: boolean;
}
