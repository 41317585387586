import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class FoodsService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  getFoods(query = "") {
    return this.http
      .get(`${Uris.FOODS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getFood(id) {
    return this.http
      .get(`${Uris.FOODS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postFood(value) {
    return this.http
      .post(`${Uris.FOODS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimento ${resp.name} creado correctamente`
          );
          return resp;
        })
      );
  }

  patchFood(id, value) {
    return this.http
      .patch(`${Uris.FOODS}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimento ${resp.name} editado correctamente`
          );
          return resp;
        })
      );
  }

  deleteFood(id) {
    return this.http
      .delete(`${Uris.FOODS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Alimento eliminado correctamente`
          );
          return resp;
        })
      );
  }

  getFoodLinkedClients(id) {
    return this.http
      .get(`${Uris.FOODS}${id}/link_clients/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getFoodLinkedFarm(id) {
    return this.http
      .get(`${Uris.FOODS}${id}/link_farms/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  LinkFoodToFarm(id, value) {
    return this.http
      .post(
        `${Uris.FOODS}${id}/link_farms/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  unassignFoodFromFarm(id, farm) {
    return this.http
      .delete(
        `${Uris.FOODS}${id}/link_farms/${farm}/`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  /* FoodBrands */
  getFoodBrands(query) {
    return this.http
      .get(`${Uris.FOODS_BRANDS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getFoodBrand(id) {
    return this.http
      .get(`${Uris.FOODS_BRANDS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postFoodBrand(value) {
    return this.http
      .post(`${Uris.FOODS_BRANDS}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Marca de alimento ${resp.name} agregado exitosamente`
          );
          return resp;
        })
      );
  }

  patchFoodBrand(id, value) {
    return this.http
      .patch(`${Uris.FOODS_BRANDS}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Marca de alimento ${resp.name} editado correctamente`
          );
          return resp;
        })
      );
  }

  deleteFoodBrand(id) {
    return this.http
      .delete(`${Uris.FOODS_BRANDS}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Marca de alimento eliminado correctamente`
          );
          return resp;
        })
      );
  }
}
