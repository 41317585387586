import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { Edge, Node, Layout, DagreNodesOnlyLayout } from "@swimlane/ngx-graph";
import * as shape from "d3-shape";
import { Subject } from "rxjs";

/* https://swimlane.github.io/ngx-graph/#quick-start */

@Component({
  selector: "app-ngx-graph",
  templateUrl: "./ngx-graph.html",
  styleUrls: ["./ngx-graph.scss"],
})
export class NgxGraphComponent implements OnInit {
  public layoutSettings = {
    orientation: "TB",
  };
  public curve: any = shape.curveLinear;
  public layout: Layout | null = null;
  public nodes: Node[] = [];
  public links: Edge[] = [];

  @Input() groups: any = [];
  @Input() user: any = null;
  @Input() count_subclient;
  autoCenter: boolean = true;
  center$: Subject<boolean> = new Subject();
  update$: Subject<boolean> = new Subject();
  /*   employees: any[] = [
    {
      id: "1",
      name: "Employee 1",
    },
    {
      id: "2",
      name: "Employee 2",
      upperManagerId: "1",
    },
    {
      id: "3",
      name: "Employee 3",
      upperManagerId: "1",
    },
    {
      id: "4",
      name: "Employee 4",
      upperManagerId: "1",
    },
  ]; */

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groups) this.generate();
  }
  public ngOnInit(): void {}

  generate = async () => {
    this.layout = new DagreNodesOnlyLayout();

    let groups = [...this.groups].map((item, idx) => {
      let obj = {
        id: (idx + 2).toString(),
        name: item.name,
        data: { ...item, farms: item.farms.map((item) => item.name) },
      };
      return idx === 0 ? obj : { ...obj, upperManagerId: "2" };
    });

    groups.unshift({
      id: "1",
      name: this.user.username,
      data: { ...this.user, groups_length: this.groups.length },
    });

    this.nodes = groups.map((item) => {
      return {
        id: item.id,
        label: item.name,
        data: item.data,
      };
    });

    let link: any = [...groups].splice(1);
    this.links = link.map((employee) => {
      return {
        source: "1",
        target: employee.id,
      };
    });

    await this.update$.next(true);
    await this.center$.next(true);
  };

  validateHeight(data) {
    if (data.users.length > 0) {
      return 150 * data.users.length;
    } else {
      return 100;
    }
  }
  clickClient(body, client) {}

  validateNameFarms = (data) => {
    return data.farms.map((item) => item.name);
  };
}
