import { NgModule } from "@angular/core";
import { Type1ComponentComponent } from "./loading_time/type-1/type-1";
/* https://bootsoon.github.io/ng-circle-progress/ */
import { NgCircleProgressModule } from "ng-circle-progress";
import { CommonModule } from "@angular/common";
import { Type2ComponentComponent } from "./loading_time/type-2/type-2";
import { LoadingStatusComponent } from "./loading_status/loading_status";
import { SpinnerComponent } from "./spinner/spinner.component";
import { TranslateModule } from "@ngx-translate/core";
@NgModule({
  imports: [
    CommonModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      animationDuration: 300,
    }),
    TranslateModule
  ],
  providers: [
    Type1ComponentComponent,
    Type2ComponentComponent,
    LoadingStatusComponent,
    SpinnerComponent,
  ],
  declarations: [
    Type1ComponentComponent,
    Type2ComponentComponent,
    LoadingStatusComponent,
    SpinnerComponent,
  ],
  exports: [
    Type1ComponentComponent,
    Type2ComponentComponent,
    LoadingStatusComponent,
    SpinnerComponent,
  ],
  schemas: [],
})
export class LoadingModule {}
