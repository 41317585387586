import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EchartComponent } from "./echar";
import { NgxEchartsModule } from "ngx-echarts";
@NgModule({
  imports: [CommonModule,
    NgxEchartsModule.forRoot({
    echarts: () => import("echarts"),
  }),],
  providers: [EchartComponent],
  declarations: [EchartComponent],
  exports: [EchartComponent],
  schemas: [],
})
export class EchartModule {}
