import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { menu } from "./const";
import moment from "moment";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-frames-device-dinamic",
  templateUrl: "./frames_device_dinamic.html",
  styleUrls: ["./frames_device_dinamic.scss"],
})
export class FramesDeviceDinamicComponent implements OnInit {
  @Output("closed") closed = new EventEmitter();
  @ViewChild("frameModal") public modal;

  deviceMenu = menu;

  @Input() devices: any = [
    /*   {
      id: 22,
      mac: "0000000000000001",
      last_login: "2024-11-22T10:54:20.544039-07:00",
      name: "W0001",
      device_type: 0,
      created_at: "2024-11-22T10:54:20.529833-07:00",
      updated_at: "2024-11-22T10:54:20.531393-07:00",
      lat: 25.313213324835505,
      lng: -108.38705351651451,
      battery: 13.62,
      panel: 18.73,
      signal_strength: 0,
      power: true,
      fuzzy_logic: false,
      nas_version: "_",
      device_check: true,
      device_status: {
        battery: {
          status:'',
          received: ''
        },
      },
    }, */
  ];

  temporal_devices = [];

  /* para saber cuando esta inicializado el componente y poder validar */
  initialValue = false;
  modalRef: null | any = null;
  close = false;

  filters: FormGroup;

  constructor(
    public globalFunction: GlobalFunction,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    config.size = "xl";
    config.centered = true;
    config.animation = false;

    this.filters = new FormGroup({
      name: new FormControl(""),
      status: new FormControl(""),
      pond: new FormControl(""),
    });
  }

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const myDiv: any = document.querySelector(`[id='frame-modal']`);
    const validateClassSwall = () => {
      const views = [
        "swal2-header",
        "swal2-container",
        "swal2-cancel",
        "swal2-popup",
        "swal2-title",
        "swal2-html-container",
        "swal2-icon-content",
        "swal2-icon",
        "swal2-confirm",
        "swal2-actions",
      ];
      const some = views.some((item) => targetElement.classList.contains(item));
      return some;
    };

    if (
      this.initialValue &&
      targetElement.id !== "menu-alert-ponds" &&
      targetElement.id !== "check-feeder" &&
      this.modalRef !== null &&
      !validateClassSwall() &&
      myDiv &&
      !myDiv.contains(targetElement) &&
      !this.close
    ) {
      this.closeModal();
      event.stopPropagation();
    }
  }
  ngAfterViewInit(): void {
    this.openModal();
  }

  ngOnInit(): void {
    let input = ["name", "pond", "status"];

    input.forEach((element) => {
      this.filters.controls[element].valueChanges
        .pipe(debounceTime(700))
        .subscribe((search) => {
          this.filterItems();
        });
    });

    let device_status = {};
    this.deviceMenu.forEach((item) => {
      device_status[item.name] = {
        status: "",
        received: "",
        failReason: "",
      };
    });
    this.devices = this.devices.map((item) => {
      return {
        ...item,
        device_check: true,
        device_status,
      };
    });

    this.temporal_devices = this.devices.map((item) => item);
  }

  closeModal = async () => {
    this.close = true;

    if (!this.globalFunction.validateSoloAdmin()) {
      let bool = await this.globalFunction.optionSwalAlert(
        "¿Seguro que quieres cerrar el panel de alerta?"
      );
      if (bool && this.close) {
        this.modalService.dismissAll();
        this.modalRef = null;
        this.closed.emit(false);
      } else {
        setTimeout(() => {
          this.close = false;
        }, 300);
      }
    } else {
      this.modalService.dismissAll();
      this.modalRef = null;

      this.closed.emit(false);
    }
  };
  setStatusDevice(index, name, status, received, reason = "") {
    this.devices.splice(index, 1, {
      ...this.devices[index],
      device_status: {
        ...this.devices[index].device_status,
        [name]: {
          ...this.devices[index].device_status[name],
          status,
          received,
          failReason: reason,
        },
      },
    });
    this.filterItems();
  }
  clickButtonMenu(menu, idxMenu) {
    const checks = this.devices.filter((item) => item.device_check);
    const hour = moment().format("YYYY/MM/DD HH:ss:mm");
    this.deviceMenu[idxMenu].sent = hour;
    const devices = menu.validateDevices([...this.devices]);
    devices.forEach((device) => {
      const index = this.devices.findIndex((item) => item.name === device.name);
      if (index >= 0) {
        this.setStatusDevice(index, menu.name, "loading", "");
        setTimeout(() => {
          this.setStatusDevice(index, menu.name, "done", hour);
        }, 2000);
        setTimeout(() => {
          this.setStatusDevice(
            index,
            menu.name,
            "error",
            hour,
            "Mensaje dinamico con el error"
          );
        }, 8000);
      }
    });
  }

  openModal = async () => {
    const ref = await this.modalService.open(this.modal);
    await ref.shown.subscribe(() => {
      this.modalRef = ref;
      setTimeout(() => {
        this.initialValue = true;
      }, 100);
    });
  };
  validateAlertColor = (menu, device) => {
    if (menu.validateStatus(menu, this.devices).type === 0) {
      return "d-none";
    }
    if (device.device_status[menu.name].status === "loading") {
      return "color-alert-loading";
    }
    if (device.device_status[menu.name].status === "done") {
      return "color-alert-success";
    }
    if (device.device_status[menu.name].status === "error") {
      return "color-alert-danger";
    }
    return "";
  };

  filterItems = () => {
    const { name, status, pond } = this.filters.value;
    const array = this.devices;

    this.temporal_devices = array.filter((item) => {
      if (!name && !status && !pond) {
        return true;
      }

      // Verifica si el ID coincide exactamente
      if (pond && Number(item.id) !== Number(pond)) {
        return false;
      }

      // Verifica si el nombre contiene la subcadena buscada (coincidencia parcial)
      if (name && !item.name.includes(name)) {
        return false;
      }

      // Verifica si alguno de los estados dentro de device_status coincide con el status buscado
      const deviceStatus = item.device_status;
      if (deviceStatus) {
        for (let key in deviceStatus) {
          if (deviceStatus[key].status === status) {
            return true; // Si encuentra un estado que coincide, retorna true
          }
        }
      }
      // Si no se encontró ningún estado que coincida, retorna false
      return false;
    });
  };
}
