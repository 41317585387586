import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

import { Router } from "@angular/router";
import { GlobalFunction } from "../../../../routes/services/globalFuntion";
import { FramesService } from "../../../../routes/services/frames.service";
import { StorageService } from "../../../../routes/services/storage.service";
import { sensors } from "../../../../routes/Clients/device/constants"
@Component({
  selector: "app-sensor-hydro",
  templateUrl: "./hydro.html",
  styleUrls: ["./hydro.scss"],
})
export class SensorHydroComponent implements OnInit {
  @Input() data;
  @Input() options = true;
  props = sensors.filter(item => item.is_hydro)

  availablehydro = false;
  constructor(
    public globalFunction: GlobalFunction,
    public frameService: FramesService,
    public Router: Router,
    public storageService:StorageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["data"]) {
      const feeder = changes["data"].currentValue;
      if (feeder.sensor_hydro) {
        this.availablehydro = feeder.sensor_hydro.power;
      }
    }
  }
  ngOnInit(): void {}

  goSensors() {
    this.Router.navigateByUrl(`/granja/sensores/${1}/${this.data.id}`);
  }

  SubmitInput() {
    /* Cada hora solo mandar peticion */
    /* Cada 3 hours : [ integer 0 al 24 ] */
    /* Cada 5 hours : [ integer 0 al 24 ] */
  }

  changeType(type) {
    this.frameService.section_sensor_hydro = type;
  }
}
