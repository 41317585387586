export const FarmDashboard = {
  icon: "",
  text: "sidebar.resumen",
  link: "/dashboard",
  img: "home.svg",
  available: true,
  permission: "view_dashboard",
};

export const Farms = {
  icon: "",
  text: "sidebar.farm",
  link: "/granja",
  img: "farm.svg",
  available: true,
  permission: "view_farms",
};

export const Feeders = {
  icon: "",
  text: "sidebar.feeders",
  link: "/alimentadoras",
  img: "feeders_2.svg",
  available: true,
  permission: "view_feeder",
};

export const Plantings = {
  icon: "",
  text: "sidebar.sow",
  link: "/siembras",
  img: "sows.svg",
  available: true,
  permission: "view_plantings",
};

export const Diets = {
  icon: "",
  text: "sidebar.diets",
  link: "/dietas",
  img: "diets.svg",
  available: true,
  permission: "view_diets",
};

export const Alerts = {
  icon: "",
  text: "sidebar.alerts",
  link: "/alerts",
  img: "alerts.svg",
  available: true,
  permission: "view_alert",
};

export const FeedingAnalisis = {
  icon: "",
  text: "sidebar.analisis",
  link: "/feeding-analisis",
  img: "food.svg",
  available: true,
  permission: "view_data_analysis",
};

export const Weather = {
  icon: "",
  text: "sidebar.climate",
  link: "/weather",
  img: "weather.svg",
  permission: "view_weather",
  available: true,
};

export const SubClients = {
  icon: "fa fa-users",
  text: "sidebar.subclients",
  link: "/subclients",
  img: "",
  available: true,
  permission: "view_subclients",
};

export const Biometries = {
  icon: "",
  text: "sidebar.biometry",
  link: "/biometrias",
  img: "metrics.svg",
  available: true,
  permission: "view_biometric",
};

export const ClientNotifications = {
  icon: "fas fa-bell",
  text: "sidebar.notifications",
  link: "client-notifications",
  img: "",
  available: true,
  permission: "view_notifications",
};

export const Tasks = {
  icon: "fa fa-sticky-note",
  text: "sidebar.tasks",
  link: "/tasks",
  img: "",
  available: true,
};

export const Waterwises = {
  icon: "",
  img: "waterwise.svg",
  text: "sidebar.waterwise",
  link: "/waterwise",
  available: true,
  permission: "view_waterwise",
};


export const UsersPermissions = {
  icon: "fas fa-lock-open",
  text: "Permisos",
  link: "/permissions",
  permission: "view_organization_chart",
  available: true,
};

export const Ranking = {
  icon: "fas fa-medal",
  text: "Ranking",
  link: "/ranking",
  available: true,
};

export const waterwise = {
  icon: "",
  img: "waterwise.svg",
  text: "sidebar.waterwise",
  link: "/waterwise",
  available: true,
  permission: "view_waterwise",
};

export const Devices = {
  text: "sidebar.devices",
  icon: "",
  img: "feeders.svg",
  submenu: [Feeders, waterwise],
};

export const Comparison = {
  icon: "",
  img: "comparison.svg",
  text: "Comparación",
  link: "/comparacion-estanques",
  available: true,
  permission: "view_waterwise",
}