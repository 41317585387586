import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class VersioningService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router
  ) {}

  getLastVersion() {
    let query = "?page_size=1&ordering=-id";

    return this.http
      .get(`${Uris.VERSIONING}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getVersions(query: any) {
    return this.http
      .get(
        `${Uris.VERSIONING}${query}&ordering=-id`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postVersion(object: any) {
    return this.http
      .post(`${Uris.VERSIONING}`, object, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Versión creada correctamente`
          );
          return resp;
        })
      );
  }

  deleteVersion(id: number) {
    return this.http
      .delete(`${Uris.VERSIONING}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Versión eliminada correctamente`
          );
          return resp;
        })
      );
  }

  patchVersion(id: number, object: any) {
    return this.http
      .patch(`${Uris.VERSIONING}${id}/`, object, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Versión editada correctamente`
          );
          return resp;
        })
      );
  }
}
