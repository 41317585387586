import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-config-sensors-device",
  templateUrl: "./config_sensor_device.html",
  styleUrls: ["./config_sensor_device.scss"],
})
export class ConfigSensorDeviceComponent implements OnInit {
  config_mode: "min-max" | "automatic" | "fuzzy-logic" = "min-max";
  @Input() type: "feeder" | "waterwise" | "farm" | "pond" = "feeder";

  @Input() id: number | "" = "";
  @Input() massiveUpdate = false;
  @Input() selectedItems = [];
  @Input() region_id: null | number = null;

  constructor() {}
  ngOnInit(): void {}
}
