import { AlertsAdminComponent } from "../../routes/Admin/alerts/alerts.component";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StorageService } from "../../routes/services/storage.service";
import { FramesService } from "../../routes/services/frames.service";
import { GlobalFunction } from "../../routes/services/globalFuntion";
import { Subscription } from "rxjs";


@Component({
  selector: "app-settings-modal",
  templateUrl: "./settings-modal.component.html",
  styleUrls: ["./settings-modal.component.scss"],
})
export class SettingModalComponent implements OnInit {
  @Input() selectedFeeders: any = [];
  @Input() feeders: any = [];
  @Input() ponds: any = [];

  @Input() type: "feeder" | "ponds";
  @Output() updateList = new EventEmitter<any>();
  pondSelected;
  TemporalFeeder: any[] = [];
  inputPondSelect: any[] = [];
  typeCurrent: "individual" | "masive_ponds" | "masive_feeders";

  /* Declarar las variables que vayamos a enviar y recibir */

  test: SettingInterface = {
    ANALOG_READING: [],
    CURRENT: [],
    FEED_RATE: [],
    SENSORS_ENABLED: [],
    SET_POINTS: [],
    CONFIRM_POINTS: [],
    REQUEST_SENSORS_ALL_ITEMS: [],
    SENSOR_FREQUENCIES_RESPONSE: [],
    CHANGE_SETTING_FRAME: [],
    CHANGE_ALERT_DISPENSER: [],
  };
  showView: SettingInterface = {
    ANALOG_READING: false,
    CURRENT: false,
    FEED_RATE: false,
    SENSORS_ENABLED: false,
    SET_POINTS: false,
    CONFIRM_POINTS: false,
    REQUEST_SENSORS_ALL_ITEMS: false,
    SENSOR_FREQUENCIES_RESPONSE: false,
    CHANGE_SETTING_FRAME: false,
    CHANGE_ALERT_DISPENSER: false,
  };

  loading: SettingInterface = {
    ANALOG_READING: "initial",
    CURRENT: "initial",
    FEED_RATE: "initial",
    SENSORS_ENABLED: "initial",
    SET_POINTS: "initial",
    CONFIRM_POINTS: "initial",
    REQUEST_SENSORS_ALL_ITEMS: "initial",
    SENSOR_FREQUENCIES_RESPONSE: "initial",
    CHANGE_SETTING_FRAME: "initial",
    CHANGE_ALERT_DISPENSER: "initial",
  };

  /* Frame de cambio de puerto */
  configurations: any = [
    {
      getButtonText: "Obtener puerto y a/Dosificador",
      read_alert: "analog_reading_alert",
      type: "ANALOG_READING",
      label: "Cambio de puerto A. aspersor",
      ports: [0, 1],
      value: "",
      titleButtons: "Alerta de apertura de apertura de dosificador",
      BooleanAlertReading: false,
      buttons: [
        {
          type: "DISPENSER_ALERT",
          value: "ON",
          class: "btn-success",
        },
        {
          type: "DISPENSER_ALERT",
          value: "OFF",
          class: "btn-danger",
        },
      ],
    },
    {
      type: "CURRENT",
      valueSprinkler: 0,
      getButtonText: "Obtener corriente",
      labelSprinkler: "Cambio de corriente aspersor",
      valueDispenser: 0,
      labelDispenser: "Cambio de corriente dosificador",
      typeSprinkler: "SUBMIT_CURRENT_SPTRINKLER",
      typeDispenser: "SUBMIT_CURRENT_DISPENSER",
    },
    /* No es frame pero es configuracion */
    {
      type: "FEED_RATE",
      label: "Tasa de alimentación",
      value: 0 /* 0 a 255 */,
    },
    {
      type: "SENSORS",
      label: "",
    },
    {
      type: "CHANGE_SETTING_FRAME",
      label: "",
      options: [
        {
          label: "Sensibilidad de sensor alerta de dosificador",
          url: "dispenser_sensor",
          data: "",
        },
        {
          label: "Sensibilidad de sensor alerta de aspersor",
          url: "sprinkler_sensor",
          data: "",
        },
      ],
    },
  ];

  nameInput = "";
  filterStatusFeeders = "";
  filterPondSeleected = "";

  sectionAnnalog = true;
  sectionCurrent = true;
  sectionFeedRate = true;
  sectionSensors = true;
  sectionAlertDispenser = true;

  sectionChangeConfigSensor = true;
  frames = [
    "CURRENT",
    "SENSORS",
    "SETPOINTSENSORSOK",
    "SETPOINTS",
    "ALERT_Communication",
    "ALERT_COMM",
    "SETPOINTPOWER",
    "ANALOG_READING_RESPONSE",
    "ANALOG_READING",
    "CHANGE_ALERT_DISPENSER",
    "DISPENSERALERTENABLED",
    "SENSORSENSIBILITY",
    "SENSOR_FREQUENCIES_RESPONSE",
  ];
  alert_dosificator = false;
  constructor(
    public alertsComponent: AlertsAdminComponent,
    public globalFunction: GlobalFunction,
    public framesService: FramesService,
    public storageService: StorageService
  ) {}

  socketSubsciption: Subscription;

  ngOnDestroy(): void {
    this.socketSubsciption.unsubscribe();
    this.resetButtons();
    this.selectedFeeders = [];
    this.TemporalFeeder = [];
    this.pondSelected = null;
  }

  ngAfterContentInit(): void {
    if (this.type === "feeder") {
      if (this.selectedFeeders.length === 1) {
        const feeder = this.selectedFeeders[0];
        let {
          status_port,
          status_dispenser_current,
          status_sprinkler_current,
          status_sprinkler_sensor,
          status_dispenser_sensor,
        } = feeder;

        let status_dispenser_alert = feeder.status_dispenser_alert;
        if (status_port >= 0) {
          this.configurations[0].value = status_port;
        }
        this.configurations[0].BooleanAlertReading = status_dispenser_alert;
        if (status_sprinkler_current) {
          this.configurations[1].valueSprinkler =
            status_sprinkler_current.toString();
        }
        if (status_dispenser_current) {
          this.configurations[1].valueDispenser =
            status_dispenser_current.toString();
        }

        if (status_dispenser_sensor) {
          this.configurations[4].options[0].data = status_dispenser_sensor;
        }
        if (status_sprinkler_sensor) {
          this.configurations[4].options[1].data = status_sprinkler_sensor;
        }
      }
    }

    const returnLoadings = (item, pond) => {
      return {
        ...item,
        pondId: pond,
        condition: true,
        loadings: {
          ANALOG_READING: "",
          CURRENT: "",
          FEED_RATE: "",
          SENSORS_ENABLED: "",
          SET_POINTS: "",
          CONFIRM_POINTS: "",
          SENSOR_FREQUENCIES_RESPONSE: "",
          CHANGE_SETTING_FRAME: "",
          CHANGE_ALERT_DISPENSER: "",
        },
      };
    };

    if (this.type === "ponds" && this.ponds && this.ponds.length > 0) {
      this.pondSelected = this.ponds[0];
      this.currentSection = `Section${this.pondSelected.id}`;
      const ponds = [...this.ponds];
      this.TemporalFeeder = [...ponds];
      this.inputPondSelect = [...ponds];
      this.TemporalFeeder.forEach((pond, idx) => {
        const returnData = pond.linked_feeders.map((item) =>
          returnLoadings(item, pond.id)
        );
        this.TemporalFeeder[idx].linked_feeders = [...returnData];
      });
    }

    if (
      this.type === "feeder" &&
      this.selectedFeeders &&
      this.selectedFeeders.length > 0
    ) {
      this.selectedFeeders = this.selectedFeeders.map(returnLoadings);
      this.TemporalFeeder = this.selectedFeeders.map(returnLoadings);
    }

    this.resetButtons();
  }

  ngOnInit(): void {
    this.typeCurrent = this.validateType();
    /* Validamos los datos si hay un solo alimentador  */

    this.socketSubsciption = this.storageService
      .watchStorage("socket")
      .subscribe((msg: any) => {
        if (msg.data && msg.data.type) {
          let { type } = msg.data;
          if (type) {
            const exist = this.frames.some((item) => item === type);
            if (exist) {
              this.getData(msg);
            }
          }
        }
      });
  }

  getData = (msg) => {
    let result: any = {};
    if (this.type === "feeder" && this.selectedFeeders.length > 0) {
      /* setDataFeeders: cambia los valores de todas las alimentadoras seleccionadas dependiendo el tipo de frame  */
      result = this.globalFunction.setDataFeeders(
        this.selectedFeeders,
        msg,
        this.showView
      );
      this.selectedFeeders = result.feeders;

      /* Vamos a detectar el indice de las alimentadoras que se cambiaron */
      let i = this.detectIndexFeeder(
        this.selectedFeeders,
        msg,
        "feeder",
        result.feederChanged
      );

      if (i >= 0) {
        this.selectedFeeders[i] = this.validateFeederLoadingStatus(
          this.selectedFeeders[i],
          msg,
          msg.data.type !== "ALERT_Communication" ? "done" : "socketError"
        );

        this.validateFeederTemporal(i);
      }
    }
    if (this.type === "ponds" && this.ponds.length > 0) {
      result = this.globalFunction.setDataPonds(
        [...this.TemporalFeeder],
        msg,
        this.showView,
        this.test
      );

      this.TemporalFeeder.forEach(async (pond, idx) => {
        let i = await this.detectIndexFeeder(pond, msg, "pond");

        this.TemporalFeeder[idx].linked_feeders =
          await this.globalFunction.setDataFeeders(
            pond.linked_feeders,
            msg,
            this.showView
          ).feeders;

        if (i >= 0) {
          this.TemporalFeeder[idx].linked_feeders[i] =
            this.validateFeederLoadingStatus(
              this.TemporalFeeder[idx].linked_feeders[i],
              msg,
              msg.data.type !== "ALERT_Communication" ? "done" : "socketError"
            );

          await this.validateFeederTemporal(idx, i);
        }
      });
    }

    if (msg.data.type !== "ALERT_Communication") {
      if (result.test) {
        let { index, test } = this.validateDuplicateAlert(result.test);
        if (index !== -1) {
          result.test.forEach((element) => {
            if (index >= 0) {
              this.test[element.type][index] = test.value;
            }
          });
        } else {
          result.test.forEach((test) => {
            let ownPropiety = this.test.hasOwnProperty(test.type);
            if (ownPropiety) {
              this.test[test.type].push(test.value);
            }
          });
        }
      }
    }
  };
  validateFeederTemporal(idxItem?, feederid?) {
    /* Validacion para pasar los datos nuevos al temporal y no se pierda los loadings */

    if (this.type === "ponds") {
      let changeFeeder = this.ponds[idxItem].linked_feeders[feederid];
      if (changeFeeder) {
        this.TemporalFeeder.forEach((allpond, idx) => {
          const idxfeeder = allpond.linked_feeders.findIndex(
            (item) => item.id === changeFeeder.id
          );
          if (idxfeeder >= 0) {
            this.TemporalFeeder[idx].linked_feeders[idxfeeder] = changeFeeder;
          }
        });
      }
    }
    if (this.type === "feeder") {
      this.TemporalFeeder.forEach((feederTemporal, idx) => {
        if (this.selectedFeeders[idxItem]) {
          const { mac } = this.selectedFeeders[idxItem];
          if (mac && mac === feederTemporal.mac) {
            this.TemporalFeeder[idx] = this.selectedFeeders[idxItem];
          }
        }
      });
    }
  }

  validateDuplicateAlert(test) {
    let index = -1;
    let testSelected: any = {};

    test.forEach((item) => {
      let type = item.type;

      index = this.test[type].findIndex(
        (test) => test.feeder.mac === item.value.feeder.mac
      );
      if (index !== -1) {
        testSelected = item;
        return;
      }
    });

    return { index, test: testSelected };
  }
  validateText = (socket): string => {
    let Allpropiety = [
      "hum",
      "hum_max",
      "hum_max_time",
      "hum_min",
      "hum_min_time",
      "temp",
      "temp_max",
      "temp_max_time",
      "temp_min",
      "temp_min_time",
      "water_temp",
      "water_temp_max",
      "water_temp_max_time",
      "water_temp_min",
      "water_temp_min_time",
    ];
    const type = socket.data.type;
    /* Validacion para saber si tiene todos los items */
    const validateAllItems = () => {
      let bool = true;
      let tempSocket = { ...socket.data };
      let deletTempSocket = ["mac", "name", "type", "version"];
      deletTempSocket.forEach((element) => {
        delete tempSocket[element];
      });

      for (const key of Allpropiety) {
        if (!tempSocket.hasOwnProperty(key)) {
          bool = false;
        }
      }
      return bool;
    };

    if (type === "SENSORS") {
      if (validateAllItems()) {
        return "REQUEST_SENSORS_ALL_ITEMS";
      }
      if (socket.data.hasOwnProperty("sensors_enabled")) {
        return "SENSORS_ENABLED";
      }
    }
    if (type === "SETPOINTS") {
      if (socket.data.hasOwnProperty("set_point_enabled")) {
        return "SET_POINTS";
      }
      if (!socket.data.hasOwnProperty("set_point_enabled")) {
        return "CONFIRM_POINTS";
      }
    }
    if (type === "DISPENSERALERTENABLED") {
      return "CHANGE_ALERT_DISPENSER";
    }
    if (type === "SENSORSENSIBILITY") {
      return "CHANGE_SETTING_FRAME";
    }

    if (type) {
      return type;
    }
    return "";
  };
  validateFeederLoadingStatus(feeder, msg, status) {
    let changeFeeder = feeder;
    if (msg.data.type === "FEED_NOT_OK") {
      return changeFeeder;
    }
    const validatText: string = this.validateText(msg);

    if (
      msg.data.type === "ALERT_Communication" &&
      validatText === "socketError"
    ) {
      changeFeeder = {
        ...changeFeeder,
        condition: false,
        loadings: {
          ...changeFeeder.loadings,
          [validatText]: status,
        },
      };
      return changeFeeder;
    }
    if (validatText !== "" && validatText !== "socketError" && validatText) {
      changeFeeder = {
        ...changeFeeder,
        condition: true,
        loadings: {
          ...changeFeeder.loadings,
          [validatText]: status,
        },
      };
      return changeFeeder;
    }
  }
  detectIndexFeeder(
    pondOrFeeder,
    msg,
    type: "feeder" | "pond",
    feederChanged?
  ) {
    let index = -1;

    if (type === "pond") {
      let arrayChanged = this.globalFunction.setDataFeeders(
        pondOrFeeder.linked_feeders,
        msg,
        this.showView
      ).feederChanged;

      arrayChanged.forEach((feeder: any) => {
        const condi = pondOrFeeder.linked_feeders.findIndex(
          (element) => feeder.id === element.id
        );
        if (condi > -1) {
          index = condi;
          return;
        }
      });
    }
    if (type === "feeder") {
      let arrayChanged = feederChanged;

      arrayChanged.forEach((feeder) => {
        const condi = pondOrFeeder.findIndex(
          (element) => feeder.id === element.id
        );
        if (condi > -1) {
          index = condi;
          return;
        }
      });
    }

    return index;
  }

  validateSpinOffOn() {
    let countOn = 0;
    let countOFF = 0;
    if (this.TemporalFeeder.length > 0 && this.type === "ponds") {
      this.TemporalFeeder.forEach((element, idx) => {
        element.linked_feeders.forEach((item) => {
          item.status_spin_time ? countOn++ : countOFF++;
        });
      });
    }
    if (this.selectedFeeders.length > 0) {
      this.selectedFeeders.forEach((item) => {
        item.status_spin_time ? countOn++ : countOFF++;
      });
    }

    return { countOn, countOFF };
  }

  validateStatusEmptied() {
    let count = 0;

    if (this.TemporalFeeder.length > 0 && this.type === "ponds") {
      this.TemporalFeeder.forEach((element, idx) => {
        element.linked_feeders.forEach((item) => {
          count++;
        });
      });
    }

    if (this.selectedFeeders.length > 0) {
      this.selectedFeeders.forEach((item) => {
        count++;
      });
    }

    return count;
  }

  validateReenviarRacion() {
    let count = 0;
    if (this.TemporalFeeder.length > 0 && this.type === "ponds") {
      this.TemporalFeeder.forEach((element) => {
        element.linked_feeders.forEach((item) => {
          count++;
        });
      });
    }

    if (this.selectedFeeders.length > 0) {
      this.selectedFeeders.forEach((item) => {
        count++;
      });
    }
    return count;
  }

  resetButtons() {
    this.loading = {
      ANALOG_READING: "initial",
      CURRENT: "initial",
      FEED_RATE: "initial",
      SENSORS_ENABLED: "initial",
      SET_POINTS: "initial",
      CONFIRM_POINTS: "initial",
      REQUEST_SENSORS_ALL_ITEMS: "initial",
      SENSOR_FREQUENCIES_RESPONSE: "initial",
      CHANGE_SETTING_FRAME: "initial",
      CHANGE_ALERT_DISPENSER: "initial",
    };
    this.test = {
      ANALOG_READING: [],
      CURRENT: [],
      FEED_RATE: [],
      SENSORS_ENABLED: [],
      SET_POINTS: [],
      CONFIRM_POINTS: [],
      REQUEST_SENSORS_ALL_ITEMS: [],
      SENSOR_FREQUENCIES_RESPONSE: [],
      CHANGE_SETTING_FRAME: [],
      CHANGE_ALERT_DISPENSER: [],
    };
  }

  getGatewayHardCodeado(errors) {
    /* Esta funcion solo es temporal se cambiara con nuevos parametros */
    var cadena = errors.toString();
    var re = /No fue posible establecer la comunicación con el gateway id /g;
    var resultado = cadena.replace(re, "");
    return resultado;
  }
  validateGatewayFeeder(error) {
    const validateErrors = (err, feeder) => {
      let validatemac = err.hasOwnProperty(feeder.mac);
      let gateway_error = validatemac ? err[feeder.mac] : "";
      const condition = validatemac ? { condition: false, gateway_error } : {};
      return condition;
    };

    if (this.type === "ponds") {
      this.TemporalFeeder.forEach((pond, idx) => {
        this.TemporalFeeder[idx].linked_feeders = pond.linked_feeders.map(
          (item) => {
            return {
              ...item,
              ...validateErrors(error, item),
            };
          }
        );
      });
    }

    if (this.type === "feeder") {
      this.TemporalFeeder = this.TemporalFeeder.map((feeder) => {
        return {
          ...feeder,
          ...validateErrors(error, feeder),
        };
      });
      this.selectedFeeders = this.selectedFeeders.map((feeder) => {
        return {
          ...feeder,
          ...validateErrors(error, feeder),
        };
      });
    }
  }
  testActions(
    type:
      | "ANALOG_READING"
      | "DISPENSER_ALERT"
      | "analog_reading_alert"
      | "CURRENT"
      | "SUBMIT_CURRENT_SPTRINKLER"
      | "SUBMIT_CURRENT_DISPENSER"
      | "SENSORS"
      | "ENABLE_DISABLE_SENSORS"
      | "FEED_RATE"
      | "CHANGE_TIME_DAYS"
      | "SENSORS_SET_POINTS"
      | "CHANGE_SETTING_FRAME"
      | "CHANGE_ALERT_DISPENSER"
      | "REQUEST_DISPENSER_ALERT"
      | "SENSOR_FREQUENCY_REQUEST",
    value?: any
  ) {
    let feedersQuery = this.validateQueryTest();

    /* FRAME DE PUERTO Y ALERTA DE DISPENSADOR */
    if (type === "ANALOG_READING") {
      if (value === "button") {
        this.showView["ANALOG_READING"] = true;

        this.loadingStatus("ANALOG_READING", "initial");

        this.framesService
          .Frame("analog_reading_alert", feedersQuery)
          .subscribe((item) => {
            this.loadingStatus("ANALOG_READING", "loading");
            if (item && item.hasOwnProperty("errors")) {
              this.validateGatewayFeeder(item.errors);
            }
          });
      } else {
        this.showView[type] = true;
        this.loadingStatus("ANALOG_READING", "initial");
        let port = Number(value);

        if (port >= 0) {
          let body = { port };
          this.framesService
            .FramePost("change_port", feedersQuery, body)
            .subscribe((item) => {
              this.loadingStatus("ANALOG_READING", "loading");
              if (item && item.hasOwnProperty("errors")) {
                this.validateGatewayFeeder(item.errors);
              }
            });
        }
      }
    }
    if (type === "DISPENSER_ALERT") {
      this.showView["ANALOG_READING"] = true;

      let body = {
        dispenser_alert: value === "ON",
      };
      this.loadingStatus("ANALOG_READING", "initial");

      this.framesService
        .FramePost("dispenser_alert", feedersQuery, body)
        .subscribe((item) => {
          this.loadingStatus("ANALOG_READING", "loading");
          if (item && item.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(item.errors);
          }
        });
    }
    /* FRAME CAMBIO DE CORRIENTE  */
    if (type === "CURRENT") {
      this.showView[type] = true;
      this.loadingStatus("CURRENT", "initial");
      this.framesService
        .Frame("request_current", feedersQuery)
        .subscribe((item) => {
          this.loadingStatus("CURRENT", "loading");
          if (item && item.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(item.errors);
          }
        });
    }
    /* Se quitaron los loadings por no tener respuesta del gateway */
    if (type === "SUBMIT_CURRENT_SPTRINKLER") {
      let body = { sprinkler_current: value };
      this.framesService
        .FramePost("change_sprinkler_current", feedersQuery, body)
        .subscribe((item) => {
          if (item && item.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(item.errors);
          }
        });
    }
    if (type === "SUBMIT_CURRENT_DISPENSER") {
      let body = { dispenser_current: value };
      this.framesService
        .FramePost("change_dispenser_current", feedersQuery, body)
        .subscribe((item) => {
          if (item && item.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(item.errors);
          }
        });
    }
    if (type === "FEED_RATE") {
      let body = { feed_rate: value };
      this.loadingStatus(type, "loading");
      this.framesService
        .FramePost("change_feed_rate", feedersQuery, body)
        .subscribe((item) => {
          let allFeedersOrPonds = this.validateFeeders();

          setTimeout((item) => {
            let sockets: any = [];
            if (this.type === "feeder") {
              allFeedersOrPonds.forEach((feeder) => {
                sockets.push({
                  data: {
                    mac: feeder.mac,
                    name: feeder.name,
                    type: "FEED_RATE",
                    feed_rate: value,
                  },
                  original_raw_frame: "",
                  type: "receive.frame",
                });
              });
            }
            if (this.type === "ponds") {
              allFeedersOrPonds.forEach((pond) => {
                pond.linked_feeders.forEach((feeder) => {
                  sockets.push({
                    data: {
                      mac: feeder.mac,
                      name: feeder.name,
                      type: "FEED_RATE",
                      feed_rate: value,
                    },
                    original_raw_frame: "",
                    type: "receive.frame",
                  });
                });
              });
            }
            sockets.forEach((socket) => {
              this.getData(socket);
            });
          }, 1000);

          if (item && item.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(item.errors);
          }
        });
    }
    /* FUNCION PARA MANDAR LOS DATOS DE LOS SENSORES */

    if (type === "SENSORS") {
      if (value === "button") {
        this.showView["REQUEST_SENSORS_ALL_ITEMS"] = true;
        this.test["REQUEST_SENSORS_ALL_ITEMS"] = [];

        this.framesService
          .Frame("sensor/frequency/request", feedersQuery)
          .subscribe((item) => {
            this.loadingStatus("REQUEST_SENSORS_ALL_ITEMS", "loading");

            if (item && item.hasOwnProperty("errors")) {
              this.validateGatewayFeeder(item.errors);
            }
          });
      } else {
        /* MANDAR TEMPERATURA   */
        this.showView["CONFIRM_POINTS"] = true;
        this.test["CONFIRM_POINTS"] = [];
        this.framesService
          .FramePost("set_points", feedersQuery, value)
          .subscribe((item) => {
            this.loadingStatus("CONFIRM_POINTS", "loading");

            if (item && item.hasOwnProperty("errors")) {
              this.validateGatewayFeeder(item.errors);
            }
          });
      }
    }
    /* Habilitar y deshabilitar sensores  */
    if (type === "ENABLE_DISABLE_SENSORS") {
      this.showView["SENSORS_ENABLED"] = true;
      this.test["SENSORS_ENABLED"] = [];
      this.framesService
        .FramePost("sensors_enabled", feedersQuery, {
          sensors_enabled: value,
        })
        .subscribe((item) => {
          this.loadingStatus("SENSORS_ENABLED", "loading");

          if (item && item.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(item.errors);
          }
        });
    }
    /* habilitar y deshabilitar points  */
    if (type === "SENSORS_SET_POINTS") {
      this.showView["SET_POINTS"] = true;
      this.test["SET_POINTS"] = [];
      this.framesService
        .FramePost("set_points_state", feedersQuery, {
          set_point_enabled: value,
        })
        .subscribe((item) => {
          this.loadingStatus("SET_POINTS", "loading");

          if (item && item.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(item.errors);
          }
        });
    }

    /* CAMBIAR LOS INTERVALOS DE TIEMPO */
    if (type === "CHANGE_TIME_DAYS") {
      this.showView["SENSOR_FREQUENCIES_RESPONSE"] = true;
      this.test["SENSOR_FREQUENCIES_RESPONSE"] = [];
      this.loadingStatus("SENSOR_FREQUENCIES_RESPONSE", "loading");
      if (value && value.hasOwnProperty("errors")) {
        this.validateGatewayFeeder(value.errors);
      }
    }

    if (type === "CHANGE_SETTING_FRAME") {
      this.showView["CHANGE_SETTING_FRAME"] = true;
      this.test["CHANGE_SETTING_FRAME"] = [];

      if (value) {
        this.framesService
          .Frame(value.url, feedersQuery, { [value.url]: value.data })
          .subscribe((item) => {
            this.loadingStatus("CHANGE_SETTING_FRAME", "loading");

            if (item && item.hasOwnProperty("errors")) {
              this.validateGatewayFeeder(item.errors);
            }
          });
      } else {
        this.framesService
          .Frame(`request_sensors_settings`, feedersQuery)
          .subscribe((item) => {
            this.loadingStatus("CHANGE_SETTING_FRAME", "loading", 0.7);

            if (item && item.hasOwnProperty("errors")) {
              this.validateGatewayFeeder(item.errors);
            }
          });
      }
    }
    if (type === "CHANGE_ALERT_DISPENSER") {
      const url = this.alert_dosificator
        ? "enable_dispenser_alert"
        : "disable_dispenser_alert";

      this.loadingStatus("CHANGE_ALERT_DISPENSER", "initial");
      this.showView["CHANGE_ALERT_DISPENSER"] = true;
      this.test["CHANGE_ALERT_DISPENSER"] = [];

      this.framesService.Frame(url, feedersQuery, {}).subscribe((item) => {
        this.loadingStatus("CHANGE_ALERT_DISPENSER", "loading");

        if (item && item.hasOwnProperty("errors")) {
          this.validateGatewayFeeder(item.errors);
        }
      });
    }
    if (type === "REQUEST_DISPENSER_ALERT") {
      this.loadingStatus("CHANGE_ALERT_DISPENSER", "initial");
      this.showView["CHANGE_ALERT_DISPENSER"] = true;
      this.test["CHANGE_ALERT_DISPENSER"] = [];

      this.framesService
        .Frame("request_dispenser_alert", feedersQuery, {})
        .subscribe((item) => {
          this.loadingStatus("CHANGE_ALERT_DISPENSER", "loading");

          if (item && item.hasOwnProperty("errors")) {
            this.validateGatewayFeeder(item.errors);
          }
        });
    }

    if (type === "SENSOR_FREQUENCY_REQUEST") {
      this.showView["SENSOR_FREQUENCIES_RESPONSE"] = true;

      this.loadingStatus("SENSOR_FREQUENCIES_RESPONSE", "initial");

      this.loadingStatus("SENSOR_FREQUENCIES_RESPONSE", "loading");
      if (value && value.hasOwnProperty("errors")) {
        this.validateGatewayFeeder(value.errors);
      }
    }
  }

  getTypeSensors = ({ type, value }) => {
    this.testActions(type, value);
  };

  loadingStatus(type, value, version?) {
    /* Cambiamos el estatus general de cada alimentadora */
    const returnLoadings = (item, pond) => {
      const pondId = pond ? { pondId: pond } : {};
      /* this.globalFunction.validateVersion(item, version) */
      return {
        ...item,
        ...pondId,
        condition: true,
        loadings: {
          ...item.loadings,
          [type]: value,
        },
      };
    };
    if (this.type === "ponds" && this.ponds && this.ponds.length > 0) {
      this.TemporalFeeder.forEach(async (pond, idx) => {
        const returnData = await pond.linked_feeders.map((item) =>
          returnLoadings(item, pond.id)
        );
        this.TemporalFeeder[idx].linked_feeders = [...returnData];
      });
    }

    if (
      this.type === "feeder" &&
      this.selectedFeeders &&
      this.selectedFeeders.length > 0
    ) {
      this.selectedFeeders = this.selectedFeeders.map(returnLoadings);
      this.TemporalFeeder = this.selectedFeeders.map(returnLoadings);
    }
  }

  filter(value, index, self) {
    return self.indexOf(value) === index;
  }
  validateFeeders() {
    if (this.ponds.length > 0) {
      /* FILTROS VACIOS */
      const filterEmpty =
        this.nameInput === "" && this.filterStatusFeeders === "";
      /* FILTROS VACIOS CON SELECCION DE ESTANQUES */
      if (filterEmpty && this.filterPondSeleected !== "") {
        return this.filterPondSeleected;
      }

      /* FILTROS VACIOS SIN SELECCION DE ESTANQUES */
      if (filterEmpty && this.filterPondSeleected === "") {
        return this.TemporalFeeder;
      }
      /* CON FILTROS  */
      if (!filterEmpty) {
        let individual: any = [];
        this.TemporalFeeder.forEach((feeder) => {
          individual = [...individual, ...feeder.linked_feeders];
        });
        return individual;
      }
    }
    if (this.TemporalFeeder.length > 0) {
      return this.TemporalFeeder;
    }
    return [];
  }
  validateQueryTest() {
    if (this.ponds && this.ponds.length > 0) {
      /* FILTROS VACIOS */
      const filterEmpty =
        this.nameInput === "" && this.filterStatusFeeders === "";
      /* FILTROS VACIOS CON SELECCION DE ESTANQUES */
      if (filterEmpty && this.filterPondSeleected !== "") {
        return `?pond_id_in=${this.filterPondSeleected}`;
      }

      /* FILTROS VACIOS SIN SELECCION DE ESTANQUES */
      if (filterEmpty && this.filterPondSeleected === "") {
        return `?pond_id_in=${this.TemporalFeeder.map((item) => item.id)}`;
      }
      /* CON FILTROS  */
      if (!filterEmpty) {
        let individual: any = [];
        this.TemporalFeeder.forEach((feeder) => {
          individual = [...individual, ...feeder.linked_feeders];
        });
        return `?id_in=${individual.map((item) => item.id)}`;
      }
    }
    if (this.TemporalFeeder.length > 0) {
      return `?id_in=${this.TemporalFeeder.map((item) => item.id)}`;
    }
    return "";
  }
  selectPond(pond) {
    this.pondSelected = pond;
    this.scrollTo(pond.id);
  }

  countFeedersTest() {
    let count = 0;
    if (
      this.TemporalFeeder &&
      this.TemporalFeeder.length > 0 &&
      this.type === "ponds"
    ) {
      this.TemporalFeeder.forEach((pond) => {
        count = count + pond.linked_feeders.length;
      });
      return count;
    }
    if (this.selectedFeeders.length > 0) {
      return this.selectedFeeders.length;
    }
    return 0;
  }

  countTotalAlerts(type) {
    let result = this.test[type].filter((test: any) => {
      const { feeder } = test;
      const conditionPondSelected = (feeder) =>
        this.filterPondSeleected !== ""
          ? feeder.pondId === Number(this.filterPondSeleected)
          : true;

      const validateName = (feeder) => {
        const { name } = feeder;
        return name
          ? feeder.name.toLowerCase().indexOf(this.nameInput.toLowerCase()) > -1
          : true;
      };
      return (
        validateName(feeder) &&
        conditionPondSelected(feeder) &&
        this.validateFilter(feeder)
      );
    });
    return result.length;
  }

  currentSection = "";
  onSectionChange(sectionId: any) {
    if (sectionId) {
      let id = sectionId.replace("Section", "");
      let pond = this.ponds.find((item) => item.id === Number(id));
      this.pondSelected = pond;
      this.currentSection = sectionId;
    }
  }

  scrollTo(section) {
    const selector: any = document.querySelector(`[id='Section${section}']`);

    selector.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  filterItems() {
    let text = this.nameInput ? this.nameInput : "";
    this.loading = {
      ANALOG_READING: "initial",
      CURRENT: "initial",
      FEED_RATE: "initial",
      SENSORS_ENABLED: "initial",
      SET_POINTS: "initial",
      CONFIRM_POINTS: "initial",
      REQUEST_SENSORS_ALL_ITEMS: "initial",
      SENSOR_FREQUENCIES_RESPONSE: "initial",
      CHANGE_SETTING_FRAME: "initial",
      CHANGE_ALERT_DISPENSER: "initial",
    };

    let result: any = [];
    let feeders: any = [];
    if (this.filterPondSeleected !== "" && this.type === "ponds") {
      const find = this.ponds.find(
        (pond) => Number(this.filterPondSeleected) === pond.id
      );
      find && (this.pondSelected = find);
    }

    /* Validacion para saber si estamos en frames masivos o individual */
    const isPonds = this.type === "ponds" && this.ponds.length > 0;

    if (isPonds) {
      /* Vamos a considerar ponds como array que nunca cambiara */
      /* Vamos a dividir los alimentadores para hacerlos individual en los frames masivos */
      const pondItems = this.ponds.map((item) => item);
      const linked_feeders = pondItems.map((pond) => pond.linked_feeders);

      if (linked_feeders.length > 0) {
        linked_feeders.forEach((element) => {
          feeders = [...feeders, ...element];
        });
      }
    }

    /* Vamos a crear un array con todas las alimentadoras */
    let allFeederPonds =
      this.ponds.length > 0 && this.type === "ponds"
        ? [...feeders]
        : [...this.selectedFeeders];

    feeders = [...allFeederPonds];
    /* filterPondSeleected */
    result = feeders.filter((feeder: any) => {
      const conditionPondSelected = (feeder) =>
        this.filterPondSeleected !== ""
          ? feeder.pondId === Number(this.filterPondSeleected)
          : true;
      return (
        feeder.name.toLowerCase().indexOf(text.toLowerCase()) > -1 &&
        conditionPondSelected(feeder) &&
        this.validateFilter(feeder)
      );
    });

    if (isPonds) {
      const solofarm = (pond) =>
        result.filter((item) => item.pondId === pond.id);
      let temp: any = [];
      this.TemporalFeeder.forEach((pond, idx) => {
        const linked_feeders = solofarm(pond);
        temp.push({
          ...pond,
          linked_feeders,
        });
      });
      this.TemporalFeeder = [...temp];
    }

    if (!isPonds) {
      let arrayFeeder = [];
      allFeederPonds.forEach((feeder) => {
        /* checar advertencia de object */
        let filter: null = result.find((item) => item.mac === feeder.mac);
        if (filter) {
          arrayFeeder.push(filter);
        }
      });

      this.TemporalFeeder = [...arrayFeeder];
    }
  }
  validateFilter = (feeder) => {
    const validateloadingDone = (type) => {
      let bool = false;
      const { loadings } = feeder;
      for (const key in loadings) {
        const element = loadings[key];
        element === type && (bool = true);
      }
      if (bool && feeder.condition && type === "done") {
        return bool && feeder.condition;
      }
      if (bool && feeder.condition && type === "loading") {
        return bool && feeder.condition;
      }
      return bool;
    };
    let type = this.filterStatusFeeders;
    if (!type) return true;

    switch (type) {
      case "error":
        return !feeder.condition || feeder.gateway_error;
      default:
        return validateloadingDone(type);
    }
  };
  validateType(): "individual" | "masive_ponds" | "masive_feeders" {
    if (this.TemporalFeeder.length === 1 && this.type === "feeder") {
      return "individual";
    }
    return this.type === "ponds" ? "masive_ponds" : "masive_feeders";
  }
  changeAnalogReadingAlert(check) {
    if (check === 0) {
      this.testActions("DISPENSER_ALERT", "ON");
    }
    if (check === 1) {
      this.testActions("DISPENSER_ALERT", "OFF");
    }
  }
  closeALLSection() {
    this.sectionAnnalog = true;
    this.sectionCurrent = true;
    this.sectionFeedRate = true;
    this.sectionSensors = true;
    this.sectionAlertDispenser = true;
    this.sectionChangeConfigSensor = true;
  }
}

export interface SettingInterface {
  ANALOG_READING: String | Array<any> | boolean;
  CURRENT: String | Array<any> | boolean;
  FEED_RATE: String | Array<any> | boolean;
  SENSORS_ENABLED: String | Array<any> | boolean;
  SET_POINTS: String | Array<any> | boolean;
  CONFIRM_POINTS: String | Array<any> | boolean;
  REQUEST_SENSORS_ALL_ITEMS: String | Array<any> | boolean;
  SENSOR_FREQUENCIES_RESPONSE: String | Array<any> | boolean;
  CHANGE_SETTING_FRAME: String | Array<any> | boolean;
  CHANGE_ALERT_DISPENSER: String | Array<any> | boolean;
}
