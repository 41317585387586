import { PermissionsService } from "../../routes/services/permissions.service";
import { VersioningService } from "./../../routes/services/versioning.service";
import { IndexedDBService } from "../../routes/services/db/indexedDb.service";
import { TranslatorService } from "../../core/translator/translator.service";
import { ParametricsService } from "../../routes/services/parametrics.service";
import { UserblockService } from "../sidebar/userblock/userblock.service";
import { FirebaseService } from "../../routes/services/firebase.service";
import { SettingsService } from "../../core/settings/settings.service";
import { ClientsService } from "../../routes/services/clients.service";
import { StorageService } from "../../routes/services/storage.service";
import { SocketService } from "../../routes/services/socket.service";
import { UsersService } from "./../../routes/services/users.service";
import { FarmService } from "../../routes/services/farm.service";
import { AuthService } from "../../routes/services/auth.service";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { HelpService } from "../../core/help/help.service";
import { MenuService } from "../../core/menu/menu.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import {
  GlobalFunction,
  urlClient,
  urlAdmin,
} from "../../routes/services/globalFuntion";
import { ElementRef, Component, ViewChild, OnInit } from "@angular/core";
import { NotificationsService } from "../../routes/services/notifications.service";

interface person {
  name: string;
  email: string;
}
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  navCollapsed = true; // for horizontal layout
  menuItems: Array<any> = []; // for horizontal layout
  personal: Array<person>;
  isNavSearchVisible: boolean;
  @ViewChild("fsbutton", { static: true }) fsbutton; // the fullscreen button
  @ViewChild("selectFarm") selectFarm: ElementRef;

  user: any;
  urlImage = "";

  mode = false;
  farms = [];
  farmData = {
    client: {},
    coordinates: [],
    device_name: "hh",
    gateway: {},
    hectares: 0,
    id: 0,
    name: "",
    off_feeders: 0,
    on_feeders: 0,
    timezone: "",
  };

  loading_farm = true;

  personal_web;
  modeSubsciption: Subscription;
  notificationSubscription: Subscription;
  currentVersion: any;
  newVersion: any;
  needsRefresh: boolean = false;
  url = "";
  farm_id: Number | "" = "";

  constructor(
    public permissionsService: PermissionsService,
    private indexedDBService: IndexedDBService,
    public userblockService: UserblockService,
    private afMessaging: AngularFireMessaging,
    public parametryService: ParametricsService,
    public versionService: VersioningService,
    public firebaseService: FirebaseService,
    public clientsService: ClientsService,
    public storageService: StorageService,
    public globalFunction: GlobalFunction,
    public translator: TranslatorService,
    public socketService: SocketService,
    public settings: SettingsService,
    public userService: UsersService,
    public AuthService: AuthService,
    public HelpService: HelpService,
    public farmService: FarmService,
    public menu: MenuService,
    public router: Router,
    public notificationsService: NotificationsService
  ) {
    window.addEventListener("beforeunload", (e) => {
      e.returnValue = "";

      this.socketService.unSuscribeSocket();
      this.modeSubsciption.unsubscribe();

      if (this.router.url !== "/login") {
        this.url = this.router.url;
      }

      if (this.url !== "/login") {
        let farm = {};
        if (this.storageService.localValue.mode === 1) {
          farm = {
            farm_id: this.farm_id,
          };
        }
        this.storageService.saveQueryFilters(
          {
            current_url: this.url,
            ...farm,
          },
          true
        );
      }
    });

    if (localStorage.getItem("version"))
      this.currentVersion = localStorage.getItem("version");

    if (this.globalFunction.validateSoloAdmin()) {
      this.getLastVersion();
    }

    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
  }

  requestPermission = async (dele = false) => {
    let token: any = await this.afMessaging.getToken.toPromise();
    if (dele && token) {
      const del = await this.afMessaging.deleteToken(token).toPromise();
      if (del) {
        let new_token: any = await this.afMessaging.getToken.toPromise();
        token = new_token;
      }
    }
    if (!token) {
      const new_token = await this.afMessaging.requestToken.toPromise();
      if (new_token) {
        token = new_token;
      }
    }

    try {
      if (token) {
        token && this.validateTokenNotifications(token);
      } else {
        this.afMessaging.requestToken.subscribe((token) => {
          token && this.validateTokenNotifications(token);
        });
      }
    } catch (error) {}
  };
  validateTokenNotifications = async (token) => {
    const suscribe_topic_all = async () => {
      await this.firebaseService.firebaseTopicSuscribeAll({}).toPromise();
    };
    const saveToken = async (name) => {
      let data = await this.firebaseService
        .saveToken({ name, token, type: 0 })
        .toPromise();

      if (data) {
        localStorage.setItem("name_firebase", name);
        suscribe_topic_all();
      }
    };
    const refreshToken = async (id, name) => {
      this.firebaseService
        .refreshToken(id, { name, token, type: 0 })
        .subscribe((item) => {
          localStorage.setItem("name_firebase", name);
          suscribe_topic_all();
        });
    };

    let tokens = await this.firebaseService
      .getTokentFirebase(`?ordering=-id`)
      .toPromise();

    let nameDevice = `${this.globalFunction.detectBrowserName()} ${
      tokens.length + 1
    }`;

    if (tokens.length === 0) {
      await saveToken(nameDevice);
    }
    if (tokens.length > 0) {
      let name_firebase = localStorage.getItem("name_firebase");

      if (name_firebase) {
        let findToken = tokens.findIndex((item) => item.name === name_firebase);

        if (findToken === -1) {
          await saveToken(name_firebase);
        }
        if (findToken >= 0) {
          await refreshToken(tokens[findToken].id, name_firebase);
        }
      }
      if (!name_firebase) {
        await saveToken(nameDevice);
      }
    }
  };

  countValidateUrl: number = 0;
  notifications: Array<any> = [];
  countNotifications: number = 0;

  permissions = {
    notifications: true,
  };
  ngOnInit() {
    this.getNotifications();
    const validated_subclient = (prop) => {
      return this.permissionsService.validatePermissionsExistSubclient(prop);
    };
    this.permissions = {
      notifications: validated_subclient("view_notifications"),
    };

    this.afMessaging.messages.subscribe((resp: any) => {
      console.log(resp);
      this.getNotifications();
      this.storageService.setItem("notifications", resp);
    });

    this.requestPermission();
    this.personal_web = this.storageService.localValue;

    if (this.personal_web) {
      this.uploadPreferenceHeader(this.personal_web);

      let idClient = this.storageService.getClientID();
      this.temporalClient = idClient;

      if (idClient) {
        if (this.user) {
          this.urlImage = this.user.client ? this.user.client.logo : "";
        }
      } else {
        this.urlImage = "";
      }
      /* Obtenern permisos al inciar el header para empezar a validar  */
    }
    let localUser: string | null = localStorage.getItem("USER") || null;
    if (localUser) {
      const user = <any>JSON.parse(localUser);
      this.user = user;
    }
    this.router.events.forEach((event) => {
      if (event["navigationTrigger"] === "imperative") {
        this.storageService.cancelRequests();
      }
      if (this.router.url !== "/login") {
        this.url = this.router.url;
        this.storageService.saveQueryFilters({
          current_url: this.router.url,
        });
      }
    });

    if (
      !this.globalFunction.validateSoloAdmin() ||
      this.personal_web.mode === 1
    ) {
      const farm_id = this.storageService.localValue.farm_id;

      this.parametryService
        .getParametricPreference(`?farm_id=${farm_id}`)
        .subscribe((resp) => {
          if (resp.length > 0) {
            let resourses = resp[0];
            this.storageService.checks_parametry_preference = resourses;
          }
        });
    }

    this.modeSubsciption = this.storageService
      .watchStorage("mode")
      .subscribe(async (mode) => {
        await this.validateCurrentUrl();

        if (this.storageService.localValue) {
          let getQuery = this.storageService.getQueryFilters({
            font_size: "",
          });
          if (getQuery && getQuery.font_size) {
            this.storageService.changeFont("default", getQuery.font_size);
          } else {
            this.storageService.changeFont("default");
          }

          this.getParametricPreference(mode);
        }
      });
  }
  getParametricPreference(mode) {
    if (!this.globalFunction.validateSoloAdmin() || mode === 1) {
      const farm_id = this.storageService.localValue.farm_id;
      this.parametryService
        .getParametricPreference(`?farm_id=${farm_id}`)
        .subscribe((resp) => {
          if (resp.length > 0) {
            let resourses = resp[0];
            this.storageService.checks_parametry_preference = resourses;
          }
        });
    }
  }

  getCurrentQuery() {
    const { mode } = this.storageService.localValue;
    return !mode && this.globalFunction.validateSoloAdmin()
      ? "query_admin"
      : "query_client";
  }
  validateCurrentUrl() {
    const { client, mode } = this.storageService.localValue;
    const query = this.storageService.localValue[this.getCurrentQuery()];

    if (query && query.current_url) {
      const current_url = query.current_url || "";
      if (this.router.url === current_url) {
        this.validateClientAdminUrl(mode, client, current_url);
      }
    } else {
      this.validateUrl(mode, client);
    }
  }

  validateClientAdminUrl = (mode, client, current_url) => {
    const validateText = (array, text) => {
      let filter = array.filter((item) => text.indexOf(item) !== -1);
      return filter.length > 0;
    };

    if (mode === 1 && client) {
      const validate = validateText(urlClient, current_url);

      if (validate) {
        this.router.navigateByUrl(current_url);
      } else {
        this.validateUrl(mode, client);
      }
    }
    if (!mode) {
      if (validateText(urlAdmin, current_url)) {
        this.router.navigateByUrl(current_url);
      } else {
        this.validateUrl(mode, client);
      }
    }
  };

  validateUrl = (mode, client) => {
    let localUSer = localStorage.getItem("USER");
    let user: any = localUSer ? <any>JSON.parse(localUSer) : "";
    /* si es un administrador o subcliente */
    if (!user.client && !client && !mode) {
      this.router.navigateByUrl(`/dashboard`);
    }
    /* Si es un subcliente usado como admin */
    if (mode && mode === 1 && client && !client.user.client) {
      this.router.navigateByUrl(`/dashboard`);
    }
    /* Si es un cliente usado como admin */
    if (mode && mode === 1 && !this.storageService.isAdmin()) {
      this.router.navigateByUrl(`/dashboard`);
    }
    /* Si es un cliente */
    if (!mode && !this.storageService.isAdmin()) {
      this.router.navigateByUrl(`/dashboard`);
    }
    if (mode === 0 || !mode) {
      this.router.navigateByUrl("/home");
    }
  };
  getLastVersion() {
    this.versionService.getLastVersion().subscribe((res) => {
      this.newVersion = res.results[0].version;

      if (this.currentVersion) {
        if (
          parseFloat(res.results[0].version) > parseFloat(this.currentVersion)
        ) {
          this.needsRefresh = true;
        }
      } else {
        localStorage.setItem("version", this.newVersion);
        this.currentVersion = this.newVersion;
      }
    });
  }

  refresh() {
    localStorage.setItem("version", this.newVersion);
    location.reload();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setFarm(value) {
    const local = this.storageService.localValue;
    const farm_id = Number(value);
    /* Validacion para cuando cambie de granja eliminar el query de pond_id  */
    let setValue = {
      ...local,
      farm_id,
    };
    this.socketService.socketNext([
      { type: "gateways.add_listener", data: { farm: Number(value) } },
    ]);
    this.indexedDBService.clearAlldb();

    this.storageService.editPreferences(setValue)?.subscribe((resp) => {
      this.storageService.localValue.farm_id = Number(value);
      this.farm_id = value;
      this.getParametricPreference(this.storageService.localValue.mode);
    });
  }

  setNavSearchVisible(stat: boolean) {
    // (stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting("offsidebarOpen");
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting("isCollapsed");
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }
  changeMode = async () => {
    const { mode, farm_id } = this.storageService.localValue;
    const local = this.storageService.localValue;

    this.storageService.localValue[this.getCurrentQuery()] = {
      ...local[this.getCurrentQuery()],
      current_url: this.url,
    };
    this.storageService.localValue["farm_id"] = Number(farm_id);
    this.storageService.localValue["mode"] = Number(mode === 1 ? 0 : 1);

    let editPreferences = await this.storageService
      .editPreferences(this.storageService.localValue)
      ?.toPromise();

    if (editPreferences) {
      if (mode === 1) {
        this.socketService.connectSocket([]);
        this.storageService.setItem("mode", 0);
        this.mode = false;
        this.indexedDBService.clearAlldb();
      } else {
        this.socketService.connectSocket([]);
        this.getFarms(true);
        this.storageService.setItem("mode", 1);
        this.mode = true;
      }
    }
    const { client } = this.storageService.localValue;

    const query = this.storageService.localValue[this.getCurrentQuery()];
    const current_url = query && query.current_url ? query.current_url : "";

    this.validateClientAdminUrl(this.mode ? 1 : 0, client, current_url);
    this.requestPermission(true);
  };
  temporalClient: String | Number = "";
  getFarms(socket = false) {
    this.farms = [];
    let { farm_id, mode } = this.storageService.localValue;
    let clientId = this.storageService.getClientID();
    this.loading_farm = true;
    const submit_farms = (farms) => {
      if (farms.length > 0) {
        if (farm_id) {
          let someFarm = farms.some(
            (item: any) => Number(item.id) === Number(farm_id)
          );
          if (socket) {
            this.socketService.connectSocket([
              {
                type: "gateways.add_listener",
                data: { farm: Number(farm_id) },
              },
            ]);
          } else {
            /* Si no existe el farm en la lista */
            if (!someFarm) {
              farm_id = farms[0].id;
              this.storageService.setItem("farmId", farms[0].id);
              this.setFarm(farm_id);
            }
          }
          /* Si no existe el farm en la lista */
        } else if (!farm_id) {
          this.setFarm(farms[0].id);

          if (this.storageService.validateSoloAdmin() && mode) {
            this.setFarm(farms[0].id);
          }
          this.storageService.setItem("farmId", farms[0].id);
        }
        this.farms = farms;
      } else {
        this.farms = [];
      }

      this.loading_farm = false;
    };

    const fields = ["id", "name"];
    const queryClient = clientId ? `&client=${clientId}` : "";
    const query = `?fields=${fields}${queryClient}`;

    const getListFarm = () => {
      this.farmService.getFarms(query).subscribe((res) => {
        submit_farms(res);
      });
    };

    getListFarm();
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }
  uploadPreferenceHeader(personal) {
    const { mode } = personal;
    let admin = this.globalFunction.validateSoloAdmin();

    if ((admin && mode === 1) || (!admin && !mode)) {
      this.getFarms();
    } else {
      this.loading_farm = false;
    }
    this.mode = mode === 1;
  }

  runHelp(content) {
    this.HelpService.runHelp(content, {});
  }

  getNotifications() {
    let query = `?page=1&page_size=20&ordering=-date&status=0`;
    this.notificationsService.getNotifications(query).subscribe((res) => {
      this.notifications = res.notifications;
      this.countNotifications = res.count;
      console.log(res);
    });
  }

  markAsSeen(notification) {
    const obj = {
      notification_id: [notification.id],
    };

    this.notificationsService.markNotificationAsSeen(obj).subscribe((res) => {
      this.getNotifications();
    });
  }
  markAsSeenAll() {
    const obj = {
      notification_id: this.notifications.map((item) => item.id),
    };
    let objDiv = document.getElementById("notifications");
    if (objDiv) {
      objDiv.scrollTop = 0;
    }
    this.notificationsService.markNotificationAsSeen(obj).subscribe((res) => {
      this.getNotifications();
    });
  }
}
