import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-config-sensor",
  templateUrl: "./config_sensors.html",
  styleUrls: ["./config_sensors.scss"],
})
export class ConfigSensorsComponent implements OnInit {
  view_sensor = false;
  values_oxygen = [
    {
      min: 0,
      max: 4,
      percentage: -20,
    },
    {
      min: 4,
      max: 8,
      percentage: -10,
    },
    {
      min: 8,
      max: 12,
      percentage: 0,
    },
    {
      min: 12,
      max: 16,
      percentage: 10,
    },
    {
      min: 16,
      max: 20,
      percentage: 20,
    },
  ];
  values_sensors = [
    {
      min: 20,
      max: 24,
      percentage: -20,
    },
    {
      min: 24,
      max: 28,
      percentage: -10,
    },
    {
      min: 28,
      max: 32,
      percentage: 0,
    },
    {
      min: 32,
      max: 36,
      percentage: 10,
    },
    {
      min: 36,
      max: 40,
      percentage: 20,
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
