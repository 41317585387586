import { EChartsOption } from "echarts";

export const fields_ponds = [
  "id",
  "coordinates",
  "db_before",
  "db_next",
  "feeder_alerts",
  "name",
  "gateway",
  "planting",
  "feeding_recommendation",
  "hectares",
  "is_done",
  "latest_tray_percentage",
  "diffuse_logic",
];

export const fields_feeders = [
  "id",
  "alert_battery",
  "alert_comm",
  "alert_dispenser",
  "alert_dispenser_opening",
  "alert_panel",
  "alert_reset",
  "alert_sprinkle",
  "date_created",
  "date_updated",
  "dispenser",
  "feeding_details",
  "flag_monitoring",
  "flag_tested",
  "has_observations",
  "lat",
  "lng",
  "mac",
  "name",
  "port_updated",
  "sensors",
  "series",
  "sensor_hydro",
  "sprinkler_alerts",
  "state_available",
  "status_battery",
  "status_date",
  "status_datetime",
  "status_db",
  "status_dispenser",
  "status_dispenser_alert",
  "status_dispenser_current",
  "status_dispenser_opening",
  "status_dispenser_sensor",
  "status_emptied",
  "status_high_speed",
  "status_lat",
  "status_lng",
  "status_low_speed",
  "status_panel",
  "status_port",
  "status_power",
  "status_ration",
  "status_speed",
  "status_speed_mode",
  "status_spin_time",
  "status_sprinkler",
  "status_sprinkler_current",
  "status_sprinkler_sensor",
  "status_time",
  "status_version",
  "test_alert_state",
  "brand",
  "virtual_weight",
  "farm",
  "weight_sensor",
  "status_lf_block",
  "config",
];

export const graph_sensor: EChartsOption = {
  title: {
    text: "Comportamiento de alimentacion",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {
    show: true,
    data:['nas 2024','dieta']
  },
  toolbox: {
    show: true,
    feature: {
      dataView: { show: true, readOnly: false },
      dataZoom: { show: true },
      restore: { show: true },
      magicType: { show: true, type: ["line", "bar"] },
      saveAsImage: { show: true },
    },
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    // prettier-ignore
    data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: "{value} Kg.",
    },
    axisPointer: {
      snap: true,
    },
  },
  visualMap: {
    show: false,
    dimension: 0,
    pieces: [
      {
        lte: 6,
        color: "green",
      },
      {
        gt: 6,
        lte: 8,
        color: "red",
      },
      {
        gt: 8,
        lte: 14,
        color: "green",
      },
      {
        gt: 14,
        lte: 17,
        color: "red",
      },
      {
        gt: 17,
        color: "green",
      },
    ],
  },
  series: [
    {
      name: "",
      type: "line",
      smooth: true,
      data: [],
      markArea: {
        itemStyle: {
          color: "rgba(255, 173, 177, 0.4)",
        },
        data: [
             [
            {
              name: "Alerta de alimento",
              xAxis: 6,
            },
            {
              xAxis: 8,
            },
          ],
          [
            {
              name: "Alerta de alimento",
              xAxis: 9,
            },
            {
              xAxis: 10,
            },
          ], 
        ],
      },
    },
    {
      name: "nas 2024",
      type: "line",
      smooth: true,
      data: [],
      color: "#30646c",
    },
    {
      name: "dieta",
      type: "line",
      smooth: true,
      data: [],
      color: "#00adac",
    },
  ],
};

export const data_tray = [
  {
    hour: 1,
    kgs_feed: 156.3,
    kgs_target: 194.7,
    kgs_dist: 38.4,
    percent: 19.7,
    title: "",
    reason: "",
  },
  {
    hour: 2,
    kgs_feed: 177.8,
    kgs_target: 213.6,
    kgs_dist: 35.8,
    percent: 16.8,
    title: "",
    reason: "",
  },
  {
    hour: 3,
    kgs_feed: 222.4,
    kgs_target: 243.9,
    kgs_dist: 21.5,
    percent: 8.8,
    title: "",
    reason: "",
  },
  {
    hour: 4,
    kgs_feed: 196.2,
    kgs_target: 220.1,
    kgs_dist: 23.9,
    percent: 10.9,
    title: "",
    reason: "",
  },
  {
    hour: 5,
    kgs_feed: 126.9,
    kgs_target: 203.9,
    kgs_dist: 77.0,
    percent: 37.8,
    title: "Alert",
    reason: "sprinkler alert",
  },
  {
    hour: 6,
    kgs_feed: 193.4,
    kgs_target: 215.5,
    kgs_dist: 22.1,
    percent: 10.2,
    title: "",
    reason: "",
  },
  {
    hour: 7,
    kgs_feed: 203.8,
    kgs_target: 231.6,
    kgs_dist: 27.8,
    percent: 12.0,
    title: "",
    reason: "",
  },
  {
    hour: 8,
    kgs_feed: 223.5,
    kgs_target: 244.0,
    kgs_dist: 20.5,
    percent: 8.4,
    title: "",
    reason: "",
  },
  {
    hour: 9,
    kgs_feed: 207.1,
    kgs_target: 227.9,
    kgs_dist: 20.8,
    percent: 9.1,
    title: "sensor alert",
    reason: "Alerta en sensor de temperatura",
  },
  {
    hour: 10,
    kgs_feed: 160.0,
    kgs_target: 200.0,
    kgs_dist: 40.0,
    percent: 20.0,
    title: "",
    reason: "",
  },
  {
    hour: 11,
    kgs_feed: 222.6,
    kgs_target: 234.2,
    kgs_dist: 11.6,
    percent: 4.9,
    title: "",
    reason: "",
  },
  {
    hour: 12,
    kgs_feed: 149.2,
    kgs_target: 180.4,
    kgs_dist: 31.2,
    percent: 17.3,
    title: "",
    reason: "",
  },
  {
    hour: 13,
    kgs_feed: 203.7,
    kgs_target: 232.1,
    kgs_dist: 28.4,
    percent: 12.2,
    title: "hydro sensors",
    reason: "Alerta de sensor de oxigeno",
  },
  {
    hour: 14,
    kgs_feed: 162.7,
    kgs_target: 194.9,
    kgs_dist: 32.2,
    percent: 16.5,
    title: "",
    reason: "",
  },
  {
    hour: 15,
    kgs_feed: 203.5,
    kgs_target: 227.7,
    kgs_dist: 24.2,
    percent: 10.6,
    title: "",
    reason: "",
  },
  {
    hour: 16,
    kgs_feed: 201.9,
    kgs_target: 224.6,
    kgs_dist: 22.7,
    percent: 10.1,
    title: "",
    reason: "",
  },
  {
    hour: 17,
    kgs_feed: 209.8,
    kgs_target: 228.9,
    kgs_dist: 19.1,
    percent: 8.3,
    title: "",
    reason: "",
  },
  {
    hour: 18,
    kgs_feed: 157.4,
    kgs_target: 195.6,
    kgs_dist: 38.2,
    percent: 19.5,
    title: "",
    reason: "",
  },
  {
    hour: 19,
    kgs_feed: 160.5,
    kgs_target: 193.3,
    kgs_dist: 32.8,
    percent: 17.0,
    title: "",
    reason: "",
  },
  {
    hour: 20,
    kgs_feed: 207.6,
    kgs_target: 233.4,
    kgs_dist: 25.8,
    percent: 11.0,
    title: "",
    reason: "",
  },
  {
    hour: 21,
    kgs_feed: 169.1,
    kgs_target: 206.2,
    kgs_dist: 37.1,
    percent: 18.0,
    title: "",
    reason: "",
  },
  {
    hour: 22,
    kgs_feed: 163.6,
    kgs_target: 204.4,
    kgs_dist: 40.8,
    percent: 20.0,
    title: "Normal",
    reason: "Alerta normal",
  },
  {
    hour: 23,
    kgs_feed: 204.3,
    kgs_target: 235.9,
    kgs_dist: 31.6,
    percent: 13.4,
    title: "",
    reason: "",
  },
  {
    hour: 24,
    kgs_feed: 162.0,
    kgs_target: 194.7,
    kgs_dist: 32.7,
    percent: 16.8,
    title: "",
    reason: "",
  },
];
