import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";

@Injectable({
  providedIn: "root",
})
export class CalibrationService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router,
  ) {}

  getCalibrations(query) {
    return this.http
      .get(`${Uris.CALIBRATION}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getCalibration(id) {
    return this.http
      .get(`${Uris.CALIBRATION}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postCalibration(value) {
    return this.http
      .post(`${Uris.CALIBRATION}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Calibracion creada correctamente`
          );
          return resp;
        })
      );
  }

  patchCalibration(id, value) {
    return this.http
      .patch(`${Uris.CALIBRATION}${id}/`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `${value.batch} editado correctamente`
          );
          return resp;
        })
      );
  }

  deleteCalibration(id) {
    return this.http
      .delete(`${Uris.CALIBRATION}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Calibracion eliminada correctamente`
          );
          return resp;
        })
      );
  }


}
