import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SettingsFeedersComponent } from "./settings-feeders";
import { LoadingModule } from "../loading/loading.module";
@NgModule({
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule,LoadingModule],
  providers: [SettingsFeedersComponent],
  declarations: [SettingsFeedersComponent],
  exports: [SettingsFeedersComponent],
})
export class SettingFeedersModule {}
