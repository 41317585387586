import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-spinner",  
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  @Input() type: string;
  @Input() vh: string;
  @Input() spinnerColor: string = 'text-primary';
  @Input() status: boolean;
  @Input() showSmall?: boolean = true;
  @Input() smallLoader?: boolean = false;
  @Input() errorText: any;

  messageSuccess: boolean = true;
  
  constructor() {}
  
  ngOnInit(): void {
    setTimeout(() => {
      //<<<---using ()=> syntax
      this.messageSuccess = false;
    }, 300000);
  }
}
