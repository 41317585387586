import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Uris } from "./Uris";
import { map, catchError } from "rxjs/operators";
import { StorageService } from "./storage.service";
@Injectable({
  providedIn: "root",
})
export class HarvestsService {
  constructor(
    private http: HttpClient,
    public router: Router,
    public storageService: StorageService
  ) { }

  PostHarvest(value) {
    return this.http
      .post(`${Uris.HARVEST}`, value, this.storageService.headers())
      .pipe(

        map((resp: any) => {
          this.storageService.animation('success', 'Éxito',
            `${value.is_final
              ? "Se ha cosechado correctamente"
              : "Se ha precosechado correctamente"
            }`
          );
          return resp;
        })
      )
  }

  getHarvest(id: number) {
    return this.http
      .get(`${Uris.HARVEST}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      )
  }

  editHarvest(id, obj, type) {
    return this.http
      .patch(`${Uris.HARVEST}${id}/`, obj, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation('success', 'Éxito',
            `${type} editada correctamente`
          );
          return resp;
        })
      )
  }

  deleteHarvest(id) {
    return this.http
      .delete(`${Uris.HARVEST}${id}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation('success', 'Éxito',
            `Precosecha eliminada correctamente`
          );
          return resp;
        })
      )
  }
}
