export const feedSection = {
  label: "help.feed.title",
  icon: "fas fa-utensils",
  content: [
    {
      element: "#manual_feed",
      title: "Alimentacion manual",
      intro: "En caso de que fallen los alimentadores, y necesites guardar los registros de alimentación.",
      position: "bottom",
    },
    {
      element: "#kg_feed",
      title: "Kilogramos por estanque",
      intro: "Cantidad total en kg a alimentar en estanque.",
      position: "bottom",
    },
    {
      element: "#kgHec",
      title: "Kilogramos por hectáreas",
      intro: "Cantidad total en kg a alimentar por hectárea.",
      position: "bottom",
    },
    {
      element: "#diet_feed",
      title: "Dietas",
      intro: "Selecciona de la lista de dietas creadas anteriormente.",
      position: "bottom",
    },
    {
      element: "#recomendation",
      title: "Recomendación",
      intro: "Basado en el charoleo, se te dará una recomendación para alimentar el estanque.",
      position: "bottom",
    },
    {
      element: "#food_feed",
      title: "Alimento",
      intro: "Selecciona el alimento que usarás.",
      position: "bottom",
    },
    {
      element: "#refeed_feed",
      title: "Reenviar racion",
      intro: "Envía la ultima configuración registrada.",
      position: "bottom",
    },
    {
      element: "#test_feed",
      title: "Test",
      intro: "Verifica si hay conectividad con la alimentadora.",
      position: "bottom",
    },
    {
      element: "#send_feed",
      title: "Enviar racion",
      intro: "Finaliza el proceso y envía la configuración de racion que haz realizado.",
      position: "bottom",
    },
  ],
};

export const alertSection = {
  label: "help.controls.title",
  icon: "fas fa-gamepad",
  content: [
    {
      element: "#power",
      title: "Power",
      intro: "Enciende y apaga la alimentadora",
      position: "bottom",
    },
    {
      element: "#dosif",
      title: "Dosificador",
      intro: "Observa el estado del dosificador.",
      position: "bottom",
    },
    {
      element: "#asper",
      title: "Aspersor",
      intro: "Observa el estado del aspersor.",
      position: "bottom",
    },
    {
      element: "#com",
      title: "Comunicación",
      intro: "La comunicación de la alimentadora con el servidor.",
      position: "bottom",
    },
    {
      element: "#reboot",
      title: "Reboot",
      intro: "Reinicios de la alimentadora.",
      position: "bottom",
    },
    {
      element: "#battery",
      title: "Batería",
      intro: "Batería que tiene la alimentadora, al hacer click mostrará la gráfica de comportamiento.",
      position: "bottom",
    },
    {
      element: "#panel",
      title: "Panel",
      intro: "Estado del panel, al hacer click mostrará la gráfica de comportamiento.",
      position: "bottom",
    },
    {
      element: "#ration",
      title: "Ración de alimentación",
      intro: "Observa si la alimentadora tiene ración, las condiciones de ellas, la dieta que usa y datos de la alimentadora en general.",
      position: "bottom",
    },
  ]
}

export const parametryAlertSection = {
  label: "help.values.title",
  icon: "fas fa-box",
  content: [
    {
      element: "#events",
      title: "Eventos",
      intro: "Son los procesos por el cual atraviesa el estanque cuando se dio de alta la parametría.",
      position: "bottom",
    },
    {
      element: "#type",
      title: "Tipo",
      intro: "El tipo de alerta que se haya generado al momento de generar la parametría, también indica si fue a la alza o a la baja dependiendo a la configuración.",
      position: "bottom",
    },
    {
      element: "#value",
      title: "Valor registrado",
      intro: "Indica el valor que se registró, también el mínimo y máximo configurado.",
      position: "bottom",
    },
  ]
}

export const foodResumen = {
  label: "help.feed-resumen.title",
  icon: "fas fa-cookie",
  content: [
    {
      element: "#today",
      title: "Alimento de hoy",
      intro: "Muestra todo el alimentadto dado este día.",
      position: "bottom",
    },
    {
      element: "#todayha",
      title: "Alimento de hoy por hectárea",
      intro: "Muestra todo el alimentado dado en kg por todas las hectáreas.",
      position: "bottom",
    },
    {
      element: "#week",
      title: "Alimento de la semana",
      intro: "Muestra todo el alimento que se suministro en la semana corriendo.",
      position: "bottom",
    },
    {
      element: "#total",
      title: "Alimento acumulado",
      intro: "Todo el alimento que se ha suministrado en el ciclo.",
      position: "bottom",
    },
  ]
}

export const biometry = {
  label: "help.biometry.title",
  icon: "fas fa-low-vision",
  content: [
    {
      element: "#growth",
      title: "Crecimiento del camaron",
      intro: "Promedio en gramos del crecimiento basado en las biometrías.",
      position: "bottom",
    },
    {
      element: "#survival",
      title: "Sobrevivencia",
      intro: "Promedio de sobrevivencia en porcentaje de lo que haz sembrado en el ciclo.",
      position: "bottom",
    },
    {
      element: "#fca",
      title: "Factor de conversión alimenticia",
      intro: "Es una medida del peso del camarón producido por kg. de alimento abastecido.",
      position: "bottom",
    },
  ]
}

export const alerts = {
  label: "help.alerts.title",
  icon: "fas fa-bell",
  content: [
    {
      element: "#sprinkler",
      title: "Aspersor",
      intro: "Es el dispositivo que distribuye/arroja el alimento, y muestra la cantidad de alertas.",
      position: "bottom",
    },
    {
      element: "#dispenser",
      title: "Dosificador",
      intro: "Es el dispositivo que suelta el alimento, y muestra la cantidad de alertas.",
      position: "bottom",
    },
    {
      element: "#battery",
      title: "Batería",
      intro: "Muestra cuantos alimentadores tienen alertas en sus baterías",
      position: "bottom",
    },
    {
      element: "#panel",
      title: "Panel solar",
      intro: "Muestra cuantos alimentadores tienen alertas en sus paneles solares",
      position: "bottom",
    },
    {
      element: "#conection",
      title: "Conexión con servidores",
      intro: "Muestra cuantos alimentadores tienen alertas de conexión, porque no llega conectividad con los servidores",
      position: "bottom",
    },
    {
      element: "#restarts",
      title: "Reinicios",
      intro: "Muestra cuantos alimentadores tienen reinicios seguidos.",
      position: "bottom",
    },
    {
      element: "#today-alerts",
      title: "Total de hoy",
      intro: "Total de alertas del día de hoy que se han generado.",
      position: "bottom",
    },
    {
      element: "#weekly-alerts",
      title: "Promedio semanal",
      intro: "Promedio de alertas que se generan semanalmente, durante el ciclo.",
      position: "bottom",
    },
  ]
}

export const parametry = {
  label: "help.parametries.title",
  icon: "fas fa-low-vision",
  content: [
    {
      element: "#oxygen",
      title: "Oxigeno",
      intro: "Oxigeno registrado del día de hoy y su promedio del ciclo.",
      position: "bottom",
    },
    {
      element: "#temprature",
      title: "Tempratura",
      intro: "Temperatura registrada del día de hoy y su promedio del ciclo.",
      position: "bottom",
    },
    {
      element: "#salinity",
      title: "Salinidad",
      intro: "Salinidad registrada del día de hoy y su promedio del ciclo.",
      position: "bottom",
    },
    {
      element: "#today-parametry",
      title: "Total del día",
      intro: "Total de parametrías registradas hoy.",
      position: "bottom",
    },
    {
      element: "#weekly-parametry",
      title: "Promedio de la semana",
      intro: "Promedio de parametrías por semana desde el inicio del ciclo.",
      position: "bottom",
    },
  ]
}

export const feeders = {
  label: "help.feeders.title",
  icon: "fas fa-shower",
  content: [
    {
      element: "#active",
      title: "Alimentadores activos",
      intro: "Todal de alimentadores trabajando, o activos.",
      position: "bottom",
    },
    {
      element: "#total-feeders",
      title: "Alimentadores dados de alta",
      intro: "Todal de alimentadores dados de alta en su cuenta.",
      position: "bottom",
    },
  ]
}

export const climate = {
  label: "help.climate.title",
  icon: "fas fa-cloud-sun",
  content: [
    {
      element: "#temperature-climate",
      title: "Temperatura",
      intro: "Temperatura del día, mínima y máxima.",
      position: "bottom",
    },
    {
      element: "#humidity",
      title: "Humedad",
      intro: "Humedad relativa del ambiente, esta depende de la temperatura y la presión del sistema.",
      position: "bottom",
    },
    {
      element: "#uv",
      title: "Rayos UV",
      intro: "Baja: <2, Moderada: 3 a 5, Alta: 6 a 7, Muy alta: 8 a 10, Extremadamente alta: 11+",
      position: "bottom",
    },
    {
      element: "#direction",
      title: "Dirección del viento",
      intro: "La dirección del viento está indicada por grados.",
      position: "bottom",
    }
  ]
}

export const farmMap = {
  label: "help.map.title",
  icon: "fas fa-map",
  content: [
    {
      element: "#map",
      title: "Mapa",
      intro: "Observa en el mapa, los estanques y las alimentadoras, los colores indican si están activas/inactivas.",
      position: "bottom",
    },
    {
      element: "#expand",
      title: "Expandir mapa",
      intro: "Haz esta pantalla más grande para tener más control en esta área.",
      position: "bottom",
    },
    {
      element: "#change-view",
      title: "Cambiar vista",
      intro: "Cambia la vista a lo que más te acomode, hace que el mapa sea más grande y el panel de alimentación más pequeño.",
      position: "bottom",
    },
  ],
}

export const multipleFeed = {
  label: "help.multiple-feed.title",
  icon: "fas fa-utensils",
  content: [
    {
      element: "#selection",
      title: "Selección",
      intro: "Selecciona los estanques los que quieras alimentar.",
      position: "bottom",
    },
    {
      element: "#options",
      title: "Ver opciones",
      intro: "Da click en este botón, para proceder a los controles y por ultimo selecciona la opción de alimentar.",
      position: "bottom",
    }
  ],
}

export const harvestPond = {
  label: "help.harvest.title",
  icon: "fas fa-boxes",
  content: [
    {
      element: "#selection",
      title: "Selección",
      intro: "Selecciona los estanques los que quieras alimentar.",
      position: "bottom",
    },
    {
      element: "#options",
      title: "Ver opciones",
      intro: "Da click en este botón, para proceder a los controles y por ultimo selecciona la opción de cosechar.",
      position: "bottom",
    },
  ],
}

export const preharvestPond = {
  label: "help.preharvest.title",
  icon: "fas fa-box-open",
  content: [
    {
      element: "#selection",
      title: "Selección",
      intro: "Selecciona los estanques los que quieras alimentar.",
      position: "bottom",
    },
    {
      element: "#options",
      title: "Ver opciones",
      intro: "Da click en este botón, para proceder a los controles y por ultimo selecciona la opción de precosechar.",
      position: "bottom",
    },
  ],
}