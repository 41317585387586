import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FramesService } from "../../../routes/services/frames.service";

@Component({
  selector: "app-comparation-weight-sensor",
  templateUrl: "./comparation_weight_sensor.html",
  styleUrls: ["./comparation_weight_sensor.scss"],
})
export class ComparationWeightSensorComponent implements OnInit {
  @Input() feeder: any = null;
  calibrate = "";
  section = "configuration";
  constructor(public frameService: FramesService) {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    if (this.feeder.option_type) {
      this.section = "";
    }
  }
  onClickVirtualWeight(val) {}
  turnOnOff(turn) {
    if (turn) {
      this.frameService
        .Frame("sensor_weight_turn_off", `?id_in=${this.feeder.id}`)
        .subscribe((item) => {});
    } else {
      this.frameService
        .Frame("sensor_weight_turn_on", `?id_in=${this.feeder.id}`)
        .subscribe((item) => {});
    }
  }
  getDataRequest() {
    this.frameService
      .Frame(`sensor_weight_data_request`, `?id_in=${this.feeder.id}`)
      .subscribe((item) => {});
  }
  setCalibrate() {
    this.frameService
      .Frame(`sensor_weight_calibration`, `?id_in=${this.feeder.id}`, {
        grams: this.calibrate,
      })
      .subscribe((item) => {});
  }
  setTarar() {
    this.frameService
      .Frame(`sensor_weight_tara`, `?id_in=${this.feeder.id}`)
      .subscribe((item) => {});
  }
  setFrequencyMinute() {
    this.frameService
      .Frame(
        `sensor_weight_request_frequency_every_minute`,
        `?id_in=${this.feeder.id}`
      )
      .subscribe((item) => {});
  }
  changeSection(section) {
    this.section = section;
  }
}
