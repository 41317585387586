import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Uris } from "./Uris";
import { GlobalFunction } from "./globalFuntion";

@Injectable({
  providedIn: "root",
})
export class ParametricsService {
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
    public router: Router,
    public globalFunction: GlobalFunction
  ) {}

  postParametricRecord(pondID, value) {
    return this.http
      .post(
        `${Uris.PONDS}${pondID}/parametric_record/`,
        value,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Parametrías registrada correctamente`
          );
          return resp;
        })
      );
  }

  deleteParametricRecord(recordId) {
    return this.http
      .delete(`${Uris.PARAMETRIC}${recordId}/`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          this.storageService.animation(
            "success",
            "Éxito",
            `Parametría eliminada correctamente`
          );
          return resp;
        })
      );
  }

  getParametricRecord(pondID, query) {
    return this.http
      .get(
        `${Uris.PONDS}${pondID}/parametric_record_list/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getParametrics(query) {
    return this.http
      .get(`${Uris.PARAMETRIC}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getParametricsAlerts(query) {
    return this.http
      .get(`${Uris.PARAMETRIC_ALERTS}${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getParametricsGraphs(pondID, query) {
    return this.http
      .get(
        `${Uris.PONDS}${pondID}/parametric_record_list_graph/${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getParametricEvents() {
    return this.http
      .get(`${Uris.PARAMETRIC_EVENT}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  postParametricEvent(value) {
    return this.http
      .post(`${Uris.PARAMETRIC_EVENT}`, value, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  exportParametricRecords(query) {
    return this.http
      .get(`${Uris.PARAMETRIC}export/${query}`, this.storageService.headers())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getParametricPreference(query) {
    return this.http
      .get(
        `${Uris.PARAMETRIC_PREFERENCE}${query}`,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  patchParametricPreferenceChangefarm(id, body) {
    return this.http
      .patch(
        `${Uris.PARAMETRIC_PREFERENCE}${id}/change-farm/`,
        body,
        this.storageService.headers()
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
