import { GatewayInstallationComponent } from "../routes/Admin/clients/network-config/gateway/installation/installation.component";
import { GatewayFormComponent } from "../routes/Admin/clients/network-config/gateway/form/form.component";
import { NetworkGatewayComponent } from "../routes/Admin/clients/network-config/gateway/gateway";
import { SettingsComponent } from "../routes/Clients/settings/settings.component";
import { UserblockComponent } from "./sidebar/userblock/userblock.component";
import { NavsearchComponent } from "./header/navsearch/navsearch.component";
import { UserblockService } from "./sidebar/userblock/userblock.service";
import { OffsidebarComponent } from "./offsidebar/offsidebar.component";
import { ComponentsModule } from "../components/components.module";
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { environment } from "../../environments/environment";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LayoutComponent } from "./layout.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalsComponent } from "./modals/modals";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: environment.apikeyMap,
      libraries: ["drawing", "geometry"],
    }),
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    ComponentsModule,
    NgbModule,
    TranslateModule
  ],
  providers: [UserblockService, GoogleMapsAPIWrapper],
  declarations: [
    GatewayInstallationComponent,
    NetworkGatewayComponent,
    GatewayFormComponent,
    OffsidebarComponent,
    UserblockComponent,
    NavsearchComponent,
    SettingsComponent,
    SidebarComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    ModalsComponent,
  ],
  exports: [
    OffsidebarComponent,
    UserblockComponent,
    NavsearchComponent,
    SidebarComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    ModalsComponent,
  ],
})
export class LayoutModule {}
